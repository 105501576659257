import { IThreeDModel } from "../types/BaseTypes"
import { getThreeDModel } from "../utils/API/APIThreeDModels"

export default class ThreeDModelsCache {
    private static instance: ThreeDModelsCache
    // TODO celou tridu otypovat
    private cache: any

    public static getInstance = (): ThreeDModelsCache => {
        if (!ThreeDModelsCache.instance) {
            ThreeDModelsCache.instance = new ThreeDModelsCache()
        }
        return ThreeDModelsCache.instance
    }

    public constructor() {
        this.cache = require("lru-cache-promise")()
    }

    public getModel = (id: number): Promise<IThreeDModel> => {
        return this.cache.getAsync(id, getThreeDModel)
    }
}
