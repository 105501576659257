import { Button, Popup, ScrollView } from "devextreme-react"
import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback, useMemo, useState } from "react"
import FunctionsList from "../../componentsSetup/common/FunctionsList"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import TabExpressionData from "../../componentsSetup/common/TabExpressionData"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { useForceUpdate } from "../../utils/CustomHooks"
import { TextSize, TextSizeSettings } from "../Text/TextEditor"

export type TableParameters = BaseParams &
    TextSize & {
        expression: string
        userDefinedRange: boolean
        paging: string
        export: boolean
        exportToPdf: boolean
        selection: boolean
        columnChooser: boolean
        // searching zatim vypnut z duvodu noveho designu tabulky
        // searching: boolean
        oneSearching: boolean
        onExportingExcelExpression: string
        onExportingPdfExpression: string
    }

export const getTableBasicConfigurations = () => {
    return [{}]
}

function TableEditor({ parameters, formData }: EditorProps<TableParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Datový zdroj">
                <div className="component-setup-container">
                    <TextSizeSettings parameters={parameters} />
                    <TabExpressionData parameters={parameters} />
                    <Form>
                        <GroupItem caption="Funkce">
                            <FunctionsList listOwner={formData} />
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="Ostatní">
                <TabOthers parameters={parameters} />
            </Item>
        </TabPanel>
    )
}

type TabOthersProps = {
    parameters: TableParameters
}

function TabOthers({ parameters }: TabOthersProps) {
    const [forceUpdate] = useForceUpdate()

    const [popupVisible, setPopupVisible] = useState(false)

    const showPopup = useCallback(() => setPopupVisible(true), [])

    const hidePopup = useCallback(() => setPopupVisible(false), [])

    const exportCommonOptions = useMemo(
        () => ({
            onValueChanged: forceUpdate
        }),
        [forceUpdate]
    )

    return (
        <div className="component-setup-container">
            <Form formData={parameters}>
                <GroupItem>
                    <SimpleItem
                        dataField="paging"
                        editorType="dxTextBox"
                        helpText="Zadejte hodnoty ve tvaru: 5, 10, 25"
                    >
                        <Label text="Stránkování" />
                    </SimpleItem>
                    <SimpleItem dataField="oneSearching" editorType="dxSwitch">
                        <Label text="Fulltextové vyhledávání" />
                    </SimpleItem>
                    {/* <SimpleItem dataField="searching" editorType="dxSwitch">
                        <Label text="Vyhledávání podle sloupců" />
                    </SimpleItem> */}
                    <SimpleItem dataField="selection" editorType="dxSwitch">
                        <Label text="Označování řádků (také pro export)" />
                    </SimpleItem>
                    <SimpleItem dataField="columnChooser" editorType="dxSwitch">
                        <Label text="Možnost výběru sloupců" />
                    </SimpleItem>
                    <GroupItem caption="Export do Excelu a PDF">
                        <SimpleItem
                            dataField="export"
                            editorType="dxSwitch"
                            editorOptions={exportCommonOptions}
                        >
                            <Label text="Export do excelu" />
                        </SimpleItem>
                        <SimpleItem
                            visible={parameters.export === true}
                            render={initMonacoEditor(parameters, "onExportingExcelExpression")}
                        >
                            <Label text="Výraz těla funkce onExporting pro excel" />
                        </SimpleItem>
                        <SimpleItem
                            dataField="exportToPdf"
                            editorType="dxSwitch"
                            editorOptions={exportCommonOptions}
                        >
                            <Label text="Export do PDF" />
                        </SimpleItem>
                        <SimpleItem
                            visible={parameters.exportToPdf === true}
                            render={initMonacoEditor(parameters, "onExportingPdfExpression")}
                        >
                            <Label text="Výraz těla funkce onExporting pro PDF" />
                        </SimpleItem>
                        <GroupItem
                            visible={parameters.exportToPdf === true || parameters.export === true}
                        >
                            <Popup
                                visible={popupVisible}
                                onHiding={hidePopup}
                                contentRender={docRender}
                            />
                            <Button text="Dokumentace k onExporting funkci" onClick={showPopup} />
                        </GroupItem>
                    </GroupItem>
                </GroupItem>
            </Form>
        </div>
    )
}

const examplesJs = {
    exampleBefore: `const onExporting = (e) => {
    const doc = new jsPDF()
    exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        columnWidths: [40, 40, 30, 30, 40],
        customizeCell({ gridCell, pdfCell }) {
            if (gridCell.rowType === "data" && gridCell.column.dataField === "Phone") {
                pdfCell.text = pdfCell.text.replace(/(\\d{3})(\\d{3})(\\d{4})/, "($1) $2-$3")
            } else if (gridCell.rowType === "group") {
                pdfCell.backgroundColor = "#BEDFE6"
            } else if (gridCell.rowType === "totalFooter") {
                pdfCell.font.style = "italic"
            }
        },
        customDrawCell(options) {
            const { gridCell, pdfCell } = options
            if (gridCell.rowType === "data" && gridCell.column.dataField === "Website") {
                options.cancel = true
                doc.setFontSize(11)
                doc.setTextColor("#0000FF")
                const textHeight = doc.getTextDimensions(pdfCell.text).h
                doc.textWithLink(
                    "website",
                    options.rect.x + pdfCell.padding.left,
                    options.rect.y + options.rect.h / 2 + textHeight / 2,
                    { url: pdfCell.text }
                )
            }
        }
    }).then(() => {
        doc.save("Companies.pdf")
    })
}`,
    exampleAfter: `self.exportDataGrid({
    jsPDFDocument: self.doc,
    component: self.component,
    columnWidths: [40, 40, 30, 30, 40],
    customizeCell({ gridCell, pdfCell }) {
        if (gridCell.rowType === "data" && gridCell.column.dataField === "Phone") {
            pdfCell.text = pdfCell.text.replace(/(\\d{3})(\\d{3})(\\d{4})/, "($1) $2-$3")
        } else if (gridCell.rowType === "group") {
            pdfCell.backgroundColor = "#BEDFE6"
        } else if (gridCell.rowType === "totalFooter") {
            pdfCell.font.style = "italic"
        }
    },
    customDrawCell(options) {
        const { gridCell, pdfCell } = options
        if (gridCell.rowType === "data" && gridCell.column.dataField === "Website") {
            options.cancel = true
            self.doc.setFontSize(11)
            self.doc.setTextColor("#0000FF")
            const textHeight = doc.getTextDimensions(pdfCell.text).h
            self.doc.textWithLink(
                "website",
                options.rect.x + pdfCell.padding.left,
                options.rect.y + options.rect.h / 2 + textHeight / 2,
                { url: pdfCell.text }
            )
        }
    }
}).then(() => {
    self.doc.save(p.props.name + ".pdf")
})`,
    exampleBeforeExcel: `const onExporting = (e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("CountriesPopulation")
    exportDataGrid({
        component: e.component,
        worksheet,
        topLeftCell: { row: 4, column: 1 }
    }).then((cellRange) => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)
        headerRow.getCell(1).value = "Country Area, Population, and GDP Structure"
        headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 }
        headerRow.getCell(1).alignment = { horizontal: "center" }
        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).value = "www.wikipedia.org"
        footerRow.getCell(1).font = { color: { argb: "BFBFBF" }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: "right" }
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                "CountriesPopulation.xlsx"
            )
        })
    })
}`,
    exampleAfterExcel: `const worksheet = self.workbook.addWorksheet("CountriesPopulation")

self.exportDataGrid({
    component: self.component,
    worksheet,
    topLeftCell: { row: 4, column: 1 }
}).then((cellRange) => {
    // header
    const headerRow = worksheet.getRow(2)
    headerRow.height = 30
    worksheet.mergeCells(2, 1, 2, 8)

    headerRow.getCell(1).value = "Country Area, Population, and GDP Structure"
    headerRow.getCell(1).font = { name: "Segoe UI Light", size: 22 }
    headerRow.getCell(1).alignment = { horizontal: "center" }

    // footer
    const footerRowIndex = cellRange.to.row + 2
    const footerRow = worksheet.getRow(footerRowIndex)
    worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)

    footerRow.getCell(1).value = "www.wikipedia.org"
    footerRow.getCell(1).font = { color: { argb: "BFBFBF" }, italic: true }
    footerRow.getCell(1).alignment = { horizontal: "right" }

    self.workbook.xlsx.writeBuffer().then((buffer) => {
        self.saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            p.props.name + ".xlsx"
        )
    })
})`
}

const docRender = () => (
    <ScrollView>
        U komponenty tabulka je možné přepsat funkci onExporting, která se volá v případě exportu do
        PDF, nebo XLSX.
        <br></br>
        Do výrazů "Výraz těla funkce onExporting" vyplňte pouze tělo funkce, nikoliv celou funkci.
        <br></br>
        Pokud funkci nevyplníte, tak se zavolá defaultní implementace bez dalších parametrů.
        <br></br>
        <br></br>
        <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://js.devexpress.com/React/Demos/WidgetsGallery/Demo/DataGrid/PDFCellCustomization/MaterialBlueLight/"
        >
            Příklad funkce onExporting pro export do PDF
        </a>
        <br></br>
        Přiložený odkaz je rovněž celou dokumentací ke knihovně DevExtreme. Z této knihovny
        používáme i naši tabulku - DataGrid. V odkazu je nutné otevřít soubor App.js pro standardní
        JavaScript, nebo App.tsx pro TypeScript.
        <br></br>
        Následně vyhledejte funkci <b>onExporting</b>, měla by vypadat např. nějak takto:
        <Form>
            <SimpleItem render={initMonacoEditor(examplesJs, "exampleBefore", true, 80, true)}>
                <Label text="To co najdete v dokumentaci" />
            </SimpleItem>
            <SimpleItem render={initMonacoEditor(examplesJs, "exampleAfter", true, 80, true)}>
                <Label text="Jak je to potřeba přepsat do výrazu" />
            </SimpleItem>
        </Form>
        <br></br>
        Jinými slovy:
        <ul>
            <li>Napište jen tělo funkce, nikoliv hlavičku a závorky.</li>
            <li>
                Instanci <b>new jsPDF()</b> obdržíte v self.doc, musíte tedy všechny výskyty{" "}
                <b>doc</b> nahradit za <b>self.doc</b>
            </li>
            <li>
                Obdobně pro proměnnou <b>component</b> a funkci <b>exportDataGrid</b>
            </li>
        </ul>
        <br></br>
        Obdobně budete postupovat pro možnost úpravy exportovací funkce{" "}
        <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://js.devexpress.com/React/Demos/WidgetsGallery/Demo/DataGrid/ExcelJSHeaderAndFooter/MaterialBlueLight/"
        >
            do Excelu
        </a>
        , např.:
        <Form>
            <SimpleItem render={initMonacoEditor(examplesJs, "exampleBeforeExcel", true, 80, true)}>
                <Label text="To co najdete v dokumentaci" />
            </SimpleItem>
            <SimpleItem render={initMonacoEditor(examplesJs, "exampleAfterExcel", true, 80, true)}>
                <Label text="Jak je to potřeba přepsat do výrazu" />
            </SimpleItem>
        </Form>
        <br></br>
        Nakonec ještě seznam knihoven sloužících pro práci s PDF a Excelem, které lze najít i na
        výše přiložených odkazech v dokumentaci DevExtreme:
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/parallax/jsPDF">
            jsPDF - pro export do PDF
        </a>
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/exceljs/exceljs">
            excelJS - pro export do Excelu
        </a>
    </ScrollView>
)

export default memo(TableEditor)
