import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useMemo } from "react"
import { $enum } from "ts-enum-util"
import { SLIDER_TOOLTIP_SETTINGS } from "../../component/EditorTypes"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { useForceUpdate } from "../../utils/CustomHooks"
import { TankColor } from "../Tank/TankEditor"

export enum PipeType {
    ROUND = "round",
    T = "T",
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical"
}

export enum PipeRoundType {
    first = 1,
    second = 2,
    third = 3,
    fourth = 4
}

export type PipeParameters = BaseParams & {
    expression: string
    size: number
    type: PipeType
    roundType: PipeRoundType
    color: TankColor
}

export const PIPE_SIZES = [6, 8, 10, 14, 18, 22, 28, 34, 40, 50, 60, 70, 80, 90, 100]

const roundTypes = [1, 2, 3, 4]

export const PIPE_SIZE_OPTIONS = {
    min: 1,
    max: PIPE_SIZES.length,
    tooltip: SLIDER_TOOLTIP_SETTINGS
}

export const PIPE_COLOR_OPTIONS = {
    items: Object.keys(TankColor)
}

const roundTypeOptions = {
    items: roundTypes
}

export const getPipeBasicConfigurations = () => {
    return $enum(PipeType)
        .getValues()
        .map((e) => ({
            type: e,
            roundType: PipeRoundType.first
        }))
}

function PipeEditor({ parameters }: EditorProps<PipeParameters>) {
    const [forceUpdate] = useForceUpdate()

    const typeOptions = useMemo(
        () => ({
            items: $enum(PipeType).getValues(),
            onValueChanged: forceUpdate
        }),
        [forceUpdate]
    )

    parameters.color = parameters.color ?? TankColor.BLUE
    parameters.size = parameters.size ?? 3
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                                <Label text="Výraz pro běh" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="color"
                                editorType="dxSelectBox"
                                editorOptions={PIPE_COLOR_OPTIONS}
                            >
                                <Label text="Barva" />
                            </SimpleItem>
                            <GroupItem colCount={2}>
                                <SimpleItem
                                    dataField="size"
                                    editorType="dxSlider"
                                    editorOptions={PIPE_SIZE_OPTIONS}
                                >
                                    <Label text="Velikost" />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="type"
                                    editorType="dxSelectBox"
                                    editorOptions={typeOptions}
                                >
                                    <Label text="Typ" />
                                </SimpleItem>
                            </GroupItem>
                            <SimpleItem
                                visible={
                                    parameters.type === PipeType.T ||
                                    parameters.type === PipeType.ROUND
                                }
                                dataField="roundType"
                                editorType="dxSelectBox"
                                editorOptions={roundTypeOptions}
                            >
                                <Label text="Otočení" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(PipeEditor)
