import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export type CentrifugeParameters = BaseParams & {
    expression: string
    expressionError: string
}

export const getCentrifugeBasicConfigurations = () => {
    return [{}]
}

function CentrifugeEditor({ parameters }: EditorProps<CentrifugeParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                                <Label text="Výraz pro běh"></Label>
                            </SimpleItem>
                            <SimpleItem render={initMonacoEditor(parameters, "expressionError")}>
                                <Label text="Výraz poruchy"></Label>
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(CentrifugeEditor)
