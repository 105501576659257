import { RefObject } from "react"

import DataGrid, {
    Column,
    Editing,
    Form,
    Lookup,
    Pager,
    Paging,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"
import { IDBConnection, IGroup, IModbusConnection } from "../types/BaseTypes"
import { gqlRead } from "../utils/APIUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"

type ModbusState = CollectionState<IDBConnection> & {
    groups: Array<IGroup>
}

class ModbusConnections extends BaseCollection<IModbusConnection, CollectionProps, ModbusState> {
    constructor(props: CollectionProps) {
        super(props, {
            entityName: "modbusConnection",
            subEntitiesToIds: ["group"],
            copyButton: true,
            title: "Modbus spojení"
        })
        this.state = {
            ...this.state,
            groups: []
        }
    }

    protected getEntities = (): Promise<Array<IGroup>> => {
        return gqlRead({
            modbusConnections: {
                id: true,
                name: true,
                address: true,
                port: true,
                maxConnections: true,
                waitDuration: true,
                responseTimeoutMs: true,
                group: {
                    id: true,
                    name: true
                }
            },
            groups: {
                id: true,
                name: true
            }
        }).then((r) => {
            this.setState({ groups: r.groups })
            return r.modbusConnections
        })
    }

    onInitNewRow = (object: any) => {
        object.data.maxConnections = 1
        object.data.waitDuration = 3
        object.data.responseTimeoutMs = 5000
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <DataGrid
                ref={ref}
                dataSource={dataSource}
                showBorders={true}
                columnHidingEnabled={true}
                onEditorPreparing={this.onEditorPreparing}
                onToolbarPreparing={this.onToolbarPreparing}
                onSelectionChanged={this.onSelectionChanged}
                onInitNewRow={this.onInitNewRow}
                height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
            >
                <Paging enabled={false} />
                <Pager visible={true} displayMode="full" showInfo={true} />
                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
                <SearchPanel visible={true} />
                <Editing
                    mode="form"
                    useIcons={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                >
                    <Form>
                        <Item itemType="group" colCount={3} colSpan={2}>
                            <Item itemType="group" caption="Základní">
                                <Item dataField="name" />
                                <Item dataField="group" />
                                <Item dataField="responseTimeoutMs" />
                            </Item>
                            <Item itemType="group" caption="Umístění">
                                <Item dataField="address" />
                                <Item dataField="port" editorType="dxNumberBox" />
                            </Item>
                            <Item itemType="group" caption="Pool spojení">
                                <Item dataField="maxConnections" editorType="dxNumberBox" />
                                <Item
                                    dataField="waitDuration"
                                    editorType="dxNumberBox"
                                    helpText="Maximální čas v sekundách, jaký vlákno čeká na přidělení spojení z poolu, který je aktuálně vyčerpán."
                                />
                            </Item>
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                <Column dataField="name" caption="Název" />
                <Column dataField="group" caption="Skupina">
                    <Lookup dataSource={this.state.groups} displayExpr="name" valueExpr="id" />
                </Column>
                <Column dataField="address" caption="Adresa" />
                <Column dataField="port" caption="Port" />
                <Column dataField="responseTimeoutMs" caption="Timeout odpovědi [ms]" />
                <Column dataField="maxConnections" caption="Maximum spojení" />
                <Column dataField="waitDuration" caption="Čas čekání" />
            </DataGrid>
        )
    }
}

export default ModbusConnections
