import { createContext, memo, useContext, useMemo } from "react"

export interface IReportPathContext {
    reportPath: Array<string>
}

export const ReportPathContext = createContext<IReportPathContext>({ reportPath: [] })

type Props = {
    children: JSX.Element
    guid: string | undefined
}

export const ReportPathProvider = memo(({ children, guid }: Props) => {
    const parentContext = useContext(ReportPathContext)
    const parentPath = parentContext.reportPath

    const context = useMemo(
        () => ({
            reportPath: guid ? [...parentPath, guid] : []
        }),
        [guid, parentPath]
    )

    return <ReportPathContext.Provider value={context}>{children}</ReportPathContext.Provider>
})
