import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import React from "react"
import { IComponent, IReport } from "../../types/BaseTypes"
import { FunctionType, IFunction } from "../../types/FunctionsType"
import ComponentUtils from "../../utils/ComponentUtils"
import ActionsTriggerEditor from "./ActionsTriggerEditor"
import TestFunction from "./functions/TestFunction"
import ListPopupEditor from "./ListPopupEditor"
import ParametersEditor from "./ParametersEditor"

type FunctionsListProps = {
    listOwner: IComponent | IReport
}

type FunctionsState = {}

class FunctionsList extends React.Component<FunctionsListProps, FunctionsState> {
    private functionRender = (item: any) => (
        <GroupItem>
            <FunctionsForm item={item} owner={this.props.listOwner} />
        </GroupItem>
    )

    private functionCopiedFilter = (item: IFunction) => {
        ComponentUtils.newIdentityWithoutGuid(item)
    }

    render() {
        return (
            <ListPopupEditor
                listOwner={this.props.listOwner}
                idProperty="guid"
                guid={true}
                listName="functions"
                itemCopiedFilter={this.functionCopiedFilter}
                formItemContent={this.functionRender}
            />
        )
    }
}

export default FunctionsList

type FunctionsProps = {
    item: IFunction
    owner: IComponent | IReport
}

class FunctionsForm extends React.Component<FunctionsProps> {
    static refreshIntervals = [
        1,
        2,
        3,
        5,
        10,
        20,
        30,
        60,
        120,
        180,
        300,
        600,
        1800,
        3600,
        7200,
        10800,
        21600,
        43200,
        86400
    ]

    private intervalOptions: any

    constructor(props: FunctionsProps) {
        super(props)

        this.intervalOptions = {
            items: FunctionsForm.refreshIntervals,
            showClearButton: true
        }
    }

    private typeSelectOptions = {
        items: Object.keys(FunctionType),
        onValueChanged: () => this.forceUpdate()
    }

    private renderFunctionTypeContent = (item: IFunction) => {
        let parameters = item.parameters
        if (!parameters) {
            parameters = item.parameters = {}
        }

        return (
            <TabPanel animationEnabled={true}>
                <Item title="Nastavení">
                    <TestFunction function={item} />
                </Item>
                <Item title="Parametry" visible={item.type !== FunctionType.READ_MODBUS}>
                    <ParametersEditor listOwner={parameters} />
                </Item>
                <Item title="Akce" visible={item.type !== FunctionType.SET_VARIABLE}>
                    <div className="component-setup-container">
                        <Form formData={parameters}>
                            <GroupItem colCount={2}>
                                <GroupItem caption="OnSuccess">
                                    <ActionsTriggerEditor
                                        listOwner={parameters}
                                        listName="onSuccessActions"
                                    />
                                </GroupItem>
                                <GroupItem caption="OnError">
                                    <ActionsTriggerEditor
                                        listOwner={parameters}
                                        listName="onErrorActions"
                                    />
                                </GroupItem>
                            </GroupItem>
                        </Form>
                    </div>
                </Item>
            </TabPanel>
        )
    }

    render() {
        const item = this.props.item

        return (
            <Form formData={item}>
                <GroupItem>
                    <GroupItem colCount={3}>
                        <SimpleItem dataField="name">
                            <Label text="jméno" />
                        </SimpleItem>
                        <SimpleItem
                            dataField="type"
                            editorType="dxSelectBox"
                            editorOptions={this.typeSelectOptions}
                        >
                            <Label text="Typ" />
                        </SimpleItem>
                        <SimpleItem
                            editorType="dxSelectBox"
                            dataField="parameters.refreshInterval"
                            editorOptions={this.intervalOptions}
                        >
                            <Label text="Interval spuštění [s]" />
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem caption="Doplňující">
                        {this.renderFunctionTypeContent(item)}
                    </GroupItem>
                </GroupItem>
            </Form>
        )
    }
}

export type FunctionProps = {
    item: IFunction
}
