import { forwardRef, memo, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { useContainerVisible, useExpression } from "../../utils/CustomHooks"
import "./FlapComponent.scss"
import { FlapOrientation, FlapParameters } from "./FlapEditor"
import { ReactComponent as Flap } from "./svg/flap.svg"

type Params = FlapParameters

type Props = ComponentProps<Params>

const FlapComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, orientation, setContainerVisible }, ref) => {
        useContainerVisible(setContainerVisible, false)

        const [state] = useExpression(expression, 0)

        const className = useMemo(() => {
            let result =
                "flap " + (orientation === FlapOrientation.VERTICAL ? "flap-vertical " : "")
            switch (Math.round(state ?? 0)) {
                case 1:
                    result += "flap-open"
                    break
                case 2:
                    result += "flap-middle"
                    break
                case 3:
                    result += "flap-error"
                    break
                default:
                    result += "flap-closed"
            }
            return result
        }, [state, orientation])

        return <Flap className={className} />
    }
)

export default memo(FlapComponent)
