import { forwardRef, memo, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { KeyType } from "../../types/KeysTypes"
import { useContainerVisible, useExpression, useImperative } from "../../utils/CustomHooks"
import "./PumpComponent.scss"
import { PumpParameters, PumpType } from "./PumpEditor"
import { ReactComponent as PumpATS } from "./svg/pump-ATS.svg"
import { ReactComponent as PumpScrew } from "./svg/pump-screw.svg"
import { ReactComponent as Pump } from "./svg/pump.svg"

type Params = PumpParameters

type Props = ComponentProps<Params>

const PumpComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, expressionError, type, setContainerVisible }, ref) => {
        useContainerVisible(setContainerVisible, false)

        const [active] = useExpression(expression, false)
        const [error] = useExpression(expressionError, false)

        useImperative(
            ref,
            useMemo(() => {
                return [
                    {
                        keyType: KeyType.SHIFT,
                        messenger: {
                            name: "type",
                            enums: [
                                { enum: PumpType.SLUDGE },
                                { enum: PumpType.ATS },
                                { enum: PumpType.SCREW }
                            ],
                            value: type
                        }
                    }
                ]
            }, [type])
        )

        let className = "pump "
        if (error) {
            className += "pump-error"
        } else if (active) {
            className += "pump-active"
        }

        if (type === PumpType.ATS) {
            return <PumpATS className={className} />
        } else if (type === PumpType.SCREW) {
            return <PumpScrew className={className} />
        } else {
            return <Pump className={className} />
        }
    }
)

export default memo(PumpComponent)
