import { ErrorInfo, PureComponent, ReactNode } from "react"

type Props = {
    children: ReactNode
    name: string
}

type State = {
    hasError: boolean
}

const ERROR_MESSAGE = "V komponentě došlo k chybě:"

class ErrorBoundary extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true }
    }

    private text = () => {
        return `${ERROR_MESSAGE} ${this.props.name}`
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const text = `
        ======================>
        ${this.text()}
        ${error}
        ${errorInfo.componentStack}
        <=======================`

        console.error(text)
    }

    render() {
        const { hasError } = this.state
        const { children } = this.props

        if (hasError) {
            return <span>{this.text()}</span>
        }

        return children
    }
}

export default ErrorBoundary
