import DatabaseManager from "../managers/DatabaseManager"
import { CacheType } from "../utils/LocalStorageUtils"

export default class DatabaseCache {
    public static getCacheFor = async (
        cacheType: CacheType,
        id: string
    ): Promise<any | undefined> => {
        return await DatabaseManager.getInstance().getById(cacheType, id)
    }

    static setCacheFor(cacheType: CacheType, id: string, cache: any) {
        DatabaseManager.getInstance().put(cacheType, id, cache)
    }
}
