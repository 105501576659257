import { forwardRef, memo, useContext } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { useContainerVisible, useExpression } from "../../utils/CustomHooks"
import "./IFrameComponent.scss"
import { IFrameParameters } from "./IFrameEditor"

type Params = IFrameParameters

type Props = ComponentProps<Params>

const IFrameComponent = forwardRef<IBaseComponent, Props>(
    ({ urlExpression, setContainerVisible }, ref) => {
        const component = useContext(ComponentContext)?.component!

        useContainerVisible(setContainerVisible, false)

        const [url] = useExpression(urlExpression, "")

        return <iframe src={url} height="100%" title={component.guid}></iframe>
    }
)

export default memo(IFrameComponent)
