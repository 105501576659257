import { Button, Popup, ScrollView } from "devextreme-react"
import { Template } from "devextreme-react/core/template"
import DataGrid, { Column, Editing, Form, Paging } from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"
import { isEqual } from "lodash"
import {
    forwardRef,
    memo,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { gqlPersist, gqlRead } from "../../utils/APIUtils"
import { useEvaluatorRef } from "../../utils/CustomHooks"
import EvalUtils from "../../utils/EvalUtils"
import { standardError, standardSuccess } from "../../utils/NotifyUtils"
import { NotificationsParameters } from "./NotificationsEditor"

type Params = NotificationsParameters

type Props = ComponentProps<Params>

const NotificationsComponent = forwardRef<IBaseComponent, Props>(
    ({ notificationGroupsIds, realSize, refreshInterval, fromDateExpression }, ref) => {
        const component = useContext(ComponentContext)?.component!
        const evaluatorContext = useEvaluatorRef()

        const popupRef = useRef<{ showPopup: () => void }>(null)
        const [tableKey, setTableKey] = useState(0)
        const [data, setData] = useState([])
        const [definitions, setDefinitions] = useState([])

        const loadData = useCallback(() => {
            if (notificationGroupsIds && notificationGroupsIds.length > 0) {
                gqlRead({
                    notificationsForNotificationGroupsIds: {
                        __args: {
                            input: notificationGroupsIds,
                            createdAtGt:
                                evaluatorContext.current?.evaluate(
                                    EvalUtils.correctExpression(fromDateExpression)
                                ) ?? ""
                        },
                        id: true,
                        name: true,
                        createdAt: true,
                        smsResult: true,
                        emailResult: true,
                        message: true,
                        notificationDefinition: {
                            id: true
                        }
                    },
                    notificationDefinitionsForNotificationGroupsIds: {
                        __args: {
                            input: notificationGroupsIds
                        },
                        id: true
                    }
                })
                    .then((r) => {
                        const newData = r.notificationsForNotificationGroupsIds
                        setData((oldData) => {
                            if (isEqual(oldData, newData)) {
                                return oldData
                            }
                            return newData
                        })
                        setDefinitions(r.notificationDefinitionsForNotificationGroupsIds)
                    })
                    .catch((e) => console.log(e))
            }
        }, [notificationGroupsIds, fromDateExpression, evaluatorContext])

        useEffect(() => {
            setTableKey((oldTableKey) => oldTableKey + 1)
        }, [data])

        useEffect(() => {
            if (refreshInterval) {
                const interval = setInterval(() => {
                    loadData()
                }, refreshInterval * 1000)
                loadData()

                return () => clearInterval(interval)
            }
        }, [loadData, refreshInterval])

        const onMenuClick = useCallback(() => {
            popupRef.current?.showPopup()
        }, [])

        const onToolbarPreparing = useCallback(
            (e: any) => {
                e.toolbarOptions.items.unshift({
                    location: "before",
                    template: "tableTitle"
                })
                if (definitions.length > 0) {
                    e.toolbarOptions.items.unshift({
                        location: "after",
                        widget: "dxButton",
                        options: {
                            icon: "contentlayout",
                            text: "Nastavení notifikací",
                            onClick: onMenuClick
                        }
                    })
                }
            },
            [definitions.length, onMenuClick]
        )

        const toolbarItemRender = useCallback(() => {
            return <div className="table-title">{component.name}</div>
        }, [component.name])

        // const onContextMenuPreparing = useCallback(
        //     (e: any) => {
        //         useReportStore2.setState({ componentRightClicked: componentContainer })
        //     },
        //     [componentContainer]
        // )

        return (
            <>
                <DataGrid
                    key={tableKey}
                    onToolbarPreparing={onToolbarPreparing}
                    dataSource={data}
                    showBorders={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    width={realSize.width}
                    height={realSize.height}
                    // onContextMenuPreparing={onContextMenuPreparing}
                >
                    <Column dataField="createdAt" caption="Čas vzniku" dataType="datetime" />
                    <Column dataField="message" caption="Zpráva" dataType="string" />
                    <Column dataField="smsResult" caption="Výsledek SMS" dataType="string" />
                    <Column dataField="emailResult" caption="Výsledek e-mail" dataType="string" />

                    <Paging defaultPageSize={10} />
                    <Template name="tableTitle" render={toolbarItemRender} />
                </DataGrid>
                <NotificationDefPopupEditorMemo
                    ref={popupRef}
                    notificationGroupsIds={notificationGroupsIds}
                />
            </>
        )
    }
)

export default memo(NotificationsComponent)

type DefProps = {
    notificationGroupsIds?: Array<number>
}

const NotificationDefPopupEditor = forwardRef<{ showPopup: () => void }, DefProps>(
    ({ notificationGroupsIds }, ref) => {
        const [popupVisible, setPopupVisible] = useState(false)
        const [notificationDefinitions, setNotificationsDefinitions] = useState([])

        useEffect(() => {
            if (notificationGroupsIds) {
                gqlRead({
                    notificationDefinitionsForNotificationGroupsIds: {
                        __args: {
                            input: notificationGroupsIds
                        },
                        id: true,
                        name: true,
                        message: true,
                        secondMessage: true,
                        telephoneNumbers: true,
                        emails: true,
                        valueColumn: true,
                        dateColumn: true,
                        conditionType: true,
                        number: true
                    }
                })
                    .then((r) => {
                        setNotificationsDefinitions(
                            r.notificationDefinitionsForNotificationGroupsIds
                        )
                    })
                    .catch((e) => console.log(e))
            }
        }, [notificationGroupsIds])

        const showPopup = useCallback(() => {
            setPopupVisible(true)
        }, [])

        useImperativeHandle(ref, () => ({ showPopup }), [showPopup])

        const onHiding = useCallback(() => {
            setPopupVisible(false)
        }, [])

        const onRowUpdated = useCallback((e: any) => {
            const nDef = Object.assign({}, e.data)

            gqlPersist("notificationDefinition", [nDef])
                .then((response) => standardSuccess("Definice notifikace aktualizována."))
                .catch(standardError)
        }, [])

        return (
            <Popup
                title="Editace definic notifikací"
                visible={popupVisible}
                onHiding={onHiding}
                dragEnabled={true}
                height="auto"
                maxHeight="80%"
            >
                <ScrollView height="93%" direction="both">
                    <DataGrid
                        dataSource={notificationDefinitions}
                        showBorders={true}
                        columnAutoWidth={true}
                        keyExpr="id"
                        columnHidingEnabled={true}
                        onRowUpdated={onRowUpdated}
                    >
                        <Paging enabled={true} />
                        <Editing mode="form" allowUpdating={true}>
                            <Form labelLocation="top">
                                <Item itemType="group" caption="Základní">
                                    <Item
                                        editorType="dxTextBox"
                                        dataField="message"
                                        caption="Zpráva"
                                    />
                                    <Item
                                        editorType="dxTextBox"
                                        dataField="secondMessage"
                                        caption="Zpráva ukončení"
                                    />
                                    <Item
                                        editorType="dxNumberBox"
                                        dataField="number"
                                        caption="Hodnota"
                                        helpText="V případě překročení hodnoty se aktivuje notifikace."
                                    />
                                </Item>
                                <Item itemType="group" caption="Odeslat na">
                                    <Item
                                        editorType="dxTextArea"
                                        dataField="emails"
                                        caption="Emaily"
                                        helpText="Oddělte čárkou."
                                    />
                                    <Item
                                        editorType="dxTextArea"
                                        dataField="telephoneNumbers"
                                        caption="Telefonní čísla"
                                        helpText="Oddělte čárkou."
                                    />
                                </Item>
                            </Form>
                        </Editing>

                        <Column dataField="name" caption="Název" />
                        <Column dataField="number" caption="Hodnota" />
                        <Column dataField="message" caption="Zpráva" />
                        <Column dataField="secondMessage" caption="Zpráva ukončení" />
                        <Column dataField="telephoneNumbers" caption="Telefonní čísla" />
                        <Column dataField="emails" caption="Emaily" />
                    </DataGrid>
                </ScrollView>
                <Button text="Ok" type="default" onClick={onHiding} />
            </Popup>
        )
    }
)

const NotificationDefPopupEditorMemo = memo(NotificationDefPopupEditor)
