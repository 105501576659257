export class NumberUtils {
    static roundTo5(num: number): number {
        return Math.ceil(num / 5) * 5
    }

    static toKMGT(result: number): { value: number; unit: string } {
        for (let index = 0; index < 5; index++) {
            if (result / 1000 > 1) {
                result = result / 1000
            } else {
                let unit = ""
                switch (index) {
                    case 0:
                        unit = ""
                        break
                    case 1:
                        unit = "k"
                        break
                    case 2:
                        unit = "M"
                        break
                    case 3:
                        unit = "G"
                        break
                    case 4:
                        unit = "T"
                        break
                }
                return { value: Math.round(result), unit: unit }
            }
        }
        return { value: 0, unit: "" }
    }
}

export const isNumber = (number: string | number | undefined): boolean => {
    return typeof number === "number"
}
