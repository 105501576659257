import Form, { GroupItem, SimpleItem } from "devextreme-react/form"
import React, { forwardRef, memo, useImperativeHandle } from "react"
import { FunctionResponse } from "../../../types/FunctionsType"
import DBConnectionsSelectBox from "../DBConnectionsSelectBox"
import { FunctionProps } from "../FunctionsList"
import OwnSQL from "../OwnSQL"
import { IFunctionRef, TEXT_AREA_OPTIONS } from "./TestFunction"

const WriteSQL = forwardRef<IFunctionRef, FunctionProps>(({ item }, ref) => {
    useImperativeHandle(
        ref,
        () => ({
            getParamsCount: () => {
                if (item.parameters) {
                    const sql = (item.parameters as any).sql
                    if (sql) {
                        return sql.split("?").length - 1
                    }
                }
                return 0
            },
            showCallResult: (response: FunctionResponse) => {
                return (
                    <React.Fragment>
                        <Form formData={response} colCount={2}>
                            <SimpleItem
                                editorType="dxTextArea"
                                dataField="sql"
                                editorOptions={TEXT_AREA_OPTIONS}
                            />
                            <SimpleItem
                                editorType="dxTextArea"
                                dataField="error"
                                visible={response.error ? true : false}
                                editorOptions={TEXT_AREA_OPTIONS}
                            />
                        </Form>
                    </React.Fragment>
                )
            }
        }),
        [item.parameters]
    )

    const parameters = item.parameters ?? {}
    item.parameters = parameters

    return (
        <Form formData={parameters}>
            <GroupItem>
                <GroupItem>
                    <DBConnectionsSelectBox fun={item} />
                </GroupItem>
                <GroupItem>
                    <OwnSQL
                        parameters={parameters as any}
                        helpText="Zadejte SQL ve tvaru INSERT nebo UPDATE s otazníky namísto proměnných parametrů."
                    />
                </GroupItem>
            </GroupItem>
        </Form>
    )
})

export default memo(WriteSQL)
