import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { $enum } from "ts-enum-util"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export enum PumpType {
    SLUDGE = "SLUDGE",
    ATS = "ATS",
    SCREW = "SCREW"
}

export type PumpParameters = BaseParams & {
    type: PumpType
    expression: string
    expressionError: string
}

const typeOptions = {
    items: Object.values(PumpType)
}

export const getPumpBasicConfigurations = () => {
    return $enum(PumpType)
        .getValues()
        .map((e) => ({
            type: e
        }))
}

function PumpEditor({ parameters }: EditorProps<PumpParameters>) {
    parameters.type = parameters.type ?? PumpType.SLUDGE

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem
                                dataField="type"
                                editorType="dxSelectBox"
                                editorOptions={typeOptions}
                            >
                                <Label text="Typ" />
                            </SimpleItem>
                            <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                                <Label text="Výraz pro běh"></Label>
                            </SimpleItem>
                            <SimpleItem render={initMonacoEditor(parameters, "expressionError")}>
                                <Label text="Výraz poruchy"></Label>
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(PumpEditor)
