import { produce } from "immer"
import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { DEVELOPMENT_MODE } from "../constants"
import { IComponentContainer } from "../report/Schema/Layer/ComponentContainer/ComponentContainer"
import { FLOW_WIDTH } from "../report/flow/Flow"
import {
    IBook,
    IDBConnection,
    IImage,
    IModbusConnection,
    INotificationGroup,
    IReport,
    IThreeDModel
} from "../types/BaseTypes"
import { gqlRead } from "../utils/APIUtils"
import { useAppStore } from "./AppStore"
import { useReportStore } from "./ReportStore"

export type SchemaPos = {
    top: number
    left: number
}

const STORE_NAME = "ReportStore2"

export type ReportState = {
    reportSaved: boolean
    editMode: boolean
    gridActive: boolean
}

export interface IReportStore2 {
    resetStore: () => void
    initReports: (reportIds: Array<number>) => void
    reportStates: Record<number, ReportState>

    getActiveReportIndex: () => number

    setReportSaved: (reportSaved: boolean, reportId: number) => void

    scrollEnabled: boolean
    setScrollEnabled: (scrollEnabled: boolean) => void

    popupVisible: boolean
    setPopupVisible: (popupVisible: boolean) => void

    schemaPos: SchemaPos
    schemaScale: number
    calculateSchemaProps: () => void

    dndComponentGuid: string | undefined
    setEditMode: (editMode: boolean) => void
    setDndComponentGuid: (dndComponentGuid: string | undefined) => void

    setGridActive: (gridActive: boolean) => void

    fetchAccessories: () => void
    dbConnections: Array<IDBConnection>
    modbusConnections: Array<IModbusConnection>
    books: Array<IBook>
    images: Array<IImage>
    threeDModels: Array<IThreeDModel>
    notificationGroups: Array<INotificationGroup>
    reports: Array<IReport>

    componentRightClicked?: IComponentContainer
    setComponentRightClicked: (componentRightClicked: IComponentContainer) => void
}

const initialState = {
    reportStates: {},

    scrollEnabled: true,

    popupVisible: false,

    schemaPos: { top: 219, left: 15 },
    schemaScale: 1,

    dndComponentGuid: undefined,

    dbConnections: [],
    modbusConnections: [],
    books: [],
    images: [],
    threeDModels: [],
    notificationGroups: [],
    reports: [],

    componentRightClicked: undefined
}

export const useReportStore2 = create<IReportStore2, [["zustand/devtools", never]]>(
    devtools(
        (set, get) => ({
            ...initialState,

            resetStore: () =>
                set((state: IReportStore2) => ({ ...initialState }), false, "resetStore"),
            initReports: (reportIds: Array<number>) =>
                set(
                    produce((state: IReportStore2) => {
                        reportIds.forEach((id) => {
                            state.reportStates[id] = {
                                reportSaved: false,
                                editMode: false,
                                gridActive: false
                            }
                        })
                    }),
                    false,
                    "initReports"
                ),

            getActiveReportIndex: () => {
                const activeReportId = useReportStore.getState().activeReportId
                if (activeReportId) {
                    return activeReportId
                }
                throw new Error("activeReportId not exist - report store 2")
            },

            setReportSaved: (reportSaved: boolean, reportId: number) =>
                set(
                    produce((state: IReportStore2) => {
                        state.reportStates[reportId].reportSaved = reportSaved
                    }),
                    false,
                    "setReportSaved"
                ),

            setScrollEnabled: (scrollEnabled: boolean) =>
                set((state: IReportStore2) => ({ scrollEnabled }), false, "setScrollEnabled"),

            setPopupVisible: (popupVisible: boolean) =>
                set((state: IReportStore2) => ({ popupVisible }), false, "setPopupVisible"),

            calculateSchemaProps: () =>
                set(
                    produce((state: IReportStore2) => {
                        const mainWidth = useAppStore.getState().reportWidth ?? FLOW_WIDTH + 10
                        // TODO padding je 10 - konstanta
                        const scale = mainWidth / (FLOW_WIDTH + 10)

                        state.schemaScale = scale
                    }),
                    false,
                    "calculateSchemaProps"
                ),

            setEditMode: (editMode: boolean) =>
                set(
                    produce((state: IReportStore2) => {
                        state.reportStates[state.getActiveReportIndex()].editMode = editMode
                    }),
                    false,
                    "setEditMode"
                ),
            setDndComponentGuid: (dndComponentGuid: string | undefined) =>
                set((state: IReportStore2) => ({ dndComponentGuid }), false, "setDndComponentGuid"),

            setGridActive: (gridActive: boolean) =>
                set(
                    produce((state: IReportStore2) => {
                        state.reportStates[state.getActiveReportIndex()].gridActive = gridActive
                    }),
                    false,
                    "setGridActive"
                ),

            fetchAccessories: () => {
                const { currentUser, groupId } = useAppStore.getState()
                const __args = {
                    input: { group: { id: groupId } }
                }

                if (currentUser!.admin) {
                    gqlRead({
                        reports: {
                            __args,
                            id: true,
                            name: true
                        },
                        dbConnections: {
                            __args,
                            id: true,
                            name: true
                        },
                        modbusConnections: {
                            __args,
                            id: true,
                            name: true
                        },
                        notificationGroups: {
                            __args,
                            id: true,
                            name: true
                        },
                        images: {
                            id: true,
                            name: true
                        },
                        threeDModels: {
                            id: true,
                            name: true
                        },
                        books: {
                            id: true,
                            name: true,
                            component: true
                        }
                    }).then((r: any) => {
                        set(
                            {
                                dbConnections: r.dbConnections,
                                modbusConnections: r.modbusConnections,
                                books: r.books,
                                images: r.images,
                                threeDModels: r.threeDModels,
                                notificationGroups: r.notificationGroups,
                                reports: r.reports
                            },
                            false,
                            "setAccessories"
                        )
                    })
                }
            },

            setComponentRightClicked: (componentRightClicked: IComponentContainer) =>
                set(
                    (state: IReportStore2) => ({ componentRightClicked }),
                    false,
                    "setComponentRightClicked"
                )
        }),
        { enabled: DEVELOPMENT_MODE, name: STORE_NAME }
    )
)

if (DEVELOPMENT_MODE) {
    mountStoreDevtool(STORE_NAME, useReportStore2)
}
