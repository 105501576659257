import {
    BLUE,
    BLUE_LIGHT,
    BLUE_SECOND,
    BROWN,
    BROWN_LIGHT,
    BROWN_SECOND,
    GREEN,
    GREEN_LIGHT,
    GREEN_SECOND,
    RED,
    RED_LIGHT,
    RED_SECOND,
    WHITE,
    WHITE_LIGHT,
    WHITE_SECOND,
    YELLOW,
    YELLOW_LIGHT,
    YELLOW_SECOND
} from "../basic/ReactCSSVariables"
import { TankColor } from "../components/Tank/TankEditor"

export enum ColorTypes {
    WHITE,
    GRAY,
    BLUE,
    SUCCESS,
    WARNING,
    ERROR
}

export default class ColorUtils {
    static fromNumber(index?: number): string {
        if (!index || index < 0 || index > 5) {
            index = 0
        }
        return ColorTypes[index]
    }

    static constantFromColor(color?: TankColor) {
        switch (color) {
            case TankColor.BLUE:
                return BLUE
            case TankColor.BROWN:
                return BROWN
            case TankColor.YELLOW:
                return YELLOW
            case TankColor.WHITE:
                return WHITE
            case TankColor.RED:
                return RED
            case TankColor.GREEN:
                return GREEN
            default:
                return BLUE
        }
    }

    static constantFromColorLight(color?: TankColor) {
        switch (color) {
            case TankColor.BLUE:
                return BLUE_LIGHT
            case TankColor.BROWN:
                return BROWN_LIGHT
            case TankColor.YELLOW:
                return YELLOW_LIGHT
            case TankColor.WHITE:
                return WHITE_LIGHT
            case TankColor.RED:
                return RED_LIGHT
            case TankColor.GREEN:
                return GREEN_LIGHT
            default:
                return BLUE_LIGHT
        }
    }

    static constantFromColorSecond(color?: TankColor) {
        switch (color) {
            case TankColor.BLUE:
                return BLUE_SECOND
            case TankColor.BROWN:
                return BROWN_SECOND
            case TankColor.YELLOW:
                return YELLOW_SECOND
            case TankColor.WHITE:
                return WHITE_SECOND
            case TankColor.RED:
                return RED_SECOND
            case TankColor.GREEN:
                return GREEN_SECOND
            default:
                return BLUE_SECOND
        }
    }
}
