import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { cloneDeep } from "lodash"
import { memo, useCallback, useContext, useMemo } from "react"
import { useReportStore } from "../../../../../../stores/ReportStore"
import { BaseParams, IBook, IComponent } from "../../../../../../types/BaseTypes"
import { ComponentCategories } from "../../../../../../types/ComponentTypes"
import { persistBook } from "../../../../../../utils/API/APILibrary"
import ComponentUtils from "../../../../../../utils/ComponentUtils"
import ComponentsCreator from "../../../../../../utils/ComponentsCreator"
import { useForceUpdate, useReportFields } from "../../../../../../utils/CustomHooks"
import { standardError, standardSuccess } from "../../../../../../utils/NotifyUtils"
import ReportUtils from "../../../../../../utils/ReportUtils"
import { ReportPathContext } from "../../../../../contexts/ReportPathContext"
import "./Editor.scss"
import ExportImportVisualOptions from "./OtherOptions/ExportImportVisualOptions"
import HandlesOptions from "./OtherOptions/HandlesOptions"

export type EditorProps<T> = {
    parameters: BaseParams & T
    formData: IComponent
}
type Props = {
    formData: IComponent
}

function Editor({ formData }: Props) {
    const switchComponent = useReportStore((state) => state.switchComponent)
    const [nodes] = useReportFields((r) => [r.parameters.flow.nodes])

    const reportPathContext = useContext(ReportPathContext)
    const reportPath = reportPathContext.reportPath

    const [forceUpdate] = useForceUpdate()

    ComponentUtils.checkUndefined(formData)
    const parameters = formData.parameters
    const componentType = formData.type!

    const typeOptions = useMemo(
        () => ({
            dataSource: ComponentCategories,
            valueExpr: "type",
            displayExpr: "name",
            grouped: true,
            searchEnabled: true
        }),
        []
    )

    const validateForm = useCallback((e: any) => {
        e.component.validate()
    }, [])

    const onLibraryAdd = useCallback(
        (name: string) => {
            const newComponent: any = cloneDeep(formData)
            ReportUtils.prepareComponentToSave(newComponent)
            const book: IBook = {
                name: name,
                path: "root",
                component: newComponent
            }

            const node = nodes?.find((n) => n.data.componentGuid === formData.guid)
            if (node && node.style) {
                book.width = parseInt(node.style.width + "")
                book.height = parseInt(node.style.height + "")
            }
            persistBook([book])
                .then((response) => standardSuccess("Komponenta uložena do knihovny"))
                .catch(standardError)
        },
        [formData, nodes]
    )

    const onBookLoaded = useCallback(
        (component: IComponent) => {
            const newComponent = ComponentUtils.cloneComponentWithGuids(component)
            ReportUtils.prepareComponentToEdit(newComponent)

            newComponent.guid = component.guid
            switchComponent(reportPath, component, newComponent)
        },
        [reportPath, switchComponent]
    )

    const childEditor = useMemo(() => {
        const componentProps = Object.assign({}, { formData }, { parameters })
        return ComponentsCreator.createEditor(componentType, componentProps)
    }, [formData, componentType, parameters])

    return (
        <div className="component-setup-container">
            <Form
                onContentReady={validateForm}
                id="componentForm"
                formData={formData}
                labelLocation="left"
                onFieldDataChanged={forceUpdate}
            >
                <GroupItem colCount={4}>
                    <SimpleItem dataField="name">
                        <Label text="Jméno" />
                    </SimpleItem>
                    <SimpleItem
                        dataField="type"
                        editorType="dxSelectBox"
                        editorOptions={typeOptions}
                    >
                        <Label text="Typ" />
                    </SimpleItem>
                    <GroupItem>
                        <HandlesOptions component={formData} />
                    </GroupItem>
                    <GroupItem>
                        <ExportImportVisualOptions
                            reportPath={reportPath}
                            onBookLoaded={onBookLoaded}
                            onLibraryAdd={onLibraryAdd}
                            component={formData}
                        />
                    </GroupItem>
                </GroupItem>
            </Form>

            <div className="component-setup-container">{childEditor}</div>
        </div>
    )
}

export default memo(Editor)
