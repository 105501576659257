import { createContext, memo, useMemo } from "react"
import { IComponent } from "../../types/BaseTypes"

export interface IComponentContext {
    component: IComponent
}

export const ComponentContext = createContext<IComponentContext | undefined>(undefined)

type Props = {
    children: JSX.Element
    component: IComponent
}

export const ComponentProvider = memo(({ children, component }: Props) => {
    const context = useMemo(
        () => ({
            component
        }),
        [component]
    )

    return <ComponentContext.Provider value={context}>{children}</ComponentContext.Provider>
})
