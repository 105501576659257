import React from "react"
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form"
import { IFunctionParameters } from "../../../types/FunctionsType"

type TablesAndColumnsProps = {
    parameters: IFunctionParameters
}

class TablesAndColumns extends React.Component<TablesAndColumnsProps, any> {
    render() {
        const parameters = this.props.parameters

        return (
            <Form className="component-setup-container" formData={parameters}>
                <GroupItem>
                    <SimpleItem
                        dataField="tables"
                        helpText="Oddělte čárkou. První tabulka bude zvolena jako hlavní, ostatní se připojí. Je možné tabulky rovnou asociovat např. 'mereni m, id i'."
                    >
                        <Label text="Tabulky" />
                    </SimpleItem>
                    <SimpleItem
                        dataField="joinIds"
                        helpText="Oddělte čárkou. Musí odpovídat pořadí tabulek v předchozím textovém poli. Např. 'id, id_id'"
                    >
                        <Label text="Sloupce spojení" />
                    </SimpleItem>
                    <SimpleItem
                        dataField="columns"
                        helpText="Oddělte čárkou. Je možné prefixovat pomocí asociace tabulky, např. 'm.mereni'."
                    >
                        <Label text="Sloupce" />
                    </SimpleItem>
                </GroupItem>
            </Form>
        )
    }
}

export default TablesAndColumns
