import { cloneDeep, isEqual } from "lodash"
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from "react"
import ImagesCache from "../../cache/ImagesCache"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { useExpression } from "../../utils/CustomHooks"
import { standardError } from "../../utils/NotifyUtils"
import { MultiImageParameters } from "./MultiImageEditor"

type Params = MultiImageParameters

type Props = ComponentProps<Params>

const MultiImageComponent = forwardRef<IBaseComponent, Props>(({ expression, imageIds }, ref) => {
    const [ids, setIds] = useState<Array<{ imageId: number }>>([])
    const [data, setData] = useState<Array<any>>([])
    const [types, setTypes] = useState<Array<string>>([])

    const [value] = useExpression(expression, 0)

    const checkImages = useCallback(() => {
        if (imageIds && !isEqual(imageIds, ids)) {
            Promise.all(imageIds.map((i) => ImagesCache.getInstance().getImage(i.imageId)))
                .then((responses: Array<any>) => {
                    const ids: Array<{ imageId: number }> = cloneDeep(imageIds)
                    const types: Array<string> = []
                    const data: Array<any> = []
                    imageIds.forEach((i, index) => {
                        types.push(responses[index].type)
                        data.push(responses[index].data)
                    })

                    setIds(ids)
                    setData(data)
                    setTypes(types)
                })
                .catch(standardError)
        }
    }, [ids, imageIds])

    useEffect(() => checkImages(), [checkImages])

    const resultValue = useMemo(() => {
        let result = Math.round(value)
        if (result < 0) result = 0
        if (result >= ids.length) result = ids.length - 1
        return result
    }, [value, ids.length])

    const result = useMemo(
        () =>
            ids.map((i, index) => {
                const type = types[index]
                const d = data[index]
                const imageId = ids[index]?.imageId

                const stringData = "data:" + type + ";base64," + d
                const style = { opacity: resultValue === index ? 1 : 0 }

                return type?.includes("pdf") ? (
                    <iframe
                        key={imageId}
                        style={style}
                        title={"id" + imageId}
                        width="100%"
                        height="100%"
                        src={stringData}
                    />
                ) : (
                    <img
                        key={imageId}
                        className="image-component"
                        style={style}
                        width="100%"
                        height="100%"
                        alt="pic"
                        src={stringData}
                    ></img>
                )
            }),
        [data, ids, resultValue, types]
    )

    return <>{result}</>
})

export default memo(MultiImageComponent)
