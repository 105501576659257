import React from "react"

import { RequiredRule } from "devextreme-react/validator"
import Form, { ButtonItem, Item as FItem, CompareRule, PatternRule } from "devextreme-react/form"
import notify from "devextreme/ui/notify"
import Popup from "devextreme-react/popup"

import { sendCodeToEmail, forgottenPassword } from "../utils/APIUsers"
import { securityApiCall } from "../utils/APIUtils"
import { NewPassword } from "../basic/Login"
import { standardSuccess } from "../utils/NotifyUtils"

type ForgottenPasswordProps = {
    forgottenPassword: (forgottenPassword: boolean) => void
}

type ForgottenPasswordState = {
    isLoading: boolean
    changePassword: boolean
}

type Email = {
    usernameOrEmail: string
}

class ForgottenPassword extends React.Component<ForgottenPasswordProps, ForgottenPasswordState> {
    private email: Email
    private newPassword: NewPassword

    constructor(props: ForgottenPasswordProps) {
        super(props)
        this.state = {
            isLoading: false,
            changePassword: false
        }

        this.email = {
            usernameOrEmail: ""
        }

        this.newPassword = {
            secret: "",
            newPassword: "",
            newPasswordAgain: ""
        }
    }

    handleSendMail = () => {
        this.setState({ isLoading: true })

        securityApiCall(
            () => sendCodeToEmail(this.email.usernameOrEmail),
            (response) => {
                this.setState({
                    changePassword: true
                })
                standardSuccess("Na email byl odeslán obnovovací kód.")
            },
            () => notify("Email není v databázi.", "error", 2000),
            (error) => notify("Komunikace se serverem nebyla navázána.", "error", 2000),
            () => this.setState({ isLoading: false })
        )
    }

    handleNewPassword = () => {
        this.setState({ isLoading: true })
        const loginRequest = Object.assign({}, this.newPassword, this.email)

        securityApiCall(
            () => forgottenPassword(loginRequest),
            (response) => {
                this.setState({
                    changePassword: true
                })
                standardSuccess("Heslo bylo úspěšně změněno.")
                this.props.forgottenPassword(false)
            },
            () => notify("Platnost kódu vypršela, nebo je neplatný.", "error", 2000),
            (error) => notify("Komunikace se serverem nebyla navázána.", "error", 2000),
            () => this.setState({ isLoading: false })
        )
    }

    passwordComparison = () => {
        return this.newPassword.newPassword
    }

    handlePopupHidden = () => {
        this.props.forgottenPassword(false)
    }

    render() {
        let popupContent = null
        if (this.state.changePassword) {
            popupContent = (
                <form>
                    <Form
                        colCount={1}
                        id="forgottenPasswordForm"
                        formData={this.newPassword}
                        labelLocation="top"
                    >
                        <FItem
                            dataField="secret"
                            editorType="dxTextBox"
                            label={{ text: "Ověření" }}
                            helpText="Zadejte kód, který Vám byl zaslán na e-mail."
                        >
                            <RequiredRule message="Vyplňte kód ověření" />
                        </FItem>
                        <FItem
                            dataField="newPassword"
                            editorType="dxTextBox"
                            label={{ text: "Nové heslo" }}
                            editorOptions={{ mode: "password" }}
                        >
                            <RequiredRule message="Vyplňte heslo" />
                            <PatternRule
                                message="Heslo musí mít alespoň 8 znaků. Musí obsahovat alespoň jedno velké a malé písmeno a číslici."
                                pattern={/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/}
                            />
                        </FItem>
                        <FItem
                            dataField="newPasswordAgain"
                            editorType="dxTextBox"
                            label={{ text: "Nové heslo znovu" }}
                            editorOptions={{ mode: "password" }}
                        >
                            <RequiredRule message="Vyplňte heslo pro ověření" />
                            <CompareRule
                                message="Hesla se neshodují"
                                comparisonTarget={this.passwordComparison}
                            />
                        </FItem>
                        <ButtonItem
                            horizontalAlignment="center"
                            buttonOptions={{
                                text: this.state.isLoading ? "... Nastavuji" : "Nastavit heslo",
                                type: "default",
                                onClick: this.handleNewPassword,
                                disabled: this.state.isLoading
                            }}
                        />
                    </Form>
                </form>
            )
        } else {
            popupContent = (
                <form>
                    <Form
                        colCount={1}
                        id="forgottenPasswordForm"
                        formData={this.email}
                        labelLocation="top"
                    >
                        <FItem
                            dataField="usernameOrEmail"
                            editorType="dxTextBox"
                            label={{ text: "Zadejte email" }}
                        >
                            <RequiredRule message="Vyplňte email" />
                        </FItem>
                        <ButtonItem
                            horizontalAlignment="center"
                            buttonOptions={{
                                text: this.state.isLoading ? "... Odesílám" : "Odeslat",
                                type: "default",
                                onClick: this.handleSendMail,
                                disabled: this.state.isLoading
                            }}
                        />
                    </Form>
                </form>
            )
        }

        return (
            <Popup
                width={600}
                height="auto"
                showTitle={true}
                title="Obnova hesla"
                visible={true}
                onHiding={this.handlePopupHidden}
            >
                {popupContent}
            </Popup>
        )
    }
}

export default ForgottenPassword
