import Form, {
    ButtonItem,
    CompareRule,
    Item as FItem,
    GroupItem,
    PatternRule
} from "devextreme-react/form"
import { RequiredRule } from "devextreme-react/validator"
import notify from "devextreme/ui/notify"
import { useState } from "react"

import { NewPassword } from "../basic/Login"
import { useAppStore } from "../stores/AppStore"
import { changePassword } from "../utils/APIUsers"
import { securityApiCall } from "../utils/APIUtils"
import { standardSuccess } from "../utils/NotifyUtils"

type ChangePasswordProps = {}

type PasswordObject = NewPassword & {
    password: string
}

export default function ChangePassword(props: ChangePasswordProps) {
    const [loading, setLoading] = useState(false)
    const [passwordObject] = useState<PasswordObject>({
        password: "",
        newPassword: "",
        newPasswordAgain: "",
        secret: ""
    })

    const changePath = useAppStore((state) => state.changePath)
    const twoFactor = useAppStore((state) => state.currentUser?.twoFactorEnabled)

    const handleChange = () => {
        setLoading(true)

        const loginRequest = Object.assign({}, passwordObject)
        securityApiCall(
            () => changePassword(loginRequest),
            (response) => {
                standardSuccess("Heslo bylo změněno.")
                changePath("/")
            },
            () => notify("Neplatné číslo ověření, nebo heslo.", "error", 2000),
            (error) => notify("Komunikace se serverem nebyla navázána.", "error", 2000),
            () => setLoading(false)
        )
    }

    const passwordComparison = () => {
        return passwordObject.newPassword
    }

    return (
        <form>
            <Form colCount={1} id="loginForm" formData={passwordObject} labelLocation="top">
                <GroupItem caption="Změna hesla">
                    <FItem
                        dataField="password"
                        editorType="dxTextBox"
                        label={{ text: "Aktuální heslo" }}
                        editorOptions={{ mode: "password" }}
                    >
                        <RequiredRule message="Vyplňte heslo" />
                        <PatternRule
                            message="Heslo musí mít alespoň 8 znaků. Musí obsahovat alespoň jedno velké a malé písmeno a číslici."
                            pattern={/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/}
                        />
                    </FItem>
                    <FItem
                        dataField="newPassword"
                        editorType="dxTextBox"
                        label={{ text: "Nové heslo" }}
                        editorOptions={{ mode: "password" }}
                    >
                        <RequiredRule message="Vyplňte heslo" />
                        <PatternRule
                            message="Heslo musí mít alespoň 8 znaků. Musí obsahovat alespoň jedno velké a malé písmeno a číslici."
                            pattern={/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/}
                        />
                    </FItem>
                    <FItem
                        dataField="newPasswordAgain"
                        editorType="dxTextBox"
                        label={{ text: "Nové heslo znovu" }}
                        editorOptions={{ mode: "password" }}
                    >
                        <RequiredRule message="Vyplňte heslo pro ověření" />
                        <CompareRule
                            message="Hesla se neshodují"
                            comparisonTarget={passwordComparison}
                        />
                    </FItem>
                </GroupItem>
                <FItem
                    visible={twoFactor === true}
                    dataField="secret"
                    editorType="dxTextBox"
                    label={{ text: "Ověření" }}
                    helpText="Zadejte šestimístný kód z aplikace Google Authenticator."
                >
                    <RequiredRule message="Vyplňte kód ověření" />
                </FItem>
                <ButtonItem
                    horizontalAlignment="center"
                    buttonOptions={{
                        text: loading ? "... Pracuji" : "Změnit",
                        type: "default",
                        onClick: handleChange,
                        disabled: loading
                    }}
                />
            </Form>
        </form>
    )
}
