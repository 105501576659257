import { IBase } from "./BaseTypes"
import { Action } from "./ComponentTypes"

export enum FunctionType {
    SET_VARIABLE = "SET_VARIABLE",
    READ_SQL = "READ_SQL",
    WRITE_SQL = "WRITE_SQL",
    READ_MODBUS = "READ_MODBUS",
    WRITE_MODBUS = "WRITE_MODBUS",
    READ_NOTIFICATIONS = "READ_NOTIFICATIONS",
    READ_NOTIFICATION_GROUPS = "READ_NOTIFICATION_GROUPS"
}

export type FunctionResponse = {
    data: any
    error: string
}

export type FParamDefinition = {
    id: string
    expression: string
}

export interface IFunctionParameters {
    refreshInterval?: number
    inputType?: string
    params?: Array<FParamDefinition>
    output?: string
    sqlType?: string
    onSuccessActions?: Array<Action>
    onErrorActions?: Array<Action>
}

export interface IFunction extends IBase {
    id?: number
    name: string
    guid: string
    type: FunctionType
    parameters?: IFunctionParameters
    dbConnection?: number | { id: number }
    modbusConnection?: number | { id: number }
}

export interface SetVariableParameters extends IFunctionParameters {
    expression: string
    output: string
}
export interface ReadSqlParameters extends IFunctionParameters {}
export interface WriteSqlParameters extends IFunctionParameters {}
export interface ReadModbusParameters extends IFunctionParameters {}
export interface WriteModbusParameters extends IFunctionParameters {}
export interface ReadNotificationsParameters extends IFunctionParameters {}
export interface ReadNotificationGroupsParameters extends IFunctionParameters {}

export interface SetVariableFunction extends IFunction {
    type: FunctionType.SET_VARIABLE
    parameters?: SetVariableParameters
}
export interface ReadSqlFunction extends IFunction {
    type: FunctionType.READ_SQL
    parameters?: ReadSqlParameters
}
export interface WriteSqlFunction extends IFunction {
    type: FunctionType.WRITE_SQL
    parameters?: WriteSqlParameters
}
export interface ReadModbusFunction extends IFunction {
    type: FunctionType.READ_MODBUS
    parameters?: ReadModbusParameters
}
export interface WriteModbusFunction extends IFunction {
    type: FunctionType.WRITE_MODBUS
    parameters?: WriteModbusParameters
}
export interface ReadNotificationsFunction extends IFunction {
    type: FunctionType.READ_NOTIFICATIONS
    parameters?: ReadNotificationsParameters
}
export interface ReadNotificationGroupsFunction extends IFunction {
    type: FunctionType.READ_NOTIFICATION_GROUPS
    parameters?: ReadNotificationGroupsParameters
}

export type TFunction =
    | SetVariableFunction
    | ReadSqlFunction
    | WriteSqlFunction
    | ReadModbusFunction
    | WriteModbusFunction
    | ReadNotificationsFunction
    | ReadNotificationGroupsFunction
