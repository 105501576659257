import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { cloneDeep } from "lodash"
import { memo } from "react"
import { Position } from "reactflow"
import { v4 } from "uuid"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { PIPE_COLOR_OPTIONS, PIPE_SIZE_OPTIONS } from "../Pipe/PipeEditor"
import { TankColor } from "../Tank/TankEditor"

export type PipeRoundParameters = BaseParams & {
    size: number
    color: TankColor
}

const BASIC_CONFIG = {
    size: 3,
    color: TankColor.BLUE,
    handles: [
        { guid: v4(), name: "levy", position: Position.Left, left: 2, top: 33.33333333 },
        {
            guid: v4(),
            name: "spodni",
            position: Position.Bottom,
            left: 66.6666666,
            bottom: 2
        }
    ]
}

export const getPipeRoundBasicConfigurations = (): Array<PipeRoundParameters> => {
    return [0, 1, 2, 3].map((a) => ({
        ...cloneDeep(BASIC_CONFIG),
        rotationExpression: a * 90 + ""
    }))
}

function PipeEditor({ parameters }: EditorProps<PipeRoundParameters>) {
    parameters.color = parameters.color ?? TankColor.BLUE
    parameters.size = parameters.size ?? 3
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                                <Label text="Výraz pro běh" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="color"
                                editorType="dxSelectBox"
                                editorOptions={PIPE_COLOR_OPTIONS}
                            >
                                <Label text="Barva" />
                            </SimpleItem>
                            <GroupItem colCount={2}>
                                <SimpleItem
                                    dataField="size"
                                    editorType="dxSlider"
                                    editorOptions={PIPE_SIZE_OPTIONS}
                                >
                                    <Label text="Velikost" />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(PipeEditor)
