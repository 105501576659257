import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { DEVELOPMENT_MODE } from "../constants"
import { componentToolbarStaticState } from "../report/ComponentToolbar/ComponentToolbar"
import { IComponentContainer } from "../report/Schema/Layer/ComponentContainer/ComponentContainer"

const STORE_NAME = "HoveredStore"

const onHoverChange = (
    container: IComponentContainer,
    newState: boolean,
    listeners: Record<string, (hovered: boolean) => void>
) => {
    container.setHovered(newState)
    listeners[container.getProps().component.guid]?.(newState)
}

export interface IHoveredStore {
    hoveredComponents: Array<IComponentContainer>
    listeners: Record<string, (hovered: boolean) => void>

    registerListener: (guid: string, callback: (hovered: boolean) => void) => void
    hoverComponent: (container: IComponentContainer) => void
    unhoverComponent: (container: IComponentContainer) => void
    peekHoverComponent: () => IComponentContainer | undefined
    hasHoveredComponents: (guid: string) => boolean
}

export const useHoveredStore = create<IHoveredStore, [["zustand/devtools", never]]>(
    devtools(
        (set, get) => ({
            hoveredComponents: [],
            listeners: {},

            registerListener: (guid: string, callback: (hovered: boolean) => void) => {
                set(
                    (state) => {
                        const listeners = { ...state.listeners }
                        listeners[guid] = callback
                        return { listeners }
                    },
                    false,
                    "registerListener"
                )
            },
            hoverComponent: (container: IComponentContainer) => {
                let { hoveredComponents, listeners } = get()
                if (
                    !hoveredComponents
                        .map((h) => h.getProps().component.guid)
                        .includes(container.getProps().component.guid)
                ) {
                    hoveredComponents.forEach((c) => onHoverChange(c, false, listeners))
                    hoveredComponents = [...hoveredComponents, container]
                    onHoverChange(container, true, listeners)
                    set((state) => ({ hoveredComponents }), false, "hoverComponent")
                }
            },
            unhoverComponent: (container: IComponentContainer) => {
                setTimeout(() => {
                    if (!componentToolbarStaticState.hovered) {
                        let { hoveredComponents, listeners } = get()
                        const index = hoveredComponents
                            .map((h) => h.getProps().component.guid)
                            .indexOf(container.getProps().component.guid)
                        if (index > -1) {
                            onHoverChange(container, false, listeners)
                            hoveredComponents.splice(index, 1)
                            if (index > 0) {
                                onHoverChange(hoveredComponents[index - 1], true, listeners)
                            }
                            hoveredComponents = [...hoveredComponents]
                            set((state) => ({ hoveredComponents }), false, "unhoverComponent")
                        }
                    }
                }, 10)
            },
            peekHoverComponent: (): IComponentContainer | undefined => {
                const hovered = get().hoveredComponents
                return hovered[hovered.length - 1]
            },
            hasHoveredComponents: (guid: string) =>
                get().peekHoverComponent()?.getProps().component.guid === guid
        }),
        { enabled: DEVELOPMENT_MODE, name: STORE_NAME }
    )
)

if (DEVELOPMENT_MODE) {
    mountStoreDevtool(STORE_NAME, useHoveredStore)
}
