class EvalUtils {
    private static readonly REGEX_COMMENTS = /\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm

    public static eval(expression: string | undefined, context?: Record<string, any>): any {
        if (expression) {
            // TODO taky by slo cachovat, tedy nastavit pro kazdy vyraz podobne jako correctExpression
            // v customHooks.
            const toEvaluation = `${EvalUtils.prepareProxies(context)}\n${expression}`

            // eslint-disable-next-line no-new-func
            const fun = Function(toEvaluation)

            const result = fun.call(context)
            return result
        }
    }

    // TODO tohle se musi nejak cachovat
    private static readonly prepareProxies = (context?: Record<string, any>) => {
        let result = "'use strict';"

        for (var key in context) {
            if (context[key] !== undefined && context[key] !== null) {
                result += `const ${key} = new Proxy(this.${key}, { get: (target, prop, receiver) => { if (prop in target) { return target[prop] } throw new ReferenceError(prop + ' is not defined in "${key}" context.') }, })
                ` // novy radek - musi byt
            }
        }

        return result
    }

    // TODO melo by se delat jen jednou v useExpression
    public static correctExpression(expression: string | undefined) {
        if (expression) {
            expression = expression.trim().replace(EvalUtils.REGEX_COMMENTS, "$1").trim()
            if (expression.startsWith("(")) {
                return `return ${expression}`
            }
            const rows = expression.split(/\r?\n/)
            if (rows.find((r) => r.trim().startsWith("return "))) {
                return expression
            }
            return `return ${expression}`
        }
    }
}

export default EvalUtils
