import React from "react"

import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { IFunctionParameters } from "../../types/FunctionsType"
import { initMonacoEditor } from "./MonacoEditor"

type OwnSQLProps = {
    parameters: IFunctionParameters
    helpText?: string
}

type OwnSQLState = {}

class OwnSQL extends React.Component<OwnSQLProps, OwnSQLState> {
    render() {
        const parameters = this.props.parameters

        return (
            <Form formData={parameters}>
                <GroupItem>
                    <SimpleItem render={initMonacoEditor(parameters, "sql", false)}>
                        <Label text="SQL" />
                    </SimpleItem>
                </GroupItem>
            </Form>
        )
    }
}

export default OwnSQL
