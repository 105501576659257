import { memo, useCallback, useMemo } from "react"
import { useReportStore2 } from "../../stores/ReportStore2"
import { IBook } from "../../types/BaseTypes"
import ComponentContainer from "../Schema/Layer/ComponentContainer/ComponentContainer"

type ComponentsProps = {
    books: Array<IBook>
    visible: boolean
    searchValue: string
}

function Components({ books, visible, searchValue }: ComponentsProps) {
    const schemaScale = useReportStore2((state) => state.schemaScale)

    const checkSize = useCallback(
        (size?: number) => {
            const result = size ? size : 50
            return result * schemaScale
        },
        [schemaScale]
    )

    const visibleStyle = useMemo(() => ({ display: visible ? undefined : "none" }), [visible])

    const render = useMemo(
        () =>
            books.map((b, i) => {
                return (
                    <div
                        key={i}
                        className="library-component-container"
                        style={{
                            display:
                                !searchValue ||
                                b.name?.toUpperCase().includes(searchValue.toUpperCase())
                                    ? undefined
                                    : "none"
                        }}
                    >
                        <div
                            className="library-component-parent"
                            style={{ width: checkSize(b.width), height: checkSize(b.height) }}
                        >
                            <ComponentContainer component={b.component} />
                        </div>
                        <span
                            className="library-component-label"
                            style={{ width: checkSize(b.width) }}
                        >
                            {b.name}
                        </span>
                    </div>
                )
            }),
        [books, checkSize, searchValue]
    )

    return (
        <div className="library-components" style={visibleStyle}>
            {render}
        </div>
    )
}

export default memo(Components)
