import { forwardRef, memo, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { useContainerVisible, useExpression } from "../../utils/CustomHooks"
import "./VentilatorComponent.scss"
import { VentilatorParameters } from "./VentilatorEditor"
import { ReactComponent as VentilatorRotor } from "./svg/ventilator-rotor.svg"
import { ReactComponent as VentilatorStator } from "./svg/ventilator-stator.svg"

type Params = VentilatorParameters

type Props = ComponentProps<Params>

const VentilatorComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, setContainerVisible }, ref) => {
        useContainerVisible(setContainerVisible, false)

        const [power] = useExpression(expression, 0)

        const style = useMemo(() => ({ animationDuration: (power ? 3 - power / 40 : 0) + "s" }), [
            power
        ])
        return (
            <>
                <VentilatorStator className="ventilator-stator" />
                <VentilatorRotor className="ventilator-rotor" style={style} />
            </>
        )
    }
)

export default memo(VentilatorComponent)
