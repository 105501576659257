import { DeviceSetupObject } from "../user/DeviceSetup"

export enum CacheType {
    GROUPS = "groups",
    REPORTS = "reports",
    REPORT = "report",
    COPIED_COMPONENTS = "copiedComponents"
}

export default class LocalStorageUtils {
    public static DEVICE_SETUP_KEY = "deviceSetup"
    public static NAVIGATION_VISIBILITY_KEY = "navigationVisibility"
    public static NAV_SELECTED_GROUP = "navSelectedGroup"

    public static setNavigationVisibility = (visibility: boolean) => {
        localStorage.setItem(LocalStorageUtils.NAVIGATION_VISIBILITY_KEY, visibility + "")
    }

    public static getNavigationVisibility = () => {
        const result = localStorage.getItem(LocalStorageUtils.NAVIGATION_VISIBILITY_KEY)
        return result && result === "true"
    }

    public static setNavSelectedGroups = (selectedGroups: Record<string, Array<any>>) => {
        localStorage.setItem(LocalStorageUtils.NAV_SELECTED_GROUP, JSON.stringify(selectedGroups))
    }

    public static getNavSelectedGroups = () => {
        const result = localStorage.getItem(LocalStorageUtils.NAV_SELECTED_GROUP)
        return JSON.parse(result ?? "{}")
    }

    public static setDeviceSetup = (setup: DeviceSetupObject) => {
        localStorage.setItem(LocalStorageUtils.DEVICE_SETUP_KEY, JSON.stringify(setup))
    }

    public static loadSetupObject = (): DeviceSetupObject => {
        const stringSetup = localStorage.getItem(LocalStorageUtils.DEVICE_SETUP_KEY)
        let result: DeviceSetupObject
        if (stringSetup) {
            result = JSON.parse(stringSetup)
        } else {
            result = {
                animations: true,
                twoFactorSuperKey: ""
            }
        }
        return result
    }
}
