import { produce } from "immer"
import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { DEVELOPMENT_MODE } from "../constants"
import LocalStorageUtils from "../utils/LocalStorageUtils"
import { useAppStore } from "./AppStore"

const STORE_NAME = "NavBarStore"

export enum ReportState {
    NORMAL = "normal",
    WARNING = "warning",
    ERROR = "error"
}

type ReportNavState = {
    state?: ReportState
}

export type INavBarStore = {
    reportsState: Record<number, ReportNavState | undefined>
    setReportState: (reportId: number, state: ReportState) => void

    headerText: string
    headerVisible: boolean
    setHeaderText: (headerText: string) => void
    setHeaderVisibility: (visibility: boolean) => void
    setHeaderTextByReportId: (reportId: number) => void

    menuVisible: boolean
    setMenuVisibility: (visibility: boolean) => void

    navSelectedGroupsIds: Record<string, Array<string | number>>
    setNavSelectedGroupsIds: (navId: string, selectedGroupsIds: Array<string | number>) => void
}

export const useNavBarStore = create<INavBarStore, [["zustand/devtools", never]]>(
    devtools(
        (set, get) => ({
            reportsState: {},
            setReportState: (reportId: number, rState: ReportState) =>
                set(
                    produce((state: INavBarStore) => {
                        const reportsState = state.reportsState
                        let navState = reportsState[reportId]
                        if (!navState) {
                            reportsState[reportId] = {}
                        }
                        reportsState[reportId]!.state = rState
                    }),
                    false,
                    "setReportState"
                ),

            headerText: "",
            headerVisible: false,
            setHeaderText: (headerText: string) =>
                set((state) => ({ headerText }), false, "setHeaderText"),
            setHeaderVisibility: (visibility: boolean) =>
                set((state) => ({ headerVisible: visibility }), false, "setHeaderVisibility"),
            setHeaderTextByReportId: (reportId: number) =>
                set(
                    (state) => {
                        const groups = useAppStore.getState().currentUser?.groups
                        const headerText = groups
                            ?.flatMap((g) => g.reports)
                            .find((r) => (r?.id ?? "") + "" === reportId + "")?.name
                        return { headerText }
                    },
                    false,
                    "setHeaderTextByReportId"
                ),

            menuVisible: LocalStorageUtils.getNavigationVisibility() ? true : false,
            setMenuVisibility: (visibility: boolean) => {
                set((state) => ({ menuVisible: visibility }), false, "setMenuVisibility")
                LocalStorageUtils.setNavigationVisibility(visibility)
            },

            navSelectedGroupsIds: LocalStorageUtils.getNavSelectedGroups(),
            setNavSelectedGroupsIds: (navId: string, selectedGroupsIds: Array<string | number>) => {
                set((state) => {
                    const navSelectedGroupsIds = { ...state.navSelectedGroupsIds }
                    navSelectedGroupsIds[navId] = [...selectedGroupsIds]
                    LocalStorageUtils.setNavSelectedGroups(navSelectedGroupsIds)
                    return { navSelectedGroupsIds }
                })
            }
        }),
        { enabled: DEVELOPMENT_MODE, name: STORE_NAME }
    )
)

if (DEVELOPMENT_MODE) {
    mountStoreDevtool(STORE_NAME, useNavBarStore)
}
