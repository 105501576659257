import { forwardRef, memo, useCallback, useContext, useEffect, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { useReportStore } from "../../stores/ReportStore"
import { KeyType } from "../../types/KeysTypes"
import { useContainerVisible, useImperative, useReportId } from "../../utils/CustomHooks"
import { PIPE_SIZES } from "../Pipe/PipeEditor"
import { TankColor } from "../Tank/TankEditor"
import { PipeTParameters } from "./PipeTEditor"
import { ReactComponent as PipeRound } from "./svg/pipe-T.svg"

type Params = PipeTParameters

type Props = ComponentProps<Params>

const TPipeSizes = PIPE_SIZES.map((s) => s * 2)

const PipeRoundComponent = forwardRef<IBaseComponent, Props>(
    ({ size, color, setContainerVisible }, ref) => {
        const reportId = useReportId()
        const setContainerSize = useReportStore((state) => state.setContainerSize)

        const component = useContext(ComponentContext)?.component!
        useContainerVisible(setContainerVisible, false)

        useImperative(
            ref,
            useMemo(() => {
                const messenger = {
                    name: "size",
                    enums: PIPE_SIZES.map((v, i) => ({ enum: i + 1 })),
                    value: size
                }
                return [
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [size])
        )

        const recalculateContainerSize = useCallback(() => {
            let maxW, maxH
            maxW = maxH = TPipeSizes[size - 1]

            setContainerSize(component, reportId, maxW, maxH)
        }, [setContainerSize, component, size, reportId])

        useEffect(() => recalculateContainerSize(), [recalculateContainerSize])

        const colorClass = "pipe-" + (color ?? TankColor.BLUE)
        const className = `${colorClass}`

        return <PipeRound className={className} />
    }
)

export default memo(PipeRoundComponent)
