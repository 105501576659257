import { memo, useCallback, useEffect, useMemo, useState } from "react"

import "../css/ReportsList.scss"
import "../css/TileContainer.scss"

import Form, { ButtonItem, Item as FItem } from "devextreme-react/form"
import Popup from "devextreme-react/popup"
import Toolbar, { Item } from "devextreme-react/toolbar"

import { ReactSortable } from "react-sortablejs"
import { MenuItem } from "../basic/HeaderItems"
import { useAppStore } from "../stores/AppStore"
import { IReport } from "../types/BaseTypes"
import {
    createReport,
    deleteReport,
    editReportName,
    editReportsOrder
} from "../utils/API/APIReports"
import AdminComponent from "../utils/AdminComponent"
import { standardError, standardSuccess } from "../utils/NotifyUtils"
import ReportUtils from "../utils/ReportUtils"
import RectangleItem from "./RectangleItem"

type Props = {
    loadCurrentUser: () => void
}

function ReportsList({ loadCurrentUser }: Props) {
    const groupId = useAppStore((state) => state.groupId)
    const currentUser = useAppStore((state) => state.currentUser!)

    const [report, setReport] = useState<IReport>({})
    const [reports, setReports] = useState<Array<IReport>>()
    const [popupVisible, setPopupVisible] = useState(false)
    const [editting, setEditting] = useState(false)
    const [dnd, setDnd] = useState(false)

    const addButtonOptions = useMemo(
        () => ({
            icon: "add",
            hint: "Přidat report.",
            onClick: () => {
                setPopupVisible(true)
                setEditting(false)
            }
        }),
        []
    )

    const saveButtonOptions = useMemo(() => {
        const saveOrder = () => {
            if (reports) {
                editReportsOrder(reports)
                    .then((response) => {
                        loadCurrentUser()
                        standardSuccess("Reporty seřazeny.")
                    })
                    .catch(standardError)
            }
        }
        return {
            icon: "save",
            hint: "Uložit pořadí rozložení.",
            onClick: saveOrder
        }
    }, [loadCurrentUser, reports])

    const dndOptions = useMemo(
        () => ({
            text: "Drag and Drop",
            hint: "Pro změnu pořadí reportů pomocí DnD zatrhněte.",
            onValueChanged: (e: any) => {
                setDnd(e.value)
            }
        }),
        []
    )

    useEffect(() => {
        const getReports = () => {
            const reports = currentUser.groups?.find((g) => g.id === groupId)?.reports ?? []
            ReportUtils.sortReports(reports)
            return reports
        }

        setReports(getReports())
    }, [currentUser, groupId])

    const handlePopupHidden = useCallback(() => {
        setPopupVisible(false)
        setReport({})
    }, [])

    const menuReportClick = useCallback((report: Object) => {
        setReport(Object.assign({}, report))
        setPopupVisible(true)
        setEditting(true)
    }, [])

    const deleteReportClick = useCallback(
        (report: IReport) => {
            deleteReport(report.id!)
                .then((response) => {
                    loadCurrentUser()
                    standardSuccess("Záznam smazán.")
                })
                .catch(standardError)
        },
        [loadCurrentUser]
    )

    const renderPopup = useCallback(() => {
        const handleSave = (e: any) => {
            e.preventDefault()

            const newReport = Object.assign({}, report)

            if (editting) {
                editReportName(newReport)
                    .then((response) => {
                        loadCurrentUser()
                        setReport({})
                        setPopupVisible(false)
                        standardSuccess("Záznam změněn.")
                    })
                    .catch(standardError)
            } else {
                createReport(newReport, groupId + "")
                    .then((response) => {
                        loadCurrentUser()
                        setReport({})
                        setPopupVisible(false)
                        standardSuccess("Záznam uložen.")
                    })
                    .catch(standardError)
            }
        }

        return (
            <form onSubmit={handleSave}>
                <Form id="reportsForm" formData={report} labelLocation="top">
                    <FItem dataField="name" />
                    <ButtonItem
                        horizontalAlignment="center"
                        buttonOptions={{
                            text: "Uložit",
                            type: "default",
                            useSubmitBehavior: true
                        }}
                    />
                </Form>
            </form>
        )
    }, [report, editting, groupId, loadCurrentUser])

    const group = useMemo(() => currentUser.groups?.find((g) => g.id === groupId), [
        currentUser.groups,
        groupId
    ])

    return (
        <div className="text-align-center">
            <span className="heading-1">Reporty skupiny {group?.name}</span>
            <div>
                <AdminComponent>
                    <div>
                        <Popup
                            width={300}
                            height="auto"
                            showTitle={true}
                            title="Přidat nový přehled"
                            dragEnabled={true}
                            hideOnOutsideClick={true}
                            visible={popupVisible}
                            contentRender={renderPopup}
                            onHiding={handlePopupHidden}
                        />
                        <Toolbar>
                            <Item location="after" widget="dxCheckBox" options={dndOptions} />
                            <Item location="after" widget="dxButton" options={saveButtonOptions} />
                            <Item location="after" widget="dxButton" options={addButtonOptions} />
                        </Toolbar>
                    </div>
                </AdminComponent>
                {reports && (
                    <ReactSortable
                        key={dnd + ""}
                        animation={200}
                        disabled={!dnd}
                        className="tile-container"
                        list={reports as any}
                        setList={(newState) => setReports(newState as any)}
                    >
                        {reports.map((report) => (
                            <RectangleItem
                                key={report.id}
                                isAdmin={currentUser.admin!}
                                menuItemClick={menuReportClick}
                                deleteItemHandle={deleteReportClick}
                                item={report}
                                itemUrl={`/${MenuItem.GROUPS}/${groupId}/${MenuItem.REPORTS}/${report.id}`}
                            />
                        ))}
                    </ReactSortable>
                )}
            </div>
        </div>
    )
}

export default memo(ReportsList)
