import { forwardRef, memo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { useContainerVisible, useExpression } from "../../utils/CustomHooks"
import "./CompressorComponent.scss"
import { CompressorParameters } from "./CompressorEditor"
import { ReactComponent as Compressor } from "./svg/compressor.svg"

type Params = CompressorParameters

type Props = ComponentProps<Params>

const CompressorComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, expressionError, setContainerVisible }, ref) => {
        useContainerVisible(setContainerVisible, false)

        const [active] = useExpression(expression, false)
        const [error] = useExpression(expressionError, false)

        let className = "compressor "
        if (error) {
            className += "compressor-error"
        } else if (active) {
            className += "compressor-active"
        }

        return <Compressor className={className} />
    }
)

export default memo(CompressorComponent)
