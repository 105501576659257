import { memo, useEffect } from "react"
import { RouteProps, useLocation } from "react-router"
import { Route } from "react-router-dom"
import GAUtils from "../utils/GAUtils"

type Props = RouteProps

const RouteMiddleware = ({ ...rest }: Props) => {
    const location = useLocation()

    useEffect(() => {
        GAUtils.sendEventCustomObject("page_view", {
            // page_title: title,
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, [location])

    return <Route {...rest} />
}

export default memo(RouteMiddleware)
