import Form, { Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export type VentilatorParameters = BaseParams & {
    expression: string
}

export const getVentilatorBasicConfigurations = () => {
    return [
        {
            expression: "10"
        }
    ]
}

function VentilatorEditor({ parameters }: EditorProps<VentilatorParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <SimpleItem
                            render={initMonacoEditor(parameters, "expression")}
                            helpText="Výsledek výrazu musí být roven číslu z intervalu 0-100, kde 0 je vypnuto."
                        >
                            <Label text="Výraz běhu" />
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(VentilatorEditor)
