import { forwardRef, memo, useCallback, useContext, useEffect, useMemo } from "react"
import { $enum } from "ts-enum-util"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { useAppStore } from "../../stores/AppStore"
import { useReportStore } from "../../stores/ReportStore"
import { KeyType } from "../../types/KeysTypes"
import {
    useContainerVisible,
    useExpression,
    useImperative,
    useReportId
} from "../../utils/CustomHooks"
import { TankColor } from "../Tank/TankEditor"
import "./PipeComponent.scss"
import { PIPE_SIZES, PipeParameters, PipeRoundType, PipeType } from "./PipeEditor"
import { ReactComponent as PipeHorizontal } from "./svg/pipe-horizontal.svg"
import { ReactComponent as PipeRound1 } from "./svg/pipe-round-1.svg"
import { ReactComponent as PipeRound2 } from "./svg/pipe-round-2.svg"
import { ReactComponent as PipeRound3 } from "./svg/pipe-round-3.svg"
import { ReactComponent as PipeRound4 } from "./svg/pipe-round-4.svg"
import { ReactComponent as PipeT1 } from "./svg/pipe-t-1.svg"
import { ReactComponent as PipeT2 } from "./svg/pipe-t-2.svg"
import { ReactComponent as PipeT3 } from "./svg/pipe-t-3.svg"
import { ReactComponent as PipeT4 } from "./svg/pipe-t-4.svg"
import { ReactComponent as PipeVertical } from "./svg/pipe-vertical.svg"

type Params = PipeParameters

type Props = ComponentProps<Params>

const roundPipeSizes = PIPE_SIZES.map((s) => s * 1.5)
const tPipeSizes = PIPE_SIZES.map((s) => s * 2)

const PipeComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, roundType, type, size, color, setContainerVisible }, ref) => {
        const deviceSetup = useAppStore((state) => state.deviceSetup)
        const reportId = useReportId()
        const setContainerSize = useReportStore((state) => state.setContainerSize)

        const component = useContext(ComponentContext)?.component!
        useContainerVisible(setContainerVisible, false)

        const [active] = useExpression(expression, false)

        useImperative(
            ref,
            useMemo(() => {
                const nested = {
                    name: "roundType",
                    enums: $enum(PipeRoundType)
                        .getValues()
                        .map((e) => ({ enum: e })),
                    value: roundType
                }
                const messenger = {
                    name: "size",
                    enums: PIPE_SIZES.map((v, i) => ({ enum: i + 1 })),
                    value: size
                }
                return [
                    {
                        keyType: KeyType.SHIFT,
                        messenger: {
                            name: "type",
                            enums: [
                                { enum: PipeType.HORIZONTAL },
                                { enum: PipeType.VERTICAL },
                                {
                                    enum: PipeType.ROUND,
                                    nested: nested
                                },
                                {
                                    enum: PipeType.T,
                                    nested: nested
                                }
                            ],
                            value: type
                        }
                    },
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [roundType, size, type])
        )

        const recalculateContainerSize = useCallback(() => {
            let maxW, maxH

            switch (type) {
                case PipeType.ROUND:
                    maxW = maxH = roundPipeSizes[size - 1]
                    break
                case PipeType.T:
                    maxW = maxH = tPipeSizes[size - 1]
                    break
                case PipeType.HORIZONTAL:
                    maxH = PIPE_SIZES[size - 1]
                    break
                case PipeType.VERTICAL:
                    maxW = PIPE_SIZES[size - 1]
                    break
            }

            setContainerSize(component, reportId, maxW, maxH)
        }, [setContainerSize, component, size, type, reportId])

        useEffect(() => recalculateContainerSize(), [recalculateContainerSize])

        const pipe = useMemo(() => {
            const activeClass = active
                ? deviceSetup.animations
                    ? "pipe-animation"
                    : "pipe-active"
                : "pipe-not-active"
            const colorClass = "pipe-" + (color ?? TankColor.BLUE)
            const className = `${activeClass} ${colorClass}`

            let pipe = <div />
            switch (type) {
                case "horizontal":
                    pipe = (
                        <PipeHorizontal
                            style={{ height: PIPE_SIZES[size - 1] }}
                            className={`pipe-${type} ${className}`}
                        />
                    )
                    break
                case "vertical":
                    pipe = (
                        <PipeVertical
                            style={{ width: PIPE_SIZES[size - 1] }}
                            className={`pipe-${type} ${className}`}
                        />
                    )
                    break
                case "round":
                    let styleRound = { width: roundPipeSizes[size - 1] }
                    switch (roundType) {
                        case 1:
                            pipe = <PipeRound1 style={styleRound} className={className} />
                            break
                        case 2:
                            pipe = <PipeRound2 style={styleRound} className={className} />
                            break
                        case 3:
                            pipe = <PipeRound3 style={styleRound} className={className} />
                            break
                        case 4:
                            pipe = <PipeRound4 style={styleRound} className={className} />
                            break
                    }
                    break
                case "T":
                    let styleT = { width: tPipeSizes[size - 1] }
                    switch (roundType) {
                        case 1:
                            pipe = <PipeT1 style={styleT} className={className} />
                            break
                        case 2:
                            pipe = <PipeT2 style={styleT} className={className} />
                            break
                        case 3:
                            pipe = <PipeT3 style={styleT} className={className} />
                            break
                        case 4:
                            pipe = <PipeT4 style={styleT} className={className} />
                            break
                    }
                    break
            }
            return pipe
        }, [roundType, size, type, active, color, deviceSetup.animations])

        return pipe
    }
)

export default memo(PipeComponent)
