import { forwardRef, memo, useCallback, useEffect, useState } from "react"
import ImagesCache from "../../cache/ImagesCache"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { standardError } from "../../utils/NotifyUtils"
import "./ImageComponent.scss"
import { ImageParameters } from "./ImageEditor"

type Params = ImageParameters

type Props = ComponentProps<Params>

const ImageComponent = forwardRef<IBaseComponent, Props>(({ imageId }, ref) => {
    const [id, setId] = useState(0)
    const [data, setData] = useState(null)
    const [type, setType] = useState("")

    const checkImage = useCallback(() => {
        if (imageId && imageId !== id) {
            ImagesCache.getInstance()
                .getImage(imageId)
                .then((response: any) => {
                    setId(imageId)
                    setData(response.data)
                    setType(response.type)
                })
                .catch(standardError)
        }
    }, [id, imageId])

    useEffect(() => checkImage(), [checkImage])

    const stringData = "data:" + type + ";base64," + data

    return type.includes("pdf") ? (
        <iframe title={"id" + imageId} width="100%" height="100%" src={stringData} />
    ) : (
        <img
            className="image-component"
            width="100%"
            height="100%"
            alt="pic"
            src={stringData}
        ></img>
    )
})

export default memo(ImageComponent)
