import {
    forwardRef,
    memo,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react"
import ReactResizeDetector from "react-resize-detector"
import "./CommonComponent.scss"

import { cloneDeep } from "lodash"
import { Handle } from "reactflow"
import { useAppStore } from "../../../../../stores/AppStore"
import { useHoveredStore } from "../../../../../stores/HoveredStore"
import { useRegisteredStore } from "../../../../../stores/RegisteredStore"
import { useReportStore } from "../../../../../stores/ReportStore"
import { BaseParams, IComponent } from "../../../../../types/BaseTypes"
import { RealSize } from "../../../../../types/ComponentTypes"
import { KeyType } from "../../../../../types/KeysTypes"
import ComponentsCreator from "../../../../../utils/ComponentsCreator"
import { useExpression, useReport2Fields } from "../../../../../utils/CustomHooks"
import { RIGHT_DEGREES, closestDegree } from "../../../../../utils/DegreeUtils"
import ErrorBoundary from "../../../../../utils/ErrorBoundary"
import {
    clearOffset,
    offsetFromStyle,
    shiftPosition,
    translateOffset
} from "../../../../../utils/FlowHandleUtils"
import { DraggableComponent } from "../../../../../utils/dnd/DraggableElement"
import { DraggableType } from "../../../../../utils/dnd/DroppableContainer"
import { ComponentContext } from "../../../../contexts/ComponentContext"
import { FlowNodeContext } from "../../../../contexts/FlowNodeContext"
import { HoverProvider } from "../../../../contexts/HoverContext"
import { ParentContext } from "../../../../contexts/ParentContext"

type Props = {
    component?: IComponent
}

export type ComponentProps<T extends BaseParams = BaseParams> = T & {
    realSize: RealSize
    setContainerVisible: (visibility: boolean) => void
}

export interface IBaseComponent {
    onKeyDown?: (keyType: KeyType) => void
    // TODO typovani
    getSpecialRect?: () => any
}

export interface ICommonComponent {
    getSpecialRect: () => any
}

const CommonComponent = forwardRef<ICommonComponent, Props>((props: Props, ref) => {
    const isAdmin = useAppStore((state) => state.currentUser!.admin)
    const onConnect = useReportStore((state) => state.onConnect)
    const [editMode] = useReport2Fields((s) => [s.editMode])
    const registerComponent = useRegisteredStore((state) => state.registerComponent)
    const hasHoveredComponents = useHoveredStore((state) => state.hasHoveredComponents)

    const flowNode = useContext(FlowNodeContext)
    const parent = useContext(ParentContext)
    const componentContext = useContext(ComponentContext)
    const component = componentContext?.component ?? props.component!
    const { guid, parameters, name, type } = component
    const visibilityExpression = parameters?.visibilityExpression
    const rotationExpression = parameters?.rotationExpression
    const transparentBackground = parameters?.transparentBackground
    const ySwitch = parameters?.ySwitch
    const handles = parameters?.handles

    const flowNodeContext = useContext(FlowNodeContext)

    const [containerVisible, setContainerVisible] = useState(true)
    const [containerVisibility, setContainerVisibility] = useState(false)
    const [realSize, setRealSize] = useState({ width: 100, height: 100 })
    const { width, height } = realSize

    const div = useRef<any>(null)
    const child = useRef<any>(null)

    const [visible] = useExpression(visibilityExpression, true)
    const [rotation] = useExpression(rotationExpression, 0)

    useEffect(() => {
        const isInnerComponent = parent?.context.parent?.parent

        setContainerVisibility(containerVisible && !transparentBackground && !isInnerComponent)
    }, [containerVisible, transparentBackground, parent])

    useImperativeHandle(
        ref,
        () => ({
            getSpecialRect: () => {
                return child.current?.getSpecialRect?.()
            }
        }),
        []
    )

    const onKeyDown = useCallback(
        (keyType: KeyType) => {
            const ch = child.current
            if (hasHoveredComponents(guid)) {
                if (editMode && ch && ch.onKeyDown) {
                    ch.onKeyDown(keyType)
                }
            }
        },
        [guid, editMode, hasHoveredComponents]
    )

    useEffect(() => registerComponent(component.guid, { onKeyDown }), [
        component.guid,
        onKeyDown,
        registerComponent
    ])

    const handleResize = useCallback((width?: number, height?: number): void => {
        if (width && height) {
            setRealSize((oldSize) => {
                if (width !== oldSize.width || height !== oldSize.height) {
                    return {
                        width,
                        height
                    }
                }
                return oldSize
            })
        }
    }, [])

    const getBook = useCallback(() => {
        const cmp = cloneDeep(component)
        // pozor na konstanty 20, plati s padding 10
        const w = containerVisibility ? width + 20 : width
        const h = containerVisibility ? height + 20 : height
        return {
            name: cmp.name,
            component: cmp as any,
            width: w,
            height: h,
            path: "root"
        }
    }, [component, containerVisibility, width, height])

    const canLibraryDrag = useCallback(() => isAdmin && editMode && !flowNodeContext, [
        editMode,
        isAdmin,
        flowNodeContext
    ])

    const defaultComponentClass = component.type ? "" : "default-component"

    const childComponent = useMemo(() => {
        const componentProps = {
            ...component.parameters,
            realSize,
            ref: child,
            setContainerVisible
        }
        return ComponentsCreator.createComponent(component.type!, componentProps)
    }, [realSize, component.parameters, component.type])

    const style = useMemo(
        () => ({
            transform: `${ySwitch ? "rotateY(180deg) " : ""} rotate(${
                isNaN(rotation) ? 0 : rotation
            }deg) `
        }),
        [rotation, ySwitch]
    )

    // tohle je potreba zavolat, jinak by nedoslo k oziveni handlu vytvoreneho za behu
    useEffect(() => {
        flowNode?.updateInternals()
    }, [handles, flowNode, rotation])

    const handlesComponents = useMemo(() => {
        if (flowNodeContext) {
            return handles?.map((h) => {
                const closestDeg = closestDegree(rotation)
                const shift = RIGHT_DEGREES.indexOf(closestDeg)
                const position = shiftPosition(h.position, shift)
                const offsetOrigin = {
                    left: h.left ? h.left : 0,
                    top: h.top ? h.top : 0,
                    right: h.right ? h.right : 0,
                    bottom: h.bottom ? h.bottom : 0
                }
                const clearedOffset = clearOffset(offsetOrigin, h.position)
                const offset = translateOffset(clearedOffset)
                const offsetStyle = offsetFromStyle(
                    clearOffset(offset, position),
                    h.position,
                    position
                )

                return (
                    <Handle
                        id={h.guid}
                        key={h.guid}
                        type="source"
                        position={position}
                        style={{
                            ...offsetStyle,
                            visibility: editMode ? "visible" : "hidden"
                            // width: "0px",
                            // height: "0px",
                            // minWidth: "0px",
                            // minHeight: "0px"
                        }}
                        isConnectable={true}
                        onConnect={onConnect}
                    />
                )
            })
        }
        return undefined
    }, [handles, onConnect, editMode, flowNodeContext, rotation])

    const render = useCallback(
        (onMouseEnter: () => void, onMouseLeave: () => void) => (
            <div
                className={`common-component ${
                    containerVisibility ? "common-component-background component-item" : ""
                } ${defaultComponentClass} ${visible ? "" : "not-visible"}`}
                style={style}
                ref={div}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <ErrorBoundary name={`[${type}: ${name}]`}>
                    {childComponent}
                    {handlesComponents}
                </ErrorBoundary>
            </div>
        ),
        [
            childComponent,
            containerVisibility,
            defaultComponentClass,
            style,
            visible,
            type,
            name,
            handlesComponents
        ]
    )

    return (
        <DraggableComponent
            item={getBook}
            draggableType={DraggableType.LIBRARY}
            canDrag={canLibraryDrag}
            w="100%"
            h="100%"
        >
            <ReactResizeDetector onResize={handleResize} targetRef={div} handleWidth handleHeight>
                <HoverProvider>{render}</HoverProvider>
            </ReactResizeDetector>
        </DraggableComponent>
    )
})

export default memo(CommonComponent)
