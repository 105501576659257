import { Group, Object3DEventMap } from "three"
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import { v4 } from "uuid"
import { MeshType } from "../components/ThreeD/ThreeDEditorUtils"
import { ThreeDGroupCustom } from "../components/ThreeDCustom/ThreeDCustomEditor"

export class ThreeDUtils {
    static parseFBX = (data: string) => {
        const loader = new FBXLoader()
        const binaryData = atob(data)
        const arrayBuffer = new ArrayBuffer(binaryData.length)
        const uintArray = new Uint8Array(arrayBuffer)
        for (let i = 0; i < binaryData.length; i++) {
            uintArray[i] = binaryData.charCodeAt(i)
        }
        return loader.parse(arrayBuffer, "")
    }

    static modelToGroupDefinition(model: Group<Object3DEventMap>): ThreeDGroupCustom {
        const result: ThreeDGroupCustom = { guid: v4() }

        const json = model.toJSON()
        const geometries = json.geometries
        const materials = json.materials
        const object = json.object

        ThreeDUtils.transformObject(object, result, geometries, materials)

        return result
    }

    static transformObject = (
        object: any,
        result: ThreeDGroupCustom,
        geometries: Array<any>,
        materials: Array<any>
    ) => {
        if (object.type === "Group") {
            if (!result.groups) {
                result.groups = []
            }

            const newGroup = {
                guid: object.uuid,
                name: object.name,
                userData: object.userData,
                layers: object.layers,
                matrix: object.matrix,
                up: object.up
            }
            result.groups.push(newGroup)

            if (object.children) {
                object.children.forEach((ch: any) => {
                    ThreeDUtils.transformObject(ch, newGroup, geometries, materials)
                })
            }
        } else if (object.type === "Mesh") {
            if (!result.meshs) {
                result.meshs = []
            }

            const newMesh = {
                guid: object.uuid,
                name: object.name,
                userData: object.userData,
                layers: object.layers,
                matrix: object.matrix,
                up: object.up,
                type: MeshType.OBJECTTHREED,
                geometry: geometries.find((g) => g.uuid === object.geometry),
                material: materials.find((g) => g.uuid === object.material)
            }
            result.meshs.push(newMesh)
        }
    }
}
