import React, { RefObject } from "react"

import { DataGrid } from "devextreme-react"
import CustomStore from "devextreme/data/custom_store"
import { cloneDeep } from "lodash"
import { toast } from "react-toastify"
import { IBase } from "../types/BaseTypes"
import { gqlDeepCopy, gqlDeepDelete, gqlDelete, gqlPersist } from "../utils/APIUtils"
import EntityUtils from "../utils/EntityUtils"
import {
    STANDARD_NOTIFY_OPTIONS,
    confirmation,
    standardError,
    standardSuccess,
    standardWarning
} from "../utils/NotifyUtils"

export type BaseCollectionOptions = {
    entityName: string
    subEntitiesToIds?: Array<string>
    title?: string
    copyButton?: boolean
    deepCopyButton?: boolean
    deepDeleteButton?: boolean
}

export type CollectionProps = {}

export type CollectionState<E> = {
    dataSource: any
    selectedData: Array<E>
    originalEntities: Array<E>
    title: string
}

export const TABLE_HEIGHT_IN_VH = 96

abstract class BaseCollection<
    E extends IBase,
    P extends CollectionProps = CollectionProps,
    S extends CollectionState<E> = CollectionState<E>
> extends React.Component<P, S> {
    public static readonly TABLE_BUTTONS = ["edit", "delete"]
    public static readonly SELECT_BOX_OPTIONS = {
        displayExpr: "name",
        valueExpr: "id"
    }
    public static readonly TAG_BOX_OPTIONS = {
        ...BaseCollection.SELECT_BOX_OPTIONS,
        showSelectionControls: true,
        applyValueMode: "useButtons"
    }

    private refDiv: RefObject<any>
    private ref: RefObject<DataGrid>
    private options: BaseCollectionOptions

    constructor(props: P, options: BaseCollectionOptions) {
        super(props)
        this.state = {
            ...this.state,
            title: options.title,
            selectedData: []
        }
        this.options = options
        this.ref = React.createRef()
        this.refDiv = React.createRef()

        this.onSelectionChanged = this.onSelectionChanged.bind(this)
        this.onCopy = this.onCopy.bind(this)
        this.createEntities = this.createEntities.bind(this)
    }

    protected abstract getEntities(): Promise<Array<E>>
    protected abstract renderGrid(
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element

    protected createEntities(values: Array<E>): Promise<Array<E>> {
        return gqlPersist(this.options.entityName, values)
    }

    protected editEntities = (values: Array<E>): Promise<Array<E>> => {
        return gqlPersist(this.options.entityName, values)
    }

    protected onCopy(values: Array<E>, oldIds?: Array<number>): Promise<Array<E>> {
        return gqlPersist(this.options.entityName, values)
    }

    protected onDeepCopy = (ids: Array<number>): Promise<Array<E>> => {
        return gqlDeepCopy(this.options.entityName, ids)
    }

    protected deleteEntities = (ids: Array<Number>): Promise<any> => {
        return gqlDelete(this.options.entityName, ids)
    }

    componentDidMount() {
        const dataSource = new CustomStore({
            key: "id",
            // TODO DEVEXTREME
            load: (loadOptions): any => {
                return this.getEntities()
                    .then((response) => {
                        this.setState({ originalEntities: cloneDeep(response) })
                        return {
                            data: EntityUtils.toIds(response, this.options.subEntitiesToIds)
                        }
                    })
                    .catch((e) => standardError(e))
            },
            insert: (values) => {
                const toastId = toast.loading("Vytvářím...", STANDARD_NOTIFY_OPTIONS)
                return this.createEntities(
                    EntityUtils.toObjects([values], this.options.subEntitiesToIds)
                )
                    .then((r) => {
                        standardSuccess(`1 ${this.options.entityName} úspěšně vytvořeno.`, toastId)
                        return r
                    })
                    .catch((e) => standardError(e, toastId))
            },
            update: (key, values) => {
                const toastId = toast.loading("Upravuji...", STANDARD_NOTIFY_OPTIONS)
                values.id = key
                return this.editEntities(
                    EntityUtils.toObjects([values], this.options.subEntitiesToIds)
                )
                    .then((r) => {
                        standardSuccess(`1 ${this.options.entityName} úspěšně upraveno.`, toastId)
                        return r
                    })
                    .catch((e) => standardError(e, toastId))
            },
            remove: (key) => {
                const toastId = toast.loading("Mažu...", STANDARD_NOTIFY_OPTIONS)
                return this.deleteEntities([key])
                    .then((r) => {
                        standardSuccess(`1 ${this.options.entityName} úspěšně smazáno.`, toastId)
                        return r
                    })
                    .catch((e) => standardError(e, toastId))
            }
        })

        this.setState({ dataSource })
    }

    handleCopy = () => {
        const clonedDTOs = cloneDeep(this.state.selectedData)
        if (clonedDTOs.length > 0) {
            const toastId = toast.loading("Kopíruji...", STANDARD_NOTIFY_OPTIONS)
            const oldIds = clonedDTOs.map((d) => d.id!)
            const preparedDTOs = clonedDTOs.map((e) => {
                e.name = e.name + "_copy"
                delete e.id
                return e
            })
            this.onCopy(EntityUtils.toObjects(preparedDTOs, this.options.subEntitiesToIds), oldIds)
                .then((r) => {
                    standardSuccess(
                        `${preparedDTOs.length} ${this.options.entityName}s úspěšně zkopírováno.`,
                        toastId
                    )
                    this.refreshTable()
                    return r
                })
                .catch((e) => standardError(e, toastId))
        } else {
            standardWarning("Vyberte položky pro zkopírování")
        }
    }

    protected handleDeepCopy = () => {
        const selectedIds = this.state.selectedData.map((d) => d.id!)
        if (selectedIds?.length > 0) {
            confirmation(
                `<i>Opravdu chcete provést hlubokou kopii ${selectedIds.length} označených?</i>`,
                "Potvrzení zkopírování",
                () => {
                    const toastId = toast.loading("Kopíruji...", STANDARD_NOTIFY_OPTIONS)
                    this.onDeepCopy(selectedIds)
                        .then((r) => {
                            standardSuccess(
                                `${selectedIds.length} ${this.options.entityName}s úspěšně provedena hluboká kopie.`,
                                toastId
                            )
                            this.refreshTable()
                        })
                        .catch((e) => standardError(e, toastId))
                }
            )
        } else {
            this.deepCopyWarning()
        }
    }

    protected deepCopyWarning = () => {
        standardWarning("Vyberte položky pro hlubokou kopii")
    }

    protected handleDeepDelete = () => {
        const selectedIds = this.state.selectedData.map((d) => d.id!)
        if (selectedIds?.length > 0) {
            confirmation(
                `<i>Opravdu chcete smazat ${selectedIds.length} označených?</i>`,
                "Potvrzení smazání",
                () => {
                    const toastId = toast.loading("Mažu...", STANDARD_NOTIFY_OPTIONS)
                    gqlDeepDelete(this.options.entityName, selectedIds)
                        .then((r) => {
                            standardSuccess(
                                `${selectedIds.length} ${this.options.entityName}s úspěšně provedeno hluboké smazání.`,
                                toastId
                            )
                            this.refreshTable()
                        })
                        .catch((e) => standardError(e, toastId))
                }
            )
        } else {
            standardWarning("Vyberte položky pro hluboké smazání")
        }
    }

    handleDelete = () => {
        const toDelete = this.state.selectedData
        if (toDelete.length > 0) {
            confirmation(
                `<i>Opravdu chcete smazat ${toDelete.length} označených?</i>`,
                "Potvrzení smazání",
                () => {
                    const toastId = toast.loading("Mažu...", STANDARD_NOTIFY_OPTIONS)
                    this.deleteEntities(toDelete.map((d) => d.id!))
                        .then((r) => {
                            standardSuccess(
                                `${toDelete.length} ${this.options.entityName}s úspěšně smazáno.`,
                                toastId
                            )
                            this.refreshTable()
                            return r
                        })
                        .catch((e) => standardError(e, toastId))
                }
            )
        } else {
            standardWarning("Vyberte položky pro smazání")
        }
    }

    protected refreshTable = () => {
        this.ref.current?.instance.refresh()
    }

    protected onSelectionChanged(data: { selectedRowsData?: Array<E> }) {
        this.setState({ selectedData: data.selectedRowsData! })
    }

    protected onEditorPreparing = (e: {
        parentType?: string
        dataField?: string
        editorOptions?: any
    }) => {
        if (e.parentType === "dataRow" && e.dataField === "password")
            e.editorOptions.mode = "password"
    }

    protected fromNames = (cellInfo: any, namesSource: Array<IBase>) => {
        const ids = cellInfo.value
        if (ids) {
            if (ids.length > 0 && ids.map) {
                const items = ids.map((v: any) => namesSource?.find((n) => n.id === v)?.name)
                return items.join(", ")
            } else {
                return namesSource.find((n) => n.id === ids)?.name
            }
        }
        return ""
    }

    protected addCustomToolbarButtons = (toolbarItems: any) => {}

    protected onToolbarPreparing = (e: any) => {
        let toolbarItems = e.toolbarOptions.items

        toolbarItems.push({
            widget: "dxButton",
            options: {
                icon: "refresh",
                hint: "Obnovit data",
                onClick: this.refreshTable
            },
            location: "after"
        })

        this.addCustomToolbarButtons(toolbarItems)

        if (this.options.copyButton) {
            toolbarItems.push({
                widget: "dxButton",
                options: {
                    icon: "copy",
                    hint: "Zkopírovat označené",
                    onClick: this.handleCopy
                },
                location: "after"
            })
        }
        toolbarItems.push({
            widget: "dxButton",
            options: {
                icon: "trash",
                hint: "Smazat označené",
                onClick: this.handleDelete
            },
            location: "after"
        })
        if (this.options.deepCopyButton) {
            toolbarItems.push({
                widget: "dxButton",
                options: {
                    icon: "copy",
                    type: "default",
                    hint: "Hluboká kopie označených",
                    onClick: this.handleDeepCopy
                },
                location: "after"
            })
        }
        if (this.options.deepDeleteButton) {
            toolbarItems.push({
                widget: "dxButton",
                options: {
                    icon: "trash",
                    type: "default",
                    hint: "Hluboké smazání označených",
                    onClick: this.handleDeepDelete
                },
                location: "after"
            })
        }
    }

    render() {
        const { title } = this.state

        return (
            <div className="base-collection-content">
                {title && (
                    <div className="text-align-center">
                        <span className="heading-2">{title}</span>
                    </div>
                )}
                <div className="collection-table" ref={this.refDiv}>
                    {this.renderGrid(
                        this.state.dataSource,
                        this.ref,
                        this.refDiv.current?.offsetTop
                    )}
                </div>
            </div>
        )
    }
}

export default BaseCollection
