import { Popup } from "devextreme-react"
import { IPopupOptions } from "devextreme-react/popup"
import {
    forwardRef,
    memo,
    ReactNode,
    Ref,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react"

type Props = IPopupOptions & {
    children?: ReactNode
}

export interface IMyPopup {
    showPopup: () => void
    hidePopup: (e?: any) => void
}

export const usePopup = (): [Ref<IMyPopup>, IMyPopup] => {
    const ref = useRef<IMyPopup>(null)
    return useMemo(
        () => [
            ref,
            {
                showPopup: () => ref.current?.showPopup(),
                hidePopup: () => ref.current?.hidePopup()
            }
        ],
        []
    )
}

const MyPopup = forwardRef<IMyPopup, Props>((props, ref) => {
    const [visible, setVisible] = useState(false)

    const hidePopup = useCallback(
        (e: any) => {
            setVisible(false)
            props.onHiding?.(e)
        },
        [setVisible, props]
    )

    useImperativeHandle(
        ref,
        () => ({
            showPopup: () => setVisible(true),
            hidePopup
        }),
        [hidePopup]
    )

    return (
        <Popup {...props} visible={visible} onHiding={hidePopup}>
            {props.children}
        </Popup>
    )
})

export default memo(MyPopup)
