import { OrbitControls } from "@react-three/drei"
import { Canvas, Vector3, useFrame } from "@react-three/fiber"
import { forwardRef, memo, useCallback, useContext, useMemo, useRef, useState } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { EvaluatorContext, IEvaluatorContext } from "../../report/contexts/EvaluatorContext"
import { useReportStore2 } from "../../stores/ReportStore2"
import { Action } from "../../types/ComponentTypes"
import {
    useContainerVisible,
    useExecuteActions,
    useExpression,
    useScrollActivator
} from "../../utils/CustomHooks"
import EvalUtils from "../../utils/EvalUtils"
import { ThreeDGroupCustom } from "../ThreeDCustom/ThreeDCustomEditor"
import "./ThreeDComponent.scss"
import {
    ThreeDAmbientLight,
    ThreeDCamera,
    ThreeDMeshSimple,
    ThreeDParameters,
    ThreeDVector
} from "./ThreeDEditor"
import { MeshType, ThreeDAnimation, ThreeDMesh, ThreeDPointLight } from "./ThreeDEditorUtils"

type Params = ThreeDParameters

type Props = ComponentProps<Params>

const ThreeDComponent = forwardRef<IBaseComponent, Props>((props, ref) => {
    const {
        camera,
        ambientLights,
        orbitControls,
        setContainerVisible,
        gridHelper,
        axesHelper
    } = props
    const evaluatorContext = useContext(EvaluatorContext)

    useContainerVisible(setContainerVisible, false)
    useScrollActivator()

    const [cameraPos, renderAmbientLights, additionalRender, canvasStyle] = useThreeDCanvasBase(
        camera,
        ambientLights,
        orbitControls,
        gridHelper,
        axesHelper
    )

    const renderMesh = useCallback(
        (mesh: ThreeDMesh) => <MeshSimple key={mesh.guid} {...mesh} />,
        []
    )

    return (
        <Canvas camera={cameraPos} style={canvasStyle}>
            <EvaluatorContext.Provider value={evaluatorContext}>
                <ambientLight intensity={0.9} />
                <ModelGroup renderMesh={renderMesh} {...props} />
                {renderAmbientLights}
                {additionalRender}
            </EvaluatorContext.Provider>
        </Canvas>
    )
})

export const useThreeDCanvasBase = (
    camera?: ThreeDCamera,
    ambientLights?: Array<ThreeDAmbientLight>,
    orbitControls?: boolean,
    gridHelper?: boolean,
    axesHelper?: boolean
): [{ position: Vector3 }, JSX.Element | null, JSX.Element | null, any] => {
    const schemaScale = useReportStore2((state) => state.schemaScale)

    const pos = camera?.position
    const cameraPosition: { position: Vector3 } = useMemo(() => {
        const camera: { position: Vector3; fov: number; far: number; near: number } = {
            position: [pos?.x ?? 0, pos?.y ?? 0, pos?.z ?? 0],
            far: 10000,
            near: 0.1
        } as any
        return camera
    }, [pos?.x, pos?.y, pos?.z])
    const renderAmbientLights = useMemo(
        () => (
            <group>
                {ambientLights?.map((l) => (
                    <ambientLight intensity={0.9} />
                ))}
            </group>
        ),
        [ambientLights]
    )
    const additionalRender = useMemo(() => {
        return (
            <>
                {orbitControls ? <OrbitControls makeDefault /> : null}
                {gridHelper ? <gridHelper args={[20000, 20000, 0xcccccc, 0xdddddd]} /> : null}
                {axesHelper ? <axesHelper args={[2000]} /> : null}
            </>
        )
    }, [orbitControls, gridHelper, axesHelper])

    const canvasStyle = useMemo(() => {
        const scale = 1 / schemaScale
        const scaleSecond = 1 + (scale - 1) * 0.56
        //TODO zatim nefunguje presne
        return {
            transform: `scale(${scaleSecond})`,
            transformOrigin: "0 0"
        }
    }, [schemaScale])

    return useMemo(() => [cameraPosition, renderAmbientLights, additionalRender, canvasStyle], [
        cameraPosition,
        renderAmbientLights,
        additionalRender,
        canvasStyle
    ])
}

function moveWithCoordinates(source: ThreeDVector, shift: Array<number>) {
    if (shift) {
        const [x, y, z] = shift
        if (source.x !== undefined) {
            source.x += x ? x : 0
        }
        if (source.y !== undefined) {
            source.y += y ? y : 0
        }
        if (source.z !== undefined) {
            source.z += z ? z : 0
        }
    }
}

export const threeDAnimate = (
    animating: boolean,
    evaluatorContext: IEvaluatorContext | undefined,
    expr: string | undefined,
    shape: THREE.Mesh | THREE.Group,
    timeRef: { current: number }
) => {
    if (animating) {
        timeRef.current += 0.01
        if (expr) {
            const animObject = evaluatorContext?.evaluate(EvalUtils.correctExpression(expr), {
                self: { time: timeRef.current }
            })
            if (animObject) {
                const { rotation, position } = animObject
                moveWithCoordinates(shape.rotation, rotation)
                moveWithCoordinates(shape.position, position)
            }
        }
    }
}

export const useThreeDAnimating = (
    enabledExpression: string | undefined,
    time: number
): boolean => {
    const evaluatorContext = useContext(EvaluatorContext)

    return useMemo(() => {
        return evaluatorContext?.evaluate(EvalUtils.correctExpression(enabledExpression), {
            self: { time }
        })
    }, [enabledExpression, evaluatorContext, time])
}

type GroupProps = ThreeDGroupCustom & {
    renderMesh: (mesh: ThreeDMesh, index: number) => JSX.Element
}

export const ModelGroup = memo(
    ({
        renderMesh,
        animation,
        positionExpression,
        groups,
        meshs,
        pointLights,
        guid
    }: GroupProps) => {
        const evaluatorContext = useContext(EvaluatorContext)

        const ref = useRef<THREE.Group>(null!)
        const time = useRef(0)

        const [position] = useExpression<Vector3>(positionExpression, [0, 0, 0])
        const animating = useThreeDAnimating(animation?.enabledExpression, time.current)

        useFrame(() =>
            threeDAnimate(
                animating,
                evaluatorContext,
                animation?.animationExpression,
                ref.current,
                time
            )
        )

        return (
            <group ref={ref} position={position}>
                {groups?.map((g) => (
                    <ModelGroup key={g.guid} {...g} renderMesh={renderMesh} />
                ))}
                {meshs?.map((m, i) => renderMesh(m, i))}
                {pointLights?.map((l) => (
                    <PointLight key={l.guid} {...l} />
                ))}
            </group>
        )
    }
)

type PointLightProps = ThreeDPointLight & {}

const PointLight = memo(({ positionExpression, intensityExpression, guid }: PointLightProps) => {
    const ref = useRef<THREE.PointLight>(null!)

    const [position] = useExpression<Vector3>(positionExpression, [0, 0, 0])
    const [intensity] = useExpression(intensityExpression, 0)

    return <pointLight ref={ref} intensity={intensity} position={position} />
})

export const useMeshBase = (
    colorExpression?: string,
    animation?: ThreeDAnimation,
    onClickActions?: Array<Action>
): [
    React.MutableRefObject<THREE.Mesh>,
    boolean,
    number | undefined,
    (e: any, value: any) => void,
    (e: any) => void
] => {
    const ref = useRef<THREE.Mesh>(null!)
    const time = useRef(0)

    const animating = useThreeDAnimating(animation?.enabledExpression, time.current)

    const [isHovered, setIsHovered] = useState(false)

    const [color] = useExpression(colorExpression, undefined)

    const evaluatorContext = useContext(EvaluatorContext)
    const executeActions = useExecuteActions()

    useFrame(() =>
        threeDAnimate(
            animating,
            evaluatorContext,
            animation?.animationExpression,
            ref.current,
            time
        )
    )

    const onHover = useCallback(
        (e: any, value: any) => {
            e.stopPropagation()
            setIsHovered(value)
        },
        [setIsHovered]
    )

    const onClick = useCallback(
        (e: any) => {
            e.stopPropagation()

            executeActions(onClickActions)
        },
        [onClickActions, executeActions]
    )

    return useMemo(() => [ref, isHovered, color, onHover, onClick], [
        isHovered,
        onHover,
        color,
        onClick
    ])
}

type MeshProps = ThreeDMeshSimple & {}

const MeshSimple = memo(
    ({
        animation,
        positionExpression,
        geometryExpression,
        colorExpression,
        onClickActions,
        guid,
        type
    }: MeshProps) => {
        const [ref, isHovered, evaluatedColor, onHover, onClick] = useMeshBase(
            colorExpression,
            animation,
            onClickActions
        )

        const [position] = useExpression<Vector3>(positionExpression, [0, 0, 0])
        const [calculatedGeometry] = useExpression<any>(geometryExpression, [0, 0, 0])

        const clr = isHovered ? 0xe5d54d : evaluatedColor ? evaluatedColor : 0x000000

        let shape
        switch (type) {
            case MeshType.BOX:
                shape = <boxGeometry args={calculatedGeometry} attach="geometry" />
                break
            case MeshType.CONE:
                shape = <coneGeometry args={calculatedGeometry} attach="geometry" />
                break
            case MeshType.SPHERE:
                shape = <sphereGeometry args={calculatedGeometry} attach="geometry" />
                break
            case MeshType.CYLINDER:
                shape = <cylinderGeometry args={calculatedGeometry} attach="geometry" />
                break
            case MeshType.TORUS:
                shape = <torusGeometry args={calculatedGeometry} attach="geometry" />
                break
            case MeshType.TETRAHEDRON:
                shape = <tetrahedronGeometry args={calculatedGeometry} attach="geometry" />
                break
            case MeshType.LATHE:
                shape = <latheGeometry args={calculatedGeometry} attach="geometry" />
                break
            case MeshType.EXTRUDE:
                shape = <extrudeGeometry args={calculatedGeometry} attach="geometry" />
                break
            case MeshType.OBJECTTHREED:
                shape = <object3D args={calculatedGeometry} attach="geometry" />
                break
        }

        return (
            <mesh
                ref={ref}
                position={position}
                onClick={(e) => onClick(e)}
                onPointerOver={(e) => onHover(e, true)}
                onPointerOut={(e) => onHover(e, false)}
            >
                {shape}
                <meshStandardMaterial attach="material" color={clr} roughness={0.6} />
            </mesh>
        )
    }
)

export default memo(ThreeDComponent)
