import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { useReportStore2 } from "../../../stores/ReportStore2"
import { FunctionProps } from "../FunctionsList"
import OutputVariable from "../OutputVariable"

export default function ReadNotifications(props: FunctionProps) {
    const notificationGroups = useReportStore2((state) => state.notificationGroups)

    const notificationGroupsOptions = {
        dataSource: notificationGroups,
        displayExpr: "name",
        valueExpr: "id"
    }

    // const showCallResult = (response: FunctionResponse) => {
    //     const data = response.data
    //     let columns
    //     if (data) {
    //         columns = data
    //             .shift()
    //             .map((n: string, i: number) => ({ dataField: i + "", caption: n }))
    //     }

    //     return (
    //         <React.Fragment>
    //             <Form formData={response} disabled={true}>
    //                 <SimpleItem
    //                     dataField="error"
    //                     editorType="dxTextArea"
    //                     visible={response.error ? true : false}
    //                     editorOptions={TEXT_AREA_OPTIONS}
    //                 ></SimpleItem>
    //             </Form>
    //             <DataGrid
    //                 dataSource={data}
    //                 showBorders={true}
    //                 columnAutoWidth={true}
    //                 columns={columns}
    //             >
    //                 <FilterRow visible={true} />
    //                 <Paging defaultPageSize={10} />
    //             </DataGrid>
    //         </React.Fragment>
    //     )
    // }

    const item = props.item

    return (
        <Form formData={item}>
            <GroupItem>
                <SimpleItem
                    dataField="parameters.groupIds"
                    editorType="dxTagBox"
                    editorOptions={notificationGroupsOptions}
                >
                    <Label text="Skupiny notifikací" />
                </SimpleItem>
                <GroupItem>
                    <OutputVariable formData={item} dataField="parameters.output" />
                </GroupItem>
            </GroupItem>
        </Form>
    )
}
