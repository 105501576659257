import { Button, Form } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { memo, useCallback, useMemo } from "react"
import { Position } from "reactflow"
import { $enum } from "ts-enum-util"
import ListPopupEditor from "../../../../../../../componentsSetup/common/ListPopupEditor"
import { IComponent, IComponentHandle } from "../../../../../../../types/BaseTypes"
import { useForceUpdate } from "../../../../../../../utils/CustomHooks"
import { POSITION_OFFSET_MAP } from "../../../../../../../utils/FlowHandleUtils"
import MyPopup, { usePopup } from "../../../../../../../utils/MyPopup"

type Props = {
    component: IComponent
}

const HandleOptions = ({ component }: Props) => {
    const [forceUpdate, fState] = useForceUpdate()
    const [ref, instance] = usePopup()

    const positionsOptions = useMemo(
        () => ({ dataSource: $enum(Position).getValues(), onValueChanged: forceUpdate }),
        [forceUpdate]
    )

    const itemContentRender = useCallback(
        (item: IComponentHandle) => {
            console.log(fState)

            item.position = item.position ?? Position.Left
            item.left = item.left ?? 0
            item.top = item.top ?? 0
            item.right = item.right ?? 0
            item.bottom = item.bottom ?? 0

            return (
                <GroupItem>
                    <SimpleItem dataField="name">
                        <Label text="Název" />
                    </SimpleItem>
                    <SimpleItem
                        dataField="position"
                        editorType="dxSelectBox"
                        editorOptions={positionsOptions}
                    >
                        <Label text="Pozice" />
                    </SimpleItem>
                    <GroupItem caption="Posun v %">
                        <SimpleItem
                            visible={POSITION_OFFSET_MAP[item.position].includes(Position.Top)}
                            dataField="top"
                            editorType="dxNumberBox"
                        >
                            <Label text="Top" />
                        </SimpleItem>
                        <SimpleItem
                            visible={POSITION_OFFSET_MAP[item.position].includes(Position.Bottom)}
                            dataField="bottom"
                            editorType="dxNumberBox"
                        >
                            <Label text="Bottom" />
                        </SimpleItem>
                        <SimpleItem
                            visible={POSITION_OFFSET_MAP[item.position].includes(Position.Left)}
                            dataField="left"
                            editorType="dxNumberBox"
                        >
                            <Label text="Left" />
                        </SimpleItem>
                        <SimpleItem
                            visible={POSITION_OFFSET_MAP[item.position].includes(Position.Right)}
                            dataField="right"
                            editorType="dxNumberBox"
                        >
                            <Label text="Right" />
                        </SimpleItem>
                    </GroupItem>
                </GroupItem>
            )
        },
        [positionsOptions, fState]
    )

    const itemLabelRender = useCallback((item: IComponentHandle) => {
        return <div>{`${item.name}`}</div>
    }, [])

    return (
        <>
            <MyPopup
                ref={ref}
                width="auto"
                height="auto"
                showTitle={true}
                title="Nastavení spojnic"
                dragEnabled={true}
            >
                <div className="component-setup-container">
                    <Form formData={component}>
                        <GroupItem>
                            <ListPopupEditor
                                listOwner={component.parameters}
                                listName="handles"
                                formItemContent={itemContentRender}
                                guid={true}
                                idProperty="guid"
                                itemRender={itemLabelRender}
                            />
                        </GroupItem>
                    </Form>
                </div>
                <Button
                    text="Ok"
                    type="default"
                    stylingMode="contained"
                    onClick={instance.hidePopup}
                />
            </MyPopup>
            <Button
                text="Spojnice komponent"
                type="default"
                stylingMode="outlined"
                onClick={instance.showPopup}
            />
        </>
    )
}

export default memo(HandleOptions)
