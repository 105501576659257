import Form, { Label, SimpleItem } from "devextreme-react/form"
import { useMemo } from "react"
import { useReportStore2 } from "../../stores/ReportStore2"
import { IFunction } from "../../types/FunctionsType"

type Props = {
    fun: IFunction
}

export default function ModbusConnectionsSelectBox(props: Props) {
    const modbusConnections = useReportStore2((state) => state.modbusConnections)

    const modbusOptions = useMemo(
        () => ({
            items: modbusConnections,
            searchEnabled: true,
            displayExpr: "name",
            valueExpr: "id"
        }),
        [modbusConnections]
    )

    return (
        <Form formData={props.fun}>
            <SimpleItem
                dataField="modbusConnection"
                editorType="dxSelectBox"
                editorOptions={modbusOptions}
            >
                <Label text="Modbus spojení" />
            </SimpleItem>
        </Form>
    )
}
