import { memo, useEffect, useState } from "react"

type Props = {
    children: JSX.Element | Array<JSX.Element | undefined>
    loadCondition: boolean
}

function LazyComponent({ children, loadCondition }: Props) {
    const [shouldLoad, setShouldLoad] = useState(false)

    useEffect(() => {
        if (loadCondition) {
            setShouldLoad(true)
        }
    }, [loadCondition])

    if (shouldLoad) {
        return <>{children}</>
    }
    return null
}

export default memo(LazyComponent)
