import { ContextMenu } from "devextreme-react"
import { dxElement } from "devextreme/core/element"
import dxContextMenu from "devextreme/ui/context_menu"
import { memo, useCallback } from "react"
import { useStore } from "reactflow"
import { useReportStore } from "../../stores/ReportStore"
import { useSelectionStore } from "../../stores/SelectionStore"
import { useInsertComponents, useReportFields } from "../../utils/CustomHooks"
import { contextMenuShown } from "../componentContextMenu/ComponentContextMenu"

type Props = {
    selector: string
}

enum ReportContextMenuOption {
    PASTE,
    MARK_ALL_NODES,
    MARK_ALL_EDGES,
    UNMARK_ALL,
    REMOVE_SELECTED,
    MARK_TO_FOREGROUND,
    MARK_TO_BACKGROUND
}

const dataSource = [
    { text: "Vložit", id: ReportContextMenuOption.PASTE },
    { text: "Všechny komponenty - označit", id: ReportContextMenuOption.MARK_ALL_NODES },
    { text: "Všechny spoje - označit", id: ReportContextMenuOption.MARK_ALL_EDGES },
    { text: "Zrušit všechna označení", id: ReportContextMenuOption.UNMARK_ALL },
    { text: "Smazat označené", id: ReportContextMenuOption.REMOVE_SELECTED },
    { text: "Do popředí (označené)", id: ReportContextMenuOption.MARK_TO_FOREGROUND },
    { text: "Do pozadí (označené)", id: ReportContextMenuOption.MARK_TO_BACKGROUND }
]

function FlowContextMenu(props: Props) {
    const remove = useReportStore((state) => state.remove)
    const [flow] = useReportFields((r) => [r.parameters.flow])
    const toTheForeground = useReportStore((state) => state.toTheForeground)
    const toTheBackground = useReportStore((state) => state.toTheBackground)

    const selectionParams = useSelectionStore((state) => state.selectionParams)

    const insertComponents = useInsertComponents()

    const unselectNodesAndEdges = useStore((state) => state.unselectNodesAndEdges)
    const addSelectedNodes = useStore((state) => state.addSelectedNodes)
    const addSelectedEdges = useStore((state) => state.addSelectedEdges)

    const itemClick = useCallback(
        (e: any) => {
            const itemData = e.itemData
            if (!itemData.items) {
                switch (itemData.id) {
                    case ReportContextMenuOption.PASTE:
                        insertComponents()
                        break
                    case ReportContextMenuOption.MARK_ALL_NODES:
                        addSelectedNodes(flow ? flow.nodes.map((n) => n.id) : [])
                        break
                    case ReportContextMenuOption.MARK_ALL_EDGES:
                        addSelectedEdges(flow ? flow.edges.map((e) => e.id) : [])
                        break
                    case ReportContextMenuOption.UNMARK_ALL:
                        unselectNodesAndEdges()
                        break
                    case ReportContextMenuOption.REMOVE_SELECTED:
                        remove(selectionParams.nodes, selectionParams.edges)
                        break
                    case ReportContextMenuOption.MARK_TO_FOREGROUND:
                        toTheForeground(selectionParams.nodes.map((n) => n.data.componentGuid))
                        break
                    case ReportContextMenuOption.MARK_TO_BACKGROUND:
                        toTheBackground(selectionParams.nodes.map((n) => n.data.componentGuid))
                        break
                }
            }
        },
        [
            insertComponents,
            remove,
            selectionParams,
            unselectNodesAndEdges,
            addSelectedEdges,
            addSelectedNodes,
            flow,
            toTheBackground,
            toTheForeground
        ]
    )

    // TODO - nefunguje, protoze pak nejdou kopirovat vyrazy atp.
    // useKeyClick(
    //     KEY_MAP.PASTE,
    //     useCallback(() => insertComponents(), [insertComponents])
    // )

    const onShowing = useCallback(
        (e: { component?: dxContextMenu; element?: dxElement; model?: any; cancel?: boolean }) => {
            if (contextMenuShown) {
                e.cancel = true
            }
        },
        []
    )

    return (
        <ContextMenu
            dataSource={dataSource}
            width={200}
            target={props.selector}
            onItemClick={itemClick}
            onShowing={onShowing}
        />
    )
}

export default memo(FlowContextMenu)
