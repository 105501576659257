import { API_BASE_URL } from "../../constants"
import { IImage } from "../../types/BaseTypes"
import { request, accessTokenExists } from "../APIUtils"

export function getImages(): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/images",
            method: "GET"
        })
    })
}

export function getImage(id: number): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/images/" + id,
            method: "GET"
        })
    })
}

export function createImage(image: any): Promise<Response> {
    return accessTokenExists(() => {
        return request(
            {
                url: API_BASE_URL + "/images",
                method: "POST",
                body: image
            },
            null
        )
    })
}

export function editImage(image: IImage): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/images",
            method: "PUT",
            body: JSON.stringify(image)
        })
    })
}

export function deleteImages(images: Array<IImage>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/images",
            method: "DELETE",
            body: JSON.stringify(images)
        })
    })
}
