import { Template } from "devextreme-react/core/template"
import DataGrid, {
    ColumnChooser,
    Export,
    Pager,
    Paging,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { exportDataGrid as excelExport } from "devextreme/excel_exporter"
import { exportDataGrid as pdfExport } from "devextreme/pdf_exporter"
import { ExportingEvent } from "devextreme/ui/data_grid"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver"
import { jsPDF } from "jspdf"
import { isEqual } from "lodash"
import { forwardRef, memo, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { EvaluatorContext } from "../../report/contexts/EvaluatorContext"
import ComponentUtils from "../../utils/ComponentUtils"
import { useExpression } from "../../utils/CustomHooks"
import "./TableComponent.scss"
import { TableParameters } from "./TableEditor"

type Params = TableParameters

type Props = ComponentProps<Params>

const DEFAULT_EXPORT_EXCEL = (e: ExportingEvent, name: string) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet("Main sheet")

    excelExport({
        component: e.component,
        worksheet,
        autoFilterEnabled: true
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), name + ".xlsx")
        })
    })
}

const DEFAULT_EXPORT_PDF = (e: ExportingEvent, name: string) => {
    const doc = new jsPDF()

    pdfExport({
        component: e.component,
        jsPDFDocument: doc,
        indent: 50
    }).then(() => {
        doc.save(name + ".pdf")
    })
}

const TableComponent = forwardRef<IBaseComponent, Props>(
    (
        {
            expression,
            selection,
            // searching,
            realSize,
            paging,
            export: exp,
            exportToPdf,
            columnChooser,
            oneSearching,
            fontSize,
            onExportingExcelExpression,
            onExportingPdfExpression
        },
        ref
    ) => {
        const component = useContext(ComponentContext)?.component
        const evaluatorContext = useContext(EvaluatorContext)
        const name = component?.name

        const [data, setData] = useState([])
        const [evaluatedData] = useExpression(expression, [])
        useEffect(() => {
            if (!isEqual(data, evaluatedData)) {
                setData(evaluatedData)
            }
        }, [data, evaluatedData])

        const rows = useMemo<Array<Array<any>>>(() => data?.map?.((d) => d), [data])
        const columns = useMemo(
            () =>
                rows?.shift?.()?.map?.((n: string, i: number) => {
                    return {
                        dataField: i + "",
                        caption: n
                    }
                }),
            [rows]
        )

        const onToolbarPreparing = useCallback((e: any) => {
            e.toolbarOptions.items.unshift({
                location: "before",
                template: "tableTitle"
            })
        }, [])

        const toolbarItemRender = useCallback(() => {
            return <div className="table-title">{name}</div>
        }, [name])

        // const onContextMenuPreparing = useCallback(
        //     (e: any) => {
        //         useReportStore2.setState({ componentRightClicked: componentContainer })
        //     },
        //     [componentContainer]
        // )

        const page = useMemo(() => paging?.split(","), [paging])

        const className = useMemo(() => `${ComponentUtils.classFromFontSize(fontSize)}`, [fontSize])

        const onExporting = useCallback(
            (e: ExportingEvent) => {
                const notEmptyName = name ? name : "nameless-component"

                if (e.format === "pdf") {
                    if (onExportingPdfExpression) {
                        evaluatorContext?.evaluate(onExportingPdfExpression, {
                            self: {
                                component: e.component,
                                doc: new jsPDF(),
                                exportDataGrid: pdfExport
                            }
                        })
                    } else {
                        DEFAULT_EXPORT_PDF(e, notEmptyName)
                    }
                } else {
                    if (onExportingExcelExpression) {
                        evaluatorContext?.evaluate(onExportingExcelExpression, {
                            self: {
                                component: e.component,
                                workbook: new Workbook(),
                                saveAs,
                                exportDataGrid: excelExport
                            }
                        })
                    } else {
                        DEFAULT_EXPORT_EXCEL(e, notEmptyName)
                    }
                }
            },
            [name, onExportingExcelExpression, onExportingPdfExpression, evaluatorContext]
        )

        const exportFormats = useMemo(
            () => (exportToPdf ? ["pdf"] : []).concat(exp ? ["xlsx"] : []),
            [exportToPdf, exp]
        )

        return (
            <DataGrid
                className={className}
                onToolbarPreparing={onToolbarPreparing}
                dataSource={rows}
                showBorders={true}
                columnAutoWidth={true}
                columns={columns}
                width={realSize.width}
                height={realSize.height}
                onExporting={onExporting}
                // onContextMenuPreparing={onContextMenuPreparing}
            >
                <Export
                    enabled={exp}
                    fileName={name}
                    allowExportSelectedData={selection}
                    formats={exportFormats}
                />

                <ColumnChooser enabled={columnChooser} />

                {/* <FilterRow visible={searching} /> */}
                <SearchPanel visible={oneSearching} />

                <Paging defaultPageSize={page?.[0] ? page[0] : 10} />
                <Pager showPageSizeSelector={true} allowedPageSizes={paging} showInfo={true} />

                {selection ? (
                    <Selection mode="multiple" selectAllMode={true} showCheckBoxesMode="onClick" />
                ) : null}

                <Template name="tableTitle" render={toolbarItemRender} />
            </DataGrid>
        )
    }
)

export default memo(TableComponent)
