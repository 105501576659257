import React, { RefObject } from "react"

import DataGrid, {
    Column,
    Form as DForm,
    Editing,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { SimpleItem } from "devextreme-react/form"

import { OrbitControls } from "@react-three/drei"
import { Canvas, Vector3 } from "@react-three/fiber"
import ThreeDModelsCache from "../cache/ThreeDModelsCache"
import { IThreeDModel } from "../types/BaseTypes"
import { createThreeDModel } from "../utils/API/APIThreeDModels"
import { gqlRead } from "../utils/APIUtils"
import { standardError, standardSuccess } from "../utils/NotifyUtils"
import { ThreeDUtils } from "../utils/ThreeDUtils"
import BaseCollection, { CollectionProps, CollectionState } from "./BaseCollection"
import {
    PopupFileUploader,
    ShowPopupHandle,
    acceptThreeD,
    threeDExtensions
} from "./PopupFileUploader"

export const threeDCamera: { near: number; far: number; position: Vector3 } = {
    near: 0.1,
    far: 10000,
    position: [-5, 2, -1]
}

type LibraryState = CollectionState<IThreeDModel> & {
    model?: object
    key: number
}

class ThreeDLibrary extends BaseCollection<IThreeDModel, CollectionProps, LibraryState> {
    private popupRef: React.RefObject<ShowPopupHandle>
    private previewSize = { width: "100%", height: "50%" }

    constructor(props: CollectionProps) {
        super(props, {
            entityName: "threeDModel",
            title: "Knihovna 3D modelů"
        })
        this.state = {
            ...this.state,
            key: 0
        }

        this.popupRef = React.createRef()
    }

    protected getEntities = (): Promise<Array<IThreeDModel>> => {
        return gqlRead({
            threeDModels: {
                id: true,
                name: true
            }
        }).then((r) => {
            return r.threeDModels
        })
    }

    onSelectionChanged = (data: { selectedRowsData?: Array<IThreeDModel> }) => {
        super.onSelectionChanged(data)

        const model = data?.selectedRowsData?.[0]
        if (model?.id) {
            ThreeDModelsCache.getInstance()
                .getModel(model.id)
                .then((response) => {
                    if (response?.data) {
                        this.setState({ model: ThreeDUtils.parseFBX(response.data) })
                    }
                })
                .catch(standardError)
        } else {
            this.setState({ model: undefined })
        }
        this.setState({ key: this.state.key + 1 })
    }

    private handleCreateImage = (file: any) => {
        createThreeDModel(file)
            .then((response) => {
                this.refreshTable()
                standardSuccess("3D model uložen.")
            })
            .catch(standardError)
    }

    protected addCustomToolbarButtons = (toolbar: any) => {
        toolbar.push({
            widget: "dxButton",
            options: {
                icon: "add",
                hint: "Nahrát 3D model",
                onClick: () => this.popupRef.current?.showPopup()
            },
            location: "after"
        })
    }

    protected renderGrid = (dataSource: any, ref: RefObject<DataGrid>): JSX.Element => {
        const model = this.state.model

        return (
            <>
                <PopupFileUploader
                    ref={this.popupRef}
                    handleSave={this.handleCreateImage}
                    fileExtensions={threeDExtensions}
                    accept={acceptThreeD}
                    maxSizeInMB={100}
                />
                <DataGrid
                    ref={ref}
                    dataSource={dataSource}
                    showBorders={true}
                    columnHidingEnabled={true}
                    onEditorPreparing={this.onEditorPreparing}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <Selection
                        mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="onClick"
                    />
                    <SearchPanel visible={true} />
                    <Editing mode="form" useIcons={true} allowUpdating={true} allowDeleting={true}>
                        <DForm colCount={2}>
                            <SimpleItem dataField="name" />
                        </DForm>
                    </Editing>

                    <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                    <Column dataField="id" caption="ID" allowEditing={false} />
                    <Column dataField="name" caption="Název" />
                </DataGrid>

                <div style={this.previewSize}>
                    <Canvas camera={threeDCamera}>
                        <pointLight intensity={0.9} position={[-1500, -1500, 150]} />
                        <ambientLight intensity={0.5} />
                        <OrbitControls makeDefault />
                        {model ? <primitive key={this.state.key} object={model} /> : null}
                        <axesHelper args={[500]} />
                        <gridHelper args={[20000, 20000, 0xcccccc, 0xdddddd]} />
                    </Canvas>
                </div>
            </>
        )
    }
}

export default ThreeDLibrary
