import { useMemo } from "react"
import { useDrag } from "react-dnd"
import { DraggableType } from "./DroppableContainer"

type DraggableComponentProps = {
    item: Record<string, any>
    draggableType: DraggableType
    children: JSX.Element
    onDropped?: (item: Record<string, any>, container: Record<string, any>) => void
    canDrag?: () => boolean
    w?: string
    h?: string
}

export const DraggableComponent = function DragableComponent({
    item,
    draggableType,
    children,
    onDropped,
    canDrag,
    w,
    h
}: DraggableComponentProps) {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: draggableType,
            item: item,
            canDrag: canDrag,
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult()
                if (item && dropResult) {
                    onDropped?.(item, dropResult as any)
                    // alert(`You dropped ${item.name} into ${(dropResult as any).name}!`)
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId()
            })
        }),
        [canDrag, item, draggableType]
    )

    const style = useMemo(() => {
        const opacity = isDragging ? 0.4 : 1
        return { opacity, width: w, height: h }
    }, [w, h, isDragging])

    return (
        <div ref={drag} data-testid={draggableType} style={style}>
            {children}
        </div>
    )
}
