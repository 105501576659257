export enum MenuItem {
    CHANGE_PASSWORD = "change-password",
    DEVICE_SETUP = "device-setup",
    LOGOUT = "logout",
    ADMINISTRATION = "administration",
    GROUPS = "groups",
    USERS = "users",
    ROLES = "roles",
    REPORTS = "reports",
    NOTIFICATIONS = "notifications",
    SCHEDULED_TASKS = "scheduled-tasks",
    LIBRARY = "library",
    IMAGES_LIBRARY = "images-library",
    THREE_D_LIBRARY = "three-d-library",
    DB_CONNECTIONS = "db-connections",
    MODBUS_CONNECTIONS = "modbus-connections",
    MODBUS_READER = "modbus-reader",
    SYSTEM_STATE = "system-state",
    LICENSE = "license"
}

export type HeaderItem = {
    id: MenuItem
    name: string
}

export type AdministrationGroup = {
    name: string
    items: Array<HeaderItem>
}

export const PROFILE_ITEMS = [
    {
        id: MenuItem.CHANGE_PASSWORD,
        name: "Změnit heslo"
    },
    {
        id: MenuItem.DEVICE_SETUP,
        name: "Nastavení zařízení"
    },
    {
        id: MenuItem.LOGOUT,
        name: "Odhlásit se"
    }
]

export const MENU_ITEMS = [
    {
        name: "Skupiny a uživatelé",
        items: [
            {
                id: MenuItem.GROUPS,
                name: "Skupiny"
            },
            {
                id: MenuItem.REPORTS,
                name: "Reporty"
            },
            {
                id: MenuItem.USERS,
                name: "Uživatelé"
            },
            {
                id: MenuItem.ROLES,
                name: "Role"
            }
        ]
    },
    {
        name: "Systém",
        items: [
            {
                id: MenuItem.LICENSE,
                name: "Přehled licence"
            },
            {
                id: MenuItem.SYSTEM_STATE,
                name: "Stav systému"
            },
            {
                id: MenuItem.SCHEDULED_TASKS,
                name: "Plánované úlohy"
            }
        ]
    },
    {
        name: "Knihovny",
        items: [
            {
                id: MenuItem.LIBRARY,
                name: "Knihovna komponent"
            },
            {
                id: MenuItem.IMAGES_LIBRARY,
                name: "Knihovna obrázků"
            },
            {
                id: MenuItem.THREE_D_LIBRARY,
                name: "Knihovna 3D modelů"
            }
        ]
    },
    {
        name: "Spojení a čtení",
        items: [
            {
                id: MenuItem.DB_CONNECTIONS,
                name: "Databázová spojení"
            },
            {
                id: MenuItem.MODBUS_CONNECTIONS,
                name: "Modbus spojení"
            },
            {
                id: MenuItem.MODBUS_READER,
                name: "Čtení modbusu"
            },
            {
                id: MenuItem.NOTIFICATIONS,
                name: "Notifikace"
            }
        ]
    }
]
