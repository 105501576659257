import { forwardRef, memo, useCallback, useEffect, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import ComponentUtils from "../../utils/ComponentUtils"
import { useExecuteRef, useExpression, useListExpression } from "../../utils/CustomHooks"
import "./GaugeSwitchComponent.scss"
import { GaugeSwitchParameters } from "./GaugeSwitchEditor"
import { ReactComponent as GaugeSwitch } from "./svg/gauge_switch.svg"

type Params = GaugeSwitchParameters

type Props = ComponentProps<Params>

const GaugeSwitchComponet = forwardRef<IBaseComponent, Props>(
    ({ enabledExpression, expression, buttons, bold, italic, fontSize, onChangeActions }, ref) => {
        const executeRef = useExecuteRef()

        const [enabled] = useExpression(enabledExpression, true)
        const [activeIndex, setActiveIndex] = useExpression(expression, 0)
        useEffect(() => {
            executeRef.current(onChangeActions, { value: activeIndex })
        }, [activeIndex, onChangeActions, executeRef])

        const [btnsValues] = useListExpression(
            useMemo(() => buttons?.map((b) => b.textExpression), [buttons])
        )
        const btns = useMemo(
            () =>
                btnsValues?.map((b: any, i: number) => ({
                    text: b,
                    guid: buttons?.[i].guid
                })),
            [btnsValues, buttons]
        )

        const onClick = useCallback(
            (index: number) => {
                const actions = buttons?.[index]?.onClickActions
                setActiveIndex(index)

                executeRef.current(actions)
            },
            [buttons, executeRef, setActiveIndex]
        )

        const onSwitch = useCallback(() => {
            if (buttons && activeIndex !== undefined && activeIndex !== null) {
                const buttonsLength = buttons.length
                let newIndex = Math.round(activeIndex + 1)

                if (newIndex >= buttonsLength) {
                    newIndex = 0
                }

                onClick(newIndex)
            }
        }, [activeIndex, buttons, onClick])

        const isTwo = btns && btns.length < 3
        const buttonClassName = `${bold && "bold"} ${italic && "italic"} `

        const className = useMemo(() => {
            let rotateClass = ""
            if (activeIndex === 0) {
                rotateClass = "gauge-switch-left"
            } else if (activeIndex === 1 && btns && btns.length > 2) {
                rotateClass = ""
            } else {
                rotateClass = "gauge-switch-right"
            }

            return `gauge-switch ${rotateClass} ${ComponentUtils.classFromFontSize(fontSize)}`
        }, [activeIndex, btns, fontSize])

        const buttonsRender = useMemo(
            () =>
                btns?.map((b, i) => (
                    <div
                        key={b.guid}
                        className={
                            buttonClassName + " " + (activeIndex === i ? "gauge-switch-active" : "")
                        }
                    >
                        <span
                            onClick={() => (enabled ? onClick(i) : undefined)}
                            className={!isTwo && (i === 0 || i === 2) ? "text-bottom" : ""}
                        >
                            {b.text}
                        </span>
                    </div>
                )),
            [btns, activeIndex, buttonClassName, enabled, isTwo, onClick]
        )

        return (
            <div className={className}>
                <div className="text-container">{buttonsRender}</div>
                <GaugeSwitch onClick={enabled ? onSwitch : undefined} />
            </div>
        )
    }
)

export default memo(GaugeSwitchComponet)
