import { Button, Form, Popup } from "devextreme-react"
import { ButtonItem, GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { cloneDeep } from "lodash"
import { useContext, useRef, useState } from "react"
import { EvaluatorContext } from "../../../report/contexts/EvaluatorContext"
import { FunctionResponse, FunctionType, IFunction } from "../../../types/FunctionsType"
import { tryFunction } from "../../../utils/API/APIComponents"
import EvalUtils from "../../../utils/EvalUtils"
import { standardError } from "../../../utils/NotifyUtils"
import ReportUtils from "../../../utils/ReportUtils"
import { initMonacoEditor } from "../MonacoEditor"
import ReadModbus from "./ReadModbus"
import ReadNotificationGroups from "./ReadNotificationGroups"
import ReadNotifications from "./ReadNotifications"
import ReadSQL from "./ReadSQL"
import SetVariable from "./SetVariable"
import WriteModbus from "./WriteModbus"
import WriteSQL from "./WriteSQL"

type TestProps = {
    function: IFunction
}

// type TestState = {
//     visible: boolean
//     paramsPopupVisible: boolean
//     paramsCount: number
//     callResult: JSX.Element
//     popupKey: number
// }

export const TEXT_AREA_OPTIONS = { autoResizeEnabled: true, maxHeight: "200px" }

export type IFunctionRef = {
    showCallResult?: (response: FunctionResponse) => JSX.Element
    getParamsCount?: () => number
}

export default function TestFunction(props: TestProps) {
    const evaluatorContext = useContext(EvaluatorContext)

    const fRef = useRef<IFunctionRef>(null)

    const [params] = useState<Record<string, string>>({})
    const [popupKey, setPopupKey] = useState(0)
    const [visible, setVisible] = useState(false)
    const [paramsPopupVisible, setParamsPopupVisible] = useState(false)
    const [paramsCount, setParamsCount] = useState(0)
    const [callResult, setCallResult] = useState(<></>)

    const hide = () => {
        setVisible(false)
    }

    const hideParamsPopup = () => {
        setParamsPopupVisible(false)
    }

    const tryFun = (params?: Array<any>) => {
        const f = cloneDeep(props.function)
        ReportUtils.prepareFunctionToSave(f as any)
        tryFunction(f, params)
            .then((response: any) => {
                setPopupKey(popupKey + 1)
                setVisible(true)
                const result = fRef.current?.showCallResult?.(response)
                if (result) {
                    setCallResult(result)
                }
            })
            .catch(standardError)
    }

    const onClick = () => {
        const paramsCount = fRef.current?.getParamsCount?.()
        if (paramsCount && paramsCount > 0) {
            setParamsPopupVisible(true)
            setParamsCount(paramsCount)
        } else {
            tryFun()
        }
    }

    const renderParams = () => {
        return (
            <Form formData={params}>
                <GroupItem>
                    {[...Array(paramsCount).keys()].map((p: number, i: number) => (
                        <SimpleItem render={initMonacoEditor(params, "p" + i)}>
                            <Label text={i + 1 + ". parametr"} />
                        </SimpleItem>
                    ))}
                </GroupItem>
                <ButtonItem
                    buttonOptions={{
                        text: "Ok",
                        type: "default",
                        onClick: () => {
                            hideParamsPopup()

                            const p: Array<any> = []
                            for (let i = 0; i < paramsCount; i++) {
                                p.push(
                                    evaluatorContext?.evaluate(
                                        EvalUtils.correctExpression(params["p" + i])
                                    )
                                )
                            }
                            tryFun(p)
                        }
                    }}
                />
            </Form>
        )
    }

    const item = props.function
    let tabContent
    switch (item.type) {
        case FunctionType.SET_VARIABLE:
            tabContent = <SetVariable ref={fRef} item={item} />
            break
        case FunctionType.READ_SQL:
            tabContent = <ReadSQL ref={fRef} item={item} />
            break
        case FunctionType.WRITE_SQL:
            tabContent = <WriteSQL ref={fRef} item={item} />
            break
        case FunctionType.READ_MODBUS:
            tabContent = <ReadModbus ref={fRef} item={item} />
            break
        case FunctionType.WRITE_MODBUS:
            tabContent = <WriteModbus ref={fRef} item={item} />
            break
        case FunctionType.READ_NOTIFICATIONS:
            tabContent = <ReadNotifications /*ref={this.fRef} */ item={item} />
            break
        case FunctionType.READ_NOTIFICATION_GROUPS:
            tabContent = <ReadNotificationGroups ref={fRef} item={item} />
            break
    }

    return (
        <>
            <div>
                <Popup
                    key={popupKey}
                    visible={visible}
                    onHiding={hide}
                    contentRender={() => callResult}
                />
            </div>
            <div>
                <Popup
                    title="Zadejte parametry za otazníky"
                    width="auto"
                    height="auto"
                    maxHeight="800"
                    visible={paramsPopupVisible}
                    onHiding={hideParamsPopup}
                    contentRender={() => renderParams()}
                />
            </div>
            {tabContent}
            <Button text="Vyzkoušet funkci" onClick={onClick} />
        </>
    )
}
