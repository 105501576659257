import { RefObject } from "react"

import DataGrid, {
    Column,
    Editing,
    Form,
    Pager,
    Paging,
    PatternRule,
    RequiredRule,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"
import ArrayStore from "devextreme/data/array_store"

import { toast } from "react-toastify"
import { IGroup, IReport, IRole, IUser } from "../types/BaseTypes"
import { signup } from "../utils/APIUsers"
import { gqlRead } from "../utils/APIUtils"
import {
    STANDARD_NOTIFY_OPTIONS,
    standardError,
    standardSuccess,
    standardWarning
} from "../utils/NotifyUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"

type UsersState = CollectionState<IUser> & {
    groups: Array<IGroup>
    reports: Array<IReport>
    roles: Array<IRole>
}

class Users extends BaseCollection<IUser, CollectionProps, UsersState> {
    private reportsTagBoxOptions: any

    constructor(props: CollectionProps) {
        super(props, {
            entityName: "user",
            subEntitiesToIds: ["groups", "reports", "roles"],
            title: "Uživatelé, jejich role, skupiny a reporty"
        })
        this.state = {
            ...this.state,
            groups: [],
            reports: [],
            roles: []
        }

        this.reportsTagBoxOptions = {
            ...BaseCollection.TAG_BOX_OPTIONS,
            displayExpr: this.reportsDisplayExpr
        }
    }

    protected getEntities = (): Promise<Array<IUser>> => {
        return gqlRead({
            users: {
                id: true,
                name: true,
                username: true,
                email: true,
                roles: {
                    id: true
                },
                groups: {
                    id: true
                },
                reports: {
                    id: true
                }
            },
            groups: {
                id: true,
                name: true
            },
            roles: {
                id: true,
                name: true
            },
            reports: {
                id: true,
                name: true,
                group: {
                    id: true,
                    name: true
                }
            }
        }).then((r) => {
            this.setState({ groups: r.groups, reports: r.reports, roles: r.roles })
            return r.users
        })
    }

    private reportsDisplayExpr = (report: IReport) => {
        if (report) {
            return `${report.group?.name}: ${report.name}`
        } else {
            return ""
        }
    }

    onGroupsChanged = (object: { data?: { groups: Array<number> } }) => {
        const groups = object.data?.groups
        if (groups) {
            this.filterReports(groups)
            this.forceUpdate()
        }
    }

    filterReports = (groups: Array<number>) => {
        const data = this.state.reports?.filter((r) =>
            groups.some((g: number) => g === r.group?.id)
        )
        this.reportsTagBoxOptions.dataSource = new ArrayStore({ data: data, key: "id" })
        return data
    }

    setCellValueGroup = (
        newData: any,
        value: Array<number>,
        currentRowData: { reports: Array<Number> }
    ) => {
        const availableReports = this.filterReports(value)
        let actualReports = currentRowData.reports
        actualReports = actualReports?.filter((r: any) =>
            availableReports?.some((report: any) => report.id === r)
        )

        newData.groups = value
        newData.reports = actualReports
    }

    private signup = () => {
        if (this.state.selectedData.length > 0) {
            const toastId = toast.loading("Odesílám...", STANDARD_NOTIFY_OPTIONS)
            signup(this.state.selectedData.map((u) => u.id!))
                .then(() => standardSuccess("E-mail uživateli odeslán", toastId))
                .catch((e) => standardError(e, toastId))
        } else {
            standardWarning("Vyberte uživatele")
        }
    }

    protected addCustomToolbarButtons = (toolbarItems: any) => {
        toolbarItems.push({
            widget: "dxButton",
            options: {
                icon: "message",
                hint: "Odeslat e-mail novému uživateli",
                onClick: this.signup
            },
            location: "after"
        })
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <DataGrid
                ref={ref}
                dataSource={dataSource}
                showBorders={true}
                columnHidingEnabled={true}
                onEditorPreparing={this.onEditorPreparing}
                onToolbarPreparing={this.onToolbarPreparing}
                // TODO DEVEXTREME
                onSelectionChanged={this.onSelectionChanged as any}
                onEditingStart={this.onGroupsChanged}
                height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
            >
                <Paging enabled={false} />
                <Pager visible={true} displayMode="full" showInfo={true} />
                <SearchPanel visible={true} />
                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
                <Editing
                    mode="form"
                    useIcons={true}
                    allowDeleting={true}
                    allowAdding={true}
                    allowUpdating={true}
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item itemType="group" caption="Přihlášení">
                                <Item dataField="username" />
                            </Item>
                            <Item itemType="group" caption="Ostatní údaje">
                                <Item dataField="name" />
                                <Item dataField="email" />
                            </Item>
                            <Item itemType="group" caption="Napojení">
                                <Item
                                    editorType="dxTagBox"
                                    dataField="roles"
                                    editorOptions={{
                                        ...BaseCollection.TAG_BOX_OPTIONS,
                                        dataSource: this.state.roles
                                    }}
                                />
                                <Item
                                    editorType="dxTagBox"
                                    dataField="groups"
                                    editorOptions={{
                                        ...BaseCollection.TAG_BOX_OPTIONS,
                                        dataSource: this.state.groups
                                    }}
                                />
                                <Item
                                    editorType="dxTagBox"
                                    dataField="reports"
                                    editorOptions={this.reportsTagBoxOptions}
                                />
                            </Item>
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                <Column dataField="id" caption="ID" allowEditing={false} hidingPriority={5} />
                <Column dataField="username" caption="Přihlašovací jméno" hidingPriority={6}>
                    <RequiredRule />
                </Column>
                <Column dataField="name" caption="Celé jméno">
                    <RequiredRule />
                </Column>
                <Column dataField="email" caption="E-mail" hidingPriority={1}>
                    <RequiredRule />
                </Column>
                <Column
                    dataField="roles"
                    caption="Role"
                    customizeText={(cellInfo: any) => this.fromNames(cellInfo, this.state.roles)}
                    hidingPriority={4}
                ></Column>
                <Column
                    dataField="groups"
                    caption="Skupiny"
                    customizeText={(cellInfo: any) => this.fromNames(cellInfo, this.state.groups)}
                    setCellValue={this.setCellValueGroup}
                    hidingPriority={3}
                ></Column>
                <Column
                    dataField="reports"
                    caption="Reporty"
                    customizeText={(cellInfo: any) => this.fromNames(cellInfo, this.state.reports)}
                    hidingPriority={2}
                ></Column>
                <Column dataField="password" visible={false} hidingPriority={4}>
                    <RequiredRule />
                    <PatternRule
                        message={
                            "Heslo musí mít alespoň 6 znaků a musí začínat písmenem. Může obsahovat pouze čísla, velká a malá písmena."
                        }
                        pattern={/^[a-zA-Z]\w{5,14}$/}
                    />
                </Column>
            </DataGrid>
        )
    }
}

export default Users
