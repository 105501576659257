export default class JSONUtils {
    static stringifyWithFunctions(
        obj: Record<string, any>,
        ignoreFields?: Array<string>,
        spaces = 2
    ) {
        const replacer = (key: string, value: any) => {
            if (!ignoreFields?.includes(key)) {
                if (typeof value === "function") {
                    const functionSource = value.toString()

                    const header = functionSource.substring(0, functionSource.indexOf("=")).trim()

                    return "parameters " + header
                }
                return value
            }
        }

        return JSON.stringify(obj, replacer, spaces)
    }
}
