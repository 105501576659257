import { IBase } from "../types/BaseTypes"

export default class EntityUtils {
    public static toIds(
        entities: Array<Record<string, any>>,
        fields?: string[]
    ): Array<Record<string, any>> {
        if (fields) {
            entities.forEach((e) => {
                fields.forEach((f) => {
                    if (e[f]) {
                        if (e[f].map) {
                            e[f] = e[f].map((a: IBase) => EntityUtils.getId(a))
                        } else {
                            e[f] = EntityUtils.getId(e[f])
                        }
                    }
                })
            })
        }
        return entities
    }

    public static toObjects(entities: Array<Record<string, any>>, fields?: string[]): Array<any> {
        if (fields) {
            entities.forEach((e) => {
                fields.forEach((f) => {
                    if (e[f]) {
                        if (e[f].map) {
                            e[f] = e[f].map((a: any) => EntityUtils.getObject(a))
                        } else {
                            e[f] = EntityUtils.getObject(e[f])
                        }
                    }
                })
            })
        }
        return entities
    }

    private static getId = (a: any) => {
        if (a.id) {
            return a.id
        }
        return a
    }

    private static getObject = (a: any) => {
        if (a.id) {
            return a
        }
        return { id: a }
    }
}
