import { SelectBox } from "devextreme-react"
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from "react"
import { $enum } from "ts-enum-util"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { KeyType } from "../../types/KeysTypes"
import ColorUtils from "../../utils/ColorUtils"
import ComponentUtils from "../../utils/ComponentUtils"
import {
    useContainerVisible,
    useExecuteActions,
    useExpression,
    useImperative
} from "../../utils/CustomHooks"
import { ComponentTextSize } from "../Text/TextEditor"
import "./SelectboxComponent.scss"
import { SelectboxParameters } from "./SelectboxEditor"

type Params = SelectboxParameters

type Props = ComponentProps<Params>

const SelectboxComponent = forwardRef<IBaseComponent, Props>(
    (
        {
            onChangeActions,
            italic,
            bold,
            fontSize,
            expression,
            indexExpression,
            colorExpression,
            enabledExpression,
            setContainerVisible
        },
        ref
    ) => {
        const execute = useExecuteActions()
        useContainerVisible(setContainerVisible, false)

        const [value, setValue] = useState("")

        //TODO deepEqual
        const [items] = useExpression(
            expression,
            []
            // useCallback((a: any, b: any) => isEqual(a, b), [])
        )
        const [index, setIndex] = useExpression(indexExpression, 0)
        const [color] = useExpression(colorExpression, 0)
        const [enabled] = useExpression(enabledExpression, true)

        useImperative(
            ref,
            useMemo(() => {
                const messenger = {
                    name: "textSize",
                    enums: $enum(ComponentTextSize)
                        .getValues()
                        .map((e) => ({ enum: e })),
                    value: fontSize
                }
                return [
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [fontSize])
        )

        const valueChanged = useCallback(
            (newValue: any) => {
                const index = items.findIndex((i) => i === newValue)

                if (index !== -1) {
                    execute(onChangeActions, { value: newValue, index })
                    setValue(newValue)
                    setIndex(index)
                }
            },
            [execute, items, onChangeActions, setIndex]
        )

        useEffect(() => {
            valueChanged(items[index])
        }, [index, items, valueChanged])

        const onValueChanged = useCallback(
            (e: { value: any; previousValue: any }) => {
                valueChanged(e.value)
            },
            [valueChanged]
        )

        const className = useMemo(
            () =>
                `selectbox-component text-align-center ${bold && "bold"} ${
                    italic && "italic"
                } text-${ColorUtils.fromNumber(color)} ${ComponentUtils.classFromFontSize(
                    fontSize
                )}`,
            [bold, color, italic, fontSize]
        )

        return (
            <SelectBox
                className={className}
                items={Array.isArray(items) ? items : []}
                onValueChanged={onValueChanged as any}
                value={value}
                disabled={!enabled}
                width="100%"
                height="100%"
            />
        )
    }
)

export default memo(SelectboxComponent)
