import React from "react"
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form"
import { IFunctionParameters } from "../../../types/FunctionsType"

type SQLTypeProps = {
    parameters: IFunctionParameters
}

class SQLType extends React.Component<SQLTypeProps, any> {
    private static types = [
        { name: "Všechna data", value: "allData" },
        { name: "Aktuální data", value: "lastData" },
        { name: "Časový interval", value: "dateRange" }
    ]
    private sqlTypeOptions: any

    constructor(props: SQLTypeProps) {
        super(props)

        this.sqlTypeOptions = {
            items: SQLType.types,
            defaultValue: SQLType.types[0],
            displayExpr: "name",
            valueExpr: "value",
            onValueChanged: this.onTypeChanged
        }
    }

    onTypeChanged = (e: any) => {
        this.forceUpdate()
    }

    render() {
        const parameters = this.props.parameters

        return (
            <Form className="component-setup-container" formData={parameters}>
                <GroupItem>
                    <SimpleItem
                        editorType="dxRadioGroup"
                        dataField="sqlType"
                        editorOptions={this.sqlTypeOptions}
                    >
                        <Label text="Vrátit data" />
                    </SimpleItem>
                    <SimpleItem
                        visible={
                            parameters &&
                            (parameters.sqlType === SQLType.types[1].value ||
                                parameters.sqlType === SQLType.types[2].value)
                        }
                        dataField="dateField"
                    >
                        <Label text="Datumový sloupec" />
                    </SimpleItem>
                    <GroupItem
                        visible={parameters && parameters.sqlType === SQLType.types[2].value}
                    >
                        <SimpleItem editorType="dxNumberBox" dataField="daysBack">
                            <Label text="Počet dní do historie" />
                        </SimpleItem>
                    </GroupItem>
                    <SimpleItem
                        visible={parameters && parameters.sqlType === SQLType.types[1].value}
                        dataField="groupBy"
                        helpText="Udává SQL GROUP BY sloupec hlavní tabulky. Tedy pro jakou každou hodnotu se dotáhne záznam. Např. 'id'."
                    >
                        <Label text="Unikátní sloupec" />
                    </SimpleItem>
                </GroupItem>
            </Form>
        )
    }
}

export default SQLType
