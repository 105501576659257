import { forwardRef, memo, useCallback, useContext, useEffect, useRef } from "react"
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { EvaluatorContext } from "../../report/contexts/EvaluatorContext"
import { useContainerVisible, useExecuteActions } from "../../utils/CustomHooks"
import EvalUtils from "../../utils/EvalUtils"
import { standardSuccess } from "../../utils/NotifyUtils"
import "./VoiceComponent.scss"
import { VoiceParameters } from "./VoiceEditor"
import { ReactComponent as Microphone } from "./svg/microphone.svg"

type Params = VoiceParameters

type Props = ComponentProps<Params>

const KEYWORD = "VIZUALIZACE"

const VoiceComponent = forwardRef<IBaseComponent, Props>(
    ({ commandsExpressions, disable, setContainerVisible }, ref) => {
        const evaluatorContext = useContext(EvaluatorContext)
        const executeActions = useExecuteActions()
        useContainerVisible(setContainerVisible, false)

        const onCommand = useCallback(
            (value: string) => {
                commandsExpressions?.forEach((v) => {
                    const result = evaluatorContext?.evaluate(
                        EvalUtils.correctExpression(v.expression),
                        { value: value }
                    )
                    if (result) {
                        executeActions(v.actions, { value: value })
                    }
                })
            },
            [commandsExpressions, executeActions, evaluatorContext]
        )

        const onTranscript = useCallback(
            (transcript: string) => {
                console.log(transcript)
                if (transcript.includes(KEYWORD)) {
                    const commands = transcript.split(KEYWORD)
                    if (!transcript.startsWith(KEYWORD)) {
                        commands.shift()
                    }
                    commands.forEach((c) => {
                        const value = c.trim()
                        if (value) {
                            standardSuccess("Příkaz: " + value)
                            onCommand(value)
                        }
                    })
                }
            },
            [onCommand]
        )

        if (disable) {
            return <Microphone />
        }
        return <Dictaphone onTranscript={onTranscript} />
    }
)

export default memo(VoiceComponent)

type DictaphoneProps = {
    onTranscript: (transcript: string) => void
}

const Dictaphone = (props: DictaphoneProps) => {
    const { listening, resetTranscript, finalTranscript } = useSpeechRecognition()
    const ref = useRef({ before30sec: "", lastTranscript: "", transcript: "" })

    const startListening = () => SpeechRecognition.startListening({ continuous: true })
    ref.current.transcript = finalTranscript
    useEffect(() => {
        console.log("Microphone starts listening")
        startListening()

        // const interval = setInterval(() => {
        //     if (ref.current.before30sec === ref.current.transcript) {
        //         resetTranscript()
        //     } else {
        //         ref.current.before30sec = ref.current.transcript
        //     }
        // }, 30000)

        // return () => {
        //     clearInterval(interval)
        // }
    }, [])

    const upper = finalTranscript.toUpperCase()
    if (ref.current.lastTranscript !== upper) {
        props.onTranscript(upper)
        ref.current.lastTranscript = upper
        resetTranscript()
    }
    return <Microphone className={listening ? "listening" : ""} />
}
