import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import React from "react"
import { IFunction } from "../../types/FunctionsType"
import SQLBuilder from "./builder/SQLBuilder"
import DBConnectionsSelectBox from "./DBConnectionsSelectBox"
import OwnSQL from "./OwnSQL"

type SQLProps = {
    function: IFunction
    textAreaHelpText?: string
}

type SQLState = {
    popupVisible: boolean
    data: any
    error: string
    sql: string
    columnNames: Array<string>
}

class SQLDataSource extends React.Component<SQLProps, SQLState> {
    private static types = [
        { name: "SQL Builder", value: "builder" },
        { name: "Vlastní SQL", value: "sql" }
    ]
    private typeOptions: any

    constructor(props: SQLProps) {
        super(props)
        this.state = {
            popupVisible: false,
            data: {},
            error: "",
            sql: "",
            columnNames: []
        }

        this.typeOptions = {
            items: SQLDataSource.types,
            defaultValue: SQLDataSource.types[0],
            displayExpr: "name",
            valueExpr: "value",
            onValueChanged: this.onTypeChanged
        }
    }

    handlePopupHidden = () => {
        this.setState({ popupVisible: false })
    }

    onTypeChanged = (e: any) => {
        this.forceUpdate()
    }

    render() {
        const fun = this.props.function
        const parameters = fun.parameters ?? {}
        if (!parameters.inputType) {
            parameters.inputType = SQLDataSource.types[0].value
        }

        return (
            <div className="component-setup-container">
                <Form formData={fun}>
                    <GroupItem>
                        <DBConnectionsSelectBox fun={fun} />
                    </GroupItem>
                    <GroupItem>
                        <SimpleItem
                            editorType="dxRadioGroup"
                            dataField="parameters.inputType"
                            editorOptions={this.typeOptions}
                        >
                            <Label text="Typ vstupu" />
                        </SimpleItem>
                    </GroupItem>
                </Form>
                {parameters.inputType === SQLDataSource.types[0].value ? (
                    <SQLBuilder parameters={parameters} />
                ) : (
                    <OwnSQL parameters={parameters} helpText={this.props.textAreaHelpText} />
                )}
            </div>
        )
    }
}

export default SQLDataSource
