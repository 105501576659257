import { Form } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { Layout } from "react-grid-layout"
import { v4 as uuid } from "uuid"
import { SLIDER_TOOLTIP_SETTINGS } from "../../component/EditorTypes"
import ComponentsList from "../../componentsSetup/common/ComponentsList"
import FunctionsList from "../../componentsSetup/common/FunctionsList"
import VariablesList from "../../componentsSetup/common/VariablesList"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export type GridParameters = BaseParams & {
    rowSize: number
    numOfColumns: number
    compactType: "vertical" | "horizontal" | "none"
    layout: Layout[]
    editMode: boolean
    marginX: number
    marginY: number
}

export const GRID_ROW_SIZES = [1, 5, 10, 15, 20, 25, 30]
export const GRID_ROW_SIZES_OPTIONS = {
    items: GRID_ROW_SIZES
}
export const GRID_COMPACT_TYPES = ["vertical", "horizontal", "none"]
export const GRID_COMPACT_TYPES_OPTIONS = {
    items: GRID_COMPACT_TYPES
}
export const GRID_NUM_OF_COLUMNS_OPTIONS = {
    min: 1,
    max: 100,
    tooltip: SLIDER_TOOLTIP_SETTINGS
}
const MARGINS = [0, ...GRID_ROW_SIZES]
const MARGINS_OPTIONS = {
    items: MARGINS
}

const GridEditor = ({ parameters, formData }: EditorProps<GridParameters>) => {
    parameters.numOfColumns = parameters.numOfColumns ?? 10
    parameters.rowSize = parameters.rowSize ?? GRID_ROW_SIZES[2]
    parameters.compactType = parameters.compactType ?? GRID_COMPACT_TYPES[0]

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Komponenty">
                <div className="component-setup-container">
                    <Form formData={parameters} colCount={2}>
                        <GroupItem>
                            <ComponentsList component={formData} />
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem
                                dataField="compactType"
                                editorOptions={GRID_COMPACT_TYPES_OPTIONS}
                                editorType="dxSelectBox"
                            >
                                <Label text="Setřesení" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="rowSize"
                                editorOptions={GRID_ROW_SIZES_OPTIONS}
                                editorType="dxSelectBox"
                            >
                                <Label text="Výška řádku" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="numOfColumns"
                                editorOptions={GRID_NUM_OF_COLUMNS_OPTIONS}
                                editorType="dxSlider"
                            >
                                <Label text="Počet sloupců" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="marginX"
                                editorOptions={MARGINS_OPTIONS}
                                editorType="dxSelectBox"
                            >
                                <Label text="Odsazení X" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="marginY"
                                editorOptions={MARGINS_OPTIONS}
                                editorType="dxSelectBox"
                            >
                                <Label text="Odsazení Y" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="Funkce">
                <FunctionsList listOwner={formData} />
            </Item>
            <Item title="Proměnné">
                <VariablesList listOwner={formData} />
            </Item>
        </TabPanel>
    )
}

export default memo(GridEditor)

export const getGridBasicConfigurations = () => {
    const guid1 = uuid()
    const guid2 = uuid()
    const guid3 = uuid()
    return [
        {
            parameters: {
                layout: [
                    {
                        w: 3,
                        h: 3,
                        x: 0,
                        y: 0,
                        i: guid1,
                        moved: false,
                        static: false
                    },
                    {
                        w: 2,
                        h: 3,
                        x: 3,
                        y: 0,
                        i: guid2,
                        moved: false,
                        static: false
                    },
                    {
                        w: 6,
                        h: 2,
                        x: 0,
                        y: 3,
                        i: guid3,
                        moved: false,
                        static: false
                    }
                ],
                editMode: false,
                numOfColumns: 5,
                rowSize: GRID_ROW_SIZES[0],
                compactType: "vertical",
                marginX: MARGINS[2],
                marginY: MARGINS[2]
            },
            components: [{ guid: guid1 }, { guid: guid2 }, { guid: guid3 }]
        }
    ]
}
