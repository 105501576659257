import { forwardRef, memo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { useContainerVisible, useExpression } from "../../utils/CustomHooks"
import "./BlowerComponent.scss"
import { BlowerParameters } from "./BlowerEditor"
import { ReactComponent as Blower } from "./svg/blower.svg"

type Params = BlowerParameters

type Props = ComponentProps<Params>

const BlowerComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, expressionError, setContainerVisible }, ref) => {
        useContainerVisible(setContainerVisible, false)

        const [active] = useExpression(expression, false)
        const [error] = useExpression(expressionError, false)

        let className = "blower "
        if (error) {
            className += "blower-error"
        } else if (active) {
            className += "blower-active"
        }

        return <Blower className={className} />
    }
)

export default memo(BlowerComponent)
