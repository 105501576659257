import Form, { GroupItem, SimpleItem } from "devextreme-react/form"
import { forwardRef, memo, useImperativeHandle } from "react"
import { FunctionResponse } from "../../../types/FunctionsType"
import { FunctionProps } from "../FunctionsList"
import ModbusDataSource from "../ModbusDataSource"
import { initMonacoEditor } from "../MonacoEditor"
import OutputVariable from "../OutputVariable"
import { IFunctionRef, TEXT_AREA_OPTIONS } from "./TestFunction"

const ReadModbus = forwardRef<IFunctionRef, FunctionProps>(({ item }, ref) => {
    useImperativeHandle(
        ref,
        () => ({
            showCallResult: (response: FunctionResponse) => {
                response.data = JSON.stringify(response.data, null, "\t")
                return (
                    <Form formData={response}>
                        <GroupItem colCount={2}>
                            <SimpleItem
                                editorType="dxTextArea"
                                dataField="values"
                                editorOptions={TEXT_AREA_OPTIONS}
                            />
                            <SimpleItem
                                editorType="dxTextArea"
                                dataField="error"
                                visible={response.error ? true : false}
                                editorOptions={TEXT_AREA_OPTIONS}
                            />
                        </GroupItem>
                        <SimpleItem render={initMonacoEditor(response, "data", false, 400)} />
                    </Form>
                )
            }
        }),
        []
    )

    return (
        <Form formData={item}>
            <GroupItem>
                <GroupItem>
                    <ModbusDataSource function={item} />
                </GroupItem>
                <GroupItem>
                    <OutputVariable formData={item} dataField="parameters.output" />
                </GroupItem>
            </GroupItem>
        </Form>
    )
})

export default memo(ReadModbus)
