import Map from "devextreme-react/map"
import { forwardRef, memo, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { useContainerVisible, useExecuteActions, useListExpression } from "../../utils/CustomHooks"
import { MapParameters } from "./MapEditor"

type Params = MapParameters

type Props = ComponentProps<Params>

const MapComponent = forwardRef<IBaseComponent, Props>(
    (
        { markers, coordinates, zoom, controls, disabled, type, setContainerVisible, onlyPreview },
        ref
    ) => {
        const execute = useExecuteActions()
        useContainerVisible(setContainerVisible, false)

        const [colorsValues] = useListExpression(
            useMemo(() => markers?.map((m) => m.colorExpression), [markers])
        )
        const colors = useMemo(() => colorsValues?.map((c) => Math.floor(c)), [colorsValues])

        const resultMarkers = useMemo(
            () =>
                markers?.map((m, i) => {
                    let first = true
                    let value = colors?.[i] ?? 0
                    if (value < 0) value = 0
                    if (value > 4) value = 4

                    return {
                        location: m.location,
                        tooltip: m.tooltip,
                        iconSrc: `${process.env.PUBLIC_URL}/img/led${value}.svg`,
                        onClick: () => {
                            if (!first) {
                                execute(m.onClickActions)
                            }
                            first = false
                        }
                    }
                }),
            [execute, markers, colors]
        )

        if (onlyPreview) {
            return <div>Mapa (pouze v režimu náhled, upravte nastavení)</div>
        }

        return (
            <Map
                height="100%"
                width="100%"
                provider="bing"
                defaultCenter={coordinates}
                defaultZoom={zoom}
                controls={controls === true && !disabled}
                disabled={disabled === true}
                type={type}
                markers={resultMarkers ?? []}
                autoAdjust={true}
            >
                {/* <ApiKey google="AIzaSyAhx-6THUitM87KpSRGpCfynHxHJrq0s3o" /> */}
            </Map>
        )
    }
)

export default memo(MapComponent)
