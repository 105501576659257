import { Canvas } from "@react-three/fiber"
import { forwardRef, memo, useCallback, useContext, useMemo, useRef } from "react"
// import { Rhino3dmLoader } from "three/examples/jsm/loaders/3DMLoader.js"
import { cloneDeep } from "lodash"
import * as THREE from "three"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { EvaluatorContext } from "../../report/contexts/EvaluatorContext"
import { useScrollActivator } from "../../utils/CustomHooks"
import { ModelGroup, useMeshBase, useThreeDCanvasBase } from "../ThreeD/ThreeDComponent"
import { ThreeDMesh } from "../ThreeD/ThreeDEditorUtils"
import "./ThreeDCustomComponent.scss"
import { ThreeDCustomParameters, ThreeDMeshCustom } from "./ThreeDCustomEditor"

type Params = ThreeDCustomParameters

type Props = ComponentProps<Params>

const ThreeDCustomComponent = forwardRef<IBaseComponent, Props>(
    (
        { threeDModels, camera, ambientLights, orbitControls, realSize, gridHelper, axesHelper },
        ref
    ) => {
        const evaluatorContext = useContext(EvaluatorContext)

        useScrollActivator()

        const canvasRef = useRef<HTMLCanvasElement>(null)
        // const [key, setKey] = useState(0)

        const [cameraPos, renderAmbientLights, aditionalRender, canvasStyle] = useThreeDCanvasBase(
            camera,
            ambientLights,
            orbitControls,
            gridHelper,
            axesHelper
        )

        const renderMesh = useCallback((mesh: ThreeDMesh, index: number) => {
            return <MeshCustom key={mesh.guid} {...(mesh as ThreeDMeshCustom)} />
        }, [])

        const modelsRender = useMemo(() => {
            return threeDModels?.map((m, i) => {
                return <ModelGroup key={i} {...m} renderMesh={renderMesh} />
            })
        }, [threeDModels, renderMesh])

        // const schemaScale = useReportStore2((state) => state.schemaScale)
        // useEffect(() => {
        // if (canvasRef.current) {
        //     const { width, height } = canvasRef.current
        //     console.log("canvas", width, height)
        // }
        //     setKey((old) => old + 1)
        // }, [schemaScale])

        // const cam = useMemo(() => {
        //     const result = cloneDeep(threeDCamera)
        //     ;(result.position as any) = cameraPos
        //     return result
        // }, [cameraPos])
        // console.log(JSON.stringify(cameraPos), JSON.stringify(cam))

        return (
            <Canvas /*key={key}*/ ref={canvasRef} camera={cameraPos} style={canvasStyle}>
                <EvaluatorContext.Provider value={evaluatorContext}>
                    <ambientLight intensity={0.9} />
                    {modelsRender}
                    {renderAmbientLights}
                    {aditionalRender}
                </EvaluatorContext.Provider>
            </Canvas>
        )
    }
)

type MeshProps = ThreeDMeshCustom & {}

const MeshCustom = memo(
    ({
        animation,
        onClickActions,
        geometry,
        material,
        guid,
        type,
        colorExpression,
        name
    }: MeshProps) => {
        const [ref, isHovered, evaluatedColor, onHover, onClick] = useMeshBase(
            colorExpression,
            animation,
            onClickActions
        )

        const geom = useMemo(() => {
            const { position, normal, uv } = geometry.data.attributes
            const result = new THREE.BufferGeometry()

            if (position?.array) {
                result.setAttribute(
                    "position",
                    new THREE.BufferAttribute(new Float32Array(position.array), position.itemSize)
                )
            }
            if (normal?.array) {
                result.setAttribute(
                    "normal",
                    new THREE.BufferAttribute(new Float32Array(normal.array), normal.itemSize)
                )
            }
            if (uv?.array) {
                result.setAttribute(
                    "uv",
                    new THREE.BufferAttribute(new Float32Array(uv.array), uv.itemSize)
                )
            }
            return result
        }, [geometry])

        const color = useMemo(() => {
            let clr = material.color
            if (isHovered) {
                clr = 0xe5d54d
            } else if (evaluatedColor !== undefined) {
                clr = evaluatedColor
            }
            return clr
        }, [evaluatedColor, material, isHovered])

        const mat = useMemo(() => {
            const ThreeAsAny: any = THREE
            const MaterialClass = ThreeAsAny[material.type]

            const clonedMaterial = cloneDeep(material)
            clonedMaterial.color = color
            const result = new MaterialClass(clonedMaterial)
            return result
        }, [material, color])

        // takhle by to melo byt spravne, ale protoze se do renderu posila material se starou
        // barvou, tak to nefunguje
        // useEffect(() => {
        //     ;(ref.current.material as any).color.set(clr)
        // }, [ref, isHovered, material.color, evaluatedColor, name])

        return (
            <mesh
                ref={ref}
                onClick={(e) => onClick(e)}
                onPointerOver={(e) => onHover(e, true)}
                onPointerOut={(e) => onHover(e, false)}
                geometry={geom}
                material={mat}
            />
        )
    }
)

export default memo(ThreeDCustomComponent)
