import React from "react"

import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"

import ModbusConnectionsSelectBox from "./ModbusConnectionsSelectBox"
import ListPopupEditor from "./ListPopupEditor"
import { MODBUS_VALUE_TYPES } from "../../types/BaseTypes"
import { IFunction } from "../../types/FunctionsType"

type ModbusProps = {
    function: IFunction
    textAreaHelpText?: string
}

type ModbusState = {
    popupVisible: boolean
    data: string
    error: string
    values: Array<number>
}

class ModbusDataSource extends React.Component<ModbusProps, ModbusState> {
    public static registersTypes = [
        { name: "Holding registers", value: "holding" },
        { name: "Input registers", value: "input" },
        { name: "Coils", value: "coils" }
    ]
    public static registersTypeOptions = {
        items: ModbusDataSource.registersTypes,
        defaultValue: ModbusDataSource.registersTypes[0],
        displayExpr: "name",
        valueExpr: "value"
    }

    private modbusTypesOptions: any

    constructor(props: ModbusProps) {
        super(props)
        this.state = {
            popupVisible: false,
            data: "",
            error: "",
            values: []
        }

        this.modbusTypesOptions = {
            items: MODBUS_VALUE_TYPES,
            defaultValue: MODBUS_VALUE_TYPES[0],
            displayExpr: "name",
            valueExpr: "value",
            onValueChanged: this.onModbusValueTypeChanged
        }
    }

    private onModbusValueTypeChanged = () => {
        this.forceUpdate()
    }

    handlePopupHidden = () => {
        this.setState({ popupVisible: false })
    }

    private renderAddress = (item: any) => {
        return (
            <GroupItem>
                <SimpleItem dataField="name">
                    <Label text="Jméno" />
                </SimpleItem>
                <SimpleItem
                    dataField="type"
                    editorType="dxSelectBox"
                    editorOptions={this.modbusTypesOptions}
                >
                    <Label text="Typ hodnot" />
                </SimpleItem>
                <SimpleItem dataField="startAddress" editorType="dxNumberBox">
                    <Label text="Počáteční adresa" />
                </SimpleItem>
                <SimpleItem dataField="addressesCount" editorType="dxNumberBox">
                    <Label text="Počet adres" />
                </SimpleItem>
                <SimpleItem
                    dataField="swappedWords"
                    editorType="dxCheckBox"
                    visible={
                        item.type &&
                        (item.type === MODBUS_VALUE_TYPES[2].value ||
                            item.type === MODBUS_VALUE_TYPES[3].value)
                    }
                >
                    <Label text="Prohozené wordy" />
                </SimpleItem>
            </GroupItem>
        )
    }

    render() {
        const fun = this.props.function

        return (
            <div className="component-setup-container">
                <Form formData={fun}>
                    <GroupItem>
                        <ModbusConnectionsSelectBox fun={fun} />
                    </GroupItem>
                    <GroupItem colCount={2}>
                        <GroupItem caption="Cesta">
                            <SimpleItem
                                dataField="parameters.registersType"
                                editorType="dxSelectBox"
                                editorOptions={ModbusDataSource.registersTypeOptions}
                            >
                                <Label text="Typ registru" />
                            </SimpleItem>
                            <SimpleItem dataField="parameters.slaveNumber" editorType="dxNumberBox">
                                <Label text="Slave number" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption="Adresy">
                            <ListPopupEditor
                                listOwner={fun.parameters}
                                listName="mAddressesRanges"
                                formItemContent={this.renderAddress}
                            />
                        </GroupItem>
                    </GroupItem>
                </Form>
            </div>
        )
    }
}

export default ModbusDataSource
