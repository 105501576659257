import React, { RefObject } from "react"

import DataGrid, {
    Column,
    Form as DForm,
    Editing,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { SimpleItem } from "devextreme-react/form"

import ImagesCache from "../cache/ImagesCache"
import { IImage } from "../types/BaseTypes"
import { createImage } from "../utils/API/APIImages"
import { gqlRead } from "../utils/APIUtils"
import { standardError, standardSuccess } from "../utils/NotifyUtils"
import BaseCollection, { CollectionProps, CollectionState } from "./BaseCollection"
import {
    PopupFileUploader,
    ShowPopupHandle,
    acceptImages,
    imagesExtensions
} from "./PopupFileUploader"

type LibraryState = CollectionState<IImage> & {
    image?: IImage
}

class ImagesLibrary extends BaseCollection<IImage, CollectionProps, LibraryState> {
    private popupRef: React.RefObject<ShowPopupHandle>

    constructor(props: CollectionProps) {
        super(props, {
            entityName: "image",
            title: "Knihovna obrázků"
        })
        this.state = {
            ...this.state
        }

        this.popupRef = React.createRef()
    }

    protected getEntities = (): Promise<Array<IImage>> => {
        return gqlRead({
            images: {
                id: true,
                name: true,
                type: true
            }
        }).then((r) => {
            return r.images
        })
    }

    onSelectionChanged = (data: { selectedRowsData?: Array<IImage> }) => {
        super.onSelectionChanged(data)

        const image = data?.selectedRowsData?.[0]
        if (image?.id) {
            ImagesCache.getInstance()
                .getImage(image.id)
                .then((response) => this.setState({ image: response as any }))
                .catch(standardError)
        }
    }

    private handleCreateImage = (file: any) => {
        createImage(file)
            .then((response) => {
                this.refreshTable()
                standardSuccess("Obrázek uložen.")
            })
            .catch(standardError)
    }

    protected addCustomToolbarButtons = (toolbar: any) => {
        toolbar.push({
            widget: "dxButton",
            options: {
                icon: "add",
                hint: "Nahrát obrázek",
                onClick: () => this.popupRef.current?.showPopup()
            },
            location: "after"
        })
    }

    protected renderGrid = (dataSource: any, ref: RefObject<DataGrid>): JSX.Element => {
        const image = this.state.image

        return (
            <>
                <PopupFileUploader
                    ref={this.popupRef}
                    handleSave={this.handleCreateImage}
                    fileExtensions={imagesExtensions}
                    accept={acceptImages}
                    maxSizeInMB={20}
                />
                <DataGrid
                    ref={ref}
                    dataSource={dataSource}
                    showBorders={true}
                    columnHidingEnabled={true}
                    onEditorPreparing={this.onEditorPreparing}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <Selection
                        mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="onClick"
                    />
                    <SearchPanel visible={true} />
                    <Editing mode="form" useIcons={true} allowUpdating={true} allowDeleting={true}>
                        <DForm colCount={2}>
                            <SimpleItem dataField="name" />
                        </DForm>
                    </Editing>

                    <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                    <Column dataField="id" caption="ID" allowEditing={false} />
                    <Column dataField="name" caption="Název" />
                </DataGrid>

                {image && (
                    <img
                        width="100%"
                        height="auto"
                        alt="preview"
                        src={`data:${image.type};base64,` + image.data}
                    ></img>
                )}
            </>
        )
    }
}

export default ImagesLibrary
