export default class GAUtils {
    static sendEvent(action: string, category: string, label: string, value: any) {
        ;(window as any).gtag("event", action, {
            event_category: category,
            event_label: label,
            value: value
        })
    }

    static sendEventCustomObject(action: string, customObject: Record<string, any>) {
        ;(window as any).gtag("event", action, customObject)
    }
}
