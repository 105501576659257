import { Button, Form } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { cloneDeep } from "lodash"
import { memo, useCallback, useEffect, useMemo } from "react"
import { PIPE_COLOR_OPTIONS, PIPE_SIZE_OPTIONS } from "../../components/Pipe/PipeEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { useEdgeEditorStore } from "../../stores/EdgeEditorStore"
import { useReportStore } from "../../stores/ReportStore"
import MyPopup, { usePopup } from "../../utils/MyPopup"
import { standardWarning } from "../../utils/NotifyUtils"
import "./Flow.scss"

type Props = {}

const EdgePopupEditor = (props: Props) => {
    const editEdge = useReportStore((state) => state.editEdge)

    const popupVisible = useEdgeEditorStore((state) => state.popupVisible)
    const setPopupVisible = useEdgeEditorStore((state) => state.setPopupVisible)
    const data = useEdgeEditorStore((state) => state.data)
    const edgeId = useEdgeEditorStore((state) => state.edgeId)

    const [ref, instance] = usePopup()
    useEffect(() => (popupVisible ? instance.showPopup() : instance.hidePopup()), [
        instance,
        popupVisible
    ])

    const formData = useMemo(() => cloneDeep(data), [data])

    const handleSave = useCallback(() => {
        setPopupVisible(false)
        if (edgeId) {
            editEdge(edgeId, formData)
        } else {
            standardWarning("Není nastaveno edgeId")
        }
    }, [edgeId, formData, editEdge, setPopupVisible])

    const popupRender = useCallback(() => {
        formData.size = formData.size ?? 3
        return (
            <>
                <Form formData={formData}>
                    <GroupItem>
                        <SimpleItem
                            render={initMonacoEditor(formData, "intensityExpression")}
                            helpText="Výraz musí vrace číslo od 0 - 100, kde 0 znamená vypnuto."
                        >
                            <Label text="Výraz rychlosti toku" />
                        </SimpleItem>
                        <SimpleItem
                            render={initMonacoEditor(formData, "reverseExpression")}
                            helpText="Pro reverzní běh je nutné vrátit true. Defaultní hodnota false."
                        >
                            <Label text="Reverzní běh" />
                        </SimpleItem>
                        <SimpleItem
                            dataField="color"
                            editorType="dxSelectBox"
                            editorOptions={PIPE_COLOR_OPTIONS}
                        >
                            <Label text="Barva" />
                        </SimpleItem>
                        <SimpleItem
                            dataField="size"
                            editorType="dxSlider"
                            editorOptions={PIPE_SIZE_OPTIONS}
                        >
                            <Label text="Velikost" />
                        </SimpleItem>
                    </GroupItem>
                </Form>
                <Button text="Ok" type="default" stylingMode="contained" onClick={handleSave} />
            </>
        )
    }, [formData, handleSave])

    const onHiding = useCallback(() => setPopupVisible(false), [setPopupVisible])

    return (
        <MyPopup
            ref={ref}
            title="Vlastnosti spojnice"
            width="auto"
            height="auto"
            contentRender={popupRender}
            onHiding={onHiding}
        />
    )
}

export default memo(EdgePopupEditor)
