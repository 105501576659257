import { forwardRef, memo, useCallback, useContext, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { useContainerVisible, useExecuteActions, useExpression } from "../../utils/CustomHooks"
import { standardWarning } from "../../utils/NotifyUtils"
import StringUtils from "../../utils/StringUtils"
import "./LedComponent.scss"
import { LedParameters } from "./LedEditor"

type Params = LedParameters

type Props = ComponentProps<Params>

const GRAY_COLOR = "#9d9d9d"

const LedComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, states, onClickActions, setContainerVisible }, ref) => {
        const component = useContext(ComponentContext)?.component!

        const execute = useExecuteActions()
        useContainerVisible(setContainerVisible, false)

        const [index] = useExpression(expression, 0)

        const onClick = useCallback(() => {
            execute(onClickActions)
        }, [execute, onClickActions])

        const indexNumber = useMemo(() => {
            if (StringUtils.isString(index)) {
                try {
                    return parseInt(index)
                } catch (error) {
                    standardWarning(index + " is not a number. Led: " + component.name)
                    return 0
                }
            }
            return index
        }, [component.name, index])

        const color =
            !indexNumber || !states || indexNumber > states.length
                ? GRAY_COLOR
                : states[Math.floor(indexNumber) - 1]?.color

        const clickable = onClickActions && onClickActions.length > 0

        return (
            <svg
                className={"led-component" + (clickable ? " clickable" : "")}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 370.38 370.38"
                onClick={onClick}
            >
                <defs></defs>
                <g id="Vrstva_2" data-name="Vrstva 2">
                    <g id="body">
                        <circle
                            className="led-cls-1"
                            style={{ fill: color }}
                            cx="185.19"
                            cy="185.19"
                            r="185.19"
                        />
                    </g>
                    <g id="shadow">
                        <path
                            className="led-cls-2"
                            d="M30.92,168.32c-3.11,62.77,27.72,124.4,70,141.44C154,331.15,94.08,247,101.4,165c5.72-64,99.76-145.59,40-132.6C97.91,41.87,34.34,99.47,30.92,168.32Z"
                        />
                    </g>
                </g>
            </svg>
        )
    }
)

export default memo(LedComponent)
