import { Button, Form, Popup } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback, useState } from "react"
import { $enum } from "ts-enum-util"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

enum ChartType {
    "line" = "line",
    "bar" = "bar",
    // "horizontalBar" = "horizontalBar",
    "radar" = "radar",
    "doughnut" = "doughnut",
    "polarArea" = "polarArea",
    "bubble" = "bubble",
    "pie" = "pie",
    "scatter" = "scatter"
}

export type ChartParameters = BaseParams & {
    type: ChartType
    dataExpression: string
    optionsExpression: string
    resetZoomButton: boolean
    exportImageButton: boolean
}

const typeOptions = { items: $enum(ChartType).getValues() }

function ChartEditor({ parameters }: EditorProps<ChartParameters>) {
    const [popupVisible, setPopupVisible] = useState(false)

    const showPopup = useCallback(() => setPopupVisible(true), [])

    const hidePopup = useCallback(() => setPopupVisible(false), [])

    const docRender = useCallback(
        () => (
            <div>
                Komponenta pouze obaluje implementaci knihovny{" "}
                <a target="_blank" rel="noopener noreferrer" href="https://www.chartjs.org/">
                    ChartJS
                </a>
                {". "}
                Komponenta použití této knihovny nijak neomezuje.
                <br></br>
                <br></br>Z této knihovny je možné použít cokoliv, co je zvěřejněno v oficiální
                dokumentaci. Základní konfiguraci tvoří objekt <b>options</b> a <b>data</b>. Obě
                položky jsou typu výraz, aby bylo možné graf měnit dynamicky. Položka{" "}
                <b>Typ grafu</b> je nutná, ale není omezující. U každého datasetu (série) je možné
                specifikovat jiný typ vykreslení dat. (Bar, line, atp.)
                <br></br>
                <br></br>
                Položka <b>data</b> dále obsahuje především položky <b>labels</b> a <b>datasets</b>.
                Položka <b>labels</b> obsahuje výčet možných hodnot pro osu <b>x</b>. Položka{" "}
                <b>datasets</b> pak obsahuje ke každému z tohoto výčtu velikost na ose <b>y</b>. V
                případě konfigurace osy <b>x</b> jako "časové" se typicky položka <b>labels</b>{" "}
                nepoužívá. Dopředu totiž většinou není znám přesný počet hodnot jednotlivých
                datasetů. Více o konfiguraci datových struktur grafu zde:{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.chartjs.org/docs/latest/general/data-structures.html"
                >
                    Data sctructures
                </a>
                <br></br>
                <br></br>
                Jednotlivé příklady použití{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.chartjs.org/docs/latest/samples/information.html"
                >
                    Examples
                </a>
                <br></br>
                Příklad konfigurace časové osy{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.chartjs.org/docs/latest/samples/scales/time-line.html"
                >
                    Time Scale
                </a>
                <br></br>
                Čas je formátován dle knihovny{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/moment/luxon/blob/master/docs/formatting.md"
                >
                    Luxon (sekce - Table of tokens)
                </a>
                {". "}
                Např. "HH:mm" odpovídá hodinám a minutám 24hod. formátu vždy na dvě místa.
                <br></br>
                Knihovna umožňuje použití řady pluginů. Jeden z nich je např. pro možnost Zoom&Pan{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.chartjs.org/chartjs-plugin-zoom/latest/guide/usage.html"
                >
                    Plugin Zoom
                </a>
                <br></br>
            </div>
        ),
        []
    )

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Datový zdroj">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <SimpleItem
                            dataField="type"
                            editorType="dxSelectBox"
                            editorOptions={typeOptions}
                        >
                            <Label text="Typ grafu" />
                        </SimpleItem>
                        <SimpleItem render={initMonacoEditor(parameters, "optionsExpression")}>
                            <Label text="Výraz pro položku options" />
                        </SimpleItem>
                        <SimpleItem render={initMonacoEditor(parameters, "dataExpression")}>
                            <Label text="Výraz pro položku data" />
                        </SimpleItem>
                        <GroupItem>
                            <Popup
                                visible={popupVisible}
                                onHiding={hidePopup}
                                contentRender={docRender}
                            />
                            <Button text="Dokumentace" onClick={showPopup} />
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="Ostatní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <SimpleItem dataField="resetZoomButton" editorType="dxCheckBox">
                            <Label text="Tlačítko na reset zoom" />
                        </SimpleItem>
                        <SimpleItem dataField="exportImageButton" editorType="dxCheckBox">
                            <Label text="Tlačítko na export do obrázku" />
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(ChartEditor)

export const getChartBasicConfigurations = () => {
    return [
        {
            type: "line",
            optionsExpression: `return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true
                },
                pinch: {
                    enabled: true
                },
                mode: "xy"
            },
            pan: {
                enabled: true,
                mode: "xy"
            }
        }
    },
    scales: {
        y: {
            position: "left",
            min: 4,
            max: 6
        },
        y1: {
            position: "right",
            min: -20,
            max: 150,
            title: {
                text: "Hladina",
                // align: "start",
                display: true
            },
            border: {
                color: "blue"
            }
        },
        x: {
            type: "time",
            display: true,
            offset: true,
            time: {
                // Luxon format string
                tooltipFormat: "DD T"
            }
        }
    }
}`,
            dataExpression: `return {
    datasets: [
        {
            type: "bar",
            label: "Dataset 1",
            data: [
                { x: "2023-01-18T07:38:47", y: 15 },
                { x: "2023-01-18T08:38:47", y: 10 },
                { x: "2023-01-18T09:38:47", y: 20 },
                { x: "2023-01-18T10:38:47", y: 5 }
            ],
            yAxisID: "y"
        },
        {
            type: "line",
            label: "Dataset 3",
            fill: false,
            data: [
                { x: "2023-01-16T23:38:47", y: 15 },
                { x: "2023-01-17T08:38:47", y: 10 },
                { x: "2023-01-18T09:38:47", y: 20 },
                { x: "2023-01-19T10:38:47", y: 5 }
            ],
            yAxisID: "y1"
        }
    ]
}`
        }
    ]
}
