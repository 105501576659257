import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { SLIDER_TOOLTIP_SETTINGS } from "../../component/EditorTypes"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action } from "../../types/ComponentTypes"
import { ComponentTextSize, TextSizeSettings } from "../Text/TextEditor"

export type ButtonParams = {
    guid: string
    textExpression?: string
    enabledExpression: string
    onClickActions?: Array<Action>
}

export type ButtonParameters = BaseParams &
    ButtonParams & {
        fontSize: ComponentTextSize
    }

export const getButtonBasicConfigurations = () => {
    return [{}]
}
export const BUTTON_MAX_SIZE = 5
export const BUTTON_SLIDER_OPTIONS = {
    min: 1,
    max: BUTTON_MAX_SIZE,
    tooltip: SLIDER_TOOLTIP_SETTINGS
}

function ButtonEditor({ parameters }: EditorProps<ButtonParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <TabBasic parameters={parameters} />
            </Item>
            <Item title="OnClick">
                <ActionsTriggerEditor listOwner={parameters} listName="onClickActions" />
            </Item>
        </TabPanel>
    )
}

type ParametersProps = {
    parameters: ButtonParameters
}

function TabBasic({ parameters }: ParametersProps) {
    return (
        <div className="component-setup-container">
            <Form formData={parameters}>
                <GroupItem>
                    <TextSizeSettings parameters={parameters} />
                </GroupItem>
                <SimpleItem render={initMonacoEditor(parameters, "textExpression")}>
                    <Label text="Výraz pro text" />
                </SimpleItem>
                <SimpleItem render={initMonacoEditor(parameters, "enabledExpression")}>
                    <Label text="Výraz aktivnosti" />
                </SimpleItem>
            </Form>
        </div>
    )
}

export default memo(ButtonEditor)
