import { GroupItem } from "devextreme-react/form"
import { memo, useCallback } from "react"
import { ComponentProvider } from "../../report/contexts/ComponentContext"
import { FunctionsProvider } from "../../report/contexts/FunctionsContext"
import { ParentProvider } from "../../report/contexts/ParentContext"
import { ReportPathProvider } from "../../report/contexts/ReportPathContext"
import { VariablesProvider } from "../../report/contexts/VariablesContext"
import Editor from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams, IComponent } from "../../types/BaseTypes"
import ComponentUtils from "../../utils/ComponentUtils"
import ListPopupEditor from "./ListPopupEditor"

type ComponentsProps = {
    component: IComponent<BaseParams>
}

const ComponentsList = ({ component }: ComponentsProps) => {
    const componentEditor = useCallback((item: IComponent<BaseParams>) => {
        return (
            <GroupItem>
                <ReportPathProvider guid={item.guid}>
                    <ComponentProvider component={item}>
                        <ParentProvider p={item}>
                            <VariablesProvider componentParent={item}>
                                <FunctionsProvider component={item} name={item.name}>
                                    <Editor formData={item} />
                                </FunctionsProvider>
                            </VariablesProvider>
                        </ParentProvider>
                    </ComponentProvider>
                </ReportPathProvider>
            </GroupItem>
        )
    }, [])

    const componentCopiedFilter = useCallback((item: IComponent) => {
        ComponentUtils.newIdentityDeepWithoutGuids(item as any)
    }, [])

    return (
        <ListPopupEditor
            listOwner={component}
            listName="components"
            idProperty="guid"
            guid={true}
            itemCopiedFilter={componentCopiedFilter}
            formItemContent={componentEditor}
        />
    )
}

export default memo(ComponentsList)
