import { ToastType } from "../utils/NotifyUtils"
import { FParamDefinition } from "./FunctionsType"

export enum ComponentsEnum {
    CHART = "CHART",
    GRAPH = "GRAPH",
    GAUGE = "GAUGE",
    TABLE = "TABLE",
    PIPE = "PIPE",
    PIPE_ROUND = "PIPE_ROUND",
    PIPE_T = "PIPE_T",
    ENGINE = "ENGINE",
    PUMP = "PUMP",
    FLAP = "FLAP",
    STIRRER = "STIRRER",
    BLOWER = "BLOWER",
    CENTRIFUGE = "CENTRIFUGE",
    TANK = "TANK",
    GRID = "GRID",
    POPUP = "POPUP",
    ROWS = "ROWS",
    COLUMNS = "COLUMNS",
    COMPRESSOR = "COMPRESSOR",
    BUTTON = "BUTTON",
    BUTTON_GROUP = "BUTTON_GROUP",
    HEADING = "HEADING",
    TEXT = "TEXT",
    SELECTBOX = "SELECTBOX",
    DATETIME = "DATETIME",
    GAUGE_SWITCH = "GAUGE_SWITCH",
    LED = "LED",
    LINE = "LINE",
    VENTILATOR = "VENTILATOR",
    MAP = "MAP",
    IMAGE = "IMAGE",
    MULTI_IMAGE = "MULTI_IMAGE",
    NOTIFICATIONS = "NOTIFICATIONS",
    VOICE = "VOICE",
    THREE_D = "THREE_D",
    THREE_D_CUSTOM = "THREE_D_CUSTOM",
    IFRAME = "IFRAME"
}

export type ComponentType =
    | ComponentsEnum.CHART
    | ComponentsEnum.GRID
    | ComponentsEnum.POPUP
    | ComponentsEnum.ROWS
    | ComponentsEnum.COLUMNS
    | ComponentsEnum.COMPRESSOR
    | ComponentsEnum.HEADING
    | ComponentsEnum.TEXT
    | ComponentsEnum.SELECTBOX
    | ComponentsEnum.DATETIME
    | ComponentsEnum.BUTTON
    | ComponentsEnum.BUTTON_GROUP
    | ComponentsEnum.GAUGE_SWITCH
    | ComponentsEnum.TABLE
    | ComponentsEnum.GRAPH
    | ComponentsEnum.GAUGE
    | ComponentsEnum.LED
    | ComponentsEnum.LINE
    | ComponentsEnum.VENTILATOR
    | ComponentsEnum.ENGINE
    | ComponentsEnum.PUMP
    | ComponentsEnum.FLAP
    | ComponentsEnum.STIRRER
    | ComponentsEnum.BLOWER
    | ComponentsEnum.CENTRIFUGE
    | ComponentsEnum.TANK
    | ComponentsEnum.PIPE
    | ComponentsEnum.PIPE_ROUND
    | ComponentsEnum.PIPE_T
    | ComponentsEnum.MAP
    | ComponentsEnum.IMAGE
    | ComponentsEnum.MULTI_IMAGE
    | ComponentsEnum.NOTIFICATIONS
    | ComponentsEnum.VOICE
    | ComponentsEnum.THREE_D
    | ComponentsEnum.THREE_D_CUSTOM
    | ComponentsEnum.IFRAME

export const ChartHead = { name: "Chart", type: ComponentsEnum.CHART }
export const GridHead = { name: "Grid", type: ComponentsEnum.GRID }
export const PopupHead = { name: "Popup", type: ComponentsEnum.POPUP }
export const RowsHead = { name: "Řádkový", type: ComponentsEnum.ROWS }
export const ColumnsHead = { name: "Sloupcový", type: ComponentsEnum.COLUMNS }
export const CompressorHead = { name: "Kompresor", type: ComponentsEnum.COMPRESSOR }
export const CentrifugeHead = { name: "Odstředivka", type: ComponentsEnum.CENTRIFUGE }
export const HeadingHead = { name: "Nadpis", type: ComponentsEnum.HEADING }
export const TextHead = { name: "Textové pole", type: ComponentsEnum.TEXT }
export const SelectboxHead = { name: "Výběrové pole", type: ComponentsEnum.SELECTBOX }
export const DateTimeHead = { name: "Datumové pole", type: ComponentsEnum.DATETIME }
export const ButtonHead = { name: "Tlačítko", type: ComponentsEnum.BUTTON }
export const LineHead = { name: "Linka", type: ComponentsEnum.LINE }
export const ButtonGroupHead = { name: "Skupina tlačítek", type: ComponentsEnum.BUTTON_GROUP }
export const GaugeSwitchHead = { name: "Kruhový přepínač", type: ComponentsEnum.GAUGE_SWITCH }
export const TableHead = { name: "Tabulka", type: ComponentsEnum.TABLE }
export const GraphHead = { name: "Graf", type: ComponentsEnum.GRAPH }
export const GaugeHead = { name: "Měřící přístroj", type: ComponentsEnum.GAUGE }
export const LedHead = { name: "LED", type: ComponentsEnum.LED }
export const EngineHead = { name: "Motor", type: ComponentsEnum.ENGINE }
export const PumpHead = { name: "Čerpadlo", type: ComponentsEnum.PUMP }
export const StirrerHead = { name: "Míchadlo", type: ComponentsEnum.STIRRER }
export const BlowerHead = { name: "Dmychadlo", type: ComponentsEnum.BLOWER }
export const VentilatorHead = { name: "Ventilátor", type: ComponentsEnum.VENTILATOR }
export const TankHead = { name: "Nádrž", type: ComponentsEnum.TANK }
export const PipeHead = { name: "Trubka (zastaralé)", type: ComponentsEnum.PIPE }
export const PipeRoundHead = { name: "Trubka L", type: ComponentsEnum.PIPE_ROUND }
export const PipeTHead = { name: "Trubka T", type: ComponentsEnum.PIPE_T }
export const FlapHead = { name: "Klapka", type: ComponentsEnum.FLAP }
export const MapHead = { name: "Mapa", type: ComponentsEnum.MAP }
export const ImageHead = { name: "Obrázek", type: ComponentsEnum.IMAGE }
export const MultiImageHead = { name: "Multi-obrázek", type: ComponentsEnum.MULTI_IMAGE }
export const NotificationsHead = { name: "Notifikace", type: ComponentsEnum.NOTIFICATIONS }
export const VoiceHead = { name: "Hlasové příkazy", type: ComponentsEnum.VOICE }
export const ThreeDHead = { name: "3D", type: ComponentsEnum.THREE_D }
export const ThreeDCustomHead = { name: "3D Custom model", type: ComponentsEnum.THREE_D_CUSTOM }
export const IFrameHead = { name: "IFrame", type: ComponentsEnum.IFRAME }

export const ComponentCategories = [
    {
        key: "Kontejnery",
        items: [GridHead, PopupHead, RowsHead, ColumnsHead]
    },
    {
        key: "Ovládací prvky",
        items: [
            HeadingHead,
            TextHead,
            SelectboxHead,
            DateTimeHead,
            ButtonHead,
            LineHead,
            ButtonGroupHead,
            GaugeSwitchHead
        ]
    },
    {
        key: "Přehledy",
        items: [TableHead, ChartHead, GraphHead, GaugeHead, LedHead]
    },
    {
        key: "Pohony",
        items: [
            EngineHead,
            PumpHead,
            StirrerHead,
            BlowerHead,
            VentilatorHead,
            CompressorHead,
            CentrifugeHead
        ]
    },
    {
        key: "Potrubí",
        items: [TankHead, PipeRoundHead, PipeTHead, PipeHead, FlapHead]
    },
    {
        key: "Ostatní",
        items: [
            MapHead,
            ImageHead,
            MultiImageHead,
            NotificationsHead,
            VoiceHead,
            ThreeDHead,
            ThreeDCustomHead,
            IFrameHead
        ]
    }
]

export type RealSize = {
    width: number
    height: number
}

export enum ActionEnum {
    FUNCTION = "function",
    VARIABLE = "variable",
    REPORT = "report",
    TOAST = "toast",
    REPORT_STATE = "reportState"
}
export type ActionType =
    | ActionEnum.FUNCTION
    | ActionEnum.VARIABLE
    | ActionEnum.REPORT
    | ActionEnum.TOAST
    | ActionEnum.REPORT_STATE

export const ActionTypes = [
    { name: "Spustit funkci", type: ActionEnum.FUNCTION },
    { name: "Nastavit proměnnou", type: ActionEnum.VARIABLE },
    { name: "Zobrazit report", type: ActionEnum.REPORT },
    { name: "Toast zpráva", type: ActionEnum.TOAST },
    { name: "Nastavit stav reportu", type: ActionEnum.REPORT_STATE }
]

export type Action = {
    id: string
    type?: ActionType
    functionGuid?: string
    outputVariable?: string
    expression?: string
    reportId?: number
    toastMessageExpression?: string
    toastType?: ToastType
    toastDuration?: number
    params?: Array<FParamDefinition>
}

export type ColorState = {
    guid: string
    color: string
}
