import { ACCESS_TOKEN, GRAPHQL_BASE_URL } from "../constants"
import { jsonToGraphQLQuery } from "json-to-graphql-query"
import { IBase } from "../types/BaseTypes"
import StringUtils from "./StringUtils"

export type FetchOptions = RequestInit & {
    url: string
    queryParams?: Record<string, string | number | boolean | undefined>
}

export const request = (options: FetchOptions, contentType?: string | null) => {
    const headers = new Headers({})
    if (contentType !== null) {
        headers.append("Content-Type", contentType === undefined ? "application/json" : contentType)
    }

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append("Authorization", "Bearer " + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers }
    options = Object.assign({}, defaults, options)

    const url =
        options.url +
        (options.queryParams ? "?" + new URLSearchParams(options.queryParams as any) : "")

    return fetch(url, options).then((response) =>
        response.json().then((json) => {
            if (!response.ok) {
                return Promise.reject(json)
            }
            return json
        })
    )
}

export function accessTokenExists(successCallback: () => Promise<any>): Promise<any> {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.")
    }
    return successCallback()
}

export function securityApiCall(
    method: () => Promise<any>,
    successCallback: (response: any) => void,
    notAutorizedCallback: () => void,
    errorCallback: (error: Error) => void,
    finallyCalback: () => void
) {
    method()
        .then((response) => successCallback(response))
        .catch((error) => {
            if (error.status === 401) {
                notAutorizedCallback()
            } else {
                errorCallback(error)
            }
        })
        .finally(() => finallyCalback?.())
}

export function gqlRead(body: object): Promise<Record<string, any>> {
    return accessTokenExists(() => {
        return request({
            url: GRAPHQL_BASE_URL,
            method: "POST",
            body: JSON.stringify({ query: `{${jsonToGraphQLQuery(body)}}` })
        }).then((r) => {
            if (r.errors) {
                throw new Error(r.errors.map((e: any) => e.message).join("\r"))
            }
            return r.data
        })
    })
}

// export function gqlWrite(body: object): Promise<Record<string, any>> {
export function gqlWrite(body: object): Promise<Array<IBase>> {
    return accessTokenExists(() => {
        return request({
            url: GRAPHQL_BASE_URL,
            method: "POST",
            body: JSON.stringify({ query: `mutation {${jsonToGraphQLQuery(body)}}` })
        }).then((r) => {
            if (r.errors) {
                throw new Error(r.errors.map((e: any) => e.message).join("\r"))
            }
            return r.data
        })
    })
}

export function gqlPersist(entityName: string, values: Array<IBase>): Promise<any> {
    return gqlWrite({
        [entityName + "s"]: {
            __args: {
                input: values
            },
            id: true
        }
    })
}

export function gqlDelete(entityName: string, ids: Array<Number>): Promise<any> {
    return gqlWrite({
        ["remove" + StringUtils.capitalize(entityName) + "s"]: {
            __args: {
                ids: ids
            }
        }
    })
}

export function gqlDeepDelete(entityName: string, ids: Array<Number>): Promise<any> {
    return gqlWrite({
        ["deepDelete" + StringUtils.capitalize(entityName) + "s"]: {
            __args: {
                ids: ids
            }
        }
    })
}

export function gqlDeepCopy(entityName: string, ids: Array<Number>): Promise<any> {
    return gqlWrite({
        ["deepCopy" + StringUtils.capitalize(entityName) + "s"]: {
            __args: {
                ids: ids
            },
            id: true
        }
    })
}

// export function apiCalls(
//     methods: Array<() => Promise<Response>>
//     successCallback: (responses: Array<any>) => void,
//     errorCallback: (errors: Array<Error>) => void,
//     finallyCalback: (() => void) | undefined
// ) {
//     const responses: Array<any> = []
//     const errors: Array<Error> = []
//     const checkEnd = () => {
//         if (responses.length + errors.length === methods.length) {
//             if (errors.length === 0) {
//                 successCallback(responses)
//             } else {
//                 errorCallback(errors)
//             }
//             finallyCalback?.()
//         }
//     }
//     methods.forEach((method, index) => {
//         method()
//             .then((response: any) => (responses[index] = response))
//             .catch((error: Error) => (errors[index] = error))
//             .finally(() => checkEnd())
//     })
// }
