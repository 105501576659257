import { createBrowserHistory } from "history"
import { memo, useContext, useEffect, useMemo } from "react"
import Loading from "../basic/Loading"
import "../css/ReactGridLayout.scss"
import "../css/ReactResizable.scss"
import "../css/Report.scss"
import { useReportStore } from "../stores/ReportStore"
import AdminComponent from "../utils/AdminComponent"
import { useReportFields } from "../utils/CustomHooks"
import EditModeComponent from "../utils/EditModeComponent"
import ComponentToolbar from "./ComponentToolbar/ComponentToolbar"
import { COMPONENT_CONTAINER_CLASS } from "./Schema/Layer/ComponentContainer/ComponentContainer"
import ComponentContextMenu from "./componentContextMenu/ComponentContextMenu"
import { EvaluatorProvider } from "./contexts/EvaluatorContext"
import { FunctionsProvider } from "./contexts/FunctionsContext"
import { ParentProvider } from "./contexts/ParentContext"
import { ReportIdContext } from "./contexts/ReportIdContext"
import { ReportPathProvider } from "./contexts/ReportPathContext"
import { VariablesProvider } from "./contexts/VariablesContext"
import Flow from "./flow/Flow"
import ReportManagement from "./management/ReportManagement"

export const history = createBrowserHistory()

type Props = {}

function Report(props: Props) {
    const reportId = useContext(ReportIdContext)?.reportId
    const fetchReport = useReportStore((state) => state.fetchReport)
    const [name, id, components, functions, variables] = useReportFields((r) => [
        r.name,
        r.id,
        r.components,
        r.functions,
        r.variables
    ])

    useEffect(() => {
        fetchReport(reportId!)
    }, [fetchReport, reportId])

    const reportData = useMemo(() => ({ name, id, components, variables, functions }), [
        name,
        id,
        components,
        variables,
        functions
    ])

    if (!id) {
        return <Loading />
    }

    return (
        <ReportPathProvider guid={undefined}>
            <ParentProvider p={reportData}>
                <VariablesProvider componentParent={reportData}>
                    <EvaluatorProvider>
                        <FunctionsProvider component={reportData} name={name}>
                            <>
                                <AdminComponent>
                                    <EditModeComponent>
                                        <ComponentContextMenu
                                            selector={"." + COMPONENT_CONTAINER_CLASS}
                                        />
                                        <ComponentToolbar />
                                    </EditModeComponent>
                                </AdminComponent>
                                <Flow />
                                <AdminComponent>
                                    <ReportManagement />
                                </AdminComponent>
                            </>
                        </FunctionsProvider>
                    </EvaluatorProvider>
                </VariablesProvider>
            </ParentProvider>
        </ReportPathProvider>
    )
}

export default memo(Report)
