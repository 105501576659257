import { RefObject } from "react"

import DataGrid, { Column, Editing, Form, SearchPanel, Selection } from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"

import { ReportPathProvider } from "../report/contexts/ReportPathContext"
import ComponentContainer from "../report/Schema/Layer/ComponentContainer/ComponentContainer"
import { IBook, IComponent } from "../types/BaseTypes"
import { getLibrary, persistBook } from "../utils/API/APILibrary"
import BaseCollection, { CollectionProps, CollectionState } from "./BaseCollection"

type LibraryState = CollectionState<IBook> & {
    component?: IComponent
}

class Library extends BaseCollection<IBook, CollectionProps, LibraryState> {
    constructor(props: CollectionProps) {
        super(props, {
            entityName: "book",
            copyButton: true,
            title: "Knihovna komponent"
        })
        this.state = {
            ...this.state
        }

        this.onSelectionChanged = this.onSelectionChanged.bind(this)
    }

    // TODO any
    protected getEntities = (): Promise<any> => {
        return getLibrary()
    }

    protected createEntities = (values: Array<IBook>): Promise<Array<IBook>> => {
        return persistBook(values)
    }

    protected editEntities = (values: Array<IBook>): Promise<Array<IBook>> => {
        return persistBook(values)
    }

    protected onCopy = (values: Array<IBook>): Promise<Array<IBook>> => {
        return persistBook(values)
    }

    protected onSelectionChanged(data: { selectedRowsData?: Array<IBook> }) {
        super.onSelectionChanged(data)

        this.setState({ component: data.selectedRowsData?.[0]?.component })
    }

    protected renderGrid = (dataSource: any, ref: RefObject<DataGrid>): JSX.Element => {
        const componentDef = this.state.component

        return (
            <>
                <DataGrid
                    ref={ref}
                    dataSource={dataSource}
                    showBorders={true}
                    columnHidingEnabled={true}
                    onEditorPreparing={this.onEditorPreparing}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <Selection
                        mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="onClick"
                    />
                    <SearchPanel visible={true} />
                    <Editing mode="form" useIcons={true} allowUpdating={true} allowDeleting={true}>
                        <Form colCount={1}>
                            <Item dataField="name" />
                        </Form>
                    </Editing>

                    <Column type="buttons" buttons={["edit", "delete"]} />
                    <Column dataField="id" caption="ID" allowEditing={false} />
                    <Column dataField="name" caption="Název" />
                </DataGrid>

                {componentDef && (
                    <ReportPathProvider guid={componentDef.guid}>
                        <div style={{ height: 500 }}>
                            {
                                <ComponentContainer
                                    component={componentDef}
                                    key={Math.floor(Math.random() * 1000000)}
                                    // parent={dummyParent}
                                />
                            }
                        </div>
                    </ReportPathProvider>
                )}
            </>
        )
    }
}

export default Library
