import { createElement } from "react"

import BlowerComponent from "../components/Blower/BlowerComponent"
import BlowerEditor from "../components/Blower/BlowerEditor"
import ButtonComponent from "../components/Button/ButtonComponent"
import ButtonEditor from "../components/Button/ButtonEditor"
import ButtonGroupComponent from "../components/ButtonGroup/ButtonGroupComponent"
import ButtonGroupEditor from "../components/ButtonGroup/ButtonGroupEditor"
import CentrifugeComponent from "../components/Centrifuge/CentrifugeComponent"
import CentrifugeEditor from "../components/Centrifuge/CentrifugeEditor"
import ChartComponent from "../components/Chart/ChartComponent"
import ChartEditor from "../components/Chart/ChartEditor"
import ColumnsComponent from "../components/Columns/ColumnsComponent"
import ColumnsEditor from "../components/Columns/ColumnsEditor"
import CompressorComponent from "../components/Compressor/CompressorComponent"
import CompressorEditor from "../components/Compressor/CompressorEditor"
import DateTimeComponent from "../components/DateTime/DateTimeComponent"
import DateTimeEditor from "../components/DateTime/DateTimeEditor"
import EngineComponent from "../components/Engine/EngineComponent"
import EngineEditor from "../components/Engine/EngineEditor"
import FlapComponent from "../components/Flap/FlapComponent"
import FlapEditor from "../components/Flap/FlapEditor"
import GaugeComponent from "../components/Gauge/GaugeComponent"
import GaugeEditor from "../components/Gauge/GaugeEditor"
import GaugeSwitchComponent from "../components/GaugeSwitch/GaugeSwitchComponent"
import GaugeSwitchEditor from "../components/GaugeSwitch/GaugeSwitchEditor"
import GraphComponent from "../components/Graph/GraphComponent"
import GraphEditor from "../components/Graph/GraphEditor"
import GridComponent from "../components/Grid/GridComponent"
import GridEditor from "../components/Grid/GridEditor"
import HeadingComponent from "../components/Heading/HeadingComponent"
import HeadingEditor from "../components/Heading/HeadingEditor"
import IFrameComponent from "../components/IFrame/IFrameComponent"
import IFrameEditor from "../components/IFrame/IFrameEditor"
import ImageComponent from "../components/Image/ImageComponent"
import ImageEditor from "../components/Image/ImageEditor"
import LedComponent from "../components/Led/LedComponent"
import LedEditor from "../components/Led/LedEditor"
import LineComponent from "../components/Line/LineComponent"
import LineEditor from "../components/Line/LineEditor"
import MapComponents from "../components/Map/MapComponents"
import MapEditor from "../components/Map/MapEditor"
import MultiImageComponent from "../components/MultiImage/MultiImageComponent"
import MultiImageEditor from "../components/MultiImage/MultiImageEditor"
import NotificationsComponent from "../components/Notifications/NotificationsComponent"
import NotificationsEditor from "../components/Notifications/NotificationsEditor"
import PipeComponent from "../components/Pipe/PipeComponent"
import PipeEditor from "../components/Pipe/PipeEditor"
import PipeRoundComponent from "../components/PipeRound/PipeRoundComponent"
import PipeRoundEditor from "../components/PipeRound/PipeRoundEditor"
import PipeTComponent from "../components/PipeT/PipeTComponent"
import PipeTEditor from "../components/PipeT/PipeTEditor"
import PopupComponent from "../components/Popup/PopupComponent"
import PopupEditor from "../components/Popup/PopupEditor"
import PumpComponent from "../components/Pump/PumpComponent"
import PumpEditor from "../components/Pump/PumpEditor"
import { RowsComponent } from "../components/Rows/RowsComponent"
import RowsEditor from "../components/Rows/RowsEditor"
import SelectboxComponent from "../components/Selectbox/SelectboxComponent"
import SelectboxEditor from "../components/Selectbox/SelectboxEditor"
import StirrerComponent from "../components/Stirrer/StirrerComponent"
import StirrerEditor from "../components/Stirrer/StirrerEditor"
import TableComponent from "../components/Table/TableComponent"
import TableEditor from "../components/Table/TableEditor"
import TankComponent from "../components/Tank/TankComponent"
import TankEditor from "../components/Tank/TankEditor"
import TextComponent from "../components/Text/TextComponent"
import TextEditor from "../components/Text/TextEditor"
import ThreeDComponent from "../components/ThreeD/ThreeDComponent"
import ThreeDEditor from "../components/ThreeD/ThreeDEditor"
import ThreeDCustomComponent from "../components/ThreeDCustom/ThreeDCustomComponent"
import ThreeDCustomEditor from "../components/ThreeDCustom/ThreeDCustomEditor"
import VentilatorComponent from "../components/Ventilator/VentilatorComponent"
import VentilatorEditor from "../components/Ventilator/VentilatorEditor"
import VoiceComponent from "../components/Voice/VoiceComponent"
import VoiceEditor from "../components/Voice/VoiceEditor"
import { ComponentProps } from "../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"

type ClassDef = {
    name: string
    componentClass: any
}

export default class ComponentsCreator {
    static componentsMap: Array<ClassDef> = [
        { name: "GraphComponent", componentClass: GraphComponent },
        { name: "ChartComponent", componentClass: ChartComponent },
        { name: "TableComponent", componentClass: TableComponent },
        { name: "GaugeComponent", componentClass: GaugeComponent },
        { name: "PipeComponent", componentClass: PipeComponent },
        { name: "PipeRoundComponent", componentClass: PipeRoundComponent },
        { name: "PipeTComponent", componentClass: PipeTComponent },
        { name: "EngineComponent", componentClass: EngineComponent },
        { name: "PumpComponent", componentClass: PumpComponent },
        { name: "FlapComponent", componentClass: FlapComponent },
        { name: "StirrerComponent", componentClass: StirrerComponent },
        { name: "BlowerComponent", componentClass: BlowerComponent },
        { name: "CentrifugeComponent", componentClass: CentrifugeComponent },
        { name: "TankComponent", componentClass: TankComponent },
        { name: "GridComponent", componentClass: GridComponent },
        { name: "PopupComponent", componentClass: PopupComponent },
        { name: "RowsComponent", componentClass: RowsComponent },
        { name: "ColumnsComponent", componentClass: ColumnsComponent },
        { name: "CompressorComponent", componentClass: CompressorComponent },
        { name: "ButtonComponent", componentClass: ButtonComponent },
        { name: "ButtonGroupComponent", componentClass: ButtonGroupComponent },
        { name: "HeadingComponent", componentClass: HeadingComponent },
        { name: "TextComponent", componentClass: TextComponent },
        { name: "SelectboxComponent", componentClass: SelectboxComponent },
        { name: "DateTimeComponent", componentClass: DateTimeComponent },
        { name: "GaugeSwitchComponent", componentClass: GaugeSwitchComponent },
        { name: "LedComponent", componentClass: LedComponent },
        { name: "LineComponent", componentClass: LineComponent },
        { name: "VentilatorComponent", componentClass: VentilatorComponent },
        { name: "MapComponent", componentClass: MapComponents },
        { name: "ImageComponent", componentClass: ImageComponent },
        { name: "MultiImageComponent", componentClass: MultiImageComponent },
        { name: "NotificationsComponent", componentClass: NotificationsComponent },
        { name: "VoiceComponent", componentClass: VoiceComponent },
        { name: "ThreeDComponent", componentClass: ThreeDComponent },
        { name: "ThreeDCustomComponent", componentClass: ThreeDCustomComponent },
        { name: "IFrameComponent", componentClass: IFrameComponent }
    ]

    static componentsSetupMap: Array<ClassDef> = [
        { name: "GraphEditor", componentClass: GraphEditor },
        { name: "ChartEditor", componentClass: ChartEditor },
        { name: "TableEditor", componentClass: TableEditor },
        { name: "GaugeEditor", componentClass: GaugeEditor },
        { name: "PipeEditor", componentClass: PipeEditor },
        { name: "PipeRoundEditor", componentClass: PipeRoundEditor },
        { name: "PipeTEditor", componentClass: PipeTEditor },
        { name: "EngineEditor", componentClass: EngineEditor },
        { name: "PumpEditor", componentClass: PumpEditor },
        { name: "FlapEditor", componentClass: FlapEditor },
        { name: "StirrerEditor", componentClass: StirrerEditor },
        { name: "BlowerEditor", componentClass: BlowerEditor },
        { name: "CentrifugeEditor", componentClass: CentrifugeEditor },
        { name: "TankEditor", componentClass: TankEditor },
        { name: "GridEditor", componentClass: GridEditor },
        { name: "PopupEditor", componentClass: PopupEditor },
        { name: "RowsEditor", componentClass: RowsEditor },
        { name: "ColumnsEditor", componentClass: ColumnsEditor },
        { name: "CompressorEditor", componentClass: CompressorEditor },
        { name: "ButtonEditor", componentClass: ButtonEditor },
        { name: "ButtonGroupEditor", componentClass: ButtonGroupEditor },
        { name: "HeadingEditor", componentClass: HeadingEditor },
        { name: "TextEditor", componentClass: TextEditor },
        { name: "SelectboxEditor", componentClass: SelectboxEditor },
        { name: "DateTimeEditor", componentClass: DateTimeEditor },
        { name: "GaugeSwitchEditor", componentClass: GaugeSwitchEditor },
        { name: "LedEditor", componentClass: LedEditor },
        { name: "LineEditor", componentClass: LineEditor },
        { name: "VentilatorEditor", componentClass: VentilatorEditor },
        { name: "MapEditor", componentClass: MapEditor },
        { name: "ImageEditor", componentClass: ImageEditor },
        { name: "MultiImageEditor", componentClass: MultiImageEditor },
        { name: "NotificationsEditor", componentClass: NotificationsEditor },
        { name: "VoiceEditor", componentClass: VoiceEditor },
        { name: "ThreeDEditor", componentClass: ThreeDEditor },
        { name: "ThreeDCustomEditor", componentClass: ThreeDCustomEditor },
        { name: "IFrameEditor", componentClass: IFrameEditor }
    ]

    static createComponent(componentType: string, props: ComponentProps) {
        return this.createComponentElement(componentType, "Component", this.componentsMap, props)
    }

    static createEditor(componentType: string, props: any) {
        return this.createComponentElement(componentType, "Editor", this.componentsSetupMap, props)
    }

    private static createComponentElement(
        componentType: string,
        suffix: string,
        components: Array<ClassDef>,
        props: any
    ) {
        if (componentType) {
            componentType = componentType.replaceAll("_", "") + suffix
            const component = components.find(
                (o) => o.name.toLowerCase() === componentType.toLowerCase()
            )
            return createElement(component?.componentClass, props, null)
        }
        return null
    }
}
