import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback } from "react"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action } from "../../types/ComponentTypes"

export type VoiceCommand = {
    expression: string
    actions: Array<Action>
}

export type VoiceParameters = BaseParams & {
    disable: boolean
    commandsExpressions: Array<VoiceCommand>
}

export const getVoiceBasicConfigurations = () => {
    return [{ disable: true }]
}

function VoiceEditor({ parameters }: EditorProps<VoiceParameters>) {
    const commandEditorRender = useCallback((item: VoiceCommand) => {
        return (
            <GroupItem>
                <Form formData={item}>
                    <SimpleItem
                        render={initMonacoEditor(item, "expression")}
                        helpText={`Výsledek výrazu očekává true/false. Výraz obdrží hodnotu self.value, která obsahuje vyslovený příkaz velkými písmeny. 
                        Tento řetězec je pak možné vyzvednout i v dalších výrazech akcí.`}
                    >
                        <Label text="Výraz příkazu" />
                    </SimpleItem>
                    <GroupItem>
                        <ActionsTriggerEditor listOwner={item} listName="actions" />
                    </GroupItem>
                </Form>
            </GroupItem>
        )
    }, [])

    const commandRender = useCallback((item: VoiceCommand) => {
        return <div>{`${item.expression}`}</div>
    }, [])

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <SimpleItem dataField="disable" editorType="dxCheckBox">
                            <Label text="Vypnuto" />
                        </SimpleItem>
                        <GroupItem caption="Příkazy">
                            <ListPopupEditor
                                listOwner={parameters}
                                listName="commandsExpressions"
                                formItemContent={commandEditorRender}
                                guid={true}
                                idProperty="guid"
                                itemRender={commandRender}
                            />
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(VoiceEditor)
