import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback } from "react"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action, ColorState } from "../../types/ComponentTypes"

export type LedParameters = BaseParams & {
    expression: string
    states?: Array<ColorState>
    onClickActions?: Array<Action>
}

export const getLedBasicConfigurations = () => {
    return [{}]
}

function LedEditor({ parameters }: EditorProps<LedParameters>) {
    const stateEditorRender = useCallback((item: ColorState) => {
        return (
            <GroupItem>
                <SimpleItem editorType="dxColorBox" dataField="color">
                    <Label text="barva" />
                </SimpleItem>
            </GroupItem>
        )
    }, [])

    const stateRender = useCallback((item: ColorState) => {
        return <div style={{ color: item.color }}>{item.color}</div>
    }, [])

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem colCount={2}>
                            <GroupItem caption="Výčet barev">
                                <ListPopupEditor
                                    listOwner={parameters}
                                    listName="states"
                                    guid={true}
                                    idProperty="guid"
                                    formItemContent={stateEditorRender}
                                    itemRender={stateRender}
                                />
                            </GroupItem>
                            <GroupItem caption="OnClick">
                                <ActionsTriggerEditor
                                    listOwner={parameters}
                                    listName="onClickActions"
                                />
                            </GroupItem>
                        </GroupItem>
                        <SimpleItem
                            render={initMonacoEditor(parameters, "expression")}
                            helpText="Výsledek výrazu musí být roven indexu pořadí barevného stavu začínajícího od jedné, kde nula znamená vypnuto."
                        >
                            <Label text="Výraz" />
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(LedEditor)
