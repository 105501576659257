import { Button } from "devextreme-react"
import { forwardRef, memo, useCallback, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { KeyType } from "../../types/KeysTypes"
import ComponentUtils from "../../utils/ComponentUtils"
import {
    useContainerVisible,
    useExecuteActions,
    useExpression,
    useImperative
} from "../../utils/CustomHooks"
import "./ButtonComponent.scss"
import { BUTTON_MAX_SIZE, ButtonParameters } from "./ButtonEditor"

type Params = ButtonParameters

type Props = ComponentProps<Params>

const ButtonComponent = forwardRef<IBaseComponent, Props>(
    ({ fontSize, textExpression, enabledExpression, onClickActions, setContainerVisible }, ref) => {
        const execute = useExecuteActions()
        useContainerVisible(setContainerVisible, false)

        const [text] = useExpression(textExpression, "")
        const [enabled] = useExpression(enabledExpression, true)

        useImperative(
            ref,
            useMemo(() => {
                const messenger = {
                    name: "size",
                    enums: Array.from(Array(BUTTON_MAX_SIZE).keys()).map((v, i) => ({
                        enum: i + 1
                    })),
                    value: fontSize
                }
                return [
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [fontSize])
        )

        const onClick = useCallback((e: any) => execute(onClickActions), [execute, onClickActions])

        const className = useMemo(
            () => `button-component ${ComponentUtils.classFromFontSize(fontSize)}`,
            [fontSize]
        )

        return (
            <Button
                width="100%"
                height="100%"
                className={className}
                disabled={!enabled}
                text={text}
                type="default"
                onClick={onClick}
            />
        )
    }
)

export default memo(ButtonComponent)
