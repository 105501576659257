import { memo, useCallback, useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import { withRouter } from "react-router-dom"

import "devextreme/dist/css/dx.common.css"
import "devextreme/dist/css/dx.light.css"

import { loadMessages, locale } from "devextreme/localization"
import czMessages from "devextreme/localization/messages/cs.json"
import "reactflow/dist/style.css"
import "./css/App.scss"
import "./css/GlobalComponents.scss"
import "./css/_variables.scss"
import "./css/globalClasses.scss"
import "./css/globalDx.scss"
import "./css/globalRGL.scss"
import "./css/globalSass.scss"

import Header from "./basic/Header"
import Login from "./basic/Login"
import Main from "./basic/Main"
import ForgottenPassword from "./user/ForgottenPassword"

import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { MenuItem } from "./basic/HeaderItems"
import Loading from "./basic/Loading"
import ReactCSSVariables from "./basic/ReactCSSVariables"
import { ACCESS_TOKEN } from "./constants"
import { useAppStore } from "./stores/AppStore"
import { IUser } from "./types/BaseTypes"
import { getCurrentUser } from "./utils/APIUsers"

import { Chart, registerables } from "chart.js"
import "chartjs-adapter-luxon"
import zoomPlugin from "chartjs-plugin-zoom"
import GAUtils from "./utils/GAUtils"
import { standardWarning } from "./utils/NotifyUtils"
Chart.register(...registerables, zoomPlugin)

type PathParamsType = {}

type Props = RouteComponentProps<PathParamsType> & {}

const App = withRouter(({ history }: Props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [forgottenPassword, setForgottenPassword] = useState(false)

    const currentUser = useAppStore((state) => state.currentUser)
    const setCurrentUser = useAppStore((state) => state.setCurrentUser)
    const setHistory = useAppStore((state) => state.setHistory)

    const fadeInRoot = () => {
        const loader = document.getElementById("loader")
        loader?.classList.add("fadeOut")
        setTimeout(() => loader?.remove(), 3000)
    }

    const handleLogout = useCallback(() => {
        localStorage.removeItem(ACCESS_TOKEN)

        setCurrentUser(undefined)
    }, [setCurrentUser])

    const checkLicense = useCallback(
        (user: IUser) => {
            GAUtils.sendEvent("license_name", "license", "Jméno licence", user.licenseName)
            GAUtils.sendEvent("device_id", user.licenseName, "ID Zařízení", user.deviceId)
            GAUtils.sendEvent("username", user.licenseName, "Jméno uživatele", user.username)
            GAUtils.sendEvent("is_admin", user.licenseName, user.username ?? "unknown", user.admin)
            GAUtils.sendEvent(
                "app_version",
                user.licenseName,
                "Verze aplikace",
                process.env.REACT_APP_VERSION
            )

            if (user.licenseExceeded) {
                GAUtils.sendEvent("license_exceeded", user.licenseName, "Licence překročena", true)

                standardWarning("Licence překročena, budete automaticky odhlášeni za 5 minut.")
                setTimeout(() => {
                    handleLogout()
                }, 5 * 60 * 1000)
            }
        },
        [handleLogout]
    )

    const loadCurrentUser = useCallback(
        (path?: string) => {
            getCurrentUser()
                .then((response: IUser) => {
                    setCurrentUser(response)
                    checkLicense(response)

                    if (path) {
                        history.push(path)
                    }
                })
                .finally(() => setIsLoading(false))
        },
        [history, setCurrentUser, checkLicense]
    )

    useEffect(() => {
        setHistory(history)
        loadCurrentUser()

        fadeInRoot()
        loadMessages(czMessages)
        locale(navigator.language)
    }, [loadCurrentUser, history, setHistory])

    // componentDidCatch(_error: Error, _errorInfo: ErrorInfo): void {
    //     standardError("Došlo k chybě. Více informací ve vývojářské konzoli - F12.", 10000)
    // }

    const onLogin = useCallback(
        (user: IUser) => {
            setCurrentUser(user)
            checkLicense(user)

            history.push("/")
        },
        [setCurrentUser, checkLicense, history]
    )

    const onForgottenPassword = (forgottenPassword: boolean) => {
        setForgottenPassword(forgottenPassword)
    }

    const handleMenuClick = (id: MenuItem) => {
        if (id === MenuItem.LOGOUT) {
            handleLogout()
        } else if (id) {
            history.push(`/${id}`)
        }
    }

    if (isLoading) {
        return <Loading />
    } else if (currentUser) {
        return (
            <>
                <ReactCSSVariables />
                <Header handleMenuClick={handleMenuClick} loadCurrentUser={loadCurrentUser} />
                <DndProvider backend={HTML5Backend}>
                    <Main loadCurrentUser={loadCurrentUser} />
                </DndProvider>
            </>
        )
    } else if (forgottenPassword) {
        return <ForgottenPassword forgottenPassword={onForgottenPassword} />
    } else {
        return <Login onLogin={onLogin} forgottenPassword={onForgottenPassword} />
    }
})

export default memo(App)
