import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useMemo } from "react"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { useForceUpdate } from "../../utils/CustomHooks"

export enum TankColor {
    BLUE = "BLUE",
    BROWN = "BROWN",
    YELLOW = "YELLOW",
    WHITE = "WHITE",
    RED = "RED",
    GREEN = "GREEN"
}

export enum TankType {
    RECTANGLE = "rectangle",
    V = "V",
    O = "O"
}

export enum TankOrientation {
    first = 1,
    second = 2
}

export type TankParameters = BaseParams & {
    max: number
    min: number
    colorDown: TankColor
    colorUp: TankColor
    expression: string
    type: TankType
    rectangleSize: number
    vSize: number
    tankOrientation: TankOrientation
}

export const getTankBasicConfigurations = () => {
    return types.map((e) => ({
        type: e,
        min: 0,
        max: 100,
        expression: "50",
        colorDown: TankColor.BLUE,
        colorUp: TankColor.BLUE,
        rectangleSize: rectangleSizes[0],
        vSize: vSizes[0],
        tankOrientation: oOrientations[0]
    }))
}

const types = ["rectangle", "V", "O"]
export const rectangleSizes = [1, 2, 3, 4, 5, 6, 7]
export const vSizes = [1, 2]
const oOrientations = [1, 2]

const colorOptions = {
    items: Object.keys(TankColor)
}

const rectangleSizeOptions = {
    items: rectangleSizes
}

const vSizeOptions = {
    items: vSizes
}

const oOrientationOptions = {
    items: oOrientations
}

function TankEditor({ parameters }: EditorProps<TankParameters>) {
    const [forceUpdate] = useForceUpdate()
    const typeOptions = useMemo(
        () => ({
            items: types,
            onValueChanged: forceUpdate
        }),
        [forceUpdate]
    )

    parameters.colorDown = parameters.colorDown ?? TankColor.BLUE
    parameters.colorUp = parameters.colorUp ?? TankColor.BLUE
    parameters.rectangleSize = parameters.rectangleSize ?? rectangleSizes[0]
    parameters.vSize = parameters.vSize ?? vSizes[0]
    parameters.vSize = parameters.tankOrientation ?? oOrientations[0]

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem colCount={2}>
                            <SimpleItem dataField="min">
                                <Label text="Minimum"></Label>
                            </SimpleItem>
                            <SimpleItem dataField="max">
                                <Label text="Maximum"></Label>
                            </SimpleItem>
                            <SimpleItem
                                dataField="colorDown"
                                editorType="dxSelectBox"
                                editorOptions={colorOptions}
                            >
                                <Label text="Barva dole" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="colorUp"
                                editorType="dxSelectBox"
                                editorOptions={colorOptions}
                            >
                                <Label text="Barva nahoře" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem colCount={2}>
                            <SimpleItem
                                dataField="type"
                                editorType="dxSelectBox"
                                editorOptions={typeOptions}
                            >
                                <Label text="Typ" />
                            </SimpleItem>
                            <SimpleItem
                                visible={parameters.type === types[0]}
                                dataField="rectangleSize"
                                editorType="dxSelectBox"
                                editorOptions={rectangleSizeOptions}
                            >
                                <Label text="Velikost" />
                            </SimpleItem>
                            <SimpleItem
                                visible={parameters.type === types[1]}
                                dataField="vSize"
                                editorType="dxSelectBox"
                                editorOptions={vSizeOptions}
                            >
                                <Label text="Velikost" />
                            </SimpleItem>
                            <SimpleItem
                                visible={parameters.type === types[2]}
                                dataField="tankOrientation"
                                editorType="dxSelectBox"
                                editorOptions={oOrientationOptions}
                            >
                                <Label text="Orientace" />
                            </SimpleItem>
                        </GroupItem>
                        <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                            <Label text="Výraz pro získání výšky hladiny"></Label>
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(TankEditor)
