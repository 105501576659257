import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import sizeof from "object-sizeof"
import { useContext } from "react"
import { VariablesContext } from "../../report/contexts/VariablesContext"
import { IComponent, IReport, IVariable } from "../../types/BaseTypes"
import ComponentUtils from "../../utils/ComponentUtils"
import { NumberUtils } from "../../utils/NumberUtils"
import ListPopupEditor from "./ListPopupEditor"
import { initMonacoEditor } from "./MonacoEditor"

type VariablesListProps = {
    listOwner: IComponent | IReport
}

export default function VariablesList(props: VariablesListProps) {
    const variables = useContext(VariablesContext)

    const variableRender = (item: IVariable) => {
        const variableValue = variables?.getVariableValueByGuid({ guid: item.guid! })
        const valueSize = NumberUtils.toKMGT(sizeof(variableValue))
        const data = { data: JSON.stringify(variableValue, null, "\t") }
        return (
            <GroupItem>
                <GroupItem>
                    <SimpleItem dataField="name">
                        <Label text="Jméno" />
                    </SimpleItem>
                </GroupItem>
                <SimpleItem
                    render={initMonacoEditor(item, "initialValue")}
                    helpText="Po načtení obrazovky se výraz vyhodnotí a tato hodnota se nastaví do proměnné."
                >
                    <Label text="Inicializační hodnota" />
                </SimpleItem>
                <SimpleItem render={initMonacoEditor(data, "data", false, 150, true)}>
                    <Label text="Aktuální hodnota" />
                </SimpleItem>
                <GroupItem>
                    Velikost proměnné: {valueSize.value + " " + valueSize.unit}B <br></br>
                    <br></br>
                </GroupItem>
            </GroupItem>
        )
    }

    const variableCopiedFilter = (item: IVariable) => {
        ComponentUtils.newIdentityWithoutGuid(item)
    }

    return (
        <ListPopupEditor
            listOwner={props.listOwner}
            idProperty="guid"
            guid={true}
            listName="variables"
            itemCopiedFilter={variableCopiedFilter}
            formItemContent={variableRender}
        />
    )
}
