import { createContext, memo, useCallback, useMemo, useState } from "react"

export interface IHoverContext {
    hover: boolean
}

export const HoverContext = createContext<IHoverContext | undefined>(undefined)

type Props = {
    children: (onHover: () => void, onHoverLeave: () => void) => JSX.Element
}

export const HoverProvider = memo(({ children }: Props) => {
    const [hover, setHover] = useState(false)

    const onMouseEnter = useCallback(() => setHover(true), [])
    const onMouseLeave = useCallback(() => setHover(false), [])

    const context = useMemo(() => ({ hover }), [hover])
    return (
        <HoverContext.Provider value={context}>
            {children(onMouseEnter, onMouseLeave)}
        </HoverContext.Provider>
    )
})
