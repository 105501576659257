import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback } from "react"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { useReportStore2 } from "../../stores/ReportStore2"
import { BaseParams } from "../../types/BaseTypes"

export type MultiImageParameters = BaseParams & {
    expression: string
    imageIds: Array<{ imageId: number }>
}

export const getMultiImageBasicConfigurations = () => {
    return [{}]
}

function MultiImageEditor({ parameters }: EditorProps<MultiImageParameters>) {
    const images = useReportStore2((state) => state.images)

    const imageEditorRender = useCallback(
        (item: { imageId: number }) => {
            const imagesOptions = {
                items: images,
                valueExpr: "id",
                displayExpr: "name"
            }

            return (
                <GroupItem>
                    <TabPanel animationEnabled={true}>
                        <Item title="Základní">
                            <Form formData={item}>
                                <SimpleItem
                                    dataField="imageId"
                                    editorType="dxSelectBox"
                                    editorOptions={imagesOptions}
                                >
                                    <Label text="Obrázek" />
                                </SimpleItem>
                            </Form>
                        </Item>
                    </TabPanel>
                </GroupItem>
            )
        },
        [images]
    )

    const imageRender = useCallback(
        (item: { imageId: number }) => {
            const name = images.find((i) => i.id === item.imageId)?.name
            return <div>{`${name}`}</div>
        },
        [images]
    )

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem
                                render={initMonacoEditor(parameters, "expression")}
                                helpText="Výsledek výrazu musí být roven indexu obrázku 0..n"
                            >
                                <Label text="Výraz" />
                            </SimpleItem>

                            <GroupItem>
                                <ListPopupEditor
                                    listOwner={parameters}
                                    listName="imageIds"
                                    formItemContent={imageEditorRender}
                                    guid={true}
                                    idProperty="guid"
                                    itemRender={imageRender}
                                />
                            </GroupItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(MultiImageEditor)
