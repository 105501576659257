import { API_BASE_URL } from "../../constants"
import { IFunction } from "../../types/FunctionsType"
import { request, accessTokenExists } from "../APIUtils"

export function getComponents(reportId: string): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/components/" + reportId,
            method: "GET"
        })
    })
}

export function getComponentTypes(): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/components/types",
            method: "GET"
        })
    })
}

export function createComponent(component: Object, reportId: number): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/components/" + reportId + "/create",
            method: "PUT",
            body: JSON.stringify(component)
        })
    })
}

export function editComponent(component: Object): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/components/edit",
            method: "PUT",
            body: JSON.stringify(component)
        })
    })
}

export function deleteComponent(id: number): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/components/" + id,
            method: "DELETE"
        })
    })
}

export function calculateComponent(id: number, parameters: any = {}): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/components/calculate/" + id,
            method: "POST",
            body: JSON.stringify(parameters)
        })
    })
}

export function tryFunction(funDef: IFunction, params?: Array<any>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/functions/try",
            method: "POST",
            body: JSON.stringify(Object.assign({}, { function: funDef }, { params: params }))
        })
    })
}

export function executeComponent(id: number, data: Object): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/components/execute/" + id,
            method: "PUT",
            body: JSON.stringify(data)
        })
    })
}
