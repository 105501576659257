import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useMemo } from "react"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { useReportStore2 } from "../../stores/ReportStore2"
import { BaseParams } from "../../types/BaseTypes"

export type NotificationsParameters = BaseParams & {
    notificationGroupsIds?: Array<number>
    refreshInterval?: number
    fromDateExpression?: string
}

export const getNotificationsBasicConfigurations = () => {
    return [{}]
}

const REFRESH_TIMES = [0, 1, 2, 3, 5, 10, 20, 30, 60, 90, 120]
const INTERVAL_OPTIONS = {
    items: REFRESH_TIMES,
    showClearButton: true
}

function NotificationsEditor({ parameters }: EditorProps<NotificationsParameters>) {
    const notificationGroups = useReportStore2((state) => state.notificationGroups)

    const notificationGroupsOptions = useMemo(() => {
        const dataSource = notificationGroups.map((n) => {
            return { name: n.name, id: n.id }
        })
        parameters.notificationGroupsIds?.forEach((ngId, i) => {
            if (!dataSource.map((d) => d.id).includes(ngId)) {
                dataSource.push({ name: "unknown to delete" + i, id: ngId })
            }
        })

        return {
            dataSource,
            displayExpr: "name",
            valueExpr: "id"
        }
    }, [notificationGroups, parameters.notificationGroupsIds])

    parameters.refreshInterval = parameters.refreshInterval ?? REFRESH_TIMES[4]

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem
                                dataField="notificationGroupsIds"
                                editorType="dxTagBox"
                                editorOptions={notificationGroupsOptions}
                            >
                                <Label text="Skupiny notifikací" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="refreshInterval"
                                editorType="dxSelectBox"
                                editorOptions={INTERVAL_OPTIONS}
                            >
                                <Label text="Refresh interval [s]" />
                            </SimpleItem>
                            <SimpleItem
                                render={initMonacoEditor(parameters, "fromDateExpression")}
                                helpText="Výraz musí vracet datum v ISO 8601."
                            >
                                <Label text="Novější než" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(NotificationsEditor)
