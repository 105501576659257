import { forwardRef, useContext, useMemo } from "react"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { ReportPathProvider } from "../../report/contexts/ReportPathContext"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import ComponentContainer from "../../report/Schema/Layer/ComponentContainer/ComponentContainer"
import "./RowsComponent.scss"
import { RowsParameters } from "./RowsEditor"

type Params = RowsParameters

type Props = ComponentProps<Params>

export const RowsComponent = forwardRef<IBaseComponent, Props>(({ rowHeight }: Props, ref) => {
    const component = useContext(ComponentContext)?.component

    let children = component?.components

    const style = useMemo(
        () => ({
            minHeight: rowHeight
        }),
        [rowHeight]
    )

    return (
        <ReportPathProvider guid={component?.guid}>
            <div className="flex-rows-container">
                {children?.map((c, i) => (
                    <div key={c.guid} style={style}>
                        <ComponentContainer component={c} />
                    </div>
                ))}
            </div>
        </ReportPathProvider>
    )
})
