import { CSSProperties, useMemo } from "react"
import { DropTargetMonitor, useDrop } from "react-dnd"

export enum DraggableType {
    LIBRARY = "LIBRARY"
}

type LayerDropAreaProps = {
    children: JSX.Element
    draggableType: DraggableType
    className?: string
    styles?: CSSProperties
    onDrop: (item: any, monitor: DropTargetMonitor<any, any>) => void
}

export const DroppableContainer = ({
    children,
    draggableType,
    onDrop,
    className,
    styles
}: LayerDropAreaProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: draggableType,
            drop: onDrop,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }),
        [onDrop, draggableType]
    )
    const isActive = canDrop && isOver
    const style = useMemo(
        () => ({ ...styles, backgroundColor: isActive ? "lightblue" : undefined }),
        [isActive, styles]
    )

    return (
        <div className={className} ref={drop} style={style}>
            {children}
        </div>
    )
}
