import { API_BASE_URL } from "../../constants"
import { IThreeDModel } from "../../types/BaseTypes"
import { accessTokenExists, request } from "../APIUtils"

export function getThreeDModels(): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/threedmodels",
            method: "GET"
        })
    })
}

export function getThreeDModel(id: number): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/threedmodels/" + id,
            method: "GET"
        })
    })
}

export function createThreeDModel(ThreeDModel: any): Promise<Response> {
    return accessTokenExists(() => {
        return request(
            {
                url: API_BASE_URL + "/threedmodels",
                method: "POST",
                body: ThreeDModel
            },
            null
        )
    })
}

export function editThreeDModel(ThreeDModel: IThreeDModel): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/threedmodels",
            method: "PUT",
            body: JSON.stringify(ThreeDModel)
        })
    })
}

export function deleteThreeDModels(ThreeDModels: Array<IThreeDModel>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/threedmodels",
            method: "DELETE",
            body: JSON.stringify(ThreeDModels)
        })
    })
}
