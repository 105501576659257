import Form, { Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export type IFrameParameters = BaseParams & {
    urlExpression: string
}

export const getIFrameBasicConfigurations = () => {
    return [{}]
}

function IFrameEditor({ parameters }: EditorProps<IFrameParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <TabBasic parameters={parameters} />
            </Item>
        </TabPanel>
    )
}

export default memo(IFrameEditor)

type ParametersProps = {
    parameters: IFrameParameters
}

function TabBasic({ parameters }: ParametersProps) {
    return (
        <div className="component-setup-container">
            <Form formData={parameters}>
                <SimpleItem
                    render={initMonacoEditor(parameters, "urlExpression")}
                    helpText="Zadejte výraz pro URL adresu, např.: https://www.google.com"
                >
                    <Label text="Výraz URL" />
                </SimpleItem>
            </Form>
        </div>
    )
}
