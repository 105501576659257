import React from "react"

import { GroupItem, Item } from "devextreme-react/form"
import ListPopupEditor from "../common/ListPopupEditor"
import { GraphParameters } from "../../components/Graph/GraphEditor"

type AxisFormProps = {
    parameters: GraphParameters
}

type AxisFormState = {}

class AxisEditor extends React.Component<AxisFormProps, AxisFormState> {
    private static positions = ["left", "right"]

    render() {
        return (
            <ListPopupEditor
                listOwner={this.props.parameters}
                listName="axises"
                itemRender={AxisInfo}
                formItemContent={(item) => (
                    <GroupItem colCount={3}>
                        <GroupItem colCount={1} caption="Základní">
                            <Item
                                dataField="name"
                                helpText="Název osy je možné uplatnit v nastavení série"
                                isRequired={true}
                            />
                            <Item
                                dataField="position"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    items: AxisEditor.positions,
                                    searchEnabled: true
                                }}
                            />
                            <Item dataField="color" editorType="dxColorBox" />
                        </GroupItem>
                        <GroupItem colCount={1} caption="Titulek">
                            <Item dataField="title.text" />
                            <Item dataField="title.font.color" editorType="dxColorBox" />
                        </GroupItem>
                        <GroupItem colCount={1} caption="Rozsah">
                            <Item dataField="wholeRange.startValue" editorType="dxNumberBox" />
                            <Item dataField="wholeRange.endValue" editorType="dxNumberBox" />
                        </GroupItem>
                        <GroupItem colCount={1} caption="Ostatní">
                            <Item dataField="allowDecimals" editorType="dxSwitch" />
                            <Item
                                dataField="pane"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    items: this.props.parameters.panes,
                                    displayExpr: "name",
                                    valueExpr: "name"
                                }}
                            />
                        </GroupItem>
                    </GroupItem>
                )}
            />
        )
    }
}

export default AxisEditor

function AxisInfo(item: any) {
    return <div style={{ color: item.color, fontWeight: "bold" }}>{item.name}</div>
}
