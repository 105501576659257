import Form, { ButtonItem, GroupItem, Label, PatternRule, SimpleItem } from "devextreme-react/form"
import { useState } from "react"
import LocalStorageUtils from "../utils/LocalStorageUtils"
import { standardSuccess } from "../utils/NotifyUtils"

type SetupProps = {}

export type DeviceSetupObject = {
    animations: boolean
    twoFactorSuperKey: string
}

export default function DeviceSetup(props: SetupProps) {
    const [setupObject] = useState(LocalStorageUtils.loadSetupObject())

    const handleSave = (e: any) => {
        e.preventDefault()
        LocalStorageUtils.setDeviceSetup(setupObject)
        standardSuccess("Nastavení uloženo do lokálního úložiště prohlížeče")
    }

    return (
        <form onSubmit={handleSave}>
            <Form colCount={1} id="deviceSetup" formData={setupObject} labelLocation="left">
                <GroupItem caption="Nastavení tohoto zařízení">
                    <SimpleItem dataField="animations" editorType="dxCheckBox">
                        <Label text="Animace potrubí" />
                    </SimpleItem>
                    <SimpleItem dataField="twoFactorSuperKey">
                        <PatternRule
                            message="Klíč musí mít alespoň 20 znaků z důvodu bezpečnosti"
                            pattern={/^.{20,200}$/}
                        />
                        <Label text="Klíč pro 2FA" />
                    </SimpleItem>
                </GroupItem>
                <ButtonItem
                    horizontalAlignment="center"
                    buttonOptions={{
                        text: "Uložit",
                        type: "default",
                        useSubmitBehavior: true
                    }}
                />
            </Form>
        </form>
    )
}
