import React from "react"
import { Link } from "react-router-dom"

import Toolbar, { Item } from "devextreme-react/toolbar"
import { confirm } from "devextreme/ui/dialog"

type RectangleItemProps = {
    menuItemClick: (item: Object) => void
    deleteItemHandle: (item: Object) => void
    item: any
    isAdmin: boolean
    itemUrl: string
}

type RectangleItemState = {}

class RectangleItem extends React.Component<RectangleItemProps, RectangleItemState> {
    private menuButtonOptions: any
    private deleteButtonOptions: any

    constructor(props: RectangleItemProps) {
        super(props)

        this.menuButtonOptions = {
            icon: "preferences",
            onClick: () => {
                props.menuItemClick(props.item)
            }
        }

        this.deleteButtonOptions = {
            icon: "trash",
            onClick: () => {
                let result = confirm(
                    `<i>Opravdu chcete smazat ${props.item.name} ?</i>`,
                    "Potvrzení smazání"
                )
                result.then((dialogResult) => {
                    if (dialogResult === true) {
                        props.deleteItemHandle(props.item)
                    }
                })
            }
        }
    }

    render() {
        return (
            <div className="report-layout">
                {this.props.isAdmin ? (
                    <div className="report-menu-toolbar">
                        <Toolbar>
                            <Item
                                location="after"
                                widget="dxButton"
                                options={this.menuButtonOptions}
                            />
                            <Item
                                location="after"
                                widget="dxButton"
                                options={this.deleteButtonOptions}
                            />
                        </Toolbar>
                    </div>
                ) : null}
                <Link to={this.props.itemUrl}>
                    <div className="tile-container-element">{this.props.item.name}</div>
                </Link>
            </div>
        )
    }
}

export default RectangleItem
