import Form, { Label, SimpleItem } from "devextreme-react/form"
import { useMemo } from "react"
import { useReportStore2 } from "../../stores/ReportStore2"
import { IFunction } from "../../types/FunctionsType"

type Props = {
    fun: IFunction
}

export default function DBConnectionsSelectBox(props: Props) {
    const dbConnections = useReportStore2((state) => state.dbConnections)

    const dbOptions = useMemo(
        () => ({
            items: dbConnections,
            searchEnabled: true,
            displayExpr: "name",
            valueExpr: "id"
        }),
        [dbConnections]
    )

    return (
        <Form formData={props.fun}>
            <SimpleItem dataField="dbConnection" editorType="dxSelectBox" editorOptions={dbOptions}>
                <Label text="Databázové spojení" />
            </SimpleItem>
        </Form>
    )
}
