import { Button, FileUploader, Popup } from "devextreme-react"
import { forwardRef, useImperativeHandle, useState } from "react"
import { IImage } from "../types/BaseTypes"
import { standardWarning } from "../utils/NotifyUtils"

export const imagesExtensions = [".jpg", ".jpeg", ".gif", ".png", ".svg"]
export const threeDExtensions = [".fbx"]
export const csvExtension = [".csv"]
export const acceptImages = "image/*"
export const acceptThreeD = ".fbx, application/x-fbx"

type UploaderProps = {
    handleSave: (file: any) => void
    fileExtensions: Array<string>
    accept: string
    maxSizeInMB: number
}

export type ShowPopupHandle = {
    showPopup: () => void
}

export const PopupFileUploader = forwardRef<ShowPopupHandle, UploaderProps>(
    ({ maxSizeInMB, handleSave, fileExtensions, accept }, ref) => {
        const emptyImage = () => {
            return {
                name: ""
            }
        }
        const [formData, setFormData] = useState<IImage>(emptyImage)
        const [popupVisible, setPopupVisible] = useState(false)
        const [key, setKey] = useState(0)

        useImperativeHandle(ref, () => ({
            showPopup: () => {
                setPopupVisible(true)
            }
        }))

        const popupHiding = () => {
            setPopupVisible(false)
        }

        const thisHandleSave = () => {
            if (formData.data) {
                const fd = new FormData()
                fd.append("file", formData.data)
                // formData.append("name", this.formData.name)
                // formData.append("groupId", 1)
                handleSave(fd)

                setFormData(emptyImage())
                setKey(key + 1)
                popupHiding()
            } else {
                standardWarning("Error in image loading to memory.")
            }
        }

        const onImageChanged = (e: any) => {
            if (e.value && e.value[0]) {
                formData.data = e.value[0]
            } else {
                alert("File not selected or browser incompatible.")
            }
        }

        return (
            <Popup
                title="Nahrání souboru"
                onHiding={popupHiding}
                visible={popupVisible}
                width="400"
                height="auto"
            >
                <form>
                    <div className="component-setup-container">
                        {/* <Form formData={this.formData}>
                                <SimpleItem dataField="name">
                                    <Label text="Jméno" />
                                </SimpleItem>
                            </Form> */}
                        <FileUploader
                            key={key}
                            labelText=""
                            accept={accept}
                            uploadMode="useForm"
                            onValueChanged={onImageChanged}
                            allowedFileExtensions={fileExtensions}
                            maxFileSize={maxSizeInMB * 1_000_000}
                        />
                        <span>
                            Maximální velikost souboru: <b>{maxSizeInMB} MB</b>.
                        </span>
                        <div>
                            Povolené typy: <b>{fileExtensions.join(" ")}</b>.
                        </div>
                    </div>
                    <Button text="Uložit" type="default" onClick={thisHandleSave} />
                </form>
            </Popup>
        )
    }
)
