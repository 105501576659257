import Form, { Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useMemo } from "react"
import { $enum } from "ts-enum-util"
import { SLIDER_TOOLTIP_SETTINGS } from "../../component/EditorTypes"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export enum LineType {
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical"
}

export type LineParameters = BaseParams & {
    size: number
    type: LineType
}

export const getLineBasicConfigurations = () => {
    return [{}]
}
export const lineSizes = [1, 2, 3, 4, 6, 8, 10, 14, 18, 26]

const sliderOptions = {
    min: 1,
    max: lineSizes.length,
    tooltip: SLIDER_TOOLTIP_SETTINGS
}

function LineEditor({ parameters }: EditorProps<LineParameters>) {
    const typeOptions = useMemo(
        () => ({
            items: $enum(LineType).getValues()
        }),
        []
    )

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <SimpleItem
                            dataField="size"
                            editorType="dxSlider"
                            editorOptions={sliderOptions}
                        >
                            <Label text="Tloušťka čáry" />
                        </SimpleItem>
                        <SimpleItem
                            dataField="type"
                            editorType="dxSelectBox"
                            editorOptions={typeOptions}
                        >
                            <Label text="Typ" />
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(LineEditor)
