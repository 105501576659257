import Dexie from "dexie"
import { CacheType } from "../utils/LocalStorageUtils"

export default class DatabaseManager extends Dexie {
    private static instance: DatabaseManager

    public static getInstance(): DatabaseManager {
        if (!DatabaseManager.instance) {
            DatabaseManager.instance = new DatabaseManager()
        }
        return DatabaseManager.instance
    }

    private report!: Dexie.Table<any, string>
    private reports!: Dexie.Table<any, string>
    private groups!: Dexie.Table<any, string>
    private copiedComponents!: Dexie.Table<any, string>

    private constructor() {
        super("Wi-Con_cache")

        this.version(1).stores({
            report: "id, data",
            reports: "id, data",
            groups: "id, data",
            copiedComponents: "id, data"
        })
    }

    public put(database: CacheType, id: string, data: any) {
        this[database].put({ id: id, data: data })
    }

    public async getById(database: CacheType, id: string) {
        const result = await this[database].where("id").equals(id).toArray()
        if (result && result.length > 0) {
            return result[0].data
        }
        return null
    }
}
