import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback, useState } from "react"
import ThreeDModelsCache from "../../cache/ThreeDModelsCache"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { useReportStore2 } from "../../stores/ReportStore2"
import { BaseParams } from "../../types/BaseTypes"
import { standardError } from "../../utils/NotifyUtils"
import { ThreeDUtils } from "../../utils/ThreeDUtils"
import { ThreeDCanvasBase } from "../ThreeD/ThreeDEditor"
import {
    CameraRender,
    GroupSettingsRender,
    OthersThreeDRender,
    ThreeDGroup,
    ThreeDMesh
} from "../ThreeD/ThreeDEditorUtils"

export type MeshCustomGeometry = {
    uuid: string
    // napr. "BufferGeometry",
    type: string
    name: string
    data: {
        attributes: {
            position: {
                itemSize: number
                type: string
                array: Array<number>
                normalized: boolean
            }
            normal: {
                itemSize: number
                type: string
                array: Array<number>
                normalized: boolean
            }
            uv: {
                itemSize: number
                type: string
                array: Array<number>
                normalized: boolean
            }
        }
    }
}

export type MeshCustomMaterial = {
    uuid: string
    // napr. "MeshPhongMaterial",
    type: string
    name: string
    color: number
    emissive: number
    specular: number
    shininess: number
    reflectivity: number
    refractionRatio: number
    depthFunc: number
    depthTest: boolean
    depthWrite: boolean
    colorWrite: boolean
    stencilWrite: boolean
    stencilWriteMask: number
    stencilFunc: number
    stencilRef: number
    stencilFuncMask: number
    stencilFail: number
    stencilZFail: number
    stencilZPass: number
}

export type ThreeDMeshCustom = ThreeDMesh & {
    geometry: MeshCustomGeometry
    material: MeshCustomMaterial
}

export type ThreeDGroupCustom = ThreeDGroup & {
    userData?: any
    layers?: number
    matrix?: Array<number>
    up?: Array<number>
}

type ThreeDModel = ThreeDGroupCustom & {
    modelId: number
}

export type ThreeDCustomParameters = BaseParams &
    ThreeDCanvasBase & {
        threeDModels?: Array<ThreeDModel>
    }

export const getThreeDCustomBasicConfigurations = () => {
    return [
        {
            orbitControls: true,
            gridHelper: true,
            axesHelper: true,
            camera: {
                position: {
                    x: -5,
                    y: 2,
                    z: -1
                }
            }
        }
    ]
}

const ThreeDCustomEditor = ({ parameters }: EditorProps<ThreeDCustomParameters>) => {
    const threeDModels = useReportStore2((state) => state.threeDModels)
    const [key, setKey] = useState(0)

    const imageEditorRender = useCallback(
        (item: ThreeDModel) => {
            const onValueChanged = (it: { value: number }) => {
                const modelId = it.value
                ThreeDModelsCache.getInstance()
                    .getModel(modelId)
                    .then((response) => {
                        if (response?.data) {
                            const parsed = ThreeDUtils.parseFBX(response.data)
                            const groupDefinition = ThreeDUtils.modelToGroupDefinition(parsed)
                            Object.assign(item, groupDefinition)
                            setKey((key) => key + 1)
                        }
                    })
                    .catch(standardError)
            }

            const modelsOptions = {
                items: threeDModels,
                valueExpr: "id",
                displayExpr: "name",
                onValueChanged: onValueChanged
            }

            return (
                <GroupItem>
                    <TabPanel animationEnabled={true}>
                        <Item title="Základní">
                            <Form formData={item}>
                                <SimpleItem
                                    dataField="modelId"
                                    editorType="dxSelectBox"
                                    editorOptions={modelsOptions}
                                >
                                    <Label text="3D Model" />
                                </SimpleItem>
                                <GroupItem>
                                    <GroupSettingsRender owner={item} />
                                </GroupItem>
                            </Form>
                        </Item>
                    </TabPanel>
                </GroupItem>
            )
        },
        [threeDModels]
    )

    const modelRender = useCallback(
        (item: { modelId: number }) => {
            const name = threeDModels.find((i) => i.id === item.modelId)?.name
            return <div>{`${name}`}</div>
        },
        [threeDModels]
    )

    return (
        <TabPanel key={key} animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <ListPopupEditor
                                listOwner={parameters}
                                listName="threeDModels"
                                formItemContent={imageEditorRender}
                                guid={true}
                                idProperty="guid"
                                itemRender={modelRender}
                            />
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="Kamera">
                <CameraRender parameters={parameters as any} />
            </Item>
            <Item title="Ostatní">
                <OthersThreeDRender parameters={parameters as any} />
            </Item>
        </TabPanel>
    )
}

export default memo(ThreeDCustomEditor)
