import { LogObject, ThreadObject } from "../administration/System"
import { API_BASE_URL } from "../constants"
import { accessTokenExists, request } from "./APIUtils"

export function getAllLogs(): Promise<Array<LogObject>> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/server-status/logs",
            method: "GET"
        })
    }) as any
}

export function getAllThreads(): Promise<Array<ThreadObject>> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/server-status/threads",
            method: "GET"
        })
    }) as any
}
