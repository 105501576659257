import { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react"
import "./Reports.scss"

import { setAutoFreeze } from "immer"
import ReactResizeDetector from "react-resize-detector"
import { useShallow } from "zustand/react/shallow"
import { MenuItem } from "../basic/HeaderItems"
import NavigationSidebar from "../basic/NavigationSidebar"
import { ReactComponent as Diode } from "../basic/svg/diode.svg"
import AccessTokenManager from "../managers/AccessTokenManager"
import { useAppStore } from "../stores/AppStore"
import { useHoveredStore } from "../stores/HoveredStore"
import { ReportState, useNavBarStore } from "../stores/NavBarStore"
import { useRegisteredStore } from "../stores/RegisteredStore"
import { useHistoryReportStore, useReportStore } from "../stores/ReportStore"
import { useReportStore2 } from "../stores/ReportStore2"
import { IGroup, IReport } from "../types/BaseTypes"
import LazyComponent from "../utils/LazyComponent"
import ReportUtils from "../utils/ReportUtils"
import Report from "./Report"
import ReportsList from "./ReportsList"
import { ReportIdProvider } from "./contexts/ReportIdContext"

type Props = {
    loadCurrentUser: () => void
    reportId?: number
}

export default function Reports({ loadCurrentUser, reportId }: Props) {
    const groupId = useAppStore((state) => state.groupId)
    const currentUser = useAppStore((state) => state.currentUser!)
    const setReportWidth = useAppStore((state) => state.setReportWidth)

    const reportsState = useNavBarStore((state) => state.reportsState)
    const setHeaderTextByReportId = useNavBarStore((state) => state.setHeaderTextByReportId)

    const [setActiveReportId, loadReportsFromCache] = useReportStore(
        useShallow((state) => [state.setActiveReportId, state.loadReportsFromCache])
    )

    const [
        setSchemaProps,
        resetReportStore2,
        popupVisible,
        initReports,
        fetchAccesories
    ] = useReportStore2(
        useShallow((state) => [
            state.calculateSchemaProps,
            state.resetStore,
            state.popupVisible,
            state.initReports,
            state.fetchAccessories
        ])
    )

    const resetRegisteredStore = useRegisteredStore((state) => state.resetStore)
    const onKeyDown = useRegisteredStore((state) => state.onKeyDown)
    const onKeyUp = useRegisteredStore((state) => state.onKeyUp)

    const clearHistoryReportStore = useHistoryReportStore((state) => state.clear)

    const ref: RefObject<HTMLDivElement> = useRef(null)

    const group = useMemo(() => currentUser.groups?.find((g) => g.id === groupId), [
        currentUser.groups,
        groupId
    ])
    const reports = group?.reports

    useEffect(() => {
        if (reports) {
            const reportIds = reports.map((r) => r.id!)

            loadReportsFromCache(reportIds)
            initReports(reportIds)
        }
    }, [reports, initReports, loadReportsFromCache])

    useEffect(() => {
        fetchAccesories()
    }, [fetchAccesories])

    useEffect(() => {
        const tokenRefresher = new AccessTokenManager(1000 * 60 * 5)

        return () => {
            tokenRefresher.shutdown()
        }
    }, [])

    useLayoutEffect(() => {
        setAutoFreeze(false)
        resetReportStore2()
        useHoveredStore.setState({ hoveredComponents: [] })
        resetRegisteredStore()
        clearHistoryReportStore()
    }, [clearHistoryReportStore, resetRegisteredStore, resetReportStore2])

    useEffect(() => {
        if (reportId) {
            setActiveReportId(reportId)
            setHeaderTextByReportId(reportId)
        }
    }, [setActiveReportId, setHeaderTextByReportId, reportId])

    const onKeyDownListener = useCallback(
        (e: any) => {
            if (!popupVisible) {
                onKeyDown(e.key)
                if (e.key.includes("Arrow")) {
                    e.preventDefault()
                }
            }
        },
        [onKeyDown, popupVisible]
    )

    const onKeyUpListener = useCallback(
        (e: any) => {
            if (!popupVisible) {
                onKeyUp(e.key)
            }
        },
        [onKeyUp, popupVisible]
    )

    useEffect(() => {
        window.addEventListener("keydown", onKeyDownListener)
        window.addEventListener("keyup", onKeyUpListener)

        return () => {
            window.removeEventListener("keydown", onKeyDownListener)
            window.removeEventListener("keyup", onKeyUpListener)
        }
    }, [onKeyDownListener, onKeyUpListener])

    const handleResize = useCallback(
        (width?: number) => {
            setReportWidth(width)
            setTimeout(setSchemaProps)
        },
        [setReportWidth, setSchemaProps]
    )

    const title = useCallback((group: IGroup) => group.name ?? "", [])

    const getGroupReports = useCallback((group: IGroup) => {
        const reports = group.reports ?? []
        ReportUtils.sortReports(reports)
        return reports
    }, [])

    const getReportLink = useCallback(
        (group: IGroup, report: IReport) =>
            `/${MenuItem.GROUPS}/${group.id}/${MenuItem.REPORTS}/${report.id}`,
        []
    )

    const renderNavReportItem = useCallback(
        (r: IReport) => {
            const reportNavState = reportsState[r.id ?? -1]
            const state = reportNavState?.state ?? ReportState.NORMAL

            return (
                <div className={`report-button state-${state}`}>
                    <div className="button-gap"></div>
                    <Diode />
                    <span>{r.name}</span>
                </div>
            )
        },
        [reportsState]
    )

    const getReportText = useCallback((r: IReport) => r.name ?? "", [])

    const reportStyle = useMemo(() => {
        if (!reportId) {
            return { display: "none" }
        }
    }, [reportId])

    const reportsRender = useMemo(() => {
        return group?.reports?.map((r) => {
            const isActive = r.id === reportId

            return (
                <div
                    className="report-inner"
                    key={r.id}
                    style={isActive ? undefined : { display: "none" }}
                >
                    <ReportIdProvider reportId={r.id!}>
                        <LazyComponent loadCondition={isActive}>
                            <Report />
                        </LazyComponent>
                    </ReportIdProvider>
                </div>
            )
        })
    }, [reportId, group])

    return (
        <>
            {!reportId && <ReportsList loadCurrentUser={loadCurrentUser} />}

            <div className="reports" style={reportStyle}>
                {reportId ? (
                    <NavigationSidebar<IGroup, IReport>
                        navId="reports"
                        groupKey="id"
                        dataSource={currentUser.groups}
                        title={title}
                        getGroupItems={getGroupReports}
                        getToLink={getReportLink}
                        renderItem={renderNavReportItem}
                        getItemText={getReportText}
                    />
                ) : null}

                <ReactResizeDetector onResize={handleResize} targetRef={ref} handleWidth>
                    <div className="report" ref={ref}>
                        {reportsRender}
                    </div>
                </ReactResizeDetector>
            </div>
        </>
    )
}
