import { Edge, OnSelectionChangeParams } from "reactflow"
import { EdgeData, IComponent, IReportFlowParameters } from "../types/BaseTypes"
import { CacheType } from "../utils/LocalStorageUtils"
import { standardSuccess, standardWarning } from "../utils/NotifyUtils"
import DatabaseManager from "./DatabaseManager"

export type CopiedComponents = {
    components: Array<IComponent>
    edges: Array<Edge<EdgeData>>
    flow: IReportFlowParameters
}

export default class ComponentCopyManager {
    private static instance: ComponentCopyManager
    private static readonly ID = "1"

    public static getInstance(): ComponentCopyManager {
        if (!ComponentCopyManager.instance) {
            ComponentCopyManager.instance = new ComponentCopyManager()
        }
        return ComponentCopyManager.instance
    }

    onCopy(
        allComponents: IComponent[],
        selectionParams: OnSelectionChangeParams,
        flow: IReportFlowParameters
    ) {
        const { nodes, edges } = selectionParams
        if (nodes.length === 0 && edges.length === 0) {
            standardWarning("Žádné komponenty ani spojnice nebyly označeny.")
            return
        }

        const selectionGuids = nodes.map((n) => n.data.componentGuid)
        const components = allComponents.filter((c) => selectionGuids.includes(c.guid))

        const stringData = JSON.stringify({ components, edges, flow })

        if (navigator.clipboard) {
            navigator.clipboard.writeText(stringData)
        } else {
            DatabaseManager.getInstance().put(
                CacheType.COPIED_COMPONENTS,
                ComponentCopyManager.ID,
                stringData
            )
            standardWarning(
                "Není dovoleno vkládat do schránky. Komponenty byly zkopírovány do databáze v rámci aplikace.",
                undefined,
                8000
            )
        }
        standardSuccess(
            `Zkopírováno ${components.length} komponent a ${edges.length} spojnic.`,
            undefined,
            5000
        )
    }

    async getCopiedComponents(): Promise<CopiedComponents> {
        let json = null
        if (navigator.clipboard) {
            json = await navigator.clipboard.readText()
        } else {
            json = await DatabaseManager.getInstance().getById(
                CacheType.COPIED_COMPONENTS,
                ComponentCopyManager.ID
            )
            standardWarning(
                "Není povolen přístup do schránky. Komponenty jsou vkládány z databáze v rámci aplikace.",
                undefined,
                8000
            )
        }
        const result: CopiedComponents = JSON.parse(json)
        if (result && result.components && result.flow) {
            return result
        } else {
            throw new Error("Nebyly nalezeny žádné komponenty nebo jejich parametry.")
        }
    }
}
