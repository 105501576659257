import { keepAlive } from "../utils/APIUsers"

export default class AccessTokenManager {
    private interval: any

    public constructor(ms: number) {
        this.interval = setInterval(() => keepAlive(), ms)
    }

    public shutdown = () => {
        clearInterval(this.interval)
    }
}
