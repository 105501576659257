import { DataGrid } from "devextreme-react"
import { FilterRow, Paging } from "devextreme-react/data-grid"
import Form, { GroupItem, SimpleItem } from "devextreme-react/form"
import React, { forwardRef, memo, useImperativeHandle } from "react"
import { FunctionResponse } from "../../../types/FunctionsType"
import { FunctionProps } from "../FunctionsList"
import OutputVariable from "../OutputVariable"
import SQLDataSource from "../SQLDataSource"
import { IFunctionRef, TEXT_AREA_OPTIONS } from "./TestFunction"

const ReadSQL = forwardRef<IFunctionRef, FunctionProps>(({ item }, ref) => {
    useImperativeHandle(
        ref,
        () => ({
            getParamsCount: () => {
                if (item.parameters) {
                    const sql = (item.parameters as any).sql
                    if (sql) {
                        return sql.split("?").length - 1
                    }
                }
                return 0
            },
            showCallResult: (response: FunctionResponse) => {
                const data = response.data
                let columns
                if (data) {
                    columns = data
                        .shift()
                        .map((n: string, i: number) => ({ dataField: i + "", caption: n }))
                }

                return (
                    <React.Fragment>
                        <Form formData={response} colCount={2}>
                            <SimpleItem
                                editorType="dxTextArea"
                                dataField="sql"
                                editorOptions={TEXT_AREA_OPTIONS}
                            />
                            <SimpleItem
                                editorType="dxTextArea"
                                dataField="error"
                                visible={response.error ? true : false}
                                editorOptions={TEXT_AREA_OPTIONS}
                            />
                        </Form>
                        <DataGrid
                            dataSource={data}
                            showBorders={true}
                            columnAutoWidth={true}
                            columns={columns}
                        >
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={10} />
                        </DataGrid>
                    </React.Fragment>
                )
            }
        }),
        [item.parameters]
    )

    return (
        <Form formData={item}>
            <GroupItem>
                <GroupItem>
                    <SQLDataSource function={item} />
                </GroupItem>
                <GroupItem>
                    <OutputVariable formData={item} dataField="parameters.output" />
                </GroupItem>
            </GroupItem>
        </Form>
    )
})

export default memo(ReadSQL)
