import { Button, Form, List, Popup, ScrollView } from "devextreme-react"
import { ItemDragging } from "devextreme-react/list"
import { cloneDeep } from "lodash"
import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import { v4 as uuid } from "uuid"
import "../../css/ListPopupEditor.scss"
import { useForceUpdate } from "../../utils/CustomHooks"
import { generateId } from "../../utils/ObjectUtils"

type Props = {
    listOwner: any
    listName: string
    guid?: boolean
    idProperty?: string
    width?: number
    formItemContent: (item: any) => any
    itemRender?: (item: any) => JSX.Element
    itemCopiedFilter?: (item: any) => void
}

function ListPopupEditor({
    listOwner,
    listName,
    guid,
    idProperty,
    width,
    itemCopiedFilter,
    itemRender,
    formItemContent
}: Props) {
    const [forceUpdate] = useForceUpdate()
    const listRef = useRef(null)

    const [idProp] = useState(idProperty ?? "id")
    const [popupVisible, setPopupVisible] = useState(false)
    const [item, setItem] = useState(undefined)

    useEffect(() => {
        if (!listOwner[listName]) {
            listOwner[listName] = []
        }
    }, [listOwner, listName])

    const fillNewId = useCallback(
        (item: Record<string, any>) => {
            const genId = (list: Array<any>, orderProperty: string) => {
                if (guid) {
                    return uuid()
                }
                return generateId(list, orderProperty)
            }

            item[idProp] = genId(listOwner[listName], idProp)
            return item
        },
        [idProp, listName, listOwner, guid]
    )

    const addItem = useCallback(
        (item: any) => {
            listOwner[listName].push(item)
            forceUpdate()
            ;(listRef.current as any)?.instance.repaint()
        },
        [forceUpdate, listName, listOwner]
    )

    const showPopup = useCallback(() => {
        setPopupVisible(true)
    }, [])

    const hidePopup = useCallback(() => {
        setPopupVisible(false)
    }, [])

    const handleItemClick = useCallback(
        (item: any) => {
            setItem(item.itemData)
            showPopup()
        },
        [showPopup]
    )

    const handleOk = useCallback(() => {
        hidePopup()
    }, [hidePopup])

    const handleAdd = useCallback(() => {
        addItem(fillNewId({}))
    }, [addItem, fillNewId])

    const handleCopyItem = useCallback(
        (item: any) => {
            const copiedItem = fillNewId(cloneDeep(item))
            itemCopiedFilter?.(copiedItem)
            addItem(copiedItem)
        },
        [addItem, fillNewId, itemCopiedFilter]
    )

    const defaultRender = useCallback((item: any) => {
        return <div>{item.name}</div>
    }, [])

    const listItemRender = useCallback(
        (item: any) => {
            return itemRender?.(item) ?? defaultRender(item)
        },
        [defaultRender, itemRender]
    )

    const iRender = useCallback(
        (item: any) => {
            return <Item item={item} itemRender={listItemRender} handleCopy={handleCopyItem} />
        },
        [handleCopyItem, listItemRender]
    )

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hidePopup}
                title={`Editace`}
                width="auto"
                height="auto"
            >
                <form>
                    <Form formData={item}>{item && formItemContent(item)}</Form>
                    <Button text="Ok" type="default" stylingMode="contained" onClick={handleOk} />
                </form>
            </Popup>
            <div className="component-setup-container">
                <Button text="Přidat" icon="add" onClick={handleAdd}></Button>
                <ScrollView id="scrollview" height="250">
                    <List
                        ref={listRef}
                        width={width ?? 300}
                        items={listOwner[listName]}
                        keyExpr={idProp}
                        itemRender={iRender}
                        allowItemDeleting={true}
                        onItemClick={handleItemClick}
                    >
                        <ItemDragging allowReordering={true}></ItemDragging>
                    </List>
                </ScrollView>
            </div>
        </>
    )
}

export default memo(ListPopupEditor)

type ItemProps = {
    item: any
    itemRender: (item: any) => JSX.Element
    handleCopy: (item: any) => void
}

class Item extends React.Component<ItemProps> {
    private handleCopy = (_: unknown) => {
        this.props.handleCopy(this.props.item)
    }

    render() {
        return (
            <div className="list-item">
                <span>{this.props.itemRender(this.props.item)}</span>
                <Button icon="copy" hint="Zkopírovat položku" onClick={this.handleCopy} />
            </div>
        )
    }
}
