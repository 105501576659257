import { Form, Popup } from "devextreme-react"
import { GroupItem, SimpleItem, Tab, TabbedItem } from "devextreme-react/form"
import { cloneDeep } from "lodash"
import {
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState
} from "react"
import FunctionsList from "../../componentsSetup/common/FunctionsList"
import VariablesList from "../../componentsSetup/common/VariablesList"
import { useReportStore } from "../../stores/ReportStore"
import { useReportStore2 } from "../../stores/ReportStore2"
import { IReport } from "../../types/BaseTypes"
import { useForceUpdate, useReport } from "../../utils/CustomHooks"

type Props = {}

export interface IReportSettingsPopup {
    showPopup: () => void
}

const ReportSettingsPopup = forwardRef<IReportSettingsPopup, Props>((props: Props, ref) => {
    const [forceUpdate] = useForceUpdate()

    const [popupVisible, setPopupVisible] = useState(false)

    const setReportPopupVisible = useReportStore2((state) => state.setPopupVisible)

    const [formData, save] = useReportForEdit()

    useImperativeHandle(ref, () => ({
        showPopup: () => {
            setPopupVisible(true)
        }
    }))

    const handlePopupHidden = useCallback(() => {
        setPopupVisible(false)
        setReportPopupVisible(false)
    }, [setReportPopupVisible])

    const okButtonClick = useCallback(() => {
        handlePopupHidden()
        save()
    }, [handlePopupHidden, save])

    const okButtonOptions = useMemo(
        () => ({
            text: "Ok",
            type: "default",
            stylingMode: "contained",
            onClick: okButtonClick
        }),
        [okButtonClick]
    )

    return (
        <Popup
            width={850}
            height="auto"
            minWidth="400"
            showTitle={true}
            title="Nastavení pro Report/Schéma"
            dragEnabled={true}
            visible={popupVisible}
            onHiding={handlePopupHidden}
        >
            <Form formData={formData} onFieldDataChanged={forceUpdate}>
                <GroupItem>
                    <TabbedItem>
                        <Tab title="Funkce">
                            <FunctionsList listOwner={formData} />
                        </Tab>
                        <Tab title="Proměnné">
                            <VariablesList listOwner={formData} />
                        </Tab>
                    </TabbedItem>
                </GroupItem>
                <SimpleItem editorType="dxButton" editorOptions={okButtonOptions} />
            </Form>
        </Popup>
    )
})

export default memo(ReportSettingsPopup)

export const useReportForEdit = (): [IReport, () => void] => {
    // TODO tohle je mozna vykonnostni problem
    const report = useReport()
    const setReports = useReportStore((state) => state.setReports)

    const [formDataReport, setFormDataReport] = useState<IReport>({})
    useEffect(() => {
        const formData = cloneDeep(report)
        setFormDataReport(formData)
    }, [report])

    const save = useCallback(() => setReports([formDataReport]), [formDataReport, setReports])
    return [formDataReport, save]
}
