import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action } from "../../types/ComponentTypes"
import { TextSettings, TextType } from "../Text/TextEditor"

export type SelectboxParameters = BaseParams &
    TextType & {
        expression: string
        indexExpression: string
        colorExpression: string
        onChangeActions?: Array<Action>
        enabledExpression: string
        // TODO spolecne s heading
    }

export const getSelectboxBasicConfigurations = () => {
    return [{}]
}

function SelectboxEditor({ parameters }: EditorProps<SelectboxParameters>) {
    return (
        <TabPanel animationEnabled={true} swipeEnabled={false}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <GroupItem>
                                <TextSettings parameters={parameters} />
                            </GroupItem>
                            <SimpleItem
                                render={initMonacoEditor(parameters, "expression")}
                                helpText="Výraz musí vracet pole primitvních hodnot, např. [1, 2, 3, 4, 5]"
                            >
                                <Label text="Výčet možností" />
                            </SimpleItem>
                            <SimpleItem
                                render={initMonacoEditor(parameters, "indexExpression")}
                                helpText="Výraz musí vracet index z pole výčtu hodnot, např. 0 pro první prvek"
                            >
                                <Label text="Index hodnoty" />
                            </SimpleItem>
                            <SimpleItem render={initMonacoEditor(parameters, "enabledExpression")}>
                                <Label text="Výraz aktivnosti" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="OnChange">
                <ActionsTriggerEditor
                    listOwner={parameters}
                    listName="onChangeActions"
                    expressionHelpText="K vybrané hodnotě je možné přistoupit pomocí self, kde self je objekt, který obsahuje value a index. Např. pro získání hodnoty použijte self.value"
                />
            </Item>
        </TabPanel>
    )
}

export default memo(SelectboxEditor)
