import { Button, Form } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { memo } from "react"
import { initMonacoEditor } from "../../../../../../../componentsSetup/common/MonacoEditor"
import { IComponent } from "../../../../../../../types/BaseTypes"
import MyPopup, { usePopup } from "../../../../../../../utils/MyPopup"
import LoadFromLibrary from "./LoadFromLibrary/LoadFromLibrary"
import SaveToLibrary from "./SaveToLibrary/SaveToLibrary"

type OtherProps = {
    reportPath: Array<String>
    component: IComponent
    onLibraryAdd: (name: string) => void
    onBookLoaded: (component: IComponent) => void
}

const ExportImportVisualOptions = ({
    component,
    onBookLoaded,
    onLibraryAdd,
    reportPath
}: OtherProps) => {
    const [ref, instance] = usePopup()

    return (
        <>
            <MyPopup
                ref={ref}
                width="auto"
                height="auto"
                showTitle={true}
                title="Export/import a vizuální nastavení"
                dragEnabled={true}
            >
                <div className="component-setup-container">
                    <Form formData={component}>
                        <GroupItem>
                            <LoadFromLibrary onBookLoaded={onBookLoaded} reportPath={reportPath} />
                            <SaveToLibrary onLibraryAdd={onLibraryAdd} />
                        </GroupItem>
                        <SimpleItem
                            dataField="parameters.transparentBackground"
                            editorType="dxCheckBox"
                        >
                            <Label text="Průhledné pozadí" />
                        </SimpleItem>
                        <SimpleItem
                            render={initMonacoEditor(component.parameters, "visibilityExpression")}
                        >
                            <Label text="Výraz viditelnosti" />
                        </SimpleItem>
                        <SimpleItem dataField="parameters.ySwitch" editorType="dxCheckBox">
                            <Label text="Převrátit kolem osy Y" />
                        </SimpleItem>
                        <SimpleItem
                            render={initMonacoEditor(component.parameters, "rotationExpression")}
                            helpText="Výraz očekává číslo ve stupních, např. 180."
                        >
                            <Label text="Výraz natočení" />
                        </SimpleItem>
                    </Form>
                </div>
                <Button
                    text="Ok"
                    type="default"
                    stylingMode="contained"
                    onClick={instance.hidePopup}
                />
            </MyPopup>
            <Button
                text="Další možnosti"
                type="default"
                stylingMode="outlined"
                onClick={instance.showPopup}
            />
        </>
    )
}

export default memo(ExportImportVisualOptions)
