import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useMemo } from "react"
import { $enum } from "ts-enum-util"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action } from "../../types/ComponentTypes"
import { useForceUpdate } from "../../utils/CustomHooks"
import { horizontalAlignOptions } from "../Heading/HeadingEditor"

export enum ComponentTextSize {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large"
}

export type TextSize = {
    fontSize: ComponentTextSize
}

export type TextType = TextSize & {
    bold: boolean
    italic: boolean
}

export type TextParameters = BaseParams &
    TextType & {
        expression: string
        colorExpression: string
        popupExpression: string
        textArea: boolean
        popup: boolean
        onSaveActions?: Array<Action>
        enabledExpression: string
        // TODO spolecne s heading
        horizontalAlign: "left" | "center" | "right"
    }

export const getTextBasicConfigurations = () => {
    return [{}]
}

function TextEditor({ parameters }: EditorProps<TextParameters>) {
    const [forceUpdate] = useForceUpdate()

    const popupOptions = useMemo(
        () => ({
            onValueChanged: forceUpdate
        }),
        [forceUpdate]
    )

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <GroupItem>
                                <TextSettings parameters={parameters} />
                            </GroupItem>
                            <SimpleItem
                                dataField="horizontalAlign"
                                editorType="dxRadioGroup"
                                editorOptions={horizontalAlignOptions}
                            >
                                <Label text="Horizontální pozice" />
                            </SimpleItem>
                            <SimpleItem render={initMonacoEditor(parameters, "enabledExpression")}>
                                <Label text="Výraz aktivnosti" />
                            </SimpleItem>
                            <SimpleItem
                                render={initMonacoEditor(parameters, "colorExpression")}
                                helpText="0 - bílá, 1 - šedá, 2 - modrá, 3 - success, 4 - warning, 5 - error"
                            >
                                <Label text="Výraz barvy" />
                            </SimpleItem>
                            <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                                <Label text="Výraz" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="Popup">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem
                                dataField="popup"
                                editorType="dxCheckBox"
                                editorOptions={popupOptions}
                            >
                                <Label text="Popup editace" />
                            </SimpleItem>
                            <GroupItem visible={parameters && parameters.popup === true}>
                                <SimpleItem
                                    render={initMonacoEditor(parameters, "popupExpression")}
                                >
                                    <Label text="Výraz hodnoty" />
                                </SimpleItem>
                                <GroupItem caption="Akce po uložení hodnoty">
                                    <ActionsTriggerEditor
                                        listOwner={parameters}
                                        listName="onSaveActions"
                                        expressionHelpText="K hodnotě zadané v popupu je možné přistoupit pomocí proměnné 'self', např.: 'self.value'"
                                    />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(TextEditor)

type SettingsProps = {
    parameters: TextType
}

export function TextSettings({ parameters }: SettingsProps) {
    return (
        <Form formData={parameters}>
            <GroupItem colCount={2}>
                <GroupItem>
                    <SimpleItem dataField="bold" editorType="dxCheckBox">
                        <Label text="Tučně" />
                    </SimpleItem>
                    <SimpleItem dataField="italic" editorType="dxCheckBox">
                        <Label text="Kurzíva" />
                    </SimpleItem>
                </GroupItem>
                <GroupItem>
                    <TextSizeSettings parameters={parameters} />
                </GroupItem>
            </GroupItem>
        </Form>
    )
}

type SettingsSizeProps = {
    parameters: TextSize
}

const radioOptions = {
    items: $enum(ComponentTextSize).getValues(),
    layout: "horizontal"
}

export function TextSizeSettings({ parameters }: SettingsSizeProps) {
    parameters.fontSize = parameters.fontSize ?? ComponentTextSize.MEDIUM

    return (
        <Form formData={parameters}>
            <SimpleItem dataField="fontSize" editorType="dxRadioGroup" editorOptions={radioOptions}>
                <Label text="Velikost písma" />
            </SimpleItem>
        </Form>
    )
}
