import { v4 as uuid } from "uuid"

const TIMER_TICK_INTERVAL_MS = 100

type Subscription = {
    tickMs: number
    onTick: () => void
}

const SUBSCRIPTIONS = new Map<string, Subscription>()

let counter = 0

setInterval(() => {
    counter += TIMER_TICK_INTERVAL_MS

    for (let subscription of SUBSCRIPTIONS.values()) {
        if (counter % subscription.tickMs === 0) {
            subscription.onTick()
        }
    }
}, TIMER_TICK_INTERVAL_MS)

export const subscribeToGlobalTimer = (subscription: Subscription): string => {
    if (subscription.tickMs % TIMER_TICK_INTERVAL_MS !== 0) {
        throw new Error("Cant register to global timer: " + JSON.stringify(subscription))
    }

    const key = uuid()

    SUBSCRIPTIONS.set(key, subscription)

    return key
}

export const unsubscribeFromGlobalTimer = (key: string) => {
    SUBSCRIPTIONS.delete(key)
}
