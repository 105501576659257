import { forwardRef, memo, useCallback, useContext, useEffect, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { useReportStore } from "../../stores/ReportStore"
import { KeyType } from "../../types/KeysTypes"
import { useContainerVisible, useImperative, useReportId } from "../../utils/CustomHooks"
import "./LineComponent.scss"
import { LineParameters, LineType, lineSizes } from "./LineEditor"

type Params = LineParameters

type Props = ComponentProps<Params>

const LineComponent = forwardRef<IBaseComponent, Props>(
    ({ size, type, setContainerVisible }, ref) => {
        const reportId = useReportId()
        const setContainerSize = useReportStore((state) => state.setContainerSize)

        const component = useContext(ComponentContext)?.component!
        useContainerVisible(setContainerVisible, false)

        useImperative(
            ref,
            useMemo(() => {
                const messenger = {
                    name: "size",
                    enums: lineSizes.map((v, i) => ({ enum: i + 1 })),
                    value: size
                }
                return [
                    {
                        keyType: KeyType.SHIFT,
                        messenger: {
                            name: "type",
                            enums: [{ enum: LineType.HORIZONTAL }, { enum: LineType.VERTICAL }],
                            value: type
                        }
                    },
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [size, type])
        )

        const recalculateContainerSize = useCallback(() => {
            let maxW, maxH

            switch (type) {
                case LineType.HORIZONTAL:
                    maxH = lineSizes[size - 1]
                    break
                case LineType.VERTICAL:
                    maxW = lineSizes[size - 1]
                    break
            }

            setContainerSize(component, reportId, maxW, maxH)
        }, [setContainerSize, component, size, type, reportId])

        useEffect(() => recalculateContainerSize(), [recalculateContainerSize])

        return <div className="line"></div>
    }
)

export default memo(LineComponent)
