import { forwardRef, memo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { useContainerVisible, useExpression } from "../../utils/CustomHooks"
import "./StirrerComponent.scss"
import { StirrerParameters } from "./StirrerEditor"
import { ReactComponent as Stirrer } from "./svg/stirrer.svg"

type Params = StirrerParameters

type Props = ComponentProps<Params>

const StirrerComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, expressionError, setContainerVisible }, ref) => {
        useContainerVisible(setContainerVisible, false)

        const [active] = useExpression(expression, false)
        const [error] = useExpression(expressionError, false)

        let className = "stirrer "
        if (error) {
            className += "stirrer-error"
        } else if (active) {
            className += "stirrer-active"
        }

        return <Stirrer className={className} />
    }
)

export default memo(StirrerComponent)
