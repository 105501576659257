import { forwardRef, memo, useContext } from "react"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { ReportPathProvider } from "../../report/contexts/ReportPathContext"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import ComponentContainer from "../../report/Schema/Layer/ComponentContainer/ComponentContainer"
import "./ColumnsComponent.scss"
import { ColumnsParameters } from "./ColumnsEditor"

type Params = ColumnsParameters

type Props = ComponentProps<Params>

const ColumnsComponent = forwardRef<IBaseComponent, Props>((props: Props, ref) => {
    const component = useContext(ComponentContext)?.component

    const children = component!.components

    return (
        <ReportPathProvider guid={component?.guid}>
            <div className="flex-columns-container">
                {children?.map((c, i) => (
                    <ComponentContainer key={c.guid} component={c} />
                ))}
            </div>
        </ReportPathProvider>
    )
})

export default memo(ColumnsComponent)
