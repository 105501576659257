import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { Button, CheckBox } from "devextreme-react"
import { useShallow } from "zustand/react/shallow"
import { useHoveredStore } from "../../stores/HoveredStore"
import { useReportStore } from "../../stores/ReportStore"
import { useReportStore2 } from "../../stores/ReportStore2"
import { ComponentsEnum } from "../../types/ComponentTypes"
import ComponentUtils from "../../utils/ComponentUtils"
import { useReport2Fields } from "../../utils/CustomHooks"
import { confirmation } from "../../utils/NotifyUtils"

type ToolbarProps = {}

export const componentToolbarStaticState = { hovered: false }

function ComponentToolbar(props: ToolbarProps) {
    const [removeComponentByPath, addComponent, copyComponent] = useReportStore(
        useShallow((state) => [
            state.removeComponentByPath,
            state.addComponent,
            state.copyComponent
        ])
    )

    const schemaScale = useReportStore2((state) => state.schemaScale)
    const dndComponentGuid = useReportStore2((state) => state.dndComponentGuid)
    const setDndComponentGuid = useReportStore2((state) => state.setDndComponentGuid)
    const [editMode] = useReport2Fields((s) => [s.editMode])

    const hoveredContainer = useHoveredStore((state) => state.peekHoverComponent())
    const unhoverComponent = useHoveredStore((state) => state.unhoverComponent)

    const [top, setTop] = useState(0)
    const [left, setLeft] = useState(0)

    const ref = useRef<HTMLDivElement>(null)

    const component = hoveredContainer?.getProps().component
    const reportPath = hoveredContainer?.getReportPath()

    useEffect(() => {
        const rectangle = ref.current?.getBoundingClientRect()

        const recalculate = () => {
            // TODO konstanty !!! zde taky navazat na sirku gridu
            if (hoveredContainer) {
                // Zde musi byt setTimeout, jinak si muze toolbar pamatovat sirku z predchozi komponenty
                setTimeout(() => {
                    const width = rectangle?.width ?? 0
                    const rect = hoveredContainer.getRect()
                    if (rect) {
                        let top = rect.top - 27
                        let left = rect.left + (rect.width - width)
                        if (left < 0) {
                            left = 0
                        }

                        setTop(top)
                        setLeft(left)
                    }
                })
            }
        }

        recalculate()
    }, [hoveredContainer, schemaScale])

    const onMouseEnter = useCallback(() => {
        componentToolbarStaticState.hovered = true
    }, [])

    const onMouseLeave = useCallback(() => {
        componentToolbarStaticState.hovered = false
        if (hoveredContainer) {
            unhoverComponent(hoveredContainer)
        }
    }, [hoveredContainer, unhoverComponent])

    const handleComponentRemove = useCallback(() => {
        onMouseLeave()
        if (component) {
            confirmation(
                `<i>Opravdu chcete smazat ${component.name}?</i>`,
                "Potvrzení smazání",
                () => removeComponentByPath(component.guid, hoveredContainer.getReportPath())
            )
        }
    }, [hoveredContainer, onMouseLeave, removeComponentByPath, component])

    const editorClick = useCallback(() => {
        onMouseLeave()
        hoveredContainer?.handleEditorClick()
    }, [hoveredContainer, onMouseLeave])

    const coordinatesClick = useCallback(() => {
        onMouseLeave()
        hoveredContainer?.handleCoordinatesClick()
    }, [hoveredContainer, onMouseLeave])

    const copyClick = useCallback(() => {
        onMouseLeave()
        if (reportPath && component) {
            copyComponent(reportPath, component)
        }
    }, [onMouseLeave, copyComponent, component, reportPath])

    const dndChanged = useCallback(
        (e: any) => {
            if (hoveredContainer && e.event) {
                const guid = hoveredContainer.getProps().component.guid
                setDndComponentGuid(e.value ? guid : undefined)
            }
        },
        [hoveredContainer, setDndComponentGuid]
    )

    const renderDndAndResize = useCallback(() => {
        switch (component?.type) {
            case ComponentsEnum.GRID:
            case ComponentsEnum.POPUP:
                return (
                    <CheckBox
                        hint="Zapnutí drag-and-drop a resize"
                        text="DnD"
                        value={component.guid === dndComponentGuid}
                        onValueChanged={dndChanged}
                    />
                )
        }
        return null
    }, [component?.guid, component?.type, dndChanged, dndComponentGuid])

    const addNewComponent = useCallback(
        (e: any) => {
            if (reportPath) {
                addComponent([...reportPath, component!.guid], ComponentUtils.createNewComponent())
            }
        },
        [addComponent, component, reportPath]
    )

    const renderAddComponent = useCallback(() => {
        switch (component?.type) {
            case ComponentsEnum.GRID:
            case ComponentsEnum.POPUP:
            case ComponentsEnum.ROWS:
            case ComponentsEnum.COLUMNS:
                return <Button hint="Přidat komponentu" icon="add" onClick={addNewComponent} />
        }
        return null
    }, [addNewComponent, component?.type])

    const visibility: "visible" | "hidden" = editMode && hoveredContainer ? "visible" : "hidden"

    const style = useMemo(() => ({ top, left, visibility }), [left, top, visibility])

    return (
        <div
            ref={ref}
            className="component-menu-button smaller-dx-button"
            style={style}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {renderDndAndResize()}
            {renderAddComponent()}
            <Button icon="preferences" hint="Nastavení" onClick={editorClick} />
            <Button icon="copy" hint="Zkopírovat do stejného kontejneru" onClick={copyClick} />
            <Button icon="splitcells" hint="Pozice a rozměry" onClick={coordinatesClick} />
            <Button icon="trash" hint="Smazat" onClick={handleComponentRemove} />
        </div>
    )
}

export default memo(ComponentToolbar)
