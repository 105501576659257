import { RefObject } from "react"

import DataGrid, {
    Column,
    Editing,
    Form,
    Lookup,
    Pager,
    Paging,
    RequiredRule,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"

import { DB_CONNECTION_TYPES, IDBConnection, IGroup } from "../types/BaseTypes"
import { gqlRead } from "../utils/APIUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"

type DBConnectionsState = CollectionState<IDBConnection> & {
    groups: Array<IGroup>
}

class DBConnections extends BaseCollection<IDBConnection, CollectionProps, DBConnectionsState> {
    constructor(props: CollectionProps) {
        super(props, {
            entityName: "dbConnection",
            subEntitiesToIds: ["group"],
            copyButton: true,
            title: "Databázová spojení"
        })
        this.state = {
            ...this.state,
            groups: []
        }
    }

    protected getEntities = (): Promise<Array<IGroup>> => {
        return gqlRead({
            dbConnections: {
                id: true,
                name: true,
                dbName: true,
                username: true,
                address: true,
                type: true,
                port: true,
                maxConnections: true,
                waitDuration: true,
                group: {
                    id: true,
                    name: true
                }
            },
            groups: {
                id: true,
                name: true
            }
        }).then((r) => {
            this.setState({ groups: r.groups })
            return r.dbConnections
        })
    }

    private onInitNewRow = (object: { data: IDBConnection }) => {
        object.data.maxConnections = 3
        object.data.waitDuration = 3
        object.data.address = "localhost"
        object.data.port = 3306
        object.data.username = "root"
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <DataGrid
                ref={ref}
                dataSource={dataSource}
                showBorders={true}
                columnHidingEnabled={true}
                onEditorPreparing={this.onEditorPreparing}
                onToolbarPreparing={this.onToolbarPreparing}
                onSelectionChanged={this.onSelectionChanged}
                onInitNewRow={this.onInitNewRow as any}
                height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
            >
                <Paging enabled={false} />
                <Pager visible={true} displayMode="full" showInfo={true} />
                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
                <SearchPanel visible={true} />
                <Editing
                    mode="popup"
                    useIcons={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item itemType="group" caption="Základní">
                                <Item dataField="name" />
                                <Item dataField="type" />
                                <Item dataField="group" />
                            </Item>
                            <Item itemType="group" caption="Ostatní">
                                <Item dataField="dbName" />
                                <Item dataField="address" />
                                <Item dataField="port" />
                            </Item>
                            <Item itemType="group" caption="Přihlášení">
                                <Item dataField="username" />
                                <Item dataField="password" />
                            </Item>
                            <Item itemType="group" caption="Pool spojení">
                                <Item dataField="maxConnections" editorType="dxNumberBox" />
                                <Item
                                    dataField="waitDuration"
                                    editorType="dxNumberBox"
                                    helpText="Maximální čas v sekundách, jaký vlákno čeká na přidělení spojení z poolu, který je aktuálně vyčerpán."
                                />
                            </Item>
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                <Column dataField="name" caption="Název" />
                <Column dataField="type" caption="Typ">
                    <Lookup dataSource={DB_CONNECTION_TYPES} />
                </Column>
                <Column dataField="group" caption="Skupina">
                    <Lookup dataSource={this.state.groups} displayExpr="name" valueExpr="id" />
                </Column>
                <Column dataField="maxConnections" caption="Maximum spojení" />
                <Column dataField="waitDuration" caption="Čas čekání" />
                <Column dataField="dbName" caption="Jméno databáze" />
                <Column dataField="address" caption="Adresa" />
                <Column dataField="port" caption="Port" />
                <Column dataField="username" caption="Uživatelské jméno" />
                <Column dataField="password" visible={false}>
                    <RequiredRule />
                </Column>
            </DataGrid>
        )
    }
}

export default DBConnections
