import TabPanel, { Item } from "devextreme-react/tab-panel"
import SQLType from "./SQLType"
import TablesAndColumns from "./TablesAndColumns"
import SQLConditions from "./SQLConditions"
import { IFunctionParameters } from "../../../types/FunctionsType"

type SQLBuilderProps = {
    parameters: IFunctionParameters
}

function SQLBuilder(props: SQLBuilderProps) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Typ dotazu">
                <SQLType parameters={props.parameters} />
            </Item>
            <Item title="Tabulky a sloupce">
                <TablesAndColumns parameters={props.parameters} />
            </Item>
            <Item title="Podmínky">
                <SQLConditions parameters={props.parameters} />
            </Item>
        </TabPanel>
    )
}

export default SQLBuilder
