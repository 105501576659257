import { GroupItem, Item } from "devextreme-react/form"
import React from "react"
import { SLIDER_TOOLTIP_SETTINGS } from "../../component/EditorTypes"
import { GraphParameters } from "../../components/Graph/GraphEditor"
import ListPopupEditor from "../common/ListPopupEditor"

type SerieFormProps = {
    parameters: GraphParameters
}

type SerieFormState = {}

class SerieForm extends React.Component<SerieFormProps, SerieFormState> {
    private static types = ["stepline", "steparea", "spline", "splinearea", "line", "area", "bar"]
    private static aggregationTypes = ["avg", "min", "max"]
    private static sliderOptions = {
        max: 10,
        min: 1,
        label: {
            visible: true
        },
        tooltip: SLIDER_TOOLTIP_SETTINGS
    }

    render() {
        return (
            <ListPopupEditor
                listOwner={this.props.parameters}
                listName="series"
                itemRender={SerieInfo}
                formItemContent={(item) => (
                    <GroupItem colCount={2}>
                        <GroupItem colCount={1} caption="Základní">
                            <Item
                                dataField="name"
                                helpText="Název musí obsahovat unikátní část řetězce konkrétní série. (Hodnoty třetího sloupce z SQL.)"
                                isRequired={true}
                            />
                            <Item
                                dataField="type"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    items: SerieForm.types,
                                    searchEnabled: true
                                }}
                            />
                            <Item dataField="color" editorType="dxColorBox" />
                            <Item
                                dataField="width"
                                editorType="dxSlider"
                                editorOptions={SerieForm.sliderOptions}
                            />
                        </GroupItem>
                        <GroupItem colCount={1} caption="Agregace">
                            <Item dataField="aggregation.enabled" editorType="dxSwitch" />
                            <Item
                                dataField="aggregation.method"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    items: SerieForm.aggregationTypes
                                }}
                            />
                        </GroupItem>
                        <GroupItem colCount={1} caption="Ostatní">
                            <Item
                                dataField="axis"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    items: this.props.parameters.axises,
                                    displayExpr: "name",
                                    valueExpr: "name"
                                }}
                            />
                            <Item
                                dataField="pane"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    items: this.props.parameters.panes,
                                    displayExpr: "name",
                                    valueExpr: "name"
                                }}
                            />
                            <Item dataField="label.visible" editorType="dxSwitch" />
                        </GroupItem>
                    </GroupItem>
                )}
            />
        )
    }
}

export default SerieForm

function SerieInfo(item: any) {
    return <div style={{ color: item.color, fontWeight: "bold" }}>{item.name}</div>
}
