import { Popup } from "devextreme-react"
import { Position } from "devextreme-react/popup"
import { memo, useCallback, useState } from "react"
import { useAppStore } from "../stores/AppStore"
import { MenuItem, PROFILE_ITEMS } from "./HeaderItems"
import "./Profile.scss"
import { ReactComponent as User } from "./svg/user.svg"

type Props = {
    handleMenuClick: (id: MenuItem) => void
}

const Profile = ({ handleMenuClick }: Props) => {
    const currentUser = useAppStore((state) => state.currentUser!)

    const [visible, setVisible] = useState(false)

    const showPopup = useCallback(() => setVisible(true), [])
    const hidePopup = useCallback(() => setVisible(false), [])

    const popupRender = useCallback(
        () => (
            <>
                {PROFILE_ITEMS.map((p, i) => (
                    <div
                        key={i}
                        className="profile-option-button"
                        onClick={() => {
                            handleMenuClick(p.id)
                            hidePopup()
                        }}
                    >
                        {p.name}
                    </div>
                ))}
            </>
        ),
        [handleMenuClick, hidePopup]
    )

    // const onShown = useCallback((e: any) => {
    //     e.component.content()[0].parentElement.parentElement.classList.add("profile-popup")
    // }, [])

    return (
        <>
            <div
                className={`profile header-button ${visible ? "profile-popup-visible" : ""}`}
                onClick={showPopup}
            >
                <User />
            </div>
            <Popup
                title={currentUser.name}
                width="300px"
                height="auto"
                onHiding={hidePopup}
                visible={visible}
                contentRender={popupRender}
                hideOnOutsideClick={true}
                showCloseButton={false}
                // onShown={onShown}
            >
                <Position my="top" at="left" of=".profile" offset="-100 50" />
            </Popup>
        </>
    )
}

export default memo(Profile)
