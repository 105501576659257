import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { TextSettings, TextType } from "../Text/TextEditor"

export type HeadingParameters = BaseParams &
    TextType & {
        text: string
        horizontalAlign: "left" | "center" | "right"
    }

export const getHeadingBasicConfigurations = () => {
    return [{ text: "Nadpis" }]
}

export const horizontalAlignOptions = {
    items: ["left", "center", "right"],
    layout: "horizontal"
}

function HeadingEditor({ parameters }: EditorProps<HeadingParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <TextSettings parameters={parameters} />
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem render={initMonacoEditor(parameters, "text")}>
                                <Label text="Text" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="horizontalAlign"
                                editorType="dxRadioGroup"
                                editorOptions={horizontalAlignOptions}
                            >
                                <Label text="Horizontální pozice" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(HeadingEditor)
