import { API_BASE_URL } from "../../constants"
import { accessTokenExists, request } from "../APIUtils"

export function executeFunction(id: number, parameters?: Array<any>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/functions/execute/" + id,
            method: "POST",
            body: JSON.stringify(parameters ?? [])
        })
    })
}
