import { OnSelectionChangeParams } from "reactflow"
import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { DEVELOPMENT_MODE } from "../constants"

const STORE_NAME = "SelectionStore"

export interface ISelectionStore {
    selectionParams: OnSelectionChangeParams

    onSelectionChange: (selectionParams: OnSelectionChangeParams) => void
}

export const useSelectionStore = create<ISelectionStore, [["zustand/devtools", never]]>(
    devtools(
        (set, get) => ({
            selectionParams: { nodes: [], edges: [] },

            onSelectionChange: (selectionParams) => {
                set({ selectionParams }, false, "onSelectionChange")
            }
        }),
        { enabled: DEVELOPMENT_MODE, name: STORE_NAME }
    )
)

if (DEVELOPMENT_MODE) {
    mountStoreDevtool(STORE_NAME, useSelectionStore)
}
