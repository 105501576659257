import { ScrollView } from "devextreme-react"
import { forwardRef, memo, useCallback, useContext } from "react"
import ReactGridLayout, { Layout } from "react-grid-layout"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import ComponentContainer from "../../report/Schema/Layer/ComponentContainer/ComponentContainer"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { ReportPathContext, ReportPathProvider } from "../../report/contexts/ReportPathContext"
import { useAppStore } from "../../stores/AppStore"
import { useReportStore } from "../../stores/ReportStore"
import { useReportStore2 } from "../../stores/ReportStore2"
import { useCoordinates, useReportId } from "../../utils/CustomHooks"
import "./GridComponent.scss"
import { GridParameters } from "./GridEditor"

type Params = GridParameters

type Props = ComponentProps<Params>

const GridComponent = forwardRef<IBaseComponent, Props>(
    ({ layout, compactType, rowSize, numOfColumns, realSize, marginX, marginY }: Props, ref) => {
        const isAdmin = useAppStore((state) => state.currentUser!.admin)
        const reportId = useReportId()
        const setComponentLayout = useReportStore((state) => state.setComponentLayout)
        const dndComponentGuid = useReportStore2((state) => state.dndComponentGuid)

        const reportPath = useContext(ReportPathContext).reportPath
        const componentContext = useContext(ComponentContext)
        const component = componentContext?.component
        const guid = component?.guid

        const children = component?.components
        const coordinates = useCoordinates(layout, children)

        const onLayoutChange = useCallback(
            (layout: Layout[]) => {
                // guid nema komponenta z knihovny
                if (guid) {
                    setComponentLayout([...reportPath, guid], layout, reportId)
                }
            },
            [reportPath, guid, setComponentLayout, reportId]
        )

        const compType = compactType === "none" ? null : compactType

        const editMode = isAdmin && dndComponentGuid === guid

        return (
            <ReportPathProvider guid={guid}>
                <ScrollView showScrollbar="onScroll">
                    <ReactGridLayout
                        className="grid-component"
                        isDraggable={editMode}
                        isResizable={editMode}
                        rowHeight={rowSize}
                        cols={numOfColumns}
                        width={realSize.width}
                        layout={layout}
                        onLayoutChange={onLayoutChange}
                        compactType={compType}
                        margin={
                            marginX !== undefined && marginY !== undefined
                                ? [marginX, marginY]
                                : undefined
                        }
                    >
                        {children?.map((c, i) => (
                            <div key={c.guid}>
                                <ComponentContainer component={c} coordinates={coordinates[i]} />
                            </div>
                        ))}
                    </ReactGridLayout>
                </ScrollView>
            </ReportPathProvider>
        )
    }
)

export default memo(GridComponent)
