import React, { RefObject } from "react"

import DataGrid, {
    Column,
    Editing,
    Form,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    RequiredRule,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"

import { Button, DropDownBox, Popup, TextArea } from "devextreme-react"
import ArrayStore from "devextreme/data/array_store"
import { cloneDeep } from "lodash"
import { initMonacoEditor } from "../componentsSetup/common/MonacoEditor"
import { IDBConnection, IGroup, IModbusConnection, IScheduledTask } from "../types/BaseTypes"
import { gqlRead, gqlWrite } from "../utils/APIUtils"
import EntityUtils from "../utils/EntityUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"

type DTO = IScheduledTask

type State = CollectionState<DTO> & {
    groups: Array<IGroup>
    dbConnections: Array<IDBConnection>
    modbusConnections: Array<IModbusConnection>
}

class ScheduledTasks extends BaseCollection<DTO, CollectionProps, State> {
    public static readonly SUB_ENTITIES = ["group", "dbConnection", "modbusConnection"]

    private dbConnectionSelectBoxOptions: any
    private modbusConnectionSelectBoxOptions: any

    constructor(props: CollectionProps) {
        super(props, {
            entityName: "scheduledTask",
            subEntitiesToIds: ScheduledTasks.SUB_ENTITIES,
            copyButton: true,
            title: "Plánované úlohy"
        })
        this.state = {
            ...this.state,
            groups: [],
            dbConnections: [],
            modbusConnections: []
        }

        this.dbConnectionSelectBoxOptions = {
            ...BaseCollection.SELECT_BOX_OPTIONS
        }

        this.modbusConnectionSelectBoxOptions = {
            ...BaseCollection.SELECT_BOX_OPTIONS
        }
    }

    protected getEntities = (): Promise<Array<DTO>> => {
        return gqlRead({
            scheduledTasks: {
                id: true,
                name: true,
                cron: true,
                script: true,
                active: true,
                group: {
                    id: true
                },
                dbConnection: {
                    id: true
                },
                modbusConnection: {
                    id: true
                }
            },
            groups: {
                id: true,
                name: true
            },
            dbConnections: {
                id: true,
                name: true,
                group: {
                    id: true
                }
            },
            modbusConnections: {
                id: true,
                name: true,
                group: {
                    id: true
                }
            }
        }).then((r) => {
            this.setState({
                groups: r.groups,
                dbConnections: r.dbConnections,
                modbusConnections: r.modbusConnections
            })
            return r.scheduledTasks
        })
    }

    private onGroupChanged = (object: { data?: { group: number } }) => {
        const group = object.data?.group
        if (group) {
            this.filterDbConnections(group)
            this.filterModbusConnections(group)
            this.forceUpdate()
        }
    }

    private filterDbConnections = (group: number) => {
        const data = this.state.dbConnections?.filter((db) => db.group?.id === group)
        this.dbConnectionSelectBoxOptions.dataSource = new ArrayStore({ data: data, key: "id" })
        return data
    }

    private filterModbusConnections = (group: number) => {
        const data = this.state.modbusConnections?.filter((m) => m.group?.id === group)
        this.modbusConnectionSelectBoxOptions.dataSource = new ArrayStore({ data: data, key: "id" })
        return data
    }

    private setCellValueGroup = (
        newData: any,
        value: number,
        currentRowData: { modbusConnection: number; dbConnection: number }
    ) => {
        const availableDbs = this.filterDbConnections(value)
        const actualDb = availableDbs.some((db) => db.id === currentRowData.dbConnection)
            ? currentRowData.dbConnection
            : null

        const availableMdbs = this.filterModbusConnections(value)
        const actualMdb = availableMdbs.some((db) => db.id === currentRowData.modbusConnection)
            ? currentRowData.modbusConnection
            : null

        newData.group = value
        newData.modbusConnection = actualMdb
        newData.dbConnection = actualDb
    }

    private onInitNewRow = (object: { data: DTO }) => {
        object.data.active = true
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <DataGrid
                ref={ref}
                dataSource={dataSource}
                showBorders={true}
                columnHidingEnabled={true}
                onEditorPreparing={this.onEditorPreparing}
                onToolbarPreparing={this.onToolbarPreparing}
                onSelectionChanged={this.onSelectionChanged as any}
                onEditingStart={this.onGroupChanged}
                onInitNewRow={this.onInitNewRow as any}
                height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
            >
                <Paging enabled={false} />
                <Pager visible={true} displayMode="full" showInfo={true} />
                <SearchPanel visible={true} />
                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
                <Editing
                    mode="form"
                    useIcons={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                >
                    <Form colCount={1}>
                        <Item itemType="group" colCount={2}>
                            <Item itemType="group" caption="Základní">
                                <Item dataField="name" />
                                <Item
                                    dataField="cron"
                                    helpText="Jedná se o formát popsaný zde: https://spring.io/blog/2020/11/10/new-in-spring-5-3-improved-cron-expressions"
                                />
                                <Item dataField="active" editorType="dxCheckBox" />
                            </Item>
                            <Item itemType="group" caption="Skupina">
                                <Item
                                    editorType="dxSelectBox"
                                    dataField="group"
                                    editorOptions={{
                                        ...BaseCollection.SELECT_BOX_OPTIONS,
                                        dataSource: this.state.groups
                                    }}
                                />
                                <Item
                                    editorType="dxSelectBox"
                                    dataField="dbConnection"
                                    editorOptions={this.dbConnectionSelectBoxOptions}
                                />
                                <Item
                                    editorType="dxSelectBox"
                                    dataField="modbusConnection"
                                    editorOptions={this.modbusConnectionSelectBoxOptions}
                                />
                            </Item>
                        </Item>
                        <Item itemType="group" caption="Script">
                            <Item dataField="script" />
                        </Item>
                    </Form>
                </Editing>

                <GroupPanel visible={true} />
                <Grouping autoExpandAll={false} />

                <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                <Column dataField="name" caption="Název">
                    <RequiredRule />
                </Column>
                <Column
                    dataField="group"
                    caption="Skupina"
                    groupIndex={0}
                    setCellValue={this.setCellValueGroup}
                    customizeText={(cellInfo: any) => this.fromNames(cellInfo, this.state.groups)}
                >
                    <RequiredRule />
                </Column>
                <Column dataField="cron" caption="Cron">
                    <RequiredRule />
                </Column>
                <Column dataField="active" caption="Zapnuto" />
                <Column
                    dataField="dbConnection"
                    caption="Databázové spojení"
                    customizeText={(cellInfo: any) =>
                        this.fromNames(cellInfo, this.state.dbConnections)
                    }
                ></Column>
                <Column
                    dataField="modbusConnection"
                    caption="Modbus spojení"
                    customizeText={(cellInfo: any) =>
                        this.fromNames(cellInfo, this.state.modbusConnections)
                    }
                />
                <Column
                    dataField="script"
                    caption="Script"
                    editCellComponent={Monaco}
                    editorOptions={{ height: 500 }}
                >
                    <RequiredRule />
                </Column>
            </DataGrid>
        )
    }
}

export default ScheduledTasks

type MonacoProps = {
    data: {
        data: IScheduledTask
        setValue: (newValue?: string) => void
        value: string
    }
}

type MonacoState = {
    formData: IScheduledTask
    opened: boolean
    popupVisible: boolean
    tryResult: string
    documentationVisible: boolean
}

// TODO osamostatnit, aby to mohlo byt jednoduse pouzitelne i jinde
class Monaco extends React.Component<MonacoProps, MonacoState> {
    private static dropDownOptions = { resizeEnabled: true, height: "150px" }

    constructor(props: MonacoProps) {
        super(props)
        this.state = {
            formData: cloneDeep(props.data.data),
            opened: false,
            popupVisible: false,
            documentationVisible: false,
            tryResult: ""
        }
    }

    private onOk = (e: any) => {
        const formData = this.state.formData
        this.props.data.setValue(formData?.script)
        this.setState({ formData: cloneDeep(formData), opened: false })
    }

    private tryTask = () => {
        const task = cloneDeep(this.state.formData)
        EntityUtils.toObjects([task], ScheduledTasks.SUB_ENTITIES)
        gqlWrite({
            tryScheduledTask: {
                __args: {
                    input: task
                }
            }
        })
            .then((r) =>
                this.setState({ tryResult: (r as any).tryScheduledTask, popupVisible: true })
            )
            .catch((e) => this.setState({ tryResult: e, popupVisible: true }))
    }

    private onHiding = () => {
        this.setState({ popupVisible: false, documentationVisible: false })
    }

    private showDocumentation = () => {
        this.setState({ documentationVisible: true })
    }

    private onContentRender = () => {
        const { tryResult, formData, popupVisible, documentationVisible } = this.state
        return (
            <>
                {initMonacoEditor(formData, "script")()}
                <Button text="Ok" onClick={this.onOk}></Button>
                <Button text="Vyzkoušet úlohu" onClick={this.tryTask}></Button>
                <Button text="Dokumentace" onClick={this.showDocumentation} />
                <Popup visible={popupVisible} onHiding={this.onHiding}>
                    <TextArea height={500} value={tryResult} />
                    <Button text="Zavřít" onClick={this.onHiding}></Button>
                </Popup>
                <Popup visible={documentationVisible} onHiding={this.onHiding}>
                    <>
                        <h2>Dokumentace scriptingu na backendu</h2>
                        <p>
                            Scriptovací jazyk backendu je rovněž JavaScript. Z tohoto scriptu je
                            možné volat předpřipravené rozhraní v Javě, které obsahuje několik
                            funkcí. Příklady jednotlivých volání:
                        </p>
                        {initMonacoEditor(DOC, "doc", false, 550, true)()}
                    </>
                </Popup>
            </>
        )
    }

    private onOpenedChange = () => {
        this.setState({ opened: !this.state.opened })
    }

    render() {
        const { opened, formData } = this.state
        return (
            <DropDownBox
                value={formData.script}
                dataSource={[formData.script]}
                contentRender={this.onContentRender}
                opened={opened}
                onOpenedChange={this.onOpenedChange}
                dropDownOptions={Monaco.dropDownOptions}
            ></DropDownBox>
        )
    }
}

const DOC = {
    doc: `// Čtení SQL (reference na úlohu, SQL)
// Vrací výsledek SQL jako JSON řetězec pole polí, kde první pole obsahuje názvy sloupců tabulky.
// K Dalším operacím s tímto řetězcem je typicky potřeba řetězec naparsovat do datové struktury pomocí JSON.parse()
ctx.readSql(self, "SELECT * FROM my_table")

// Zápis SQL (reference na úlohu, SQL)
// Vrací řetězec "OK" v případě, že bylo SQL provedeno v pořádku.
ctx.writeSql(self, "INSERT INTO my_table (column1, column2) VALUES (value1, value2)")

// Čtení Modbus holding registers (reference na úlohu, první adresa, počet adres, slave number)
// Vrací výsledek Modbus čtení jako řetězec pole čísel (Java int)
ctx.readHolding(self, 10, 5, 1)

// Zápis Modbus holding registers (reference na úlohu, první adresa, počet adres, slave number, pole čísel (Java int))
// Vrací řetězec "OK" v případě, že byl zápis holding registers proveden v pořádku.
ctx.writeHolding(self, 10, 2, 1, [13256, 12455])

// Odeslání SMS (reference na úlohu, Klíč, UTF, Zpráva, ...tel. čísla)
// Vrací řetězec "OK" v případě, že byla SMS odeslána v pořádku.
ctx.sendSms(self, "aaaaaabbbbbcccccc", false, "Moje zpráva", "123 456 789", "987 654 321")

// Odeslání E-mailu (reference na úlohu, Pole e-mailů, předmět, obsah e-mailu)
// Vrací řetězec "OK" v případě, že byl e-mail odeslán v pořádku.
ctx.sendEmail(self, ["abc@def.gh", "def@abc.gh"], "Předmět", "Obsah")


// Kompletní příklad
const jsonResult = ctx.readSql(self, "SELECT * FROM MY_TABLE")
const objResult = JSON.parse(jsonResult)
const value = objResult[1][2] + objResult[2][2]

const actualDate = new Date().toISOString()
const message = "Součet hodnot je " + value

ctx.sendSms(self, "aaaaaabbbbbcccccc", true, message, "123 456 789", "987 654 321")
ctx.sendEmail(self, ["abc@def.gh", "def@abc.gh"], "Součet hodnot k " + actualDate, message)

return message`
}
