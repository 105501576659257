import { cloneDeep } from "lodash"
import { v4 as uuid } from "uuid"
import { ComponentTextSize } from "../components/Text/TextEditor"
import { PropsMessenger } from "../stores/ReportStore"
import { IComponent, IReport, IVariable } from "../types/BaseTypes"
import { IFunction } from "../types/FunctionsType"

class ComponentUtils {
    static checkComponentsExist(report: IReport) {
        if (!report.components) {
            report.components = []
        }
        return report.components
    }

    static createNewComponent(): IComponent {
        return {
            guid: uuid(),
            parameters: {},
            components: [],
            functions: [],
            variables: []
        }
    }

    static cloneComponents(mirrorComponents: Array<IComponent>) {
        return mirrorComponents.map((c) => this.cloneComponent(c))
    }

    static cloneComponent(mirrorComponent: IComponent) {
        const component = cloneDeep(mirrorComponent)

        component.guid = uuid()
        component.businessId = undefined
        ComponentUtils.newIdentityDeepWithoutGuids(component)

        return component
    }

    static cloneComponentWithGuids(mirrorComponent: IComponent) {
        const component = cloneDeep(mirrorComponent)

        ComponentUtils.newIdentityDeepWithoutGuids(component)

        return component
    }

    static newIdentityDeepWithoutGuids(component: IComponent) {
        ComponentUtils.newIdentityWithoutGuid(component)

        component?.components?.forEach((c) => ComponentUtils.newIdentityDeepWithoutGuids(c))
        component?.functions?.forEach((f) => ComponentUtils.newIdentityWithoutGuid(f))
        component?.variables?.forEach((v) => ComponentUtils.newIdentityWithoutGuid(v))
    }

    static newIdentityWithoutGuid(cfv: IComponent | IFunction | IVariable) {
        cfv.id = undefined
        cfv.businessId = undefined
    }

    static checkUndefined(component: IComponent) {
        component.parameters = component.parameters ?? {}
        component.functions = component.functions ?? []
        component.variables = component.variables ?? []
        component.components = component.components ?? []
    }

    static deepCopyComponents(
        components: IComponent[] | undefined,
        dbConnectionId?: number,
        modbusConnectionId?: number
    ) {
        components?.forEach((c) => {
            c.id = undefined
            ;(c as any).businessId = undefined
            c.variables?.forEach((v) => {
                v.id = undefined
                ;(v as any).businessId = undefined
            })
            ComponentUtils.deepCopyFunctions(c.functions, dbConnectionId, modbusConnectionId)
            ComponentUtils.deepCopyComponents(c.components, dbConnectionId, modbusConnectionId)
        })
    }

    static deepCopyFunctions(
        functions: IFunction[] | undefined,
        dbConnectionId?: number,
        modbusConnectionId?: number
    ) {
        functions?.forEach((f) => {
            f.id = undefined
            ;(f as any).businessId = undefined
            f.dbConnection = (dbConnectionId === -1 ? undefined : dbConnectionId) as any
            f.modbusConnection = (modbusConnectionId === -1 ? undefined : modbusConnectionId) as any
        })
    }

    static classFromFontSize(size: ComponentTextSize) {
        return "font-size-" + size
    }

    static changeProps(
        propsMessenger: PropsMessenger,
        parameters?: any,
        reverse = false,
        nested = false
    ): boolean {
        if (parameters) {
            const enumsLength = propsMessenger.enums.length
            const enumFrameIndex = propsMessenger.enums.findIndex(
                (e) => e.enum === propsMessenger.value
            )
            const enumFrame = propsMessenger.enums[enumFrameIndex]
            let index
            if (enumFrame?.nested) {
                const changed = ComponentUtils.changeProps(
                    enumFrame.nested,
                    parameters,
                    reverse,
                    true
                )
                if (changed) {
                    return false
                }
            }
            if (
                (reverse && enumFrameIndex === 0) ||
                (!reverse && enumsLength - 1 === enumFrameIndex)
            ) {
                index = reverse ? enumsLength - 1 : 0
                parameters[propsMessenger.name] = propsMessenger.enums[index].enum
                if (nested) {
                    return false
                }
            } else {
                index = reverse ? enumFrameIndex - 1 : enumFrameIndex + 1
                parameters[propsMessenger.name] = propsMessenger.enums[index].enum
            }

            const nestedMessenger = propsMessenger.enums[index].nested
            if (nestedMessenger) {
                ComponentUtils.checkNested(nestedMessenger, parameters)
            }
            return true
        }
        return false
    }

    private static checkNested = (propsMessenger: PropsMessenger, parameters: any) => {
        const value = parameters[propsMessenger.name]
        if (!value) {
            parameters[propsMessenger.name] = propsMessenger.enums[0].enum

            const nested = propsMessenger.enums[0].nested
            if (nested) {
                ComponentUtils.checkNested(nested, parameters)
            }
        }
    }
}

export default ComponentUtils
