import { Button, Form, Popup } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { memo, useCallback, useMemo, useState } from "react"
import { useReportStore2 } from "../../../../../../../../stores/ReportStore2"
import { IComponent } from "../../../../../../../../types/BaseTypes"
import CommonComponent from "../../../../CommonComponent/CommonComponent"

type LoadProps = {
    reportPath: Array<String>
    onBookLoaded: (component: IComponent) => void
}

const LoadFromLibrary = (props: LoadProps) => {
    const books = useReportStore2((state) => state.books)

    const [popupVisible, setPopupVisible] = useState(false)
    const [component, setComponent] = useState(undefined)
    const [formData, setFormData] = useState({ id: -1 })

    const componentChanged = useCallback(
        (item: any) => {
            const bookId = item.value
            const book = books?.find((b) => b.id === bookId)
            setComponent(book?.component as any)
        },
        [books]
    )

    const libraryOptions = useMemo(
        () => ({
            items: books,
            displayExpr: "name",
            valueExpr: "id",
            onValueChanged: componentChanged
        }),
        [books, componentChanged]
    )

    const handlePopupShow = useCallback(() => {
        setPopupVisible(true)
    }, [])

    const handlePopupHidden = useCallback(() => {
        setPopupVisible(false)
    }, [])

    const onOk = useCallback(() => {
        const component = books.find((b) => b.id === formData.id)?.component
        if (component) {
            props.onBookLoaded(component as any)
            setFormData({ id: -1 })
        }
        handlePopupHidden()
    }, [books, formData.id, handlePopupHidden, props])

    return (
        <>
            <Popup
                width="auto"
                height="auto"
                showTitle={true}
                title="Načíst z knihovny"
                dragEnabled={true}
                visible={popupVisible}
                onHiding={handlePopupHidden}
            >
                <Form formData={formData}>
                    <SimpleItem
                        dataField="id"
                        editorType="dxSelectBox"
                        editorOptions={libraryOptions}
                    >
                        <Label text="Komponenta"></Label>
                    </SimpleItem>
                    <GroupItem>
                        {component && (
                            <div className="library_component_preview">
                                {<CommonComponent key={Math.floor(Math.random() * 1000000)} />}
                            </div>
                        )}
                    </GroupItem>
                    <GroupItem>
                        <Button text="Převzít" type="default" onClick={onOk}></Button>
                    </GroupItem>
                </Form>
            </Popup>
            <Button
                text="Načíst z knihovny"
                type="normal"
                stylingMode="contained"
                onClick={handlePopupShow}
            />
        </>
    )
}

export default memo(LoadFromLibrary)
