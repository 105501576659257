import { useCallback, useEffect } from "react"
import "./Header.scss"

import { Helmet } from "react-helmet"
import { useAppStore } from "../stores/AppStore"
import { useNavBarStore } from "../stores/NavBarStore"
import { useReportFields } from "../utils/CustomHooks"
import { MenuItem } from "./HeaderItems"
import HeaderMiddle from "./HeaderMiddle"
import Profile from "./Profile"
import { HEADER_HEIGHT } from "./ReactCSSVariables"
import { ReactComponent as Line } from "./svg/line.svg"
import { ReactComponent as Settings } from "./svg/settings.svg"

type Props = {
    handleMenuClick: (id: MenuItem) => void
    loadCurrentUser: (path?: string) => void
}

export const APP_NAME = "Wi-Con"
const NAVBAR_ID = "navbar"

export default function Header({ handleMenuClick, loadCurrentUser }: Props) {
    const currentUser = useAppStore((state) => state.currentUser!)
    const changePath = useAppStore((state) => state.changePath)
    const menuVisible = useNavBarStore((state) => state.menuVisible)
    const setMenuVisibility = useNavBarStore((state) => state.setMenuVisibility)
    const headerText = useNavBarStore((state) => state.headerText)
    const headerVisible = useNavBarStore((state) => state.headerVisible)

    const [name] = useReportFields((r) => [r.name])

    useEffect(() => {
        let header = document.getElementById(NAVBAR_ID)
        var prevScrollpos = window.pageYOffset

        window.onscroll = function () {
            if (header) {
                var currentScrollPos = window.pageYOffset
                if (prevScrollpos > currentScrollPos) {
                    header.style.top = "0"
                } else if (prevScrollpos !== currentScrollPos) {
                    header.style.top = "-" + HEADER_HEIGHT
                }
                prevScrollpos = currentScrollPos
            }
        }
    }, [])

    const administrationClick = useCallback(() => changePath("/" + MenuItem.ADMINISTRATION), [
        changePath
    ])
    const changeMenuVisibility = useCallback(() => setMenuVisibility(!menuVisible), [
        menuVisible,
        setMenuVisibility
    ])

    return (
        <header id={NAVBAR_ID} className="upper-menu-outer">
            <Helmet>
                <title>{name ? name : APP_NAME}</title>
            </Helmet>
            <div className="upper-menu-inner">
                <div className="left-group-outer">
                    <div className="left-group">
                        {headerVisible && (
                            <div
                                className={`header-button navigation-name ${
                                    menuVisible ? "navigation-open" : ""
                                }`}
                                onClick={changeMenuVisibility}
                            >
                                <div className="burger">
                                    <Line className="first" />
                                    <Line className="second" />
                                    <Line className="third" />
                                </div>

                                <div className="fluid">
                                    <div className="fluid-item">{headerText}</div>
                                </div>

                                <div className="static">
                                    <div className="static-item"></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <HeaderMiddle loadCurrentUser={loadCurrentUser} />
                <div className="right-group-outer">
                    <div className="right-group">
                        {currentUser.admin && (
                            <div className="settings header-button" onClick={administrationClick}>
                                <Settings />
                            </div>
                        )}
                        <div>
                            <Profile handleMenuClick={handleMenuClick} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
