export enum KeyType {
    SHIFT = "Shift",
    CONTROL = "Control",
    PLUS = "+",
    MINUS = "-",
    ARROW_LEFT = "ArrowLeft",
    ARROW_RIGHT = "ArrowRight",
    ARROW_UP = "ArrowUp",
    ARROW_DOWN = "ArrowDown"
}
