import { createContext, memo, useContext, useMemo } from "react"
import { useAppStore } from "../../stores/AppStore"
import EvalUtils from "../../utils/EvalUtils"
import { standardError } from "../../utils/NotifyUtils"
import { IParentContext, ParentContext } from "./ParentContext"
import { VariablesContext } from "./VariablesContext"

export interface IEvaluatorContext {
    context: Record<string, any>
    evaluate(expression: string | undefined, subContext?: Record<string, any>): any
}

export const EvaluatorContext = createContext<IEvaluatorContext | undefined>(undefined)

const VARS_OBJECT = "v"
const PARAMS_OBJECT = "p"

type Props = {
    children: JSX.Element
}

export const EvaluatorProvider = memo(({ children }: Props) => {
    const isAdmin = useAppStore((state) => state.currentUser!.admin)

    const parentContext = useContext(ParentContext)
    const variablesContext = useContext(VariablesContext)

    const ctx = useMemo(() => {
        return {
            [VARS_OBJECT]: variablesContext?.context,
            [PARAMS_OBJECT]: parentContext?.context
        }
    }, [variablesContext?.context, parentContext?.context])

    const context = useMemo(
        () => ({
            context: ctx,
            evaluate: (expression: string, subContext: Record<string, any>) => {
                const context = Object.assign({}, ctx, subContext)
                try {
                    return EvalUtils.eval(expression, context)
                } catch (e) {
                    logException(e, expression, isAdmin, parentContext)
                    return undefined
                }
            }
        }),
        [parentContext, ctx, isAdmin]
    )

    return <EvaluatorContext.Provider value={context}>{children}</EvaluatorContext.Provider>
})

const exceptionLogged: Array<string> = []

const constructPath = (parentContext?: IParentContext): string => {
    const result: Array<string | undefined> = []
    let parent = parentContext?.context.parent
    while (true) {
        if (parent) {
            result.push(parent.props.name)
            parent = parent.parent
        } else {
            break
        }
    }
    return result.reverse().join(" => ")
}

const logException = (
    e: any,
    expression: string,
    isAdmin: boolean,
    parentContext?: IParentContext
) => {
    if (isAdmin && exceptionLogged.length === 0) {
        standardError({
            message: "Chyba ve výrazu. Podrobnosti ve vývojářské konzoli."
        })
    }

    const exceptionMessage = `========================>
    path: ${constructPath(parentContext)}
    expression: 
    ${expression}
    ${e}
    <========================`

    if (!exceptionLogged.includes(exceptionMessage)) {
        console.error(exceptionMessage)
        exceptionLogged.push(exceptionMessage)
    }
}
