import { isEqual, isObject, transform } from "lodash"

export function generateId(objects: Array<any>, idPropertyName: string = "id") {
    if (objects.length === 0) {
        return 0
    }
    for (let i = 0; i < Number.MAX_VALUE; i++) {
        const object = objects.find((o: any) => {
            return o.hasOwnProperty(idPropertyName) && o[idPropertyName].toString() === i.toString()
        })
        if (!object) {
            return i
        }
    }
    throw new Error("Nebylo možné vygenerovat id pro prvky: " + objects)
}

export function difference(object: any, base: any) {
    function changes(object: any, base: any) {
        return transform(object, function (result: any, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] =
                    isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
            }
        })
    }
    return changes(object, base)
}
