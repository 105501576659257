import { Edge, Node, Position } from "reactflow"
import { $enum } from "ts-enum-util"
import { TankColor } from "../components/Tank/TankEditor"
import { ComponentType } from "./ComponentTypes"
import { IFunction, TFunction } from "./FunctionsType"

export enum DBConnectionType {
    MARIADB = "MARIADB",
    HSQL = "HSQL",
    H2 = "H2",
    SQLSERVER = "SQLSERVER",
    POSTGRE = "POSTGRE"
}

export enum ModbusAddress {
    BOOLEAN = "BOOLEAN",
    FLOAT = "FLOAT",
    W = "W",
    DW = "DW"
}

export const MODBUS_VALUE_TYPES = [
    { name: "Boolean", value: ModbusAddress.BOOLEAN },
    { name: "Word", value: ModbusAddress.W },
    { name: "DoubleWord", value: ModbusAddress.DW },
    { name: "Float", value: ModbusAddress.FLOAT }
]

export type BearerToken = {
    accessToken: string
    tokenType: string
}

export interface IBase {
    id?: number
    businessId?: string
    name?: string
}

export interface IDateAudit extends IBase {
    createdAt?: string
    updatedAt?: string
}

export interface IRole extends IBase {}

export interface IUser extends IDateAudit {
    username?: string
    email?: string
    createdAt?: string
    admin: boolean
    twoFactorEnabled?: boolean
    licenseExceeded: boolean
    licenseName: string
    deviceId: string
    roles?: Array<IRole>
    groups?: Array<IGroup>
    reports?: Array<IReport>
}

export const DB_CONNECTION_TYPES = $enum(DBConnectionType).getValues()

export interface IDBConnection extends IBase {
    dbName?: string
    username?: string
    password?: string
    address?: string
    type?: DBConnectionType
    port?: number
    group?: IGroup
    maxConnections?: number
    waitDuration?: number
}

export interface IModbusConnection extends IBase {
    address?: string
    port?: number
    group?: IGroup
    maxConnections?: number
    waitDuration?: number
}

export interface IVariable extends IBase {
    guid?: string
    initialValue?: string
}

export interface IComponentHandle {
    guid?: string
    name?: string
    position: Position
    top?: number
    bottom?: number
    left?: number
    right?: number
}

export interface BaseParams {
    visibilityExpression?: string
    rotationExpression?: string
    transparentBackground?: boolean
    ySwitch?: boolean
    handles?: Array<IComponentHandle>
}

export interface IComponent<T extends BaseParams = BaseParams> extends IBase, IComponentParent {
    guid: string
    type?: ComponentType
    parameters?: T
}

export interface IBaseList {
    id?: number
}

export interface IComponentParent {
    name?: string
    variables?: Array<IVariable>
    functions?: Array<TFunction>
    components?: Array<IComponent>
}

export interface NodeData {
    componentGuid: string
    intersecting?: boolean
    maxWidth?: number
    minWidth?: number
    maxHeight?: number
    minHeight?: number
    hidden?: boolean
}

export interface EdgeData {
    intensityExpression?: string
    reverseExpression?: string
    size?: number
    color?: TankColor
}

export interface IReportFlowParameters {
    nodes?: Array<Node<NodeData>>
    edges?: Array<Edge<EdgeData>>
    gridWidth?: number
    gridHeight?: number
}

export type RequiredFlowParameters = IReportFlowParameters & {
    nodes: Array<Node<NodeData>>
    edges: Array<Edge<EdgeData>>
}

export interface IReportParameters {
    flow?: IReportFlowParameters
}

export type RequiredReportParameters = IReportParameters & {
    flow: RequiredFlowParameters
}

export interface IReport extends IDateAudit, IComponentParent {
    rank?: number
    group?: IGroup
    parameters?: IReportParameters
}

export type RequiredReport = IReport & {
    name: string
    rank: number
    parameters: RequiredReportParameters
    components: Array<IComponent>
    variables: Array<IVariable>
    functions: Array<IFunction>
}

export interface IGroup extends IBase {
    reports?: Array<IReport>
    users?: Array<IUser>
}

export type GroupIDs = IGroup & {
    reports: Array<number>
    users: Array<number>
}

export type LoginResponse = {
    secretSet?: boolean
    barcodeAddress?: string
    token?: BearerToken
    user?: IUser
}

export interface INotificationGroup extends IBase {
    disabled?: boolean
    users?: Array<IUser>
    group?: IGroup
}

export interface INotificationDefinition extends IBase {
    notificationGroup?: INotificationGroup
    modbusValues?: Array<IModbusValue>
}

export interface IModbusFrame extends IBase {
    type?: ModbusAddress
    swappedWords?: boolean
    disabled?: boolean
    slaveNumber?: number
    fromAddress?: number
    toAddress?: number
    fromValueId?: number
    readInterval?: number
    group?: IGroup
    dbConnection?: IDBConnection
    modbusConnection?: IModbusConnection
}

export interface IModbusValue extends IBase {
    valueId?: number
    multiplier?: number
    notifications?: boolean
    biggerThen?: boolean
    edgeValue?: number
    message_in?: string
    message_out?: string
    numbers?: string
    emails?: string
    saveHistoryInterval?: number
    saveByChangeOnly?: boolean
    everyDayIn?: Date
    frame?: IModbusFrame
    notification?: INotificationDefinition
}

export interface IBook extends IBase {
    path: string
    width?: number
    height?: number
    component: IComponent
}

export interface IImage extends IBase {
    type?: string
    data?: string
}

export interface IThreeDModel extends IBase {
    data?: string
}

export interface IScheduledTask extends IBase {
    cron?: string
    active?: boolean
    script?: string
    dbConnection?: IDBConnection
    modbusConnection?: IModbusConnection
    notificationDefinition?: INotificationDefinition
}
