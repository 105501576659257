import { createContext, memo, useContext, useMemo } from "react"

type Parent = {
    parent: Parent | undefined
    props: Record<string, any>
}

export interface IParentContext {
    context: Parent
}

export const ParentContext = createContext<IParentContext | undefined>(undefined)

type Props = {
    children: JSX.Element
    p: Record<string, any>
}

export const ParentProvider = memo(({ children, p }: Props) => {
    const parentContext = useContext(ParentContext)

    const context = useMemo(
        () => ({
            context: {
                parent: parentContext?.context,
                props: p
            }
        }),
        [parentContext?.context, p]
    )

    return <ParentContext.Provider value={context}>{children}</ParentContext.Provider>
})
