import { Form, Popup } from "devextreme-react"
import { ButtonItem, Label, SimpleItem } from "devextreme-react/form"
import React from "react"
import { DropTargetMonitor } from "react-dnd"
import { IBook } from "../../types/BaseTypes"
import { persistBook } from "../../utils/API/APILibrary"
import { DraggableType, DroppableContainer } from "../../utils/dnd/DroppableContainer"
import { standardError, standardSuccess } from "../../utils/NotifyUtils"
import ReportUtils from "../../utils/ReportUtils"
import Components from "./Components"

type ComponentsState = {
    popupVisible: boolean
    book?: IBook
}

type ComponentsProps = {
    books: Array<IBook>
    visible: boolean
    searchValue: string
    refreshCustom: () => void
}

class CustomComponents extends React.Component<ComponentsProps, ComponentsState> {
    private formData: { name: string }
    private confirmButtonOptions: any

    constructor(props: ComponentsProps) {
        super(props)
        this.state = {
            popupVisible: false
        }

        this.formData = { name: "" }

        this.confirmButtonOptions = { text: "Uložit", type: "default", onClick: this.handleCreate }
    }

    private handleCreate = () => {
        const { book } = this.state
        const name = this.formData.name
        if (book) {
            ReportUtils.prepareComponentToSave(book.component)
            persistBook([{ ...book, name }])
                .then((r) => {
                    this.formData.name = ""
                    standardSuccess("Komponenta " + name + " uložena do knihovny")
                    this.hidePopup()
                    this.props.refreshCustom()
                })
                .catch(standardError)
        }
    }

    private onDrop = (item: any, monitor: DropTargetMonitor<any, any>) => {
        this.setState({ book: item })
        this.showPopup()
    }

    private showPopup = () => {
        this.setState({ popupVisible: true })
    }

    private hidePopup = () => {
        this.setState({ popupVisible: false })
    }

    private popupRender = () => {
        return (
            <Form formData={this.formData}>
                <SimpleItem dataField="name">
                    <Label text="Název" />
                </SimpleItem>
                <ButtonItem horizontalAlignment="left" buttonOptions={this.confirmButtonOptions} />
            </Form>
        )
    }

    render() {
        const { books: components, visible, searchValue } = this.props
        const { popupVisible } = this.state
        return (
            <>
                <DroppableContainer
                    onDrop={this.onDrop}
                    draggableType={DraggableType.LIBRARY}
                    styles={{ overflow: "auto" }}
                >
                    <Components books={components} visible={visible} searchValue={searchValue} />
                </DroppableContainer>
                <Popup
                    width="auto"
                    height="auto"
                    title="Zadejte název"
                    visible={popupVisible}
                    onHiding={this.hidePopup}
                    contentRender={this.popupRender}
                />
            </>
        )
    }
}

export default CustomComponents
