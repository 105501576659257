import { API_BASE_URL } from "../../constants"
import { IBook } from "../../types/BaseTypes"
import { request, accessTokenExists } from "./../APIUtils"

export function getLibrary(): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/library",
            method: "GET"
        })
    })
}

export function persistBook(books: Array<IBook>): Promise<Array<IBook>> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/library",
            method: "POST",
            body: JSON.stringify(books)
        })
    })
}
