import { Button, TextBox } from "devextreme-react"
import { memo, useCallback, useEffect, useState } from "react"
import { IBook } from "../../types/BaseTypes"
import { gqlRead } from "../../utils/APIUtils"
import { SystemLibraryComponents } from "./BasicConfigurations"
import Components from "./Components"
import CustomComponents from "./CustomComponents"

enum LibrarySection {
    SYSTEM = "system",
    CUSTOM = "custom"
}

type Props = {}

function Library(props: Props) {
    const [searchValue, setSearchValue] = useState("")
    const [section, setSection] = useState(LibrarySection.SYSTEM)
    const [booksLoaded, setBooksLoaded] = useState(false)
    const [systemComponents, setSystemComponents] = useState<Array<IBook>>([])
    const [customComponents, setCustomComponents] = useState<Array<IBook>>([])

    useEffect(() => {
        const loadComponents = () =>
            setSystemComponents(
                SystemLibraryComponents.map((c) => ({
                    name: c.name,
                    component: c as any,
                    path: "root",
                    width: 50,
                    height: 50
                }))
                // .filter(
                //     (c) =>
                //         c.component.type === ComponentsEnum.TEXT ||
                //         c.component.type === ComponentsEnum.BUTTON ||
                //         c.component.type === ComponentsEnum.DATETIME
                // )
                // []
            )

        loadComponents()
    }, [])

    const searchChange = useCallback((e: any) => {
        setSearchValue(e.value)
    }, [])

    const systemClick = useCallback(() => {
        setSection(LibrarySection.SYSTEM)
    }, [])

    const loadBooks = useCallback(() => {
        gqlRead({
            books: {
                id: true,
                name: true,
                component: true,
                width: true,
                height: true
            }
        }).then((r: any) => {
            setCustomComponents(r.books)
            // setCustomComponents([r.books[0]])
        })
    }, [])

    const customClick = useCallback(() => {
        if (!booksLoaded) {
            loadBooks()
        }
        setBooksLoaded(true)
        setSection(LibrarySection.CUSTOM)
    }, [booksLoaded, loadBooks])

    return (
        <div className={`library`}>
            <div className="library-left_section">
                <Button
                    text="Systémové"
                    width="150"
                    onClick={systemClick}
                    type={section === LibrarySection.SYSTEM ? "default" : "normal"}
                />
                <Button
                    text="Vlastní"
                    width="150"
                    onClick={customClick}
                    type={section === LibrarySection.CUSTOM ? "default" : "normal"}
                />
            </div>
            <div className="library-right_section">
                <div className="search-line">
                    <TextBox
                        placeholder="Hledat"
                        onValueChanged={searchChange}
                        valueChangeEvent="input"
                        showClearButton={true}
                        width="250"
                    />
                </div>
                <Components
                    books={systemComponents}
                    searchValue={searchValue}
                    visible={section === LibrarySection.SYSTEM}
                />
                <CustomComponents
                    books={customComponents}
                    searchValue={searchValue}
                    visible={section === LibrarySection.CUSTOM}
                    refreshCustom={loadBooks}
                />
            </div>
        </div>
    )
}

export default memo(Library)
