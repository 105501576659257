import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback, useMemo } from "react"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { useForceUpdate } from "../../utils/CustomHooks"

export type GaugeRange = {}

export type GaugeParameters = BaseParams & {
    type: GaugeType
    orientation: GaugeOrientation
    expression: string
    valueType: string
    startValue: number
    endValue: number
    tickInterval: number
    export: boolean
    ranges: Array<Range>
}

export enum GaugeType {
    circular = "circular",
    linear = "linear"
}

export enum GaugeOrientation {
    horizontal = "horizontal",
    vertical = "vertical"
}

export const getGaugeBasicConfigurations = () => {
    return [{}]
}

function GaugeEditor({ parameters }: EditorProps<GaugeParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <TabBase parameters={parameters} />
            </Item>
            <Item title="Rozsahy">
                <TabRanges parameters={parameters} />
            </Item>
        </TabPanel>
    )
}

export default memo(GaugeEditor)

type TabBaseProps = {
    parameters: GaugeParameters
}

const orientationOptions = {
    items: Object.keys(GaugeOrientation)
}

function TabBase({ parameters }: TabBaseProps) {
    const [forceUpdate] = useForceUpdate()
    const typeOptions = useMemo(
        () => ({
            items: Object.keys(GaugeType),
            onValueChanged: forceUpdate
        }),
        [forceUpdate]
    )

    parameters.type = parameters.type ?? GaugeType.circular
    parameters.orientation = parameters.orientation ?? GaugeOrientation.horizontal

    return (
        <div className="component-setup-container">
            <Form formData={parameters}>
                <GroupItem>
                    <GroupItem colCount={2}>
                        <SimpleItem
                            dataField="type"
                            editorType="dxSelectBox"
                            editorOptions={typeOptions}
                        >
                            <Label text="Typ" />
                        </SimpleItem>
                        <SimpleItem
                            visible={parameters.type === GaugeType.linear}
                            dataField="orientation"
                            editorType="dxSelectBox"
                            editorOptions={orientationOptions}
                        >
                            <Label text="Orientace" />
                        </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={2}>
                        <SimpleItem dataField="startValue" editorType="dxNumberBox">
                            <Label text="Minimum" />
                        </SimpleItem>
                        <SimpleItem dataField="endValue" editorType="dxNumberBox">
                            <Label text="Maximum" />
                        </SimpleItem>
                        <SimpleItem dataField="tickInterval" editorType="dxNumberBox">
                            <Label text="Délka dílku stupnice" />
                        </SimpleItem>
                        <SimpleItem dataField="valueType">
                            <Label text="Jednotky" />
                        </SimpleItem>
                        <SimpleItem dataField="export" editorType="dxSwitch">
                            <Label text="Export do PDF / obrázku" />
                        </SimpleItem>
                    </GroupItem>
                    <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                        <Label text="Výraz" />
                    </SimpleItem>
                </GroupItem>
            </Form>
        </div>
    )
}

function TabRanges(props: TabBaseProps) {
    const popupContent = useCallback(
        (item: any) => (
            <GroupItem>
                <GroupItem colCount={3}>
                    <SimpleItem dataField="startValue" editorType="dxNumberBox">
                        <Label text="Od" />
                    </SimpleItem>
                    <SimpleItem dataField="endValue" editorType="dxNumberBox">
                        <Label text="Do" />
                    </SimpleItem>
                    <SimpleItem dataField="color" editorType="dxColorBox">
                        <Label text="Barva" />
                    </SimpleItem>
                </GroupItem>
            </GroupItem>
        ),
        []
    )

    return (
        <ListPopupEditor
            listOwner={props.parameters}
            listName="ranges"
            itemRender={RangeInfo}
            formItemContent={popupContent}
        />
    )
}

function RangeInfo(item: any) {
    return (
        <div
            style={{ color: item.color, fontWeight: "bold" }}
        >{`${item.startValue} - ${item.endValue} ${item.color}`}</div>
    )
}
