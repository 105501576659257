export const RIGHT_DEGREES = [0, 90, 180, 270, 360]

export const closestDegree = (deg: number): number => {
    deg = deg % 360

    const helpObject = { closestDegree: RIGHT_DEGREES[0], minDistance: 360 }
    RIGHT_DEGREES.forEach((d) => {
        const distance = Math.abs(deg - d)
        if (distance < helpObject.minDistance) {
            helpObject.minDistance = distance
            helpObject.closestDegree = d
        }
    })

    return helpObject.closestDegree
}
