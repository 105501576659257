import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export enum FlapOrientation {
    VERTICAL = "VERTICAL",
    HORIZONTAL = "HORIZONTAL"
}

export type FlapParameters = BaseParams & {
    orientation: FlapOrientation
    expression: string
}

export const getFlapBasicConfigurations = () => {
    return [{}]
}
const orientationOptions = {
    items: Object.values(FlapOrientation)
}

function FlapEditor({ parameters }: EditorProps<FlapParameters>) {
    parameters.orientation = parameters.orientation ?? FlapOrientation.HORIZONTAL

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem
                                dataField="orientation"
                                editorType="dxSelectBox"
                                editorOptions={orientationOptions}
                            >
                                <Label text="Orientace" />
                            </SimpleItem>
                            <SimpleItem
                                render={initMonacoEditor(parameters, "expression")}
                                helpText="0 - zavřeno, 1 - otevřeno, 2 - mezistav, 3 - chyba"
                            >
                                <Label text="Výraz pro běh"></Label>
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(FlapEditor)
