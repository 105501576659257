import { ButtonGroup } from "devextreme-react"
import { forwardRef, memo, useCallback, useEffect, useMemo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { KeyType } from "../../types/KeysTypes"
import ComponentUtils from "../../utils/ComponentUtils"
import {
    useContainerVisible,
    useExecuteRef,
    useExpression,
    useImperative,
    useListExpression
} from "../../utils/CustomHooks"
import { BUTTON_MAX_SIZE } from "../Button/ButtonEditor"
import "./ButtonGroupComponent.scss"
import { ButtonGroupParameters } from "./ButtonGroupEditor"

type Params = ButtonGroupParameters

type Props = ComponentProps<Params>

const TYPE = "default"
const MARGIN = { margin: "auto" }

const ButtonGroupComponent = forwardRef<IBaseComponent, Props>(
    (
        { enabledExpression, expression, buttons, fontSize, setContainerVisible, onChangeActions },
        ref
    ) => {
        useContainerVisible(setContainerVisible, false)
        const executeRef = useExecuteRef()

        const [enabled] = useExpression(enabledExpression, true)

        const [activeIndex, setActiveIndex] = useExpression<number | undefined>(
            expression,
            undefined
        )
        useEffect(() => {
            executeRef.current(onChangeActions, { value: activeIndex })
        }, [activeIndex, onChangeActions, executeRef])

        const [btnsValues] = useListExpression(
            useMemo(() => buttons?.map((b) => b.textExpression), [buttons])
        )
        const btns = useMemo(
            () =>
                btnsValues?.map((b: any, i: number) => ({
                    text: b,
                    guid: buttons?.[i].guid,
                    type: TYPE as any
                })),
            [btnsValues, buttons]
        )

        useImperative(
            ref,
            useMemo(() => {
                const messenger = {
                    name: "size",
                    enums: Array.from(Array(BUTTON_MAX_SIZE).keys()).map((v, i) => ({
                        enum: i + 1
                    })),
                    value: fontSize
                }
                return [
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [fontSize])
        )

        const onClick = useCallback(
            (e: any) => {
                const guid = e.itemData.guid
                const index = buttons?.findIndex((b) => b.guid === guid)

                if (index !== undefined) {
                    setActiveIndex(index)

                    executeRef.current(buttons?.[index]?.onClickActions)
                }
            },
            [buttons, executeRef, setActiveIndex]
        )

        const selectedItems = useMemo(() => {
            if (activeIndex !== undefined && activeIndex !== null) {
                return [btns?.[activeIndex]?.guid]
            }
            return []
        }, [activeIndex, btns])

        const className = useMemo(
            () => `button-group-component ${ComponentUtils.classFromFontSize(fontSize)}`,
            [fontSize]
        )

        return (
            <ButtonGroup
                className={className}
                width="100%"
                height="100%"
                keyExpr="guid"
                style={MARGIN}
                items={btns}
                onItemClick={onClick}
                selectedItemKeys={selectedItems}
                disabled={!enabled}
            />
        )
    }
)

export default memo(ButtonGroupComponent)
