import { produce } from "immer"
import { cloneDeep, isEqual, isNumber, throttle } from "lodash"
import * as R from "ramda"
import { Layout } from "react-grid-layout"
import { toast } from "react-toastify"
import {
    Connection,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    OnConnect,
    OnEdgesChange,
    OnNodesChange,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges
} from "reactflow"
import { mountStoreDevtool } from "simple-zustand-devtools"
import { v4 } from "uuid"
import { TemporalState, temporal } from "zundo"
import { StoreApi, create, useStore } from "zustand"
import { devtools } from "zustand/middleware"
import DatabaseCache from "../cache/DatabaseCache"
import { GridParameters } from "../components/Grid/GridEditor"
import { DEVELOPMENT_MODE } from "../constants"
import { CopiedComponents } from "../managers/ComponentCopyManager"
import { ComponentCoordinates } from "../report/Schema/Layer/ComponentContainer/ComponentCoordinatesEditor/ComponentCoordinatesEditor"
import { EdgeData, IComponent, IReport, NodeData, RequiredReport } from "../types/BaseTypes"
import { editReport, getReport } from "../utils/API/APIReports"
import ComponentUtils from "../utils/ComponentUtils"
import { CacheType } from "../utils/LocalStorageUtils"
import { STANDARD_NOTIFY_OPTIONS, standardError, standardSuccess } from "../utils/NotifyUtils"
import ReportUtils from "../utils/ReportUtils"
import { useAppStore } from "./AppStore"
import { useReportStore2 } from "./ReportStore2"

export type ComponentSize = {
    x: number
    y: number
    w: number
    h: number
}

const STORE_NAME = "ReportStore"

const DEBOUNCE_HISTORY_MS = 2000

export type PropsMessenger = {
    name: string
    enums: Array<{ enum: any; nested?: PropsMessenger }>
    value: any
}

export interface IReportStore {
    reports: Array<RequiredReport>
    activeReportId?: number
    setActiveReportId: (activeReportId: number) => void
    getReport: (reportId: number, reports: Array<RequiredReport>) => RequiredReport
    getActiveReport: (reports: Array<RequiredReport>) => RequiredReport

    setReports: (report: Array<IReport>) => void
    loadReportsFromCache: (reportIds: Array<number>) => void
    fetchReport: (reportId: number) => void
    saveReport: () => void

    addFlowComponent: (newComponent: IComponent, newNode: Node) => void
    editEdge: (edgeId: string, data: EdgeData) => void
    removeEdge: (edgeId: string) => void
    onNodesChange: OnNodesChange
    onEdgesChange: OnEdgesChange
    onConnect: OnConnect
    // setNodesIntersecting: (nodeIds: Array<string>) => void
    toTheForeground: (componentGuids: Array<string>) => void
    toTheBackground: (componentGuids: Array<string>) => void
    changeNodeHidden: (reportId: number, nodeId: string, hidden: boolean) => void

    addComponent: (reportPath: Array<string>, newComponent: IComponent) => void
    copyComponent: (reportPath: Array<string>, component: IComponent) => void
    updateComponent: (reportPath: Array<string>, newData: IComponent) => void
    switchComponent: (
        reportPath: Array<String>,
        oldComponent: IComponent,
        newComponent: IComponent
    ) => void
    setComponentSize: (guid: string, size: ComponentSize) => void
    setContainerSize: (
        component: IComponent,
        reportId: number,
        width: number | undefined,
        height: number | undefined
    ) => void
    changeComponent: (component: IComponent) => void
    changeComponentProps: (
        reportPath: Array<string>,
        guid: string,
        propsMessenger: PropsMessenger,
        reverse?: boolean
    ) => void
    remove: (nodes: Array<Node<NodeData>>, edges: Array<Edge<EdgeData>>) => void
    removeComponentByPath: (guid: string, reportPath: Array<string>) => void
    setComponentLayout: (reportPath: Array<string>, layout: Layout[], reportId: number) => void
    changeCoordinates: (
        reportPath: Array<string>,
        coordinates: ComponentCoordinates,
        guid: string
    ) => void
    insertComponents: (copiedComponents: CopiedComponents) => void
}

export const useReportStore = create<
    IReportStore,
    [["zustand/devtools", never], ["temporal", StoreApi<TemporalState<IReportStore>>]]
>(
    devtools(
        temporal(
            (set, get) => ({
                reports: [],

                setActiveReportId: (activeReportId: number) => {
                    set({ activeReportId }, false, "setActiveReportId")
                },
                getReport: (reportId: number, reports: Array<RequiredReport>) => {
                    const report = reports.find((r) => r.id === reportId)
                    if (report) {
                        return report
                    }
                    throw new Error("Report not found by id: " + reportId)
                },
                getActiveReport: (reports: Array<RequiredReport>) => {
                    const { activeReportId, getReport } = get()
                    if (activeReportId !== undefined) {
                        return getReport(activeReportId, reports)
                    }
                    throw new Error("Report activeId not set.")
                },

                setReports: (newReports: Array<IReport>) => {
                    const state = get()
                    let reports = state.reports

                    newReports.forEach((r) => {
                        ReportUtils.prepareReportForEdit(r)
                        const report = ReportUtils.initReport(r)

                        const existingReport = reports.find((r) => r.id === report.id)
                        if (existingReport) {
                            reports = reports.map((r) => (r.id === report.id ? report : r))
                        } else {
                            reports = [...reports, report]
                        }
                    })

                    set({ reports }, false, "setReports")
                },
                loadReportsFromCache: (reportIds: Array<number>) => {
                    const reportPromises: Array<Promise<IReport>> = []
                    reportIds.forEach((id) => {
                        reportPromises.push(
                            DatabaseCache.getCacheFor(CacheType.REPORT, reportKey(id))
                        )
                    })

                    Promise.all(reportPromises).then((reports) => {
                        get().setReports(reports.filter((r) => r))
                    })
                },
                fetchReport: (reportId: number) => {
                    const { reports } = get()

                    getReport(reportId).then((report) => {
                        const existingReport = reports.find((r) => r.id === report.id)

                        if (!existingReport || existingReport.updatedAt !== report.updatedAt) {
                            get().setReports([report])
                            DatabaseCache.setCacheFor(
                                CacheType.REPORT,
                                reportKey(report.id!),
                                report
                            )
                        }
                    })
                },
                saveReport: () => {
                    const { currentUser, groupId } = useAppStore.getState()
                    const state = get()
                    const report = state.reports.find((r) => r.id === state.activeReportId)

                    if (report) {
                        const r = cloneDeep(report)
                        ReportUtils.prepareReportToSave(r)

                        const toastId = toast.loading("Ukládám report...", STANDARD_NOTIFY_OPTIONS)
                        editReport(r)
                            .then((response) => {
                                DatabaseCache.setCacheFor(
                                    CacheType.REPORT,
                                    currentUser?.id + ":" + groupId + ":" + report.id,
                                    response
                                )

                                const r = response as IReport

                                get().setReports([r])
                                // zde musi byt oddeleni od vlakna, jinak by se to opet vynulovalo
                                setTimeout(() => {
                                    useReportStore2.getState().setReportSaved(true, r.id!)
                                })

                                standardSuccess("Rozvržení uloženo.", toastId)
                            })
                            .catch((e) => standardError(e, toastId))
                    }
                },

                addFlowComponent: (newComponent: IComponent, newNode: Node) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            report.components.push(newComponent)
                            report.parameters.flow.nodes.push(newNode)
                        }),
                        false,
                        "addFlowComponent"
                    ),
                editEdge: (edgeId: string, data: EdgeData) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const edge = report.parameters.flow.edges.find((e) => e.id === edgeId)
                            if (edge) {
                                edge.data = data
                            }
                        }),
                        false,
                        "addFlowComponent"
                    ),
                removeEdge: (edgeId: string) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            report.parameters.flow.edges = report.parameters.flow.edges.filter(
                                (e) => e.id !== edgeId
                            )
                        }),
                        false,
                        "addFlowComponent"
                    ),
                onNodesChange: (changes: NodeChange[]) => {
                    const state = get()
                    const { reports, activeReportId } = state
                    const reportIndex = reports.findIndex((r) => r.id === activeReportId)

                    const lensPath = R.lensPath<IReportStore, Array<Node>>([
                        "reports",
                        reportIndex,
                        "parameters",
                        "flow",
                        "nodes"
                    ])
                    const nodesToChange = R.view(lensPath, state)
                    const newNodes = applyNodeChanges(changes, nodesToChange)
                    if (!isEqual(nodesToChange, newNodes)) {
                        const newState = R.over(lensPath, (c) => newNodes, state)
                        set(newState, false, "onNodesChange")
                    }
                },
                onEdgesChange: (changes: EdgeChange[]) => {
                    const state = get()
                    const { reports, activeReportId } = state
                    const reportIndex = reports.findIndex((r) => r.id === activeReportId)

                    const lensPath = R.lensPath<IReportStore, Array<Edge>>([
                        "reports",
                        reportIndex,
                        "parameters",
                        "flow",
                        "edges"
                    ])
                    const edgesToChange = R.view(lensPath, state)
                    const newEdges = applyEdgeChanges(changes, edgesToChange)
                    if (!isEqual(edgesToChange, newEdges)) {
                        const newState = R.over(lensPath, (c) => newEdges, state)
                        set(newState, false, "onEdgesChange")
                    }
                },
                onConnect: (connection: Connection) => {
                    set(
                        produce((state: IReportStore) => {
                            const edgeParams = {
                                ...connection,
                                type: "customEdge"
                            }

                            const report = state.getActiveReport(state.reports)
                            report.parameters.flow.edges = addEdge(
                                edgeParams,
                                report.parameters.flow.edges
                            )
                        }),
                        false,
                        "onConnect"
                    )
                },
                // zdrzovalo
                // setNodesIntersecting: (nodeIds: Array<string>) => {
                //     set(
                //         produce((state: IReportStore) => {
                //             state.parameters.flow.nodes.forEach(
                //                 (n) => (n.data.intersecting = nodeIds.includes(n.id))
                //             )
                //         }),
                //         false,
                //         "onNodeDrag"
                //     )
                // },
                toTheForeground: (componentGuids: Array<string>) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const flow = report.parameters.flow

                            const nodes = flow.nodes.filter((n) =>
                                componentGuids.includes(n.data.componentGuid)
                            )

                            // pohyb pred trubky
                            nodes.forEach((n) => {
                                n.style = n.style ?? {}
                                n.style.zIndex = 0
                            })

                            flow.nodes = [
                                ...flow.nodes.filter(
                                    (n) => !componentGuids.includes(n.data.componentGuid)
                                ),
                                ...nodes
                            ]
                        }),
                        false,
                        "toTheForeground"
                    ),
                toTheBackground: (componentGuids: Array<string>) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const flow = report.parameters.flow

                            const nodes = flow.nodes.filter((n) =>
                                componentGuids.includes(n.data.componentGuid)
                            )

                            // pohyb za trubky
                            nodes.forEach((n) => {
                                n.style = n.style ?? {}
                                n.style.zIndex = -1
                            })

                            flow.nodes = [
                                ...nodes,
                                ...flow.nodes.filter(
                                    (n) => !componentGuids.includes(n.data.componentGuid)
                                )
                            ]
                        }),
                        false,
                        "toTheBackground"
                    ),
                changeNodeHidden: (reportId: number, nodeId: string, hidden: boolean) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getReport(reportId, state.reports)
                            const flow = report.parameters.flow

                            const node = flow.nodes.find((n) => n.id === nodeId)
                            if (node) {
                                node.className = hidden ? "not-visible" : ""
                                node.data.hidden = hidden
                            }
                        }),
                        false,
                        "changeNodeHidden"
                    ),

                addComponent: (reportPath: Array<string>, newComponent: IComponent) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const parent = ReportUtils.findParentOf(report, reportPath)
                            parent.components = parent.components ?? []
                            parent.components.push(newComponent)
                        }),
                        false,
                        "addComponent"
                    ),
                copyComponent: (reportPath: Array<string>, component: IComponent) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const parent = ReportUtils.findParentOf(report, reportPath)

                            const newComponent = cloneDeep(component)
                            ComponentUtils.newIdentityDeepWithoutGuids(newComponent)
                            newComponent.guid = v4()

                            parent.components = parent.components ?? []
                            parent.components.push(newComponent)

                            if (report === parent) {
                                const nodes = report.parameters.flow.nodes
                                const node = nodes.find((n) => n.id === component.guid)

                                if (node) {
                                    const newNode = cloneDeep(node)
                                    newNode.id = newNode.data.componentGuid = newComponent.guid

                                    newNode.positionAbsolute = { x: 0, y: 0 }
                                    newNode.position.x = newNode.positionAbsolute.x =
                                        node.position.x + 30
                                    newNode.position.y = newNode.positionAbsolute.y =
                                        node.position.y + 30

                                    nodes.push(newNode)
                                }
                            }
                        }),
                        false,
                        "copyComponent"
                    ),
                updateComponent: (reportPath: Array<string>, newData: IComponent) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const parent = ReportUtils.findParentOf(report, reportPath)
                            const components = parent.components
                            if (components) {
                                const index = components.findIndex((c) => c.guid === newData.guid)
                                components[index] = { ...newData }
                            }
                        }),
                        false,
                        "updateComponent"
                    ),
                switchComponent: (
                    reportPath: Array<String>,
                    oldComponent: IComponent,
                    newComponent: IComponent
                ) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const parent = ReportUtils.findParentOf(report, reportPath)
                            const components = parent.components
                            if (components) {
                                const index = components.findIndex(
                                    (c) => c.guid === oldComponent.guid
                                )
                                components[index] = newComponent
                            }
                        }),
                        false,
                        "switchComponent"
                    ),
                setComponentSize: (guid: string, size: ComponentSize) =>
                    set(
                        produce((state: IReportStore) => {
                            // TODO
                            // ComponentUtils.setComponentSize(state.report, guid, size)
                        }),
                        false,
                        "setComponentSize"
                    ),
                setContainerSize: (
                    component: IComponent,
                    reportId: number,
                    width: number | undefined,
                    height: number | undefined
                ) => {
                    if (component.guid) {
                        const state = get()
                        const { reports } = state
                        const reportIndex = reports.findIndex((r) => r.id === reportId)
                        const report = reports[reportIndex]

                        const nodeIndex = report.parameters.flow.nodes.findIndex(
                            (n) => n.data.componentGuid === component.guid
                        )

                        const lensPath = R.lensPath<IReportStore, Node>([
                            "reports",
                            reportIndex,
                            "parameters",
                            "flow",
                            "nodes",
                            nodeIndex
                        ])

                        const node = R.view(lensPath, state)
                        const newNode = cloneDeep(node)

                        if (newNode) {
                            newNode.style = newNode.style ?? {}
                            if (height) {
                                newNode.height = newNode.style.height = newNode.data.maxHeight = newNode.data.minHeight = height
                            }
                            if (width) {
                                newNode.width = newNode.style.width = newNode.data.maxWidth = newNode.data.minWidth = width
                            }
                        }

                        if (!isEqual(newNode, node)) {
                            const newState = R.over(lensPath, (n) => newNode, state)
                            set(newState, false, "setContainerSize")
                        }
                    }
                },

                changeComponent: (component: IComponent) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const components = report.components.filter(
                                (c) => c.guid !== component.guid
                            )
                            components.push(component)
                        }),
                        false,
                        "changeComponent"
                    ),

                changeComponentProps: (
                    reportPath: Array<string>,
                    guid: string,
                    propsMessenger: PropsMessenger,
                    reverse?: boolean
                ) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const parent = ReportUtils.findParentOf(report, reportPath)
                            const component = parent.components?.find((c) => c.guid === guid)
                            if (component) {
                                ComponentUtils.changeProps(
                                    propsMessenger,
                                    component.parameters,
                                    reverse,
                                    false
                                )
                            }
                        }),
                        false,
                        "changeComponentProps"
                    ),
                remove: (nodes: Array<Node<NodeData>>, edges: Array<Edge<EdgeData>>) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const flow = report.parameters.flow

                            const componentGuids = nodes.map((n) => n.data.componentGuid)
                            const nodeIds = nodes.map((n) => n.id)
                            const edgeIds = edges.map((e) => e.id)

                            report.components = report.components.filter(
                                (c) => !componentGuids.includes(c.guid)
                            )
                            flow.nodes = flow.nodes.filter((n) => !nodeIds.includes(n.id))
                            flow.edges = flow.edges.filter((e) => !edgeIds.includes(e.id))
                        }),
                        false,
                        "remove"
                    ),
                removeComponentByPath: (guid: string, reportPath: Array<string>) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const parent = ReportUtils.findParentOf(report, reportPath)
                            parent.components = parent.components!.filter((c) => c.guid !== guid)

                            report.parameters.flow.nodes = report.parameters.flow.nodes.filter(
                                (n) => n.data.componentGuid !== guid
                            )
                        }),
                        false,
                        "removeComponentByPath"
                    ),
                setComponentLayout: (
                    reportPath: Array<string>,
                    layout: Layout[],
                    reportId: number
                ) => {
                    const state = get()
                    const { reports } = state
                    const reportIndex = reports.findIndex((r) => r.id === reportId)
                    const report = reports[reportIndex]

                    let componentPath
                    try {
                        componentPath = ReportUtils.findLensParentOf(report, reportPath)
                    } catch (e) {
                        // knihovni komponenta
                        return
                    }

                    const lensPath = R.lensPath<IReportStore, Layout[]>([
                        "reports",
                        reportIndex,
                        ...componentPath,
                        "parameters",
                        "layout"
                    ])
                    const oldLayout = R.view(lensPath, state)
                    // je potreba ocistit promenne typu undefined, jinak isEqual neprojde
                    const newLayout = JSON.parse(JSON.stringify(layout))
                    if (!isEqual(oldLayout, newLayout)) {
                        const newState = R.over(lensPath, (l) => newLayout, state)
                        set(newState, false, "setComponentLayout")
                    }
                },
                changeCoordinates: (
                    reportPath: Array<string>,
                    coordinates: ComponentCoordinates,
                    guid: string
                ) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            const node = report.parameters.flow.nodes.find(
                                (n) => n.data.componentGuid === guid
                            )
                            if (node) {
                                if (isNumber(coordinates.x) && isNumber(coordinates.y)) {
                                    node.position.x = coordinates.x
                                    node.position.y = coordinates.y
                                    if (node.style) {
                                        node.width = node.style.width = coordinates.w
                                        node.height = node.style.height = coordinates.h
                                    }
                                }
                            } else {
                                const parent = ReportUtils.findParentOf(report, reportPath)
                                const layout: Layout[] | undefined = (parent as IComponent<
                                    GridParameters
                                >).parameters?.layout

                                if (layout) {
                                    const l = layout.find((l) => l.i === guid)!
                                    l.x = coordinates.x!
                                    l.y = coordinates.y!
                                    l.w = coordinates.w!
                                    l.h = coordinates.h!
                                }
                            }
                        }),
                        false,
                        "changeCoordinates"
                    ),
                insertComponents: (copiedComponents: CopiedComponents) =>
                    set(
                        produce((state: IReportStore) => {
                            const report = state.getActiveReport(state.reports)
                            // componenty a edge se pro jistotu klonuji
                            const nodes = copiedComponents.flow.nodes
                            const components = cloneDeep(copiedComponents.components)
                            const edges = cloneDeep(copiedComponents.edges)

                            if (components && nodes) {
                                // nastaveni novych guidu komponent a zapamatovani v mape ke starym
                                const oldToNew = new Map<string, string>()
                                const newToOld = new Map<string, string>()
                                components.forEach((c, i) => {
                                    ComponentUtils.newIdentityDeepWithoutGuids(c)
                                    const newGuid = v4()
                                    oldToNew.set(c.guid, newGuid)
                                    newToOld.set(newGuid, c.guid)
                                    c.guid = newGuid
                                })

                                edges.forEach((e) => {
                                    // mozna nebude potreba
                                    // e.id = v4()

                                    e.source = oldToNew.get(e.source) ?? ""
                                    e.target = oldToNew.get(e.target) ?? ""
                                    // bude potreba zkontrolovat, zda muze byt prazdny retezec
                                    e.id = `reactflow__edge-${e.source}${e.sourceHandle}-${e.target}${e.targetHandle}`
                                })

                                // zde bude potreba projit flow.nodes a vytahnout do pole nove souradnice komponent
                                const componentGuids = components.map((c) => c.guid)
                                const newNodes = nodes.filter((n) =>
                                    componentGuids.includes(
                                        oldToNew.get(n.data.componentGuid) ?? ""
                                    )
                                )
                                components.forEach((c) => {
                                    const oldGuid = newToOld.get(c.guid)!
                                    const newGuid = oldToNew.get(oldGuid)!
                                    const newNode = newNodes?.find(
                                        (n) => n.data.componentGuid === oldGuid
                                    )
                                    if (newNode) {
                                        newNode.id = newGuid
                                        newNode.data.componentGuid = newGuid
                                    }
                                })

                                // pridani prvkuu do storu
                                report.components.push(...components)
                                report.parameters.flow.nodes.push(...newNodes)
                                report.parameters.flow.edges.push(...edges)
                            }
                        }),
                        false,
                        "insertComponents"
                    )
            }),
            {
                handleSet: (handleSet) =>
                    throttle<typeof handleSet>((state) => {
                        handleSet(state)
                    }, DEBOUNCE_HISTORY_MS),
                equality: (a, b) => {
                    const result = isEqual(a, b)

                    //kontrola
                    // if (!result) {
                    // console.log("dif", difference(a, b))
                    // }

                    return result
                }
            }
        ),
        { enabled: DEVELOPMENT_MODE, name: STORE_NAME }
    )
)

const reportKey = (reportId: number) => {
    const { currentUser, groupId } = useAppStore.getState()
    return currentUser?.id + ":" + groupId + ":" + reportId
}

useReportStore.subscribe((state) => {
    // TODO potreba dotahnout
    // useReportStore2.getState().setReportSaved(false)
})

if (DEVELOPMENT_MODE) {
    mountStoreDevtool(STORE_NAME, useReportStore)
}

export const useHistoryReportStore = <T>(
    selector: (state: TemporalState<IReportStore>) => T,
    equality?: (a: T, b: T) => boolean
) => useStore(useReportStore.temporal, selector, equality)

// nejdriv zapauzujeme a pak se dava resume v ReportManagement dle editModu
useReportStore.temporal.getState().pause()
