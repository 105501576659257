import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { DEVELOPMENT_MODE } from "../constants"
import { KeyType } from "../types/KeysTypes"

const STORE_NAME = "RegisteredStore"

export type OnKeyDown = {
    onKeyDown: (keyType: KeyType) => void
}

export interface IRegisteredStore {
    resetStore: () => void

    keysDown: Array<KeyType>
    allComponents: Record<string, OnKeyDown>
    registerComponent: (guid: string, component: OnKeyDown) => void
    isKeyPressed: (keyType: KeyType) => boolean
    onKeyDown: (keyType: KeyType) => void
    onKeyUp: (keyType: KeyType) => void
}

const initialState = {
    keysDown: [],
    allComponents: {}
}

export const useRegisteredStore = create<IRegisteredStore, [["zustand/devtools", never]]>(
    devtools(
        (set, get) => ({
            ...initialState,

            resetStore: () => set((state) => ({ ...initialState }), false, "resetStore"),

            registerComponent: (guid: string, component: OnKeyDown) =>
                set(
                    (state) => {
                        if (guid) {
                            const allComponents = { ...state.allComponents }
                            allComponents[guid] = component
                            return { allComponents }
                        }
                        return state
                    },
                    false,
                    "registerComponent"
                ),
            isKeyPressed: (keyType: KeyType) => get().keysDown.includes(keyType),
            onKeyDown: (keyType: KeyType) =>
                set(
                    (state) => {
                        let result = state.keysDown
                        if (!state.keysDown.includes(keyType)) {
                            result = [...result, keyType]
                        }
                        Object.keys(state.allComponents).forEach((k) =>
                            state.allComponents[k].onKeyDown(keyType)
                        )
                        return { keysDown: result }
                    },
                    false,
                    "onKeyDown"
                ),
            onKeyUp: (keyType: KeyType) =>
                set(
                    (state) => {
                        const result = [...state.keysDown]
                        result.splice(result.indexOf(keyType), 1)
                        return { keysDown: result }
                    },
                    false,
                    "onKeyUp"
                )
        }),
        { enabled: DEVELOPMENT_MODE, name: STORE_NAME }
    )
)

if (DEVELOPMENT_MODE) {
    mountStoreDevtool(STORE_NAME, useRegisteredStore)
}
