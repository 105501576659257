import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { DEVELOPMENT_MODE } from "../constants"
import { EdgeData } from "../types/BaseTypes"

const STORE_NAME = "EdgeEditorStore"

export interface IEdgeEditorStore {
    popupVisible: boolean
    data: EdgeData
    edgeId?: string

    setPopupVisible: (popupVisible: boolean) => void
    setData: (data: EdgeData, edgeId?: string) => void
}

export const useEdgeEditorStore = create<IEdgeEditorStore, [["zustand/devtools", never]]>(
    devtools(
        (set, get) => ({
            popupVisible: false,
            data: {},

            setPopupVisible: (popupVisible: boolean) =>
                set((state) => ({ popupVisible }), false, "setPopupVisible"),
            setData: (data: EdgeData, edgeId?: string) =>
                set((state) => ({ data, edgeId }), false, "setData")
        }),
        { enabled: DEVELOPMENT_MODE, name: STORE_NAME }
    )
)

if (DEVELOPMENT_MODE) {
    mountStoreDevtool(STORE_NAME, useEdgeEditorStore)
}
