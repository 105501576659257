import { ACCESS_TOKEN, API_BASE_URL } from "../constants"
import { IUser, LoginResponse } from "../types/BaseTypes"
import { request, accessTokenExists } from "./APIUtils"

export function preLogin(loginRequest: Object): Promise<LoginResponse> {
    return request({
        url: API_BASE_URL + "/auth/prelogin",
        method: "POST",
        body: JSON.stringify(loginRequest)
    })
}

export function login(loginRequest: Object) {
    return request({
        url: API_BASE_URL + "/auth/signin",
        method: "POST",
        body: JSON.stringify(loginRequest)
    })
}

export function firstLogin(loginRequest: Object) {
    return request({
        url: API_BASE_URL + "/auth/firstlogin",
        method: "POST",
        body: JSON.stringify(loginRequest)
    })
}

export function changePassword(loginRequest: Object) {
    return request({
        url: API_BASE_URL + "/auth/change-password",
        method: "POST",
        body: JSON.stringify(loginRequest)
    })
}

export function sendCodeToEmail(email: string) {
    return request({
        url: API_BASE_URL + `/auth/send-code-to-email/${email}`,
        method: "GET"
    })
}

export function forgottenPassword(loginRequest: Object) {
    return request({
        url: API_BASE_URL + "/auth/forgotten-password",
        method: "POST",
        body: JSON.stringify(loginRequest)
    })
}

export function signup(signupRequest: Array<number>) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: "POST",
        body: JSON.stringify(signupRequest)
    })
}

export function getCurrentUser(): Promise<IUser> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/users/me",
            method: "GET"
        })
    })
}

export function getUsers() {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/users",
            method: "GET"
        })
    })
}

export function editUser(user: Object) {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/users",
            method: "PUT",
            body: JSON.stringify(user)
        })
    })
}

export function deleteUsers(users: Object) {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/users",
            method: "DELETE",
            body: JSON.stringify(users)
        })
    })
}

export function keepAlive() {
    accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/auth/keepalive",
            method: "GET"
        })
    }).then((response: any) => {
        localStorage.setItem(ACCESS_TOKEN, response.accessToken)
    })
}
