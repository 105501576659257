import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { NodeProps, NodeResizer, useStore, useUpdateNodeInternals } from "reactflow"
import ComponentContainer from "../Schema/Layer/ComponentContainer/ComponentContainer"

import { useReportStore } from "../../stores/ReportStore"
import { NodeData } from "../../types/BaseTypes"
import { useExpression, useReport2Fields, useReportFields } from "../../utils/CustomHooks"
import { FlowNodeContext } from "../contexts/FlowNodeContext"
import "./FlowNode.scss"

type Props = NodeProps<NodeData>

const FlowNode = ({ id, data, selected, xPos, yPos }: Props) => {
    const { componentGuid, minHeight, maxHeight, minWidth, maxWidth, hidden } = data

    const [reportId, components] = useReportFields((r) => [r.id, r.components])
    const changeNodeHidden = useReportStore((state) => state.changeNodeHidden)

    const node = useStore((s) => s.nodeInternals.get(id))

    const [editMode] = useReport2Fields((s) => [s.editMode])

    const updateNodeInternals = useUpdateNodeInternals()

    const [frameVisible, setFrameVisible] = useState(false)
    const [resizing, setResizing] = useState(false)

    const component = useMemo(() => components?.find((c) => c.guid === componentGuid), [
        components,
        componentGuid
    ])

    const [visibility] = useExpression(component?.parameters?.visibilityExpression, true)
    useEffect(() => {
        if (reportId) {
            let newHidden = !(visibility || editMode)
            if ((!hidden && newHidden) || (hidden && !newHidden)) {
                changeNodeHidden(reportId, id, newHidden)
            }
        }
    }, [reportId, id, changeNodeHidden, visibility, editMode, hidden])

    const coordinates = useMemo(
        () => ({
            x: node?.position.x,
            y: node?.position.y,
            w: parseInt(node?.style?.width + "" ?? "0"),
            h: parseInt(node?.style?.height + "" ?? "0")
        }),
        [node]
    )

    const className = useMemo(() => `node ${editMode && selected ? "node-selected" : ""} `, [
        editMode,
        selected
    ])

    const onMouseEnter = useCallback(() => setFrameVisible(true), [])

    const onMouseLeave = useCallback(() => {
        if (!resizing) {
            setFrameVisible(false)
        }
    }, [resizing])

    const onResizeStart = useCallback(() => setResizing(true), [])

    const onResizeEnd = useCallback(() => {
        setResizing(false)
    }, [])

    const context = useMemo(() => ({ updateInternals: () => updateNodeInternals(id) }), [
        id,
        updateNodeInternals
    ])

    // tohle je fail, ale bohuzel to bez toho nekdy nacte trubky posunute od jejich handlu
    useEffect(() => {
        setTimeout(() => updateNodeInternals(id), 1000)
    }, [updateNodeInternals, id])

    return (
        <div className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <NodeResizer
                color={selected ? "red" : "green"}
                isVisible={editMode && (frameVisible || selected)}
                minHeight={minHeight}
                maxHeight={maxHeight}
                minWidth={minWidth}
                maxWidth={maxWidth}
                onResizeStart={onResizeStart}
                onResizeEnd={onResizeEnd}
            />
            <FlowNodeContext.Provider value={context}>
                {component && (
                    <ComponentContainer component={component} coordinates={coordinates} />
                )}
            </FlowNodeContext.Provider>
        </div>
    )
}

export const nodeTypes = {
    customNode: FlowNode
}

export default memo(FlowNode)
