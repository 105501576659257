import MEditor, { BeforeMount, Monaco, loader } from "@monaco-editor/react"
import Button from "devextreme-react/button"
import { Popup } from "devextreme-react/popup"
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import "../../css/MonacoEditor.scss"
import { EvaluatorContext } from "../../report/contexts/EvaluatorContext"
import EvalUtils from "../../utils/EvalUtils"
import JSONUtils from "../../utils/JSONUtils"

//offline beh
loader.config({ paths: { vs: process.env.PUBLIC_URL + "/js/vs" } })

type MonacoEditorProps = {
    item: any
    dataField: string
    validation?: boolean
    height?: number
    disabled?: boolean
}

type EditorProps = {
    fullscreenMode: boolean
    value: string | undefined
    handleValue: (value: string | undefined) => void
    editorRef?: any
    validation?: boolean
    height?: number
    disabled?: boolean
}

type TestProps = {
    evaluate: () => unknown
}

const MonacoEditor = ({ item, dataField, validation, height, disabled }: MonacoEditorProps) => {
    const evaluatorContext = useContext(EvaluatorContext)

    const [value, setValue] = useState(item[dataField])
    const [popupValue, setPopupValue] = useState(value)
    const [fullscreenMode, setFullscreenMode] = useState(false)

    const fullscreenEditorRef = useRef<any>(null)

    useEffect(() => {
        const currentValue = item[dataField]

        if (currentValue !== value) {
            setValue(currentValue)
        }
    }, [dataField, item, value])

    useEffect(() => {
        if (!fullscreenMode) {
            setPopupValue(value)
        }
    }, [fullscreenMode, value])

    const handleFullscreen = useCallback(() => {
        setFullscreenMode(true)
    }, [])

    const handleOnHiding = useCallback(() => {
        setFullscreenMode(false)

        fullscreenEditorRef.current?.setValue(value ?? "")
    }, [value])

    const handleValue = useCallback(
        (value: string | undefined) => {
            setValue(value)

            item[dataField] = value
        },
        [item, dataField]
    )

    const handleSave = useCallback(() => {
        handleValue(fullscreenEditorRef.current.getValue())
        setFullscreenMode(false)
    }, [handleValue])

    const testEvaluate = useCallback(() => {
        if (value) {
            return evaluatorContext?.evaluate(EvalUtils.correctExpression(value))
        }
        return undefined
    }, [value, evaluatorContext])

    return (
        <>
            <Popup
                onHiding={handleOnHiding}
                visible={fullscreenMode}
                fullScreen={true}
                maxHeight="100%"
            >
                <MyEditor
                    fullscreenMode={true}
                    value={popupValue}
                    handleValue={handleValue}
                    editorRef={fullscreenEditorRef}
                    validation={validation}
                    disabled={disabled}
                />

                <div className="btn-confirm">
                    <Button type="default" onClick={handleSave} text="OK" />
                </div>
            </Popup>

            <div className="editor-col">
                <div className="editor-col__editor">
                    <MyEditor
                        fullscreenMode={false}
                        value={value}
                        handleValue={handleValue}
                        validation={validation}
                        height={height}
                        disabled={disabled}
                    />
                </div>

                <div className="editor-col__btn">
                    <Button onClick={handleFullscreen} icon="fullscreen" width={50} height={50} />
                    {evaluatorContext && <Test evaluate={testEvaluate} />}
                </div>
            </div>
        </>
    )
}

export default memo(MonacoEditor)

const MyEditor = memo(
    ({
        validation,
        disabled,
        fullscreenMode,
        value,
        handleValue,
        height,
        editorRef
    }: EditorProps) => {
        const evaluatorContext = useContext(EvaluatorContext)
        const context = evaluatorContext?.context

        const editorContainerRef = useRef(null)
        const monacoRef = useRef<Monaco | null>(null)

        const editorOptions = useMemo(
            () => ({
                scrollbar: {
                    horizontal: "hidden",
                    vertical: "hidden"
                },
                minimap: {
                    enabled: false
                },
                renderValidationDecorations: validation === false ? "off" : "editable",
                readOnly: disabled ?? false
            }),
            [disabled, validation]
        )

        const fullscreenEditorOptions = useMemo(
            () => ({
                minimap: {
                    enabled: false
                },
                renderValidationDecorations: validation === false ? "off" : "editable",
                readOnly: validation === false ? disabled ?? false : false
            }),
            [disabled, validation]
        )

        const handleEditorDidMount = useCallback(
            (editor: any): void => {
                editorRef.current = editor
            },
            [editorRef]
        )

        const options = fullscreenMode ? fullscreenEditorOptions : editorOptions

        const typesDefinitionSource = useMemo(() => {
            const result = [
                // `declare const readonly v: ${JSON.stringify(variablesContext?.context)};`,
                // `declare const readonly p: ${JSON.stringify(parentContext?.context)};`
                // `declare const funcObject: {`,
                // `    getOneFunc: () => ${abc};`,
                // `    getSecondFunc: (a: string, ...values: Array<number>) => number;`,
                // `};`,
                // `/** @deprecated */`,
                // `declare const readonly oldVar: any;`,
            ]

            for (var key in context) {
                result.push(
                    // `declare const readonly ${key}: ${JSON.stringify(context[key])};`
                    `declare const readonly ${key}: ${JSONUtils.stringifyWithFunctions(
                        context[key],
                        ["array"]
                    )};`
                )
            }

            return result
        }, [context])

        // tohle je bohuzel nutne takhle delat, protoze Monaco mezi vsemi editory sdili code-completion.
        const beforeMount: BeforeMount = useCallback((monaco) => {
            monacoRef.current = monaco
        }, [])

        const onMouseEnter = useCallback(() => {
            monacoRef.current?.languages.typescript.javascriptDefaults.setExtraLibs([
                { content: typesDefinitionSource.join("\n") }
            ])
        }, [typesDefinitionSource])

        return (
            <>
                <div
                    className="editor-container"
                    ref={editorContainerRef}
                    onMouseEnter={onMouseEnter}
                >
                    <MEditor
                        onChange={fullscreenMode ? undefined : handleValue}
                        onMount={fullscreenMode ? handleEditorDidMount : undefined}
                        value={value ?? ""}
                        beforeMount={beforeMount}
                        // TODO fix type
                        options={options as any}
                        loading="Editor se načítá..."
                        height={fullscreenMode ? "85vh" : height ? height + "px" : "50px"}
                        language="javascript"
                    />
                </div>
            </>
        )
    }
)

const Test = memo(({ evaluate }: TestProps) => {
    const [popupVisible, setPopupVisible] = useState(false)
    const [result, setResult] = useState("")

    const onHiding = useCallback(() => {
        setPopupVisible(false)
    }, [])

    const onEvaluate = useCallback(() => {
        setPopupVisible(true)
        setResult(JSON.stringify(evaluate(), null, "\t"))
    }, [evaluate])

    return (
        <>
            <Button text="Test" onClick={onEvaluate} />
            <Popup visible={popupVisible} onHiding={onHiding}>
                <div className="editor-container">
                    <MEditor
                        value={result}
                        loading="Editor se načítá..."
                        height="65vh"
                        language="json"
                    />
                </div>
            </Popup>
        </>
    )
})

function initMonacoEditor(
    item: unknown,
    dataField: string,
    validation?: boolean,
    height?: number,
    disabled?: boolean
): () => JSX.Element {
    return () => (
        <MonacoEditor
            item={item}
            dataField={dataField}
            validation={validation}
            height={height}
            disabled={disabled}
        />
    )
}

export { initMonacoEditor }
