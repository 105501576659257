import ReactDOM from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"

import "react-toastify/dist/ReactToastify.css"
import "./css/index.scss"
import "./fonts/Roboto/Roboto-Bold.ttf"
import "./fonts/Roboto/Roboto-Italic.ttf"
import "./fonts/Roboto/Roboto-Light.ttf"
import "./fonts/Roboto/Roboto-LightItalic.ttf"
import "./fonts/Roboto/Roboto-Regular.ttf"

import { ToastContainer } from "react-toastify"
import App from "./App"
import { unregister } from "./registerServiceWorker"
import { standardError } from "./utils/NotifyUtils"

window.addEventListener("error", (_event: ErrorEvent) => {
    standardError("Došlo k chybě. Více informací ve vývojářské konzoli - F12.", 10000)
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <Router>
        <App />
        <ToastContainer />
    </Router>
)

unregister()
