import { memo, useCallback, useContext, useMemo, useRef, useState } from "react"
import "./ComponentContainer.scss"

import CommonComponent, { ICommonComponent } from "./CommonComponent/CommonComponent"

import { ShowPopupHandle } from "../../../../administration/PopupFileUploader"
import { useHoveredStore } from "../../../../stores/HoveredStore"
import { useReportStore2 } from "../../../../stores/ReportStore2"
import { IComponent } from "../../../../types/BaseTypes"
import AdminComponent from "../../../../utils/AdminComponent"
import EditModeComponent from "../../../../utils/EditModeComponent"
import { ComponentProvider } from "../../../contexts/ComponentContext"
import { EvaluatorProvider } from "../../../contexts/EvaluatorContext"
import { FunctionsProvider } from "../../../contexts/FunctionsContext"
import { ParentProvider } from "../../../contexts/ParentContext"
import { ReportPathContext } from "../../../contexts/ReportPathContext"
import { VariablesProvider } from "../../../contexts/VariablesContext"
import ComponentCoordinatesEditor, {
    ComponentCoordinates,
    IComponentCoordinatesEditor
} from "./ComponentCoordinatesEditor/ComponentCoordinatesEditor"
import ComponentPopupEditor from "./ComponentPopupEditor/ComponentPopupEditor"

type Props = {
    component: IComponent
    coordinates?: ComponentCoordinates
}

export interface IComponentContainer {
    handleEditorClick: () => void
    setHovered: (hovered: boolean) => void
    getProps: () => Props
    getRect: () => DOMRect | undefined
    handleCoordinatesClick: () => void
    getReportPath: () => Array<string>
}

export const COMPONENT_CONTAINER_CLASS = "component-layout"

function ComponentContainer({ component, coordinates }: Props) {
    const popupVisible = useReportStore2((state) => state.popupVisible)
    const setComponentRightClicked = useReportStore2((state) => state.setComponentRightClicked)
    const hoverComponent = useHoveredStore((state) => state.hoverComponent)
    const unhoverComponent = useHoveredStore((state) => state.unhoverComponent)

    const { reportPath } = useContext(ReportPathContext)

    const [hovered, setHoveredState] = useState(false)

    const coordinatesEditorRef = useRef<IComponentCoordinatesEditor>(null)
    const commonComponentRef = useRef<ICommonComponent>(null)

    const ref = useRef<HTMLDivElement>(null)
    const popupEditorRef = useRef<ShowPopupHandle>(null)

    const self = useMemo<IComponentContainer>(() => {
        return {
            getReportPath: () => reportPath,
            setHovered: setHoveredState,
            handleEditorClick: () => {
                popupEditorRef.current?.showPopup()
            },
            getProps: () => ({ component, reportPath }),
            getRect: (): DOMRect | undefined => {
                const specialRect = commonComponentRef.current?.getSpecialRect()
                if (specialRect) {
                    return specialRect
                }
                return ref.current?.getBoundingClientRect()
            },
            handleCoordinatesClick: () => {
                coordinatesEditorRef.current?.handlePopupShow()
            }
        }
    }, [component, reportPath])

    const onClick = useCallback(
        (e: any) => {
            if (e.type === "contextmenu") {
                // console.log("Right click" + this.props.component.name)
                setComponentRightClicked(self)
            } else {
                // console.log("standardClick" + this.props.component.name)
            }
        },
        [self, setComponentRightClicked]
    )

    const onMouseEnter = useCallback(() => {
        if (!popupVisible) {
            hoverComponent(self)
        }
    }, [hoverComponent, popupVisible, self])

    const onMouseMove = useCallback(() => {
        if (!popupVisible) {
            hoverComponent(self)
        }
    }, [hoverComponent, popupVisible, self])

    const onMouseLeave = useCallback(() => {
        if (!popupVisible) {
            unhoverComponent(self)
        }
    }, [popupVisible, self, unhoverComponent])

    return (
        <ComponentProvider component={component}>
            <ParentProvider p={component}>
                <VariablesProvider componentParent={component}>
                    <EvaluatorProvider>
                        <FunctionsProvider component={component} name={component.name}>
                            <div
                                ref={ref}
                                className={COMPONENT_CONTAINER_CLASS}
                                onClick={onClick}
                                onContextMenu={onClick}
                                onMouseMove={onMouseMove}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                            >
                                <AdminComponent>
                                    <EditModeComponent>
                                        <ComponentPopupEditor ref={popupEditorRef} />
                                        {coordinates && (
                                            <ComponentCoordinatesEditor
                                                ref={coordinatesEditorRef}
                                                coordinates={coordinates}
                                            />
                                        )}
                                    </EditModeComponent>
                                </AdminComponent>
                                <CommonComponent ref={commonComponentRef} />
                                <AdminComponent>
                                    <EditModeComponent>
                                        <div
                                            className={
                                                COMPONENT_CONTAINER_CLASS +
                                                "-overlap " +
                                                (hovered
                                                    ? COMPONENT_CONTAINER_CLASS +
                                                      "-overlap--hovered"
                                                    : "")
                                            }
                                        />
                                    </EditModeComponent>
                                </AdminComponent>
                            </div>
                        </FunctionsProvider>
                    </EvaluatorProvider>
                </VariablesProvider>
            </ParentProvider>
        </ComponentProvider>
    )
}

export default memo(ComponentContainer)
