import { Form } from "devextreme-react"
import { Label, SimpleItem } from "devextreme-react/form"
import React from "react"
import { BaseParams } from "../../types/BaseTypes"
import { initMonacoEditor } from "./MonacoEditor"

type TabExpressionProps = {
    parameters: BaseParams
    helpText?: string
}

class TabExpressionData extends React.Component<TabExpressionProps, {}> {
    render() {
        return (
            <div className="component-setup-container">
                <Form formData={this.props.parameters}>
                    <SimpleItem
                        // TODO helptext
                        helpText={this.props.helpText}
                        render={initMonacoEditor(this.props.parameters, "expression")}
                    >
                        <Label text="Výraz pro získání dat" />
                    </SimpleItem>
                </Form>
            </div>
        )
    }
}

export default TabExpressionData
