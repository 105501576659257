import { memo } from "react"
import { useReport2Fields } from "./CustomHooks"

type Props = {
    children: JSX.Element | Array<JSX.Element | undefined>
}

function EditModeComponent({ children }: Props) {
    const [editMode] = useReport2Fields((s) => [s.editMode])

    if (editMode) {
        return <>{children}</>
    }
    return null
}

export default memo(EditModeComponent)
