import {
    forwardRef,
    memo,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useState
} from "react"

import { Button } from "devextreme-react"
import Popup, { ToolbarItem } from "devextreme-react/popup"

import { cloneDeep } from "lodash"
import { ShowPopupHandle } from "../../../../../administration/PopupFileUploader"
import { useReportStore } from "../../../../../stores/ReportStore"
import { useReportStore2 } from "../../../../../stores/ReportStore2"
import { IComponent } from "../../../../../types/BaseTypes"
import ComponentUtils from "../../../../../utils/ComponentUtils"
import { ComponentContext } from "../../../../contexts/ComponentContext"
import { ReportPathContext } from "../../../../contexts/ReportPathContext"
import Editor from "./Editor/Editor"

type Props = {}

const ComponentPopupEditor = forwardRef<ShowPopupHandle, Props>((props, ref) => {
    const updateComponent = useReportStore((state) => state.updateComponent)
    const setReportPopupVisible = useReportStore2((state) => state.setPopupVisible)

    const reportPath = useContext(ReportPathContext).reportPath
    const componentContext = useContext(ComponentContext)
    const component = componentContext?.component!

    const [popupVisible, setPopupVisible] = useState(false)
    const [fullscreen, setFullscreen] = useState(false)
    const [formDataComponent, setFormDataComponent] = useState<IComponent>()

    useImperativeHandle(ref, () => ({
        showPopup: () => {
            setPopupVisible(true)
            setReportPopupVisible(true)
        }
    }))

    useEffect(() => {
        const result = cloneDeep(component)
        ComponentUtils.checkUndefined(result)
        setFormDataComponent(result)
    }, [component])

    const hidePopup = useCallback(() => {
        setPopupVisible(false)
        setReportPopupVisible(false)
    }, [setReportPopupVisible])

    const handleSave = useCallback(() => {
        hidePopup()
        if (formDataComponent) {
            updateComponent(reportPath, cloneDeep(formDataComponent))
        }
    }, [formDataComponent, reportPath, updateComponent, hidePopup])

    const renderForm = useCallback(() => {
        const fullscreenButtonOptions = {
            icon: "fullscreen",
            onClick: () => setFullscreen(!fullscreen)
        }

        return (
            <form>
                <ToolbarItem
                    widget="dxButton"
                    location="after"
                    options={fullscreenButtonOptions}
                ></ToolbarItem>

                {formDataComponent && <Editor formData={formDataComponent} />}

                <Button text="Ok" type="default" stylingMode="contained" onClick={handleSave} />
            </form>
        )
    }, [formDataComponent, handleSave, fullscreen])

    return (
        <Popup
            width="auto"
            height="auto"
            showTitle={true}
            fullScreen={fullscreen}
            title={"Editace komponenty " + component?.name}
            dragEnabled={true}
            visible={popupVisible}
            onHiding={hidePopup}
            contentRender={renderForm}
        ></Popup>
    )
})

export default memo(ComponentPopupEditor)
