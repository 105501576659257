import React from "react"

import { GroupItem, Item } from "devextreme-react/form"
import ListPopupEditor from "../common/ListPopupEditor"
import { GraphParameters } from "../../components/Graph/GraphEditor"

type PaneFormProps = {
    parameters: GraphParameters
}

function PaneEditor(props: PaneFormProps) {
    return (
        <ListPopupEditor
            listOwner={props.parameters}
            listName="panes"
            itemRender={PaneInfo}
            formItemContent={(item) => (
                <GroupItem colCount={1} caption="Základní">
                    <Item
                        dataField="name"
                        helpText="Název panelu je možné uplatnit v nastavení série"
                        isRequired={true}
                    />
                </GroupItem>
            )}
        />
    )
}

export default PaneEditor

function PaneInfo(item: any) {
    return <div style={{ color: item.color, fontWeight: "bold" }}>{item.name}</div>
}
