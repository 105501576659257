// import Lottie from "react-lottie"
import { forwardRef, memo } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { useContainerVisible } from "../../utils/CustomHooks"
import "./EngineComponent.scss"
import { EngineParameters } from "./EngineEditor"

type Params = EngineParameters

type Props = ComponentProps<Params>

const EngineComponent = forwardRef<IBaseComponent, Props>(({ setContainerVisible }, ref) => {
    useContainerVisible(setContainerVisible, false)
    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationData,
    //     rendererSettings: {
    //         preserveAspectRatio: "xMidYMid slice"
    //     }
    // }

    // chybi prepsat fillLoader

    return (
        <div>Motor - není implementován</div>
        // <Lottie
        //     options={EngineComponent.defaultOptions}
        //     isStopped={false}
        //     isPaused={!this.state.active}
        //     isClickToPauseDisabled={true}
        // />
    )
})

export default memo(EngineComponent)
