import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import React, { forwardRef, memo, useImperativeHandle } from "react"
import { FunctionResponse } from "../../../types/FunctionsType"
import { FunctionProps } from "../FunctionsList"
import ModbusConnectionsSelectBox from "../ModbusConnectionsSelectBox"
import ModbusDataSource from "../ModbusDataSource"
import { IFunctionRef, TEXT_AREA_OPTIONS } from "./TestFunction"

const WriteModbus = forwardRef<IFunctionRef, FunctionProps>(({ item }, ref) => {
    useImperativeHandle(
        ref,
        () => ({
            getParamsCount: () => {
                let counter = 0
                if (item.parameters) {
                    const parameters = item.parameters as any
                    if (parameters.valueType === "?") {
                        counter++
                    }
                    if (parameters.startAddress === "?") {
                        counter++
                    }
                    if (parameters.modbusValue === "?") {
                        counter++
                    }
                }
                return counter
            },
            showCallResult: (response: FunctionResponse) => {
                return (
                    <React.Fragment>
                        <Form formData={response} colCount={2}>
                            <SimpleItem
                                editorType="dxTextArea"
                                dataField="error"
                                visible={response.error ? true : false}
                                editorOptions={TEXT_AREA_OPTIONS}
                            />
                        </Form>
                    </React.Fragment>
                )
            }
        }),
        [item.parameters]
    )

    return (
        <Form formData={item}>
            <GroupItem>
                <GroupItem>
                    <GroupItem>
                        <ModbusConnectionsSelectBox fun={item} />
                    </GroupItem>
                    <SimpleItem dataField="parameters.slaveNumber" editorType="dxNumberBox">
                        <Label text="Slave number" />
                    </SimpleItem>
                    <SimpleItem dataField="parameters.swappedWords" editorType="dxCheckBox">
                        <Label text="Prohozené wordy" />
                    </SimpleItem>
                    <SimpleItem
                        dataField="parameters.registersType"
                        editorType="dxSelectBox"
                        editorOptions={ModbusDataSource.registersTypeOptions}
                    >
                        <Label text="Typ registru" />
                    </SimpleItem>
                    <SimpleItem
                        dataField="parameters.valueType"
                        helpText="Povolené hodnoty 'BOOLEAN', 'W', 'DW', 'FLOAT' nebo '?'."
                    >
                        <Label text="Typ hodnot" />
                    </SimpleItem>
                    <SimpleItem dataField="parameters.startAddress" helpText="Je možné uvést '?'.">
                        <Label text="Počáteční adresa" />
                    </SimpleItem>
                    <SimpleItem
                        dataField="parameters.modbusValue"
                        helpText="Pro typ 'BOOLEAN' je hodnota 1 - true, 0 - false. Je možné uvést '?'."
                    >
                        <Label text="Hodnota" />
                    </SimpleItem>
                </GroupItem>
            </GroupItem>
        </Form>
    )
})

export default memo(WriteModbus)
