import React from "react"
import "../css/Loading.scss"

class Loading extends React.Component {
    render() {
        return (
            <div className="load-container">
                <p className="load-text">Visualization</p>
                <div className="lds-dual-ring"></div>
            </div>
        )
    }
}

export default Loading
