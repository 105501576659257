import React from "react"
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form"
import { IFunctionParameters } from "../../../types/FunctionsType"

type SQLConditionsProps = {
    parameters: IFunctionParameters
}

class SQLConditions extends React.Component<SQLConditionsProps, any> {
    private conditionOptions: any

    constructor(props: SQLConditionsProps) {
        super(props)

        this.conditionOptions = {
            autoResizeEnabled: true,
            minWidth: 600
        }
    }

    render() {
        const parameters = this.props.parameters

        return (
            <Form className="component-setup-container" formData={parameters}>
                <GroupItem>
                    <SimpleItem
                        dataField="condition"
                        editorType="dxTextArea"
                        helpText="Zadejte podmínky jako v SQL. Lze použít i asociace tabulek pro sloupce. Např. '(i.id = 3001 OR i.id = 3002) AND m.hodnota < 200'"
                        editorOptions={this.conditionOptions}
                    >
                        <Label text="Podmínky" />
                    </SimpleItem>
                </GroupItem>
            </Form>
        )
    }
}

export default SQLConditions
