import { History } from "history"
import { mountStoreDevtool } from "simple-zustand-devtools"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { DEVELOPMENT_MODE } from "../constants"
import { IUser } from "../types/BaseTypes"
import { DeviceSetupObject } from "../user/DeviceSetup"
import LocalStorageUtils from "../utils/LocalStorageUtils"

const STORE_NAME = "AppStore"

export type IAppStore = {
    deviceSetup: DeviceSetupObject
    currentUser?: IUser
    setCurrentUser: (currentUser?: IUser) => void

    groupId: number
    setGroupId: (groupId: number) => void

    mainWidth?: number
    reportWidth?: number
    setMainWidth: (mainWidth?: number) => void
    setReportWidth: (reportWidth?: number) => void

    history?: History
    setHistory: (history: History) => void
    changePath: (path: string) => void

    mainIsScrollable: boolean
    setMainIsScrollable: (mainIsScrollable: boolean) => void
}

export const useAppStore = create<IAppStore, [["zustand/devtools", never]]>(
    devtools(
        (set, get) => ({
            deviceSetup: LocalStorageUtils.loadSetupObject(),
            currentUser: undefined,
            setCurrentUser: (currentUser?: IUser) =>
                set(
                    (state) => {
                        ;(window as any).user = currentUser
                        return { currentUser }
                    },
                    false,
                    "setCurrentUser"
                ),

            groupId: -1,
            setGroupId: (groupId: number) => set((state) => ({ groupId }), false, "setGroupId"),

            setMainWidth: (mainWidth?: number) =>
                set((state) => ({ mainWidth }), false, "setMainWidth"),
            setReportWidth: (reportWidth?: number) =>
                set((state) => ({ reportWidth }), false, "setReportWidth"),

            history: undefined,
            setHistory: (history: History) => set((state) => ({ history }), false, "setHistory"),
            changePath: (path: string) => {
                get().history?.push(path)
            },

            mainIsScrollable: true,
            setMainIsScrollable: (mainIsScrollable: boolean) =>
                set((state) => ({ mainIsScrollable }), false, "setMainIsScrollable")
        }),
        { enabled: DEVELOPMENT_MODE, name: STORE_NAME }
    )
)

if (DEVELOPMENT_MODE) {
    mountStoreDevtool(STORE_NAME, useAppStore)
}
