import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import AxisEditor from "../../componentsSetup/chart/AxisEditor"
import PaneEditor from "../../componentsSetup/chart/PaneEditor"
import SerieEditor from "../../componentsSetup/chart/SerieEditor"
import TabExpressionData from "../../componentsSetup/common/TabExpressionData"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export type GraphPane = {}

export type GraphAxis = {}

export type GraphSerieLabel = {
    visible: boolean
}

export type GraphSerieAggregation = {
    enabled: boolean
    method: string
}

export type GraphSerie = {
    // TODO pane a axis by meli byt referencovany v seriich dle ID
    pane: string
    axis: string
    type: string
    color: string
    width: number
    label: GraphSerieLabel
    aggregation: GraphSerieAggregation
}

export type GraphInterval = {
    interval: string
    intervalNum: number
}

export type GraphParameters = BaseParams & {
    expression: string
    panes: Array<GraphPane>
    axises: Array<GraphAxis>
    series: Array<GraphSerie>
    scrollBar: boolean
    userDefinedRange: boolean
    aggregation: GraphInterval
}

export const getGraphBasicConfigurations = () => {
    return [{}]
}

function GraphEditor({ parameters }: EditorProps<GraphParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Datový zdroj">
                <TabExpressionData
                    parameters={parameters}
                    helpText="Data musí mít tři sloupce. 
                        Hodnoty prvního budou zobrazeny na ose (x), druhého na ose (y) a třetí sloupec rozlišuje jednotlivé série."
                />
            </Item>
            <Item title="Série">
                <SerieEditor parameters={parameters} />
            </Item>
            <Item title="Osy">
                <AxisEditor parameters={parameters} />
            </Item>
            <Item title="Panely">
                <PaneEditor parameters={parameters} />
            </Item>
            <Item title="Ostatní">
                <TabOthers parameters={parameters} />
            </Item>
        </TabPanel>
    )
}

export default memo(GraphEditor)

type TabOthersProps = {
    parameters: GraphParameters
}

const types = [
    "milliseconds",
    "seconds",
    "minutes",
    "hours",
    "days",
    "weeks",
    "months",
    "quarters",
    "years"
]

const intervalOptions = {
    items: types
}

function TabOthers({ parameters }: TabOthersProps) {
    return (
        <div className="component-setup-container">
            <Form formData={parameters}>
                <GroupItem>
                    <SimpleItem dataField="scrollBar" editorType="dxSwitch">
                        <Label text="Zoom & Scroll" />
                    </SimpleItem>
                </GroupItem>
                <GroupItem colCount={1} caption="Agregace">
                    <SimpleItem
                        dataField="aggregation.interval"
                        editorType="dxSelectBox"
                        editorOptions={intervalOptions}
                    />
                    <SimpleItem dataField="aggregation.intervalNum" editorType="dxNumberBox" />
                </GroupItem>
            </Form>
        </div>
    )
}
