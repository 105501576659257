import { API_BASE_URL } from "../../constants"
import { IReport } from "../../types/BaseTypes"
import { accessTokenExists, request } from "../APIUtils"

export function getReports(): Promise<Array<IReport>> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/reports",
            method: "GET"
        })
    })
}

export function getReport(id: number): Promise<IReport> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/report/" + id,
            method: "GET"
        })
    })
}

export function editReport(report: IReport): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/reports",
            method: "PUT",
            body: JSON.stringify(report)
        })
    })
}

export function editReportName(report: IReport): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/reports/name",
            method: "PUT",
            body: JSON.stringify(report)
        })
    })
}

export function editReportsOrder(reports: Array<IReport>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/reports/order",
            method: "POST",
            body: JSON.stringify(reports)
        })
    })
}

export function createReport(report: Object, groupId: string): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/reports/" + groupId,
            method: "POST",
            body: JSON.stringify(report)
        })
    })
}

export function deleteReport(id: number): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/reports/" + id,
            method: "DELETE"
        })
    })
}

export function deleteReports(reports: Array<any>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/reports/",
            method: "DELETE",
            body: JSON.stringify(reports)
        })
    })
}

export function createMultipleReports(reports: Array<IReport>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/reports",
            method: "POST",
            body: JSON.stringify(reports)
        })
    })
}
