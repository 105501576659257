import { LinearGauge } from "devextreme-react"
import CircularGauge, {
    Export,
    Font,
    Label,
    Range,
    RangeContainer,
    Scale,
    Size,
    Title
} from "devextreme-react/circular-gauge"
import { Geometry } from "devextreme-react/linear-gauge"
import { forwardRef, memo, useCallback, useContext } from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { useExpression } from "../../utils/CustomHooks"
import "./GaugeComponent.scss"
import { GaugeParameters, GaugeType } from "./GaugeEditor"

type Params = GaugeParameters

type Props = ComponentProps<Params>

const GaugeComponent = forwardRef<IBaseComponent, Props>(
    (
        {
            expression,
            type,
            realSize,
            startValue,
            endValue,
            tickInterval,
            ranges,
            valueType,
            orientation,
            export: exp
        },
        ref
    ) => {
        const component = useContext(ComponentContext)?.component!

        const [value] = useExpression(expression, 0)

        const customizeText = useCallback(
            ({ valueText }: any) => {
                return `${valueText} ${valueType}`
            },
            [valueType]
        )

        if (type === GaugeType.circular) {
            return (
                <CircularGauge id="gauge" value={value}>
                    <Size width={realSize.width} height={realSize.height} />
                    <Scale startValue={startValue} endValue={endValue} tickInterval={tickInterval}>
                        <Label customizeText={customizeText} />
                    </Scale>
                    <RangeContainer>
                        {ranges?.map((r: any, index: number) => (
                            <Range
                                key={index}
                                startValue={r.startValue}
                                endValue={r.endValue}
                                color={r.color}
                            />
                        ))}
                    </RangeContainer>
                    <Export enabled={exp} />
                    <Title text={component.name}>
                        <Font size={28} />
                    </Title>
                </CircularGauge>
            )
        } else {
            return (
                <LinearGauge id="gauge" value={value}>
                    <Size width={realSize.width} height={realSize.height} />
                    <Geometry orientation={orientation} />
                    <Scale startValue={startValue} endValue={endValue} tickInterval={tickInterval}>
                        <Label customizeText={customizeText} />
                    </Scale>
                    <RangeContainer>
                        {ranges?.map((r: any, index: number) => (
                            <Range
                                key={index}
                                startValue={r.startValue}
                                endValue={r.endValue}
                                color={r.color}
                            />
                        ))}
                    </RangeContainer>
                    <Export enabled={exp} />
                    <Title text={component.name}>
                        <Font size={28} />
                    </Title>
                </LinearGauge>
            )
        }
    }
)

export default memo(GaugeComponent)
