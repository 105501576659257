import { memo } from "react"

const style = getComputedStyle(document.documentElement)
export const HEADER_HEIGHT = style.getPropertyValue("--header-height")
export const NAV_BAR_WIDTH = style.getPropertyValue("--nav-bar-width")

export const BLUE_ORIGIN = style.getPropertyValue("--blue-origin")
export const BLUE = style.getPropertyValue("--blue")
export const BLUE_SECOND = style.getPropertyValue("--blue-second")
export const BLUE_LIGHT = style.getPropertyValue("--blue-light")

export const BROWN_ORIGIN = style.getPropertyValue("--brown-origin")
export const BROWN = style.getPropertyValue("--brown")
export const BROWN_SECOND = style.getPropertyValue("--brown-second")
export const BROWN_LIGHT = style.getPropertyValue("--brown-light")

export const YELLOW_ORIGIN = style.getPropertyValue("--yellow-origin")
export const YELLOW = style.getPropertyValue("--yellow")
export const YELLOW_SECOND = style.getPropertyValue("--yellow-second")
export const YELLOW_LIGHT = style.getPropertyValue("--yellow-light")

export const WHITE_ORIGIN = style.getPropertyValue("--white-origin")
export const WHITE = style.getPropertyValue("--white")
export const WHITE_SECOND = style.getPropertyValue("--white-second")
export const WHITE_LIGHT = style.getPropertyValue("--white-light")

export const RED_ORIGIN = style.getPropertyValue("--red-origin")
export const RED = style.getPropertyValue("--red")
export const RED_SECOND = style.getPropertyValue("--red-second")
export const RED_LIGHT = style.getPropertyValue("--red-light")

export const GREEN_ORIGIN = style.getPropertyValue("--green-origin")
export const GREEN = style.getPropertyValue("--green")
export const GREEN_SECOND = style.getPropertyValue("--green-second")
export const GREEN_LIGHT = style.getPropertyValue("--green-light")

//v souboru NavigationSidebar.scss musí být stejná hodnota max width jako tady
export const NAV_BAR_FULLSCREEN_WIDTH = 600

const ReactCSSVariables = () => {
    return null
}

export default memo(ReactCSSVariables)
