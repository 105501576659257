import { RefObject } from "react"

import DataGrid, {
    Column,
    Editing,
    Form,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"

import { Popup, Form as SForm } from "devextreme-react"
import { ButtonItem, Item, Label, SimpleItem } from "devextreme-react/form"
import { cloneDeep } from "lodash"
import { IDBConnection, IGroup, IModbusConnection, IReport } from "../types/BaseTypes"
import { createMultipleReports, getReports } from "../utils/API/APIReports"
import { gqlRead } from "../utils/APIUtils"
import { standardError, standardSuccess } from "../utils/NotifyUtils"
import ReportUtils from "../utils/ReportUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"

type State = CollectionState<IReport> & {
    groups: Array<IGroup>
    dbConnections: Array<IDBConnection>
    modbusConnections: Array<IModbusConnection>
    popupVisible: boolean
}

class AdministrationReports extends BaseCollection<IReport, CollectionProps, State> {
    private deepCopyData: { groupId: number; dbConnectionId: number; modbusConnectionId: number }

    private dbConnectionsOptions: any
    private modbusConnectionsOptions: any

    constructor(props: CollectionProps) {
        super(props, {
            entityName: "report",
            subEntitiesToIds: ["group", "users", "components", "functions", "variables"],
            copyButton: true,
            deepCopyButton: true,
            title: "Reporty"
        })
        this.state = {
            ...this.state,
            groups: [],
            dbConnections: [],
            modbusConnections: [],
            popupVisible: false
        }
        this.deepCopyData = { groupId: -1, dbConnectionId: -1, modbusConnectionId: -1 }
    }

    protected getEntities = (): Promise<Array<IGroup>> => {
        gqlRead({
            groups: {
                id: true,
                name: true
            },
            dbConnections: {
                id: true,
                name: true,
                group: {
                    id: true
                }
            },
            modbusConnections: {
                id: true,
                name: true,
                group: {
                    id: true
                }
            }
        }).then((r) => {
            this.setState({
                groups: r.groups,
                dbConnections: r.dbConnections,
                modbusConnections: r.modbusConnections
            })
        })

        return getReports()
    }

    private onGroupChange = (item: any) => {
        const groupId = item.value

        this.dbConnectionsOptions = {
            ...BaseCollection.SELECT_BOX_OPTIONS,
            dataSource: this.state.dbConnections?.filter((dbc) => dbc.group?.id === groupId)
        }
        this.modbusConnectionsOptions = {
            ...BaseCollection.SELECT_BOX_OPTIONS,
            dataSource: this.state.modbusConnections?.filter((mc) => mc.group?.id === groupId)
        }

        this.forceUpdate()
    }

    handleDeepCopy = () => {
        if (this.state.selectedData.length > 0) {
            this.setState({ popupVisible: true })
        } else {
            this.deepCopyWarning()
        }
    }

    onCopy = (values: Array<IReport>, oldIds?: Array<number>): Promise<Array<IReport>> => {
        values.forEach((v) => {
            delete v.createdAt
            delete v.updatedAt
        })
        return super.onCopy(values, oldIds)
    }

    hidePopup = () => {
        this.setState({ popupVisible: false })
    }

    handleCopy = () => {
        const { groupId, dbConnectionId, modbusConnectionId } = this.deepCopyData
        const newReports: any = cloneDeep(
            this.state.selectedData.map((s) =>
                this.state.originalEntities.find((oe) => oe.id === s.id)
            )
        )
        ReportUtils.deepCopyReports(newReports, groupId, dbConnectionId, modbusConnectionId)
        ReportUtils.prepareReportsToSave(newReports)
        createMultipleReports(newReports)
            .then((response) => {
                standardSuccess("Reporty byly zkopírovány.")
                this.refreshTable()
            })
            .catch(standardError)
        this.hidePopup()
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <>
                <Popup
                    width="auto"
                    height="auto"
                    title="Hluboká kopie"
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopup}
                >
                    <form>
                        <SForm formData={this.deepCopyData}>
                            <SimpleItem
                                editorType="dxSelectBox"
                                dataField="groupId"
                                editorOptions={{
                                    ...BaseCollection.SELECT_BOX_OPTIONS,
                                    dataSource: this.state.groups,
                                    onValueChanged: this.onGroupChange
                                }}
                            >
                                <Label text="Skupina"></Label>
                            </SimpleItem>
                            <SimpleItem
                                editorType="dxSelectBox"
                                dataField="dbConnectionId"
                                editorOptions={this.dbConnectionsOptions}
                            >
                                <Label text="DB Spojení"></Label>
                            </SimpleItem>
                            <SimpleItem
                                editorType="dxSelectBox"
                                dataField="modbusConnectionId"
                                editorOptions={this.modbusConnectionsOptions}
                            >
                                <Label text="Modbus Spojení"></Label>
                            </SimpleItem>
                            <ButtonItem
                                buttonOptions={{
                                    text: "Zkopírovat",
                                    onClick: this.handleCopy,
                                    type: "default"
                                }}
                            ></ButtonItem>
                        </SForm>
                    </form>
                </Popup>
                <DataGrid
                    ref={ref}
                    dataSource={dataSource}
                    showBorders={true}
                    columnHidingEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}
                    height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
                >
                    <Paging enabled={false} />
                    <Pager visible={true} displayMode="full" showInfo={true} />
                    <SearchPanel visible={true} />
                    <Selection
                        mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="onClick"
                    />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={false} />

                    <Editing
                        mode="form"
                        useIcons={true}
                        allowDeleting={true}
                        allowAdding={true}
                        allowUpdating={true}
                    >
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="name" />
                            </Item>
                        </Form>
                    </Editing>

                    <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                    <Column dataField="name" caption="Název" />
                    <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                    <Column
                        customizeText={(cellInfo: any) =>
                            this.fromNames(cellInfo, this.state.groups)
                        }
                        dataField="group"
                        caption="Skupina"
                        groupIndex={0}
                    ></Column>
                </DataGrid>
            </>
        )
    }
}

export default AdministrationReports
