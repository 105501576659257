import { RefObject } from "react"

import DataGrid, {
    Column,
    Editing,
    Form,
    Pager,
    Paging,
    RequiredRule,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"

import { IRole, IUser } from "../types/BaseTypes"
import { gqlRead } from "../utils/APIUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"

type RolesState = CollectionState<IUser> & {}

class Roles extends BaseCollection<IRole, CollectionProps, RolesState> {
    constructor(props: CollectionProps) {
        super(props, {
            entityName: "role",
            // subEntitiesToIds: ["groups", "reports"],
            title: "Uživatelské role"
        })
        this.state = {
            ...this.state
        }
    }

    protected getEntities = (): Promise<Array<IRole>> => {
        return gqlRead({
            roles: {
                id: true,
                name: true
            }
        }).then((r) => {
            return r.roles
        })
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <DataGrid
                ref={ref}
                dataSource={dataSource}
                showBorders={true}
                columnHidingEnabled={true}
                onEditorPreparing={this.onEditorPreparing}
                onToolbarPreparing={this.onToolbarPreparing}
                onSelectionChanged={this.onSelectionChanged}
                height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
            >
                <Paging enabled={false} />
                <Pager visible={true} displayMode="full" showInfo={true} />
                <SearchPanel visible={true} />
                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
                <Editing
                    mode="form"
                    useIcons={true}
                    allowDeleting={true}
                    allowAdding={true}
                    allowUpdating={true}
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item itemType="group" caption="Název">
                                <Item dataField="name" />
                            </Item>
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                <Column
                    dataField="id"
                    caption="ID"
                    allowEditing={false}
                    visible={false}
                    hidingPriority={0}
                />
                <Column dataField="name" caption="Název" hidingPriority={5}>
                    <RequiredRule />
                </Column>
            </DataGrid>
        )
    }
}

export default Roles
