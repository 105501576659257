import { ContextMenu } from "devextreme-react"
import { memo, useCallback, useMemo } from "react"
import ComponentCopyManager from "../../managers/ComponentCopyManager"
import { useReportStore } from "../../stores/ReportStore"
import { useReportStore2 } from "../../stores/ReportStore2"
import { useSelectionStore } from "../../stores/SelectionStore"
import { useReportFields } from "../../utils/CustomHooks"

type MenuProps = {
    selector: string
}

enum ContextMenuOption {
    EDIT,
    COPY_MARKED,
    FOREGROUND,
    BACKGROUND
}
const dataSource = [
    { text: "Editace", id: ContextMenuOption.EDIT },
    { text: "Zkopírovat označené", id: ContextMenuOption.COPY_MARKED },
    { text: "Do popředí", id: ContextMenuOption.FOREGROUND },
    { text: "Do pozadí", id: ContextMenuOption.BACKGROUND }
]

export let contextMenuShown = false

function ComponentContextMenu(props: MenuProps) {
    const [components, flow] = useReportFields((r) => [r.components, r.parameters.flow])
    const toTheForeground = useReportStore((state) => state.toTheForeground)
    const toTheBackground = useReportStore((state) => state.toTheBackground)

    const componentRightClicked = useReportStore2((state) => state.componentRightClicked)
    const componentGuid = useMemo(() => componentRightClicked?.getProps().component.guid, [
        componentRightClicked
    ])

    const selectionParams = useSelectionStore((state) => state.selectionParams)

    const copyComponents = useCallback(() => {
        if (components && flow) {
            ComponentCopyManager.getInstance().onCopy(components, selectionParams, flow)
        }
    }, [components, flow, selectionParams])

    const itemClick = useCallback(
        (e: any) => {
            const itemData = e.itemData
            if (!itemData.items) {
                switch (itemData.id) {
                    case ContextMenuOption.EDIT:
                        componentRightClicked?.handleEditorClick()
                        break
                    case ContextMenuOption.COPY_MARKED:
                        copyComponents()
                        break
                    case ContextMenuOption.FOREGROUND:
                        if (componentGuid) {
                            toTheForeground([componentGuid])
                        }
                        break
                    case ContextMenuOption.BACKGROUND:
                        if (componentGuid) {
                            toTheBackground([componentGuid])
                        }
                        break
                }
            }
        },
        [componentRightClicked, copyComponents, toTheBackground, toTheForeground, componentGuid]
    )

    // TODO - nefunguje, protoze pak nejdou kopirovat vyrazy atp.
    // useKeyClick(
    //     KEY_MAP.COPY,
    //     useCallback(() => copyComponents(), [copyComponents])
    // )

    const onShowing = useCallback(() => {
        contextMenuShown = true
    }, [])

    const onHiding = useCallback(() => {
        contextMenuShown = false
    }, [])

    return (
        <ContextMenu
            dataSource={dataSource}
            width={200}
            target={props.selector}
            onItemClick={itemClick}
            onShowing={onShowing}
            onHiding={onHiding}
        />
    )
}

export default memo(ComponentContextMenu)
