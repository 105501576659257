import { createContext, memo, useMemo } from "react"

export interface IReportIdContext {
    reportId: number
}

export const ReportIdContext = createContext<IReportIdContext | undefined>(undefined)

type Props = {
    children: JSX.Element
    reportId: number
}

export const ReportIdProvider = memo(({ children, reportId }: Props) => {
    const context = useMemo(
        () => ({
            reportId
        }),
        [reportId]
    )

    return <ReportIdContext.Provider value={context}>{children}</ReportIdContext.Provider>
})
