import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { useCallback, useContext, useMemo, useState } from "react"
import { $enum } from "ts-enum-util"
import { FunctionsContext } from "../../report/contexts/FunctionsContext"
import { VariablesContext } from "../../report/contexts/VariablesContext"
import { useAppStore } from "../../stores/AppStore"
import { useReportStore2 } from "../../stores/ReportStore2"
import { Action, ActionEnum, ActionTypes as actionTypes } from "../../types/ComponentTypes"
import { DEFAULT_TOAST_DURATION, ToastType } from "../../utils/NotifyUtils"
import ListPopupEditor from "./ListPopupEditor"
import { initMonacoEditor } from "./MonacoEditor"
import OutputVariable from "./OutputVariable"
import ParametersEditor from "./ParametersEditor"

type Props = {
    listOwner: any
    listName: string
    expressionHelpText?: string
}

export default function ActionsTriggerEditor({ expressionHelpText, listOwner, listName }: Props) {
    const currentUser = useAppStore((state) => state.currentUser)
    const reports = useReportStore2((state) => state.reports)

    const [keyForm, setKeyForm] = useState(0)

    const variablesContext = useContext(VariablesContext)
    const functionsContext = useContext(FunctionsContext)

    const forceUpdate = useCallback(() => setKeyForm(keyForm + 1), [keyForm])

    const actionTypeOptions = useMemo(
        () => ({
            items: actionTypes,
            displayExpr: "name",
            valueExpr: "type",
            onValueChanged: forceUpdate
        }),
        [forceUpdate]
    )

    const functionsOptions = useMemo(
        () => ({
            items: functionsContext?.getAvailableFunctions(),
            displayExpr: "name",
            valueExpr: "guid"
        }),
        [functionsContext]
    )

    const reportsOptions = useMemo(
        () => ({
            items: reports,
            displayExpr: "name",
            valueExpr: "id"
        }),
        [reports]
    )

    const toastOptions = useMemo(
        () => ({
            items: $enum(ToastType).getValues()
        }),
        []
    )

    const actionRender = useCallback(
        (item: Action) => {
            item.toastDuration = item.toastDuration ?? DEFAULT_TOAST_DURATION / 1000
            return (
                <GroupItem>
                    <SimpleItem
                        editorType="dxSelectBox"
                        dataField="type"
                        editorOptions={actionTypeOptions}
                    >
                        <Label text="Typ akce" />
                    </SimpleItem>
                    <GroupItem key={keyForm}>
                        <GroupItem visible={item.type === ActionEnum.FUNCTION}>
                            <SimpleItem
                                dataField="functionGuid"
                                editorType="dxSelectBox"
                                editorOptions={functionsOptions}
                            >
                                <Label text="Spustit funkci" />
                            </SimpleItem>
                            <GroupItem caption="Parametry funkce">
                                <ParametersEditor
                                    listOwner={item}
                                    expressionHelpText={expressionHelpText}
                                />
                            </GroupItem>
                        </GroupItem>
                        <GroupItem visible={item.type === ActionEnum.VARIABLE}>
                            <GroupItem>
                                <OutputVariable formData={item} dataField="outputVariable" />
                            </GroupItem>
                            <SimpleItem
                                render={initMonacoEditor(item, "expression")}
                                helpText={expressionHelpText}
                            >
                                <Label text="Výraz" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem visible={item.type === ActionEnum.REPORT}>
                            <SimpleItem
                                dataField="reportId"
                                editorType="dxSelectBox"
                                editorOptions={reportsOptions}
                            >
                                <Label text="Report" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem visible={item.type === ActionEnum.TOAST}>
                            <SimpleItem
                                dataField="toastType"
                                editorType="dxSelectBox"
                                editorOptions={toastOptions}
                            >
                                <Label text="Typ zprávy" />
                            </SimpleItem>
                            <SimpleItem
                                render={initMonacoEditor(item, "toastMessageExpression")}
                                helpText={expressionHelpText}
                            >
                                <Label text="Zpráva" />
                            </SimpleItem>
                            <SimpleItem dataField="toastDuration" helpText={expressionHelpText}>
                                <Label text="Doba zobrazení [s]" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem visible={item.type === ActionEnum.REPORT_STATE}>
                            <SimpleItem
                                render={initMonacoEditor(item, "expression")}
                                helpText={`Výsledek výrazu musí pole o dvou prvcích, kde první prvek je id reportu a druhý je řetězec "normal", "warning", nebo "error". 
                            Např.: [3, "warning"]
                            ${expressionHelpText ?? ""}`}
                            >
                                <Label text="Výraz" />
                            </SimpleItem>
                            <GroupItem>
                                <div>Reporty k dispozici:</div>
                                {currentUser?.groups?.map((g, i) => (
                                    <div key={i}>{`${g.name}: ${g.reports?.map(
                                        (r) => `[${r.id} ${r.name}]`
                                    )}`}</div>
                                ))}
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                </GroupItem>
            )
        },
        [
            actionTypeOptions,
            currentUser?.groups,
            expressionHelpText,
            functionsOptions,
            reportsOptions,
            toastOptions,
            keyForm
        ]
    )

    const actionItemRender = useCallback(
        (item: Action) => {
            let name
            if (item.type === ActionEnum.FUNCTION) {
                name = functionsContext
                    ?.getAvailableFunctions()
                    .find((f) => f.guid === item.functionGuid)?.name
            } else if (item.type === ActionEnum.VARIABLE) {
                name = variablesContext
                    ?.getAvailableVariables()
                    .find((v) => v.guid === item.outputVariable)?.name
            } else if (item.type === ActionEnum.TOAST) {
                name = item.toastMessageExpression
            } else {
                name = reports.find((v) => v.id === item.reportId)?.name
            }

            return <div>{name ?? item.type}</div>
        },
        [variablesContext, functionsContext, reports]
    )

    return (
        <ListPopupEditor
            listOwner={listOwner}
            listName={listName}
            itemRender={actionItemRender}
            formItemContent={actionRender}
        />
    )
}
