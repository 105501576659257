import { Form } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import ComponentsList from "../../componentsSetup/common/ComponentsList"
import FunctionsList from "../../componentsSetup/common/FunctionsList"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import VariablesList from "../../componentsSetup/common/VariablesList"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import {
    GridParameters,
    GRID_COMPACT_TYPES,
    GRID_COMPACT_TYPES_OPTIONS,
    GRID_NUM_OF_COLUMNS_OPTIONS,
    GRID_ROW_SIZES,
    GRID_ROW_SIZES_OPTIONS
} from "../Grid/GridEditor"
import { ComponentTextSize, TextSizeSettings } from "../Text/TextEditor"

export type PopupParameters = BaseParams &
    GridParameters & {
        fullscreen: boolean
        maxWidth: number
        maxHeight: number
        buttonTextExpression: string
        buttonEnabledExpression: string
        fontSize: ComponentTextSize
        titleExpression: string
    }

export const getPopupBasicConfigurations = () => {
    return [{ buttonTextExpression: '"open"' }]
}

function PopupEditor({ parameters, formData }: EditorProps<PopupParameters>) {
    parameters.editMode = false

    parameters.numOfColumns = parameters.numOfColumns ?? 10
    parameters.rowSize = parameters.rowSize ?? GRID_ROW_SIZES[0]
    parameters.compactType = parameters.compactType ?? GRID_COMPACT_TYPES[0]

    return (
        <TabPanel animationEnabled={true} swipeEnabled={false}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <SimpleItem dataField="fullscreen" editorType="dxCheckBox" />
                        <GroupItem colCount={2}>
                            <SimpleItem
                                dataField="maxWidth"
                                editorType="dxNumberBox"
                                helpText="v pixelech"
                            >
                                <Label text="Max. šířka" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="maxHeight"
                                editorType="dxNumberBox"
                                helpText="v pixelech"
                            >
                                <Label text="Max. výška" />
                            </SimpleItem>
                        </GroupItem>
                        <SimpleItem render={initMonacoEditor(parameters, "titleExpression")}>
                            <Label text="Výraz titulku popupu" />
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
            <Item title="Tlačítko">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <TextSizeSettings parameters={parameters} />
                        </GroupItem>
                        <SimpleItem render={initMonacoEditor(parameters, "buttonTextExpression")}>
                            <Label text="Výraz textu tlačítka" />
                        </SimpleItem>
                        <SimpleItem
                            render={initMonacoEditor(parameters, "buttonEnabledExpression")}
                        >
                            <Label text="Výraz aktivnosti" />
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
            <Item title="Komponenty">
                <div className="component-setup-container">
                    <Form formData={parameters} colCount={2}>
                        <GroupItem>
                            <ComponentsList component={formData} />
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem
                                dataField="compactType"
                                editorOptions={GRID_COMPACT_TYPES_OPTIONS}
                                editorType="dxSelectBox"
                            >
                                <Label text="Setřesení" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="rowSize"
                                editorOptions={GRID_ROW_SIZES_OPTIONS}
                                editorType="dxSelectBox"
                            >
                                <Label text="Výška řádku" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="numOfColumns"
                                editorOptions={GRID_NUM_OF_COLUMNS_OPTIONS}
                                editorType="dxSlider"
                            >
                                <Label text="Počet sloupců" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="Funkce">
                <FunctionsList listOwner={formData} />
            </Item>
            <Item title="Proměnné">
                <VariablesList listOwner={formData} />
            </Item>
        </TabPanel>
    )
}

export default memo(PopupEditor)
