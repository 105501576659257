import { isEqual } from "lodash"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
    GetVariableParams,
    SetVariableParams,
    VariablesContext
} from "../report/contexts/VariablesContext"
import { IVariable } from "../types/BaseTypes"
import EvalUtils from "../utils/EvalUtils"
import { standardWarning } from "../utils/NotifyUtils"

export const useVariables = (
    variableDefs?: Array<IVariable>
): [
    (name: string, data: any, variableNotFound?: () => void) => void,
    (params: SetVariableParams) => void,
    (params: GetVariableParams) => Value,
    Record<string, any>
] => {
    const parentVariablesContext = useContext(VariablesContext)

    const [variables, setVariables] = useState<Array<Variable> | undefined>([])

    useEffect(() => {
        setVariables((oldVariables) => {
            const newVariables = variableDefs?.map((def) => new Variable(def))

            newVariables?.forEach((v) => {
                const oldVar = oldVariables?.find((ov) => ov.definition.guid === v.definition.guid)
                if (oldVar) {
                    v.setValue(oldVar.getValue())
                } else {
                    const { initialValue, name, id } = v.definition

                    if (typeof initialValue === "string") {
                        try {
                            v.setValue(EvalUtils.eval(EvalUtils.correctExpression(initialValue)))
                        } catch (error) {
                            standardWarning(
                                "Nepodařilo se inicializovat proměnnou: [" +
                                    name +
                                    " " +
                                    id +
                                    "]: " +
                                    error
                            )
                        }
                    }
                }
            })

            return newVariables
        })
    }, [variableDefs])

    const setVariableValue = useCallback(
        (variable: Variable, data: any) => {
            if (data !== undefined && !isEqual(variable.getValue(), data)) {
                variable.setValue(data)

                if (variables) {
                    setVariables([...variables])
                }
            }
        },
        [variables]
    )

    const setByGuid = useCallback(
        ({ guid, data, variableNotFound }: SetVariableParams) => {
            if (variables) {
                const variable = variables.find((v) => v.definition.guid === guid)

                if (variable) {
                    setVariableValue(variable, data)
                } else {
                    variableNotFound?.()
                }
            }
        },
        [variables, setVariableValue]
    )

    const setByName = useCallback(
        (name: string, data: any) => {
            if (variables) {
                const variable = variables.find((v) => v.definition.name === name)

                if (variable) {
                    setVariableValue(variable, data)
                } else {
                    parentVariablesContext?.setVariableByName(name, data)
                }
            }
        },
        [variables, setVariableValue, parentVariablesContext]
    )

    const context = useMemo(() => {
        const context: Record<string, any> = {}

        variables?.forEach((variable: Variable) => {
            const { name, guid } = variable.definition

            if (name && guid) {
                context[name] = variable.getValue()
            }
        })

        context.setVariableByName = setByName

        return context
    }, [variables, setByName])

    const getByGuid = useCallback(
        ({ guid, variableNotFound }: GetVariableParams) => {
            const variable = variables?.find((v) => v.definition.guid === guid)
            if (variable) {
                return variable.getValue()
            }
            return variableNotFound?.()
        },
        [variables]
    )

    return useMemo(() => [setByName, setByGuid, getByGuid, context], [
        setByName,
        setByGuid,
        getByGuid,
        context
    ])
}

type Value = string | number | boolean | null

export class Variable {
    readonly definition: IVariable
    private value?: Value

    constructor(definition: IVariable) {
        this.definition = definition
    }

    public setValue = (value: any) => {
        this.value = value
    }

    public getValue = () => {
        return this.value
    }
}
