import { Button, Popup, ScrollView } from "devextreme-react"
import {
    forwardRef,
    memo,
    useCallback,
    useContext,
    useImperativeHandle,
    useRef,
    useState
} from "react"
import ReactGridLayout, { Layout } from "react-grid-layout"
import ReactResizeDetector from "react-resize-detector"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import ComponentContainer from "../../report/Schema/Layer/ComponentContainer/ComponentContainer"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { ReportPathContext, ReportPathProvider } from "../../report/contexts/ReportPathContext"
import { useAppStore } from "../../stores/AppStore"
import { useReportStore } from "../../stores/ReportStore"
import {
    useContainerVisible,
    useCoordinates,
    useExpression,
    useReport2Fields,
    useReportId
} from "../../utils/CustomHooks"
import "./PopupComponent.scss"
import { PopupParameters } from "./PopupEditor"

type Params = PopupParameters

type Props = ComponentProps<Params>

const PopupComponent = forwardRef<IBaseComponent, Props>(
    (
        {
            layout,
            buttonTextExpression,
            titleExpression,
            buttonEnabledExpression,
            maxHeight,
            maxWidth,
            fullscreen,
            rowSize,
            numOfColumns,
            compactType,
            fontSize,
            setContainerVisible
        },
        ref
    ) => {
        const isAdmin = useAppStore((state) => state.currentUser!.admin)
        const reportId = useReportId()
        const setComponentLayout = useReportStore((state) => state.setComponentLayout)
        const [editMode] = useReport2Fields((s) => [s.editMode])

        const reportPathContext = useContext(ReportPathContext)
        const reportPath = reportPathContext.reportPath
        const component = useContext(ComponentContext)?.component!
        const guid = component.guid
        useContainerVisible(setContainerVisible, false)

        const children = component.components
        const [visible, setVisible] = useState(false)
        const [width, setWidth] = useState(0)
        const coordinates = useCoordinates(layout, children)
        const gridRef = useRef<HTMLDivElement>(null)

        const [buttonText] = useExpression(buttonTextExpression, "")
        const [popupTitle] = useExpression(titleExpression, "")
        const [buttonEnabled] = useExpression(buttonEnabledExpression, true)

        useImperativeHandle(
            ref,
            () => ({
                getSpecialRect: () => {
                    if (!visible) {
                        return undefined
                    }
                    return gridRef.current?.getBoundingClientRect()
                }
            }),
            [visible]
        )

        const showPopup = useCallback(() => {
            setVisible(true)
        }, [])

        const onHiding = useCallback(() => {
            setVisible(false)
        }, [])

        const handleResize = useCallback((width?: number) => {
            if (width && width !== 0) {
                setWidth(width)
            }
        }, [])

        const onLayoutChange = useCallback(
            (layout: Layout[]) => {
                setComponentLayout([...reportPath, guid], layout, reportId)
            },
            [reportPath, guid, setComponentLayout, reportId]
        )

        const compactTypeResult = compactType === "none" ? null : compactType
        const editModeResult = editMode && isAdmin

        return (
            <>
                <Popup
                    onHiding={onHiding}
                    visible={visible}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    title={popupTitle}
                    showCloseButton={true}
                    fullScreen={fullscreen}
                >
                    <ReportPathProvider guid={component.guid}>
                        <ScrollView scrollByContent={true} scrollByThumb={true}>
                            <ReactResizeDetector onResize={handleResize} handleWidth>
                                <div ref={gridRef}>
                                    <ReactGridLayout
                                        isDraggable={editModeResult}
                                        isResizable={editModeResult}
                                        rowHeight={rowSize}
                                        cols={numOfColumns}
                                        width={width}
                                        layout={layout}
                                        onLayoutChange={onLayoutChange}
                                        compactType={compactTypeResult}
                                    >
                                        {children?.map((c, i) => (
                                            <div key={c.guid}>
                                                <ComponentContainer
                                                    component={c}
                                                    coordinates={coordinates[i]}
                                                />
                                            </div>
                                        ))}
                                    </ReactGridLayout>
                                </div>
                            </ReactResizeDetector>
                        </ScrollView>
                    </ReportPathProvider>
                </Popup>
                <Button
                    width="100%"
                    height="100%"
                    className={`button-component button-size-${fontSize}`}
                    disabled={!buttonEnabled}
                    text={buttonText}
                    type="default"
                    onClick={showPopup}
                />
            </>
        )
    }
)

export default memo(PopupComponent)
