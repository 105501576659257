import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useMemo } from "react"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { useReportStore2 } from "../../stores/ReportStore2"
import { BaseParams } from "../../types/BaseTypes"

export type ImageParameters = BaseParams & {
    imageId: number
}

export const getImageBasicConfigurations = () => {
    return [{}]
}

function ImageEditor({ parameters }: EditorProps<ImageParameters>) {
    const images = useReportStore2((state) => state.images)
    const imagesOptions = useMemo(
        () => ({
            items: images,
            valueExpr: "id",
            displayExpr: "name"
        }),
        [images]
    )

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <SimpleItem
                                dataField="imageId"
                                editorType="dxSelectBox"
                                editorOptions={imagesOptions}
                            >
                                <Label text="Obrázek" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(ImageEditor)
