import { getBlowerBasicConfigurations } from "../../components/Blower/BlowerEditor"
import { getButtonBasicConfigurations } from "../../components/Button/ButtonEditor"
import { getButtonGroupBasicConfigurations } from "../../components/ButtonGroup/ButtonGroupEditor"
import { getCentrifugeBasicConfigurations } from "../../components/Centrifuge/CentrifugeEditor"
import { getChartBasicConfigurations } from "../../components/Chart/ChartEditor"
import { getColumnsBasicConfigurations } from "../../components/Columns/ColumnsEditor"
import { getCompressorBasicConfigurations } from "../../components/Compressor/CompressorEditor"
import { getDateTimeBasicConfigurations } from "../../components/DateTime/DateTimeEditor"
import { getEngineBasicConfigurations } from "../../components/Engine/EngineEditor"
import { getFlapBasicConfigurations } from "../../components/Flap/FlapEditor"
import { getGaugeBasicConfigurations } from "../../components/Gauge/GaugeEditor"
import { getGaugeSwitchBasicConfigurations } from "../../components/GaugeSwitch/GaugeSwitchEditor"
import { getGraphBasicConfigurations } from "../../components/Graph/GraphEditor"
import { getGridBasicConfigurations } from "../../components/Grid/GridEditor"
import { getHeadingBasicConfigurations } from "../../components/Heading/HeadingEditor"
import { getIFrameBasicConfigurations } from "../../components/IFrame/IFrameEditor"
import { getImageBasicConfigurations } from "../../components/Image/ImageEditor"
import { getLedBasicConfigurations } from "../../components/Led/LedEditor"
import { getLineBasicConfigurations } from "../../components/Line/LineEditor"
import { getMapBasicConfigurations } from "../../components/Map/MapEditor"
import { getMultiImageBasicConfigurations } from "../../components/MultiImage/MultiImageEditor"
import { getNotificationsBasicConfigurations } from "../../components/Notifications/NotificationsEditor"
import { getPipeRoundBasicConfigurations } from "../../components/PipeRound/PipeRoundEditor"
import { getPipeTBasicConfigurations } from "../../components/PipeT/PipeTEditor"
import { getPopupBasicConfigurations } from "../../components/Popup/PopupEditor"
import { getPumpBasicConfigurations } from "../../components/Pump/PumpEditor"
import { getRowsBasicConfigurations } from "../../components/Rows/RowsEditor"
import { getSelectboxBasicConfigurations } from "../../components/Selectbox/SelectboxEditor"
import { getStirrerBasicConfigurations } from "../../components/Stirrer/StirrerEditor"
import { getTableBasicConfigurations } from "../../components/Table/TableEditor"
import { getTankBasicConfigurations } from "../../components/Tank/TankEditor"
import { getTextBasicConfigurations } from "../../components/Text/TextEditor"
import { getVentilatorBasicConfigurations } from "../../components/Ventilator/VentilatorEditor"
import { getVoiceBasicConfigurations } from "../../components/Voice/VoiceEditor"
import { IComponent } from "../../types/BaseTypes"
import {
    BlowerHead,
    ButtonGroupHead,
    ButtonHead,
    CentrifugeHead,
    ChartHead,
    ColumnsHead,
    CompressorHead,
    DateTimeHead,
    EngineHead,
    FlapHead,
    GaugeHead,
    GaugeSwitchHead,
    GraphHead,
    GridHead,
    HeadingHead,
    IFrameHead,
    ImageHead,
    LedHead,
    LineHead,
    MapHead,
    MultiImageHead,
    NotificationsHead,
    PipeRoundHead,
    PipeTHead,
    PopupHead,
    PumpHead,
    RowsHead,
    SelectboxHead,
    StirrerHead,
    TableHead,
    TankHead,
    TextHead,
    VentilatorHead,
    VoiceHead
} from "../../types/ComponentTypes"

export const SystemLibraryComponents: Array<IComponent> = [
    ...getGridBasicConfigurations().map((p) => ({
        ...GridHead,
        ...p
    })),
    ...getRowsBasicConfigurations().map((p) => ({
        ...RowsHead,
        ...p
    })),
    ...getColumnsBasicConfigurations().map((p) => ({
        ...ColumnsHead,
        ...p
    })),
    ...getPopupBasicConfigurations().map((p) => ({
        ...PopupHead,
        parameters: p
    })),
    ...getHeadingBasicConfigurations().map((p) => ({
        ...HeadingHead,
        parameters: p
    })),
    ...getTextBasicConfigurations().map((p) => ({
        ...TextHead,
        parameters: p
    })),
    ...getSelectboxBasicConfigurations().map((p) => ({
        ...SelectboxHead,
        parameters: p
    })),
    ...getDateTimeBasicConfigurations().map((p) => ({
        ...DateTimeHead,
        parameters: p
    })),
    ...getButtonBasicConfigurations().map((p) => ({
        ...ButtonHead,
        parameters: p
    })),
    ...getLineBasicConfigurations().map((p) => ({
        ...LineHead,
        parameters: p
    })),
    ...getButtonGroupBasicConfigurations().map((p) => ({
        ...ButtonGroupHead,
        parameters: p
    })),
    ...getGaugeSwitchBasicConfigurations().map((p) => ({
        ...GaugeSwitchHead,
        parameters: p
    })),
    ...getTableBasicConfigurations().map((p) => ({
        ...TableHead,
        parameters: p
    })),
    ...getChartBasicConfigurations().map((p) => ({
        ...ChartHead,
        parameters: p
    })),
    ...getGraphBasicConfigurations().map((p) => ({
        ...GraphHead,
        parameters: p
    })),
    ...getGaugeBasicConfigurations().map((p) => ({
        ...GaugeHead,
        parameters: p
    })),
    ...getLedBasicConfigurations().map((p) => ({
        ...LedHead,
        parameters: p
    })),
    ...getEngineBasicConfigurations().map((p) => ({
        ...EngineHead,
        parameters: p
    })),
    ...getPumpBasicConfigurations().map((p) => ({
        ...PumpHead,
        parameters: p
    })),
    ...getCompressorBasicConfigurations().map((p) => ({
        ...CompressorHead,
        parameters: p
    })),
    ...getStirrerBasicConfigurations().map((p) => ({
        ...StirrerHead,
        parameters: p
    })),
    ...getBlowerBasicConfigurations().map((p) => ({
        ...BlowerHead,
        parameters: p
    })),
    ...getCentrifugeBasicConfigurations().map((p) => ({
        ...CentrifugeHead,
        parameters: p
    })),
    ...getVentilatorBasicConfigurations().map((p) => ({
        ...VentilatorHead,
        parameters: p
    })),
    ...getTankBasicConfigurations().map((p) => ({
        ...TankHead,
        parameters: p
    })),
    // stare pipe jsou deprecated
    // ...getPipeBasicConfigurations().map((p) => ({
    //     ...PipeHead,
    //     parameters: p
    // })),
    ...getPipeRoundBasicConfigurations().map((p) => ({
        ...PipeRoundHead,
        parameters: p
    })),
    ...getPipeTBasicConfigurations().map((p) => ({
        ...PipeTHead,
        parameters: p
    })),
    ...getFlapBasicConfigurations().map((p) => ({
        ...FlapHead,
        parameters: p
    })),
    ...getMapBasicConfigurations().map((p) => ({
        ...MapHead,
        parameters: p
    })),
    ...getImageBasicConfigurations().map((p) => ({
        ...ImageHead,
        parameters: p
    })),
    ...getMultiImageBasicConfigurations().map((p) => ({
        ...MultiImageHead,
        parameters: p
    })),
    ...getNotificationsBasicConfigurations().map((p) => ({
        ...NotificationsHead,
        parameters: p
    })),
    ...getVoiceBasicConfigurations().map((p) => ({
        ...VoiceHead,
        parameters: p
    })),
    // zakomentovano, protoze se to kouse v editacnim rezimu
    // ...getThreeDBasicConfigurations().map((p) => ({
    //     ...ThreeDHead,
    //     parameters: p
    // })),
    // ...getThreeDCustomBasicConfigurations().map((p) => ({
    //     ...ThreeDCustomHead,
    //     parameters: p
    // })),
    ...getIFrameBasicConfigurations().map((p) => ({
        ...IFrameHead,
        parameters: p
    }))
] as any
