import {
    ForwardedRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react"
import { Layout } from "react-grid-layout"
import { useKeyPress, useStore } from "reactflow"
import { useStateIfMounted } from "use-state-if-mounted"
import { useShallow } from "zustand/react/shallow"
import ComponentCopyManager from "../managers/ComponentCopyManager"
import { IBaseComponent } from "../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentCoordinates } from "../report/Schema/Layer/ComponentContainer/ComponentCoordinatesEditor/ComponentCoordinatesEditor"
import { ComponentContext } from "../report/contexts/ComponentContext"
import { EvaluatorContext, IEvaluatorContext } from "../report/contexts/EvaluatorContext"
import { FunctionsContext } from "../report/contexts/FunctionsContext"
import { ReportIdContext } from "../report/contexts/ReportIdContext"
import { ReportPathContext } from "../report/contexts/ReportPathContext"
import { VariablesContext } from "../report/contexts/VariablesContext"
import { PropsMessenger, useReportStore } from "../stores/ReportStore"
import { IComponent, RequiredReport } from "../types/BaseTypes"
import { Action } from "../types/ComponentTypes"
import { KeyType } from "../types/KeysTypes"
import { executeActions } from "./ActionUtils"

import { useHoveredStore } from "../stores/HoveredStore"
import { ReportState, useReportStore2 } from "../stores/ReportStore2"
import EvalUtils from "./EvalUtils"

export const useConstructor = (callBack = () => {}): void => {
    const [hasBeenCalled, setHasBeenCalled] = useStateIfMounted(false)
    if (hasBeenCalled) return
    callBack()
    setHasBeenCalled(true)
}

export const useForceUpdate = () => {
    const [state, updateState] = useStateIfMounted<any>({})
    return [useCallback(() => updateState({}), [updateState]), useMemo(() => state, [state])]
}

export const useCoordinates = (layout: Layout[], children?: Array<IComponent>) => {
    const [coordinates, setCoordinates] = useStateIfMounted<
        Array<ComponentCoordinates | undefined>
    >([])

    useEffect(() => {
        if (children) {
            const coordinates = children?.map((c) => layout?.find((l) => l.i === c.guid))
            setCoordinates(coordinates)
        }
    }, [children, layout, setCoordinates])

    return coordinates
}

export function useContainerVisible(
    setContainerVisible: (visible: boolean) => void,
    visible: boolean
) {
    useEffect(() => setContainerVisible(visible), [setContainerVisible, visible])
}

export function useExecuteActions() {
    const evaluatorContext = useContext(EvaluatorContext)
    const variablesContext = useContext(VariablesContext)
    const functionsContext = useContext(FunctionsContext)

    return useCallback(
        (actions?: Array<Action>, selfValue?: any) =>
            executeActions(
                evaluatorContext,
                variablesContext,
                functionsContext,
                actions,
                selfValue
            ),
        [evaluatorContext, variablesContext, functionsContext]
    )
}

export function useExecuteRef() {
    const execute = useExecuteActions()
    const executeRef = useRef(execute)

    useEffect(() => {
        executeRef.current = execute
    }, [execute])

    return executeRef
}

export function useEvaluatorRef() {
    const evaluatorContext = useContext(EvaluatorContext)
    const evaluatorRef = useRef(evaluatorContext)

    useEffect(() => {
        evaluatorRef.current = evaluatorContext
    }, [evaluatorContext])

    return evaluatorRef
}

export function useImperative(
    ref: ForwardedRef<IBaseComponent>,
    props: Array<{
        keyType: KeyType
        messenger: PropsMessenger | Array<PropsMessenger>
        reverse?: boolean
    }>
) {
    const changeComponentProps = useReportStore((state) => state.changeComponentProps)

    const reportPath = useContext(ReportPathContext).reportPath
    const componentContext = useContext(ComponentContext)
    const component = componentContext?.component
    const guid = component?.guid

    const onKeyDown = useCallback(
        (keyType: KeyType) => {
            const prop = props.find((p) => p.keyType === keyType)
            if (prop && guid) {
                const messenger = prop.messenger
                if (Array.isArray(messenger)) {
                    messenger.forEach((m) =>
                        changeComponentProps(reportPath, guid, m, prop.reverse)
                    )
                } else {
                    changeComponentProps(reportPath, guid, messenger, prop.reverse)
                }
            }
        },
        [changeComponentProps, guid, props, reportPath]
    )

    useImperativeHandle(ref, () => ({ onKeyDown }), [onKeyDown])
}

export const useInsertComponents = () => {
    const insertComponents = useReportStore((state) => state.insertComponents)
    const unselectNodesAndEdges = useStore((state) => state.unselectNodesAndEdges)

    return useCallback(() => {
        unselectNodesAndEdges()
        ComponentCopyManager.getInstance()
            .getCopiedComponents()
            .then((r) => insertComponents(r))
    }, [insertComponents, unselectNodesAndEdges])
}

export enum KEY_MAP {
    COPY = "Control+c",
    PASTE = "Control+v",
    SAVE = "Control+s",
    BACKWARDS = "Control+z",
    FORWARDS = "Control+y",
    DELETE = "del"
}

export const useKeyClick = (key: KEY_MAP | Array<KEY_MAP>, onClickStart: () => void) => {
    const keyDown = useKeyPress(key)

    useEffect(() => {
        if (keyDown) {
            onClickStart()
        }
    }, [onClickStart, keyDown])
}

function getNewValue(
    expression: string | undefined,
    evaluatorContext: IEvaluatorContext | undefined
) {
    if (expression !== undefined && expression !== null) {
        return evaluatorContext?.evaluate(expression)
    }
}

function useCorrectedExpression(expression: string | undefined) {
    return useMemo(() => EvalUtils.correctExpression(expression), [expression])
}

function useCorrectedExpressions(expressions: Array<string | undefined> | undefined) {
    return useMemo(() => expressions?.map((e) => EvalUtils.correctExpression(e)), [expressions])
}

export function useExpression<S = undefined>(
    expression: string | undefined,
    initialValue: S | (() => S)
): [S, (newValue: S) => void] {
    const evaluatorContext = useContext(EvaluatorContext)
    const correctedExpression = useCorrectedExpression(expression)

    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        const newValue = getNewValue(correctedExpression, evaluatorContext)

        if (newValue !== undefined) {
            setValue(newValue)
        }
    }, [correctedExpression, setValue, evaluatorContext])

    return useMemo(() => [value, setValue], [value, setValue])
}

export function useListExpression(
    expressions: Array<string | undefined> | undefined,
    initialValue?: Array<any> | (() => Array<any>)
): [Array<any> | undefined, (value: Array<any>) => void] {
    const [value, setValue] = useStateIfMounted(initialValue)

    const evaluatorContext = useContext(EvaluatorContext)
    const correctedExpressions = useCorrectedExpressions(expressions)

    useEffect(() => {
        const newValues = correctedExpressions?.map((e) => getNewValue(e, evaluatorContext))
        setValue(newValues)
    }, [correctedExpressions, setValue, evaluatorContext])

    return useMemo(() => [value, setValue], [value, setValue])
}

export function useReportId() {
    const reportId = useContext(ReportIdContext)?.reportId
    if (reportId) {
        return reportId
    }
    throw new Error("Report id does not exist")
}

export function useReport() {
    const reportId = useContext(ReportIdContext)?.reportId
    return useReportStore((state) => {
        const report = state.reports.find((r) => r.id === reportId)
        if (report) {
            return report
        }
        return new Error("Report not found by useReport")
    })
}

export function useReportFields<T>(fields: (report: RequiredReport) => T): T | [] {
    const reportId = useContext(ReportIdContext)?.reportId

    return useReportStore(
        useShallow((state) => {
            const report = state.reports.find((r) => r.id === reportId)

            if (!report) {
                return []
            }
            return fields(report)
        })
    )
}

export function useReport2Fields<T>(fields: (reportState: ReportState) => T): T {
    const reportId = useContext(ReportIdContext)?.reportId
    if (!reportId) {
        throw new Error("ReportId is not set - useReport2Fields")
    }

    return useReportStore2(
        useShallow((state) => {
            const reportState = state.reportStates[reportId]

            if (!reportState) {
                throw new Error("reportState not found, reportId " + reportId)
            }
            return fields(reportState)
        })
    )
}

export function useScrollActivator() {
    const setScrollEnabled = useReportStore2((store) => store.setScrollEnabled)
    const registerListener = useHoveredStore((store) => store.registerListener)
    const componentGuid = useContext(ComponentContext)?.component.guid

    useEffect(() => {
        if (componentGuid) {
            registerListener(componentGuid, (hovered) => {
                setScrollEnabled(!hovered)
            })
        }
    }, [componentGuid, registerListener, setScrollEnabled])
}
