import { API_BASE_URL } from "../../constants"
import { accessTokenExists, request } from "../APIUtils"

// TODO any
export function getLicense(): Promise<Array<any>> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/license",
            method: "GET"
        })
    }) as any
}
