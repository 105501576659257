import { CheckBox, DataGrid, List, Popup } from "devextreme-react"
import React from "react"

import { SearchPanel } from "devextreme-react/data-grid"
import Loading from "../basic/Loading"
import "../css/System.scss"
import { getAllLogs, getAllThreads } from "../utils/APISystem"
import { standardError } from "../utils/NotifyUtils"

type SystemProps = {}

type SystemState = {
    isLoading: boolean
    popupVisible: boolean
    fileName?: string
    fileRows?: Array<String>
    autoRefresh: boolean
    threads: Array<ThreadObject>
}

export type LogObject = {
    fileName: string
    fileSize: number
    body: string
    guid?: string
}

export type ThreadObject = {
    id: number
    name: string
    state: string
    isDaemon: boolean
    cpuTime: number
}

type FormData = {
    logs: Array<LogObject>
}

class System extends React.Component<SystemProps, SystemState> {
    private formData: FormData
    private interval: any

    constructor(props: SystemProps) {
        super(props)
        this.state = {
            isLoading: true,
            popupVisible: false,
            fileRows: [],
            threads: [],
            autoRefresh: false
        }

        this.formData = {
            logs: []
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.loadData()
        this.interval = setInterval(() => {
            if (this.state.autoRefresh) {
                this.loadData()
            }
        }, 10000)

        getAllThreads()
            .then((response: Array<ThreadObject>) => {
                this.setState({ threads: response })
            })
            .catch(standardError)
    }

    loadData = () => {
        getAllLogs()
            .then((response: Array<LogObject>) => {
                this.formData = { logs: response }
                if (this.state.fileName) {
                    this.makeRows(this.state.fileName)
                }
            })
            .catch(standardError)
            .finally(() => this.setState({ isLoading: false }))
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    private logRender = (item: LogObject) => {
        return (
            <div className="log-row-inner-div" style={{ backgroundColor: "white" }}>{`${
                item.fileName
            } ${item.fileSize / 1000} kB`}</div>
        )
    }

    private rowsRender = (item: string) => {
        let backgroundColor = "white"
        let color = "black"
        if (item.includes(" SEVERE ")) {
            backgroundColor = "red"
            color = "white"
        } else if (item.includes(" WARNING ")) {
            backgroundColor = "orange"
            color = "white"
        } else if (item.includes(" DEBUG ")) {
            backgroundColor = "lightblue"
        }
        return (
            <div className="log-row-inner-div" style={{ backgroundColor: backgroundColor, color }}>
                <span style={{ whiteSpace: "pre-line" }}>{`${item}`}</span>
            </div>
        )
    }

    private handleItemClick = (item: any) => {
        this.setState({ fileName: item.itemData.fileName })
        this.makeRows(item.itemData.fileName)
        this.showPopup()
    }

    private makeRows = (fileName: string) => {
        const body = this.formData.logs.find((l) => l.fileName === fileName)?.body
        if (body) {
            const rows: Array<string> = []
            body.split("\n").forEach((v, i) => {
                if (
                    v.includes(" SEVERE ") ||
                    v.includes(" INFO ") ||
                    v.includes(" DEBUG ") ||
                    v.includes(" WARNING ")
                ) {
                    rows.push(v)
                } else {
                    rows[rows.length - 1] = rows[rows.length - 1] + "\n" + v
                }
            })
            this.setState({ fileRows: rows.reverse() })
        }
    }

    private showPopup = () => {
        this.setState({ popupVisible: true })
    }

    private hidePopup = () => {
        this.setState({ popupVisible: false })
    }

    private onValueChanged = (value: any) => {
        this.setState({ autoRefresh: value.value })
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />
        }

        return (
            <>
                <div style={{ padding: "10px" }}>{"Soubory logů"}</div>
                <Popup visible={this.state.popupVisible} onHiding={this.hidePopup}>
                    <CheckBox
                        style={{ padding: "10px" }}
                        text="Automatický refresh každých 10 s."
                        onValueChanged={this.onValueChanged}
                        value={this.state.autoRefresh}
                    />
                    <List
                        className="log-rows"
                        dataSource={this.state.fileRows}
                        itemRender={this.rowsRender}
                        searchEnabled={true}
                        pageLoadMode="scrollBottom"
                    />
                </Popup>
                <List
                    dataSource={this.formData.logs}
                    itemRender={this.logRender}
                    onItemClick={this.handleItemClick}
                    height="100px"
                />

                <div style={{ padding: "10px" }}>Vlákna</div>
                <DataGrid
                    dataSource={this.state.threads}
                    showBorders={true}
                    keyExpr="id"
                    columns={["name", "id", "state", "cpuTime"]}
                    columnAutoWidth={true}
                    paging={{ enabled: false }}
                >
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                </DataGrid>
            </>
        )
    }
}

export default System
