import { memo, useEffect } from "react"
import { Link } from "react-router-dom"
import { MenuItem } from "../basic/HeaderItems"
import "../css/TileContainer.scss"
import { useAppStore } from "../stores/AppStore"

type Props = {}

function GroupsList(props: Props) {
    const currentUser = useAppStore((state) => state.currentUser)!
    const changePath = useAppStore((state) => state.changePath)

    useEffect(() => {
        const autoNavigate = () => {
            const groups = currentUser.groups
            const isAdmin = currentUser.admin
            if (groups?.length === 1 && !isAdmin) {
                const group = groups[0]
                const reports = group.reports
                const path = `/${MenuItem.GROUPS}/${group.id}/${MenuItem.REPORTS}`

                if (reports?.length === 1) {
                    changePath(`${path}/${reports[0].id}`)
                } else {
                    changePath(path)
                }
            }
        }

        autoNavigate()
    }, [changePath, currentUser.admin, currentUser.groups])

    return (
        <div className="text-align-center">
            <span className="heading-1">Skupiny</span>
            <div className="tile-container">
                {currentUser.groups?.map((group) => (
                    <Link key={group.id} to={`/${MenuItem.GROUPS}/${group.id}/${MenuItem.REPORTS}`}>
                        <div className="tile-container-element">{group.name}</div>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default memo(GroupsList)
