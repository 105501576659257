import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import React from "react"
import { FParamDefinition, IFunctionParameters } from "../../types/FunctionsType"
import ListPopupEditor from "./ListPopupEditor"
import { initMonacoEditor } from "./MonacoEditor"

type ParametersProps = {
    listOwner: IFunctionParameters
    expressionHelpText?: string
}

type ParametersState = {}

class ParametersEditor extends React.Component<ParametersProps, ParametersState> {
    private renderParam = (item: any) => {
        return (
            <GroupItem>
                <SimpleItem
                    render={initMonacoEditor(item, "expression")}
                    helpText={this.props.expressionHelpText}
                >
                    <Label text="Výraz" />
                </SimpleItem>
            </GroupItem>
        )
    }

    private expressionRender = (item: FParamDefinition) => {
        return <div>{item.expression}</div>
    }

    render() {
        const listOwner = this.props.listOwner

        return (
            <ListPopupEditor
                listOwner={listOwner}
                listName="params"
                itemRender={this.expressionRender}
                formItemContent={this.renderParam}
            />
        )
    }
}

export default ParametersEditor
