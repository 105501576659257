import { DateBox } from "devextreme-react"
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from "react"
import { $enum } from "ts-enum-util"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { KeyType } from "../../types/KeysTypes"
import ColorUtils from "../../utils/ColorUtils"
import ComponentUtils from "../../utils/ComponentUtils"
import {
    useContainerVisible,
    useExecuteRef,
    useExpression,
    useImperative
} from "../../utils/CustomHooks"
import DateUtils from "../../utils/DateUtils"
import { ComponentTextSize } from "../Text/TextEditor"
import { DateTimeParameters } from "./DateTimeEditor"

type Params = DateTimeParameters

type Props = ComponentProps<Params>

const DateTimeComponent = forwardRef<IBaseComponent, Props>(
    (
        {
            expression,
            enabledExpression,
            colorExpression,
            fontSize,
            italic,
            bold,
            setContainerVisible,
            onChangeActions
        },
        ref
    ) => {
        const executeRef = useExecuteRef()
        useContainerVisible(setContainerVisible, false)

        const [enabled] = useExpression(enabledExpression, true)
        const [color] = useExpression(colorExpression, 0)
        const [value, setValue] = useExpression(expression, undefined)
        const oldValue = useRef(value)
        // TODO zde je brzda
        // mozna spis fronta, ktera by vzdy provedla jen jednu akci napr. za vterinu a tu posledni?
        useEffect(() => {
            if (DateUtils.moreThenXSeconds(1, oldValue.current, value)) {
                // console.log("dateChange", Date.parse(value ?? ""))
                oldValue.current = value
                executeRef.current(onChangeActions, { value: value })
            }
        }, [executeRef, value, onChangeActions])

        useImperative(
            ref,
            useMemo(() => {
                const messenger = {
                    name: "textSize",
                    enums: $enum(ComponentTextSize)
                        .getValues()
                        .map((e) => ({ enum: e })),
                    value: fontSize
                }
                return [
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [fontSize])
        )

        const onValueChanged = useCallback(
            (e: any) => {
                // const previousValue = e.previousValue
                const newValue = e.value
                setValue(newValue)
            },
            [setValue]
        )

        const className = useMemo(
            () =>
                `${bold && "bold"} ${italic && "italic"} text-${ColorUtils.fromNumber(
                    color
                )} ${ComponentUtils.classFromFontSize(fontSize)}`,
            [bold, color, italic, fontSize]
        )

        return (
            <DateBox
                width="100%"
                height="100%"
                className={className}
                disabled={!enabled}
                onValueChanged={onValueChanged}
                value={value}
                type="datetime"
            />
        )
    }
)

export default memo(DateTimeComponent)
