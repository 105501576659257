import { RefObject } from "react"

import DataGrid, {
    Column,
    Editing,
    Form,
    Pager,
    Paging,
    RequiredRule,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"

import { IGroup, IUser } from "../types/BaseTypes"
import { gqlRead } from "../utils/APIUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"

type State = CollectionState<IGroup> & {
    users: Array<IUser>
}

class AdministrationGroups extends BaseCollection<IGroup, CollectionProps, State> {
    constructor(props: CollectionProps) {
        super(props, {
            entityName: "group",
            subEntitiesToIds: ["users"],
            copyButton: true,
            title: "Skupiny a jejich uživatelé"
        })
        this.state = {
            ...this.state,
            users: []
        }
    }

    protected getEntities = (): Promise<Array<IGroup>> => {
        return gqlRead({
            groups: {
                id: true,
                name: true,
                users: {
                    id: true
                }
            },
            users: {
                id: true,
                name: true
            }
        }).then((r) => {
            this.setState({ users: r.users })
            return r.groups
        })
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <DataGrid
                ref={ref}
                dataSource={dataSource}
                showBorders={true}
                columnHidingEnabled={true}
                onEditorPreparing={this.onEditorPreparing}
                onToolbarPreparing={this.onToolbarPreparing}
                onSelectionChanged={this.onSelectionChanged}
                height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
            >
                <Paging enabled={false} />
                <Pager visible={true} displayMode="full" showInfo={true} />
                <SearchPanel visible={true} />
                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
                <Editing
                    mode="form"
                    useIcons={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                >
                    <Form colCount={1}>
                        <Item dataField="name" />
                        <Item
                            editorType="dxTagBox"
                            dataField="users"
                            editorOptions={{
                                ...BaseCollection.TAG_BOX_OPTIONS,
                                dataSource: this.state.users
                            }}
                        />
                    </Form>
                </Editing>

                <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                <Column dataField="name" caption="Název">
                    <RequiredRule />
                </Column>

                <Column
                    dataField="users"
                    caption="Uživatelé"
                    customizeText={(cellInfo: any) => this.fromNames(cellInfo, this.state.users)}
                ></Column>
            </DataGrid>
        )
    }
}

export default AdministrationGroups
