import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { v4 as uuid } from "uuid"
import ComponentsList from "../../componentsSetup/common/ComponentsList"
import FunctionsList from "../../componentsSetup/common/FunctionsList"
import VariablesList from "../../componentsSetup/common/VariablesList"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export type ColumnsParameters = BaseParams & {}

export const getColumnsBasicConfigurations = () => {
    return [{ parameters: {}, components: [{ guid: uuid() }, { guid: uuid() }] }]
}

function ColumnsEditor({ formData }: EditorProps<ColumnsParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Komponenty">
                <ComponentsList component={formData} />
            </Item>
            <Item title="Funkce">
                <FunctionsList listOwner={formData} />
            </Item>
            <Item title="Proměnné">
                <VariablesList listOwner={formData} />
            </Item>
        </TabPanel>
    )
}

export default memo(ColumnsEditor)
