import React, { RefObject } from "react"

import { Button } from "devextreme-react"
import DataGrid, {
    Column,
    Editing,
    Form,
    Grouping,
    GroupPanel,
    MasterDetail,
    Pager,
    Paging,
    RequiredRule,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import SForm, { Item, Label, GroupItem as SGroupItem, SimpleItem } from "devextreme-react/form"
import ArrayStore from "devextreme/data/array_store"
import {
    IDBConnection,
    IGroup,
    IModbusConnection,
    IModbusFrame,
    IModbusValue,
    INotificationDefinition,
    MODBUS_VALUE_TYPES
} from "../types/BaseTypes"
import { uploadCSV } from "../utils/API/APIModbusFrames"
import { gqlDeepDelete, gqlPersist, gqlRead } from "../utils/APIUtils"
import { confirmation, standardError, standardSuccess } from "../utils/NotifyUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"
import { NotificationDefinitionsGrid } from "./Notifications"
import { csvExtension, PopupFileUploader, ShowPopupHandle } from "./PopupFileUploader"

type ModbusReaderState = CollectionState<IModbusFrame> & {
    groups: Array<IGroup>
    dbConnections: Array<IDBConnection>
    modbusConnections: Array<IModbusConnection>
}

class ModbusReader extends BaseCollection<IModbusFrame, CollectionProps, ModbusReaderState> {
    private dbConnectionSelectBoxOptions: any
    private modbusConnectionSelectBoxOptions: any
    private modbusTypesOptions: any
    private popupRef: React.RefObject<ShowPopupHandle>

    constructor(props: CollectionProps) {
        super(props, {
            entityName: "modbusFrame",
            subEntitiesToIds: ["group", "dbConnection", "modbusConnection"],
            copyButton: true,
            deepCopyButton: true,
            deepDeleteButton: true,
            title: "Modbusové rámce"
        })
        this.state = {
            ...this.state,
            groups: [],
            dbConnections: [],
            modbusConnections: []
        }
        this.popupRef = React.createRef()

        this.dbConnectionSelectBoxOptions = {
            ...BaseCollection.SELECT_BOX_OPTIONS
        }

        this.modbusConnectionSelectBoxOptions = {
            ...BaseCollection.SELECT_BOX_OPTIONS
        }

        this.modbusTypesOptions = {
            items: MODBUS_VALUE_TYPES,
            defaultValue: MODBUS_VALUE_TYPES[0],
            displayExpr: "name",
            valueExpr: "value"
        }
    }

    protected getEntities = (): Promise<Array<IGroup>> => {
        return gqlRead({
            modbusFrames: {
                id: true,
                name: true,
                type: true,
                swappedWords: true,
                disabled: true,
                slaveNumber: true,
                fromAddress: true,
                toAddress: true,
                fromValueId: true,
                readInterval: true,
                group: {
                    id: true
                },
                dbConnection: {
                    id: true
                },
                modbusConnection: {
                    id: true
                }
            },
            groups: {
                id: true,
                name: true
            },
            dbConnections: {
                id: true,
                name: true,
                group: {
                    id: true
                }
            },
            modbusConnections: {
                id: true,
                name: true,
                group: {
                    id: true
                }
            }
        }).then((r) => {
            this.setState({
                groups: r.groups,
                dbConnections: r.dbConnections,
                modbusConnections: r.modbusConnections
            })
            return r.modbusFrames
        })
    }

    protected addCustomToolbarButtons = (toolbar: any) => {
        toolbar.push({
            widget: "dxButton",
            options: {
                icon: "upload",
                hint: "Nahrát CSV",
                onClick: () => this.popupRef.current?.showPopup()
            },
            location: "after"
        })
    }

    private onRowUpdating = (options: any) => {
        options.newData = Object.assign(options.oldData, options.newData)
    }

    private onInitNewRow = (object: any) => {
        object.data.slaveNumber = 1
    }

    private handleCreateCSV = (file: any) => {
        uploadCSV(file)
            .then((response) => {
                this.refreshTable()
                standardSuccess("Čtení podle zdrojového CSV vytvořeno.")
            })
            .catch(standardError)
    }

    private onGroupChanged = (object: { data?: { group: number } }) => {
        const group = object.data?.group
        if (group) {
            this.filterDbConnections(group)
            this.filterModbusConnections(group)
            this.forceUpdate()
        }
    }

    private filterDbConnections = (group: number) => {
        const data = this.state.dbConnections?.filter((db) => db.group?.id === group)
        this.dbConnectionSelectBoxOptions.dataSource = new ArrayStore({ data: data, key: "id" })
        return data
    }

    private filterModbusConnections = (group: number) => {
        const data = this.state.modbusConnections?.filter((m) => m.group?.id === group)
        this.modbusConnectionSelectBoxOptions.dataSource = new ArrayStore({ data: data, key: "id" })
        return data
    }

    private setCellValueGroup = (
        newData: any,
        value: number,
        currentRowData: { modbusConnection: number; dbConnection: number }
    ) => {
        const availableDbs = this.filterDbConnections(value)
        const actualDb = availableDbs.some((db) => db.id === currentRowData.dbConnection)
            ? currentRowData.dbConnection
            : null

        const availableMdbs = this.filterModbusConnections(value)
        const actualMdb = availableMdbs.some((db) => db.id === currentRowData.modbusConnection)
            ? currentRowData.modbusConnection
            : null

        newData.group = value
        newData.modbusConnection = actualMdb
        newData.dbConnection = actualDb
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <>
                <PopupFileUploader
                    ref={this.popupRef}
                    handleSave={this.handleCreateCSV}
                    fileExtensions={csvExtension}
                    accept=".csv"
                    maxSizeInMB={10}
                />
                <DataGrid
                    ref={ref}
                    dataSource={dataSource}
                    showBorders={true}
                    // onEditorPreparing={this.onEditorPreparing}
                    onRowUpdating={this.onRowUpdating}
                    onToolbarPreparing={this.onToolbarPreparing}
                    // TODO DEVEXTREME
                    onSelectionChanged={this.onSelectionChanged as any}
                    onInitNewRow={this.onInitNewRow}
                    onEditingStart={this.onGroupChanged}
                    height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
                >
                    <Paging enabled={false} />
                    <Pager visible={true} displayMode="full" showInfo={true} />
                    <Selection
                        mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="onClick"
                    />
                    <SearchPanel visible={true} />
                    <Editing
                        mode="popup"
                        useIcons={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true}
                    >
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item itemType="group" caption="Základní">
                                    <Item dataField="name" />
                                    <Item
                                        dataField="type"
                                        editorType="dxSelectBox"
                                        editorOptions={this.modbusTypesOptions}
                                    />
                                    <Item
                                        dataField="readInterval"
                                        editorType="dxNumberBox"
                                        helpText="Interval čtení modbusu. Při každém čtení se hodnoty uloží do actual_data v případě, že se změnily."
                                    />
                                    <Item dataField="swappedWords" editorType="dxCheckBox" />
                                    <Item dataField="disabled" editorType="dxCheckBox" />
                                    <Item itemType="group" caption="Rozsah" colCount={2}>
                                        <Item dataField="fromAddress" editorType="dxNumberBox" />
                                        <Item dataField="toAddress" editorType="dxNumberBox" />
                                        <Item dataField="fromValueId" editorType="dxNumberBox" />
                                    </Item>
                                </Item>
                                <Item itemType="group" caption="Ostatní">
                                    <Item
                                        editorType="dxSelectBox"
                                        dataField="group"
                                        editorOptions={{
                                            ...BaseCollection.SELECT_BOX_OPTIONS,
                                            dataSource: this.state.groups
                                        }}
                                    />
                                    <Item
                                        editorType="dxSelectBox"
                                        dataField="dbConnection"
                                        editorOptions={this.dbConnectionSelectBoxOptions}
                                    />
                                    <Item
                                        editorType="dxSelectBox"
                                        dataField="modbusConnection"
                                        editorOptions={this.modbusConnectionSelectBoxOptions}
                                    />
                                    <Item dataField="slaveNumber" editorType="dxNumberBox" />
                                </Item>
                            </Item>
                        </Form>
                    </Editing>
                    <MasterDetail enabled={true} component={ModbusValuesGrid} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={false} />

                    <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                    <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                    <Column dataField="name" caption="Rámec">
                        <RequiredRule />
                    </Column>
                    <Column dataField="type" caption="Typ hodnot">
                        <RequiredRule />
                    </Column>
                    <Column dataField="readInterval" caption="Interval čtení [s]" />
                    <Column dataField="swappedWords" caption="Prohozené wordy" />
                    <Column dataField="fromAddress" caption="Adresa od">
                        <RequiredRule />
                    </Column>
                    <Column dataField="toAddress" caption="Adresa do">
                        <RequiredRule />
                    </Column>
                    <Column dataField="fromValueId" caption="od ID hodnoty">
                        <RequiredRule />
                    </Column>
                    <Column
                        dataField="group"
                        caption="Skupina"
                        groupIndex={0}
                        setCellValue={this.setCellValueGroup}
                        customizeText={(cellInfo: any) =>
                            this.fromNames(cellInfo, this.state.groups)
                        }
                    ></Column>
                    <Column dataField="disabled" caption="Vypnuto" />
                    <Column
                        dataField="dbConnection"
                        caption="Databázové spojení"
                        customizeText={(cellInfo: any) =>
                            this.fromNames(cellInfo, this.state.dbConnections)
                        }
                    ></Column>
                    <Column
                        dataField="modbusConnection"
                        caption="Modbus spojení"
                        customizeText={(cellInfo: any) =>
                            this.fromNames(cellInfo, this.state.modbusConnections)
                        }
                    />
                    <Column dataField="slaveNumber" caption="Slave number" />
                </DataGrid>
            </>
        )
    }
}

export default ModbusReader

type ValuesProps = CollectionProps & {
    data: { data: IModbusFrame }
}

type ValuesState = CollectionState<IModbusValue> & {
    modbusFrame: IModbusFrame
    modbusFrames: Array<IModbusFrame>
}

class ModbusValuesGrid extends BaseCollection<IModbusValue, ValuesProps, ValuesState> {
    constructor(props: ValuesProps) {
        super(props, {
            entityName: "modbusValue",
            copyButton: true,
            title: "Hodnoty rámce " + props.data.data.name,
            subEntitiesToIds: ["frame"]
        })
        this.state = {
            ...this.state,
            modbusFrame: props.data.data,
            modbusFrames: []
        }
    }

    protected getEntities = (): Promise<Array<IModbusValue>> => {
        return gqlRead({
            modbusValues: {
                __args: {
                    input: { frame: { id: this.state.modbusFrame.id } }
                },
                id: true,
                name: true,
                valueId: true,
                notifications: true,
                multiplier: true,
                saveHistoryInterval: true,
                saveByChangeOnly: true,
                everyDayIn: true,
                frame: {
                    id: true
                },
                notification: {
                    id: true,
                    name: true,
                    message: true,
                    secondMessage: true,
                    telephoneNumbers: true,
                    emails: true,
                    master: true,
                    conditionType: true,
                    number: true,
                    modbusValues: {
                        id: true
                    }
                }
            },
            modbusFrames: {
                id: true,
                name: true
            }
        }).then((r) => {
            this.setState({ modbusFrames: r.modbusFrames })
            return r.modbusValues
        })
    }

    private onRowUpdating = (options: any) => {
        options.newData = Object.assign(options.oldData, options.newData)
    }

    private onInitNewRow = (object: { data: IModbusValue }) => {
        object.data.frame = this.state.modbusFrame.id as any
    }

    protected createEntities = (values: Array<IModbusValue>) => {
        values.forEach((v) => {
            if (v.everyDayIn instanceof Date) {
                v.everyDayIn = v.everyDayIn.toISOString() as any
            }
        })
        return super.createEntities(values)
    }

    protected renderGrid = (dataSource: any, ref: RefObject<DataGrid>): JSX.Element => {
        return (
            <DataGrid
                ref={ref}
                dataSource={dataSource}
                showBorders={true}
                columnHidingEnabled={true}
                onRowUpdating={this.onRowUpdating}
                onToolbarPreparing={this.onToolbarPreparing}
                onSelectionChanged={this.onSelectionChanged}
                onInitNewRow={this.onInitNewRow as any}
            >
                <SearchPanel visible={true} />
                <MasterDetail
                    enabled={true}
                    component={(props) => (
                        <NotificationDetail data={props.data.data} refresh={this.refreshTable} />
                    )}
                />
                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
                <Editing
                    mode="popup"
                    useIcons={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                >
                    <Form labelLocation="top">
                        <Item itemType="group" colCount={3} colSpan={2}>
                            <Item itemType="group" caption="Základní">
                                <Item
                                    editorType="dxSelectBox"
                                    dataField="frame"
                                    editorOptions={{
                                        ...BaseCollection.SELECT_BOX_OPTIONS,
                                        dataSource: this.state.modbusFrames
                                    }}
                                />
                                <Item dataField="name" />
                                <Item dataField="valueId" editorType="dxNumberBox" />
                                <Item dataField="multiplier" editorType="dxNumberBox" />
                            </Item>
                            <Item itemType="group" caption="Ukládání do Databáze">
                                <Item
                                    dataField="saveHistoryInterval"
                                    editorType="dxNumberBox"
                                    helpText="Interval ukládání hodnoty do tabulky historie"
                                />
                                <Item dataField="saveByChangeOnly" editorType="dxCheckBox" />
                                <Item
                                    dataField="everyDayIn"
                                    editorType="dxDateBox"
                                    editorOptions={{ type: "time" }}
                                />
                            </Item>
                            <Item itemType="group" caption="Notifikace">
                                <Item dataField="notifications" editorType="dxCheckBox" />
                            </Item>
                        </Item>
                    </Form>
                </Editing>
                <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                <Column dataField="name" caption="Název">
                    <RequiredRule />
                </Column>
                <Column
                    dataField="frame"
                    caption="Rámec"
                    customizeText={(cellInfo: any) =>
                        this.fromNames(cellInfo, this.state.modbusFrames)
                    }
                ></Column>
                <Column dataField="valueId" caption="ID Hodnoty">
                    <RequiredRule />
                </Column>
                <Column dataField="multiplier" caption="Násobitel" />
                <Column
                    dataField="notifications"
                    caption="Posílat notifikace"
                    // setCellValue={this.setCellValue}
                />
                <Column dataField="saveHistoryInterval" caption="Interval ukládání historie [s]" />
                <Column dataField="saveByChangeOnly" caption="Uložit jen při změně" />
                <Column dataField="everyDayIn" caption="Uložit pravidelně každý den v" />
            </DataGrid>
        )
    }
}

type NotificationProps = {
    data: IModbusValue
    refresh: () => void
}

type NotificationState = {
    modbusValue: IModbusValue
}

class NotificationDetail extends React.Component<NotificationProps, NotificationState> {
    private notification: INotificationDefinition

    constructor(props: NotificationProps) {
        super(props)
        this.state = {
            modbusValue: props.data
        }

        this.notification = props.data.notification ?? {}
    }

    private save = () => {
        const notification = this.notification
        const modbusValue = this.state.modbusValue

        notification.modbusValues = notification.modbusValues ?? []
        if (!notification.modbusValues.map((mv) => mv.id).includes(modbusValue.id)) {
            notification.modbusValues.push({ id: modbusValue.id })
        }

        gqlPersist("notificationDefinition", [this.notification])
            .then((r) => {
                modbusValue.notification = r.notificationDefinitions[0]
                modbusValue.frame = { id: modbusValue.frame } as any
                gqlPersist("modbusValue", [modbusValue])
                    .then((response) => {
                        this.props.refresh()
                        standardSuccess("Notifikace úspěšně uložena")
                    })
                    .catch(standardError)
            })
            .catch(standardError)
    }

    private delete = () => {
        if (this.notification.id) {
            confirmation(
                `<i>Opravdu chcete smazat definici notifikace i se všemi historickými výskyty?</i>`,
                "Potvrzení smazání",
                () =>
                    gqlDeepDelete("notificationDefinition", [this.notification.id!])
                        .then((r) => {
                            this.props.refresh()
                            standardSuccess("Notifikace úspěšně smazána")
                        })
                        .catch(standardError)
            )
        }
    }

    render() {
        return (
            <>
                <div className="text-align-center">
                    <span className="heading-2">
                        {"Notifikace hodnoty " + this.props.data.name}
                    </span>
                </div>
                <SForm formData={this.notification} colCount={2}>
                    <SGroupItem caption="Základní">
                        <SimpleItem dataField="name">
                            <Label text="Název" />
                        </SimpleItem>
                        <SimpleItem dataField="message">
                            <Label text="Zpráva výskytu" />
                        </SimpleItem>
                        <SimpleItem dataField="secondMessage">
                            <Label text="Zpráva konce výskytu" />
                        </SimpleItem>
                        <SimpleItem dataField="telephoneNumbers" helpText="Oddělte čárkou.">
                            <Label text="Telefonní čísla" />
                        </SimpleItem>
                        <SimpleItem dataField="emails" helpText="Oddělte čárkou.">
                            <Label text="Emaily" />
                        </SimpleItem>
                        <SimpleItem
                            dataField="master"
                            editorType="dxCheckBox"
                            helpText="Při aktivaci této notifikace se všechny ostatní výskyty ze stejné skupiny notifikací zruší."
                        >
                            <Label text="Master" />
                        </SimpleItem>
                    </SGroupItem>
                    <SGroupItem caption="Podmínky">
                        <SimpleItem
                            dataField="conditionType"
                            editorType="dxRadioGroup"
                            editorOptions={NotificationDefinitionsGrid.typeOptions}
                        ></SimpleItem>
                        <SimpleItem dataField="number" editorType="dxNumberBox">
                            <Label text="Hodnota" />
                        </SimpleItem>
                    </SGroupItem>
                    <SGroupItem></SGroupItem>
                    <SGroupItem>
                        <SGroupItem>
                            <Button text="Uložit notifikaci" onClick={this.save} />
                            <Button text="Smazat notifikaci i její výskyty" onClick={this.delete} />
                        </SGroupItem>
                    </SGroupItem>
                </SForm>
            </>
        )
    }
}
