import { forwardRef, memo, useCallback, useMemo, useState } from "react"
import { $enum } from "ts-enum-util"
import { v4 as uuid } from "uuid"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { KeyType } from "../../types/KeysTypes"
import { useContainerVisible, useExpression, useImperative } from "../../utils/CustomHooks"
import "./TankComponent.scss"
import {
    TankColor,
    TankOrientation,
    TankParameters,
    TankType,
    rectangleSizes,
    vSizes
} from "./TankEditor"

type Params = TankParameters

type Props = ComponentProps<Params>

const TankComponent = forwardRef<IBaseComponent, Props>(
    (
        {
            tankOrientation,
            expression,
            max,
            type,
            vSize,
            rectangleSize,
            colorDown,
            colorUp,
            setContainerVisible
        },
        ref
    ) => {
        useContainerVisible(setContainerVisible, false)

        const [guid] = useState(uuid)
        const [gradientGuid] = useState(uuid)
        // const [height, setHeight] = useState(0)
        const [heightFromExpr] = useExpression(expression, 0)
        const height = useMemo(() => {
            let result = heightFromExpr
            result = result / max
            result = result * 96
            if (result < 0) {
                result = 0
            }
            return result
        }, [heightFromExpr, max])

        useImperative(
            ref,
            useMemo(() => {
                const nested = {
                    name: "tankOrientation",
                    enums: $enum(TankOrientation)
                        .getValues()
                        .map((e) => ({ enum: e })),
                    value: tankOrientation
                }
                const plusMinus = [
                    {
                        name: "rectangleSize",
                        enums: rectangleSizes.map((v, i) => ({ enum: i + 1 })),
                        value: rectangleSize
                    },
                    {
                        name: "vSize",
                        enums: vSizes.map((v, i) => ({ enum: i + 1 })),
                        value: vSize
                    }
                ]

                return [
                    {
                        keyType: KeyType.SHIFT,
                        messenger: {
                            name: "type",
                            enums: [
                                { enum: TankType.RECTANGLE },
                                {
                                    enum: TankType.V
                                },
                                {
                                    enum: TankType.O,
                                    nested
                                }
                            ],
                            value: type
                        }
                    },
                    {
                        keyType: KeyType.PLUS,
                        messenger: plusMinus
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: plusMinus,
                        reverse: true
                    }
                ]
            }, [type, rectangleSize, tankOrientation, vSize])
        )

        const rectangle = useCallback(
            (width: number) => (
                <>
                    <rect
                        x="8.333"
                        y="8.333"
                        width={width}
                        height="1062.99"
                        clipPath={`url(#${guid})`}
                        fill={`url(#${gradientGuid})`}
                    />
                    <path
                        className="tank-stroke"
                        d={`M8.333,8.333l0,1062.99l${width},0l-0,-1062.99`}
                    />
                </>
            ),
            [gradientGuid, guid]
        )

        const vNarrow = useCallback(
            () => (
                <>
                    <path
                        d="M8.333,8.333l885.827,0l0,332.032l-282.451,730.96l-320.924,0l-282.452,-730.96l0,-332.032"
                        clipPath={`url(#${guid})`}
                        fill={`url(#${gradientGuid})`}
                    />
                    <path
                        className="tank-stroke"
                        d="M894.16,8.333l0,332.032l-282.451,730.96l-320.924,0l-282.452,-730.96l0,-332.032"
                    />
                </>
            ),
            [gradientGuid, guid]
        )

        const vMiddle = useCallback(
            () => (
                <>
                    <path
                        d="M1543.77,8.333l0,332.032l-527.523,730.96l-480.386,0l-527.524,-730.96l0,-332.032l1535.43,0"
                        clipPath={`url(#${guid})`}
                        fill={`url(#${gradientGuid})`}
                    />
                    <path
                        className="tank-stroke"
                        d="M1543.77,8.333l0,332.032l-527.523,730.96l-480.386,0l-527.524,-730.96l0,-332.032"
                    />
                </>
            ),
            [gradientGuid, guid]
        )

        const oVertical = useCallback(
            () => (
                <>
                    <path
                        d="M894.16,1287.98l0,-1062.99c0,-119.575 -198.463,-216.656 -442.913,-216.656c-244.451,0 -442.914,97.081 -442.914,216.656l0,1062.99c0,119.576 198.463,216.656 442.914,216.656c244.45,-0 442.913,-97.08 442.913,-216.656"
                        clipPath={`url(#${guid})`}
                        fill={`url(#${gradientGuid})`}
                    />
                    <path
                        className="tank-stroke"
                        d="M894.16,1287.98l0,-1062.99c0,-119.575 -198.463,-216.656 -442.913,-216.656c-244.451,0 -442.914,97.081 -442.914,216.656l0,1062.99c0,119.576 198.463,216.656 442.914,216.656c244.45,-0 442.913,-97.08 442.913,-216.656"
                    />
                </>
            ),
            [gradientGuid, guid]
        )

        const oHorizontal = useCallback(
            () => (
                <>
                    <path
                        d="M224.989,894.16l1062.99,0c119.576,0 216.656,-198.463 216.656,-442.913c-0,-244.451 -97.08,-442.914 -216.656,-442.914l-1062.99,0c-119.575,0 -216.656,198.463 -216.656,442.914c0,244.45 97.081,442.913 216.656,442.913"
                        clipPath={`url(#${guid})`}
                        fill={`url(#${gradientGuid})`}
                    />
                    <path
                        className="tank-stroke"
                        d="M224.989,894.16l1062.99,0c119.576,0 216.656,-198.463 216.656,-442.913c-0,-244.451 -97.08,-442.914 -216.656,-442.914l-1062.99,0c-119.575,0 -216.656,198.463 -216.656,442.914c0,244.45 97.081,442.913 216.656,442.913"
                    />
                </>
            ),
            [gradientGuid, guid]
        )

        const clipRect = useMemo(
            () => (
                <clipPath id={guid}>
                    <rect className="clip-rect" x="0" y="0" width="100%" height={height + "%"} />
                </clipPath>
            ),
            [height, guid]
        )

        const linearGradient = useMemo(() => {
            return (
                <linearGradient id={gradientGuid} x2="0" y2="1">
                    <stop
                        className={`tank-${colorUp ?? TankColor.BLUE}`}
                        offset={100 - height + "%"}
                    />
                    <stop className={`tank-${colorDown ?? TankColor.BLUE}`} offset="100%" />
                </linearGradient>
            )
        }, [colorDown, colorUp, gradientGuid, height])

        const result = useMemo(() => {
            let tank = <rect />
            let size
            switch (type) {
                case "rectangle":
                    switch (rectangleSize) {
                        case 1:
                            size = "903 1080"
                            tank = rectangle(885)
                            break
                        case 2:
                            size = "1553 1080"
                            tank = rectangle(1535)
                            break
                        case 3:
                            size = "2202 1080"
                            tank = rectangle(2184)
                            break
                        case 4:
                            size = "2718 1080"
                            tank = rectangle(2700)
                            break
                        case 5:
                            size = "3318 1080"
                            tank = rectangle(3300)
                            break
                        case 6:
                            size = "3918 1080"
                            tank = rectangle(3900)
                            break
                        case 7:
                            size = "4618 1080"
                            tank = rectangle(4600)
                            break
                    }
                    break
                case "V":
                    switch (vSize) {
                        case 1:
                            size = "903 1080"
                            tank = vNarrow()
                            break
                        case 2:
                            size = "1553 1080"
                            tank = vMiddle()
                            break
                    }
                    break
                case "O":
                    switch (tankOrientation) {
                        case 1:
                            size = "903 1513"
                            tank = oVertical()
                            break
                        case 2:
                            size = "1513 903"
                            tank = oHorizontal()
                            break
                    }
            }
            return { size, tank }
        }, [
            oHorizontal,
            oVertical,
            rectangle,
            rectangleSize,
            tankOrientation,
            type,
            vMiddle,
            vNarrow,
            vSize
        ])

        return (
            <svg
                width="100%"
                height="100%"
                viewBox={"0 0 " + result.size}
                version="1.1"
                className="tank"
            >
                <defs>
                    {clipRect}
                    {linearGradient}
                </defs>
                {result.tank}
            </svg>
        )
    }
)

export default memo(TankComponent)
