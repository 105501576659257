import { Form } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import { v4 as uuid } from "uuid"
import ComponentsList from "../../componentsSetup/common/ComponentsList"
import FunctionsList from "../../componentsSetup/common/FunctionsList"
import VariablesList from "../../componentsSetup/common/VariablesList"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"

export type RowsParameters = BaseParams & {
    rowHeight: number
}

export const getRowsBasicConfigurations = () => {
    return [
        {
            parameters: { rowHeight: ROW_HEIGHTS[0] },
            components: [{ guid: uuid() }, { guid: uuid() }]
        }
    ]
}

const ROW_HEIGHTS = Array.from(new Array(50).keys()).map((_, i) => i * 5 + 10)
const ROW_HEIGHTS_OPTIONS = {
    items: ROW_HEIGHTS
}

function RowsEditor({ parameters, formData }: EditorProps<RowsParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Komponenty">
                <div className="component-setup-container">
                    <Form formData={parameters} colCount={2}>
                        <GroupItem>
                            <ComponentsList component={formData} />
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem
                                dataField="rowHeight"
                                editorOptions={ROW_HEIGHTS_OPTIONS}
                                editorType="dxSelectBox"
                            >
                                <Label text="Výška řádku" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="Funkce">
                <FunctionsList listOwner={formData} />
            </Item>
            <Item title="Proměnné">
                <VariablesList listOwner={formData} />
            </Item>
        </TabPanel>
    )
}

export default memo(RowsEditor)
