import { Form } from "devextreme-react"
import { useEffect, useState } from "react"

import { SimpleItem } from "devextreme-react/form"
import { initMonacoEditor } from "../componentsSetup/common/MonacoEditor"
import "../css/System.scss"

import Loading from "../basic/Loading"
import { getLicense } from "../utils/API/APILicense"
import { standardError } from "../utils/NotifyUtils"

type LicenseProps = {}

export default function License(props: LicenseProps) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any>(undefined)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        getLicense()
            .then((response: Array<any>) => setData({ data: JSON.stringify(response, null, "\t") }))
            .catch(standardError)
            .finally(() => setLoading(false))
    }

    if (loading) {
        return <Loading />
    }

    return (
        <Form formData={data}>
            <SimpleItem render={initMonacoEditor(data, "data", false, 800, true)} />
        </Form>
    )
}
