import Chart, {
    Animation,
    ArgumentAxis,
    CommonSeriesSettings,
    Font,
    Legend,
    LoadingIndicator,
    Pane,
    ScrollBar,
    SeriesTemplate,
    Size,
    Title,
    Tooltip,
    ValueAxis,
    ZoomAndPan
} from "devextreme-react/chart"
import { isEqual } from "lodash"
import {
    forwardRef,
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { HoverContext } from "../../report/contexts/HoverContext"
import { useExpression } from "../../utils/CustomHooks"
import "./GraphComponent.scss"
import { GraphParameters } from "./GraphEditor"

type Params = GraphParameters

type Props = ComponentProps<Params>

const LABEL = { format: "shortDateShortTime" }

const GraphComponent = forwardRef<IBaseComponent, Props>(
    ({ expression, series, realSize, scrollBar, aggregation, panes, axises }, ref) => {
        const component = useContext(ComponentContext)?.component!
        const hoverContext = useContext(HoverContext)

        const chartRef = useRef<Chart>(null)

        const orderData = useCallback(
            (data: Array<Array<any | undefined> | undefined> | undefined) => {
                const first = data?.shift?.()
                data?.sort((a, b) => {
                    if (a && b) {
                        return a?.toString()?.localeCompare?.(b?.toString())
                    }
                    return 0
                })
                data?.unshift(first)
            },
            []
        )

        const [tempData, setTempData] = useState<Array<Array<any>>>()
        const [evaluatedData] = useExpression(expression, [])
        useEffect(() => {
            if (!hoverContext?.hover || !tempData) {
                orderData(evaluatedData)
                if (!isEqual(evaluatedData, tempData)) {
                    setTempData(evaluatedData)
                }
            }
        }, [evaluatedData, tempData, orderData, hoverContext?.hover])

        const columnNames = useMemo(() => tempData?.[0], [tempData])
        const data = useMemo<Array<Record<string, any>> | undefined>(() => {
            if (columnNames) {
                return tempData?.slice(1)?.map?.((r: Array<any>) => ({
                    [columnNames[0]]: r[0],
                    [columnNames[1]]: parseFloat(r[1]),
                    [columnNames[2]]: r[2]
                }))
            }
            return undefined
        }, [tempData, columnNames])

        const customizeSeries = useCallback(
            (valueFromNameField: string) => {
                const serie = series?.find((s: any) => valueFromNameField?.includes?.(s.name))
                if (serie) {
                    return {
                        pane: serie.pane,
                        type: serie.type,
                        label: {
                            visible: serie.label?.visible
                        },
                        color: serie.color,
                        axis: serie.axis,
                        width: serie.width,
                        aggregation: {
                            enabled: serie.aggregation?.enabled,
                            method: serie.aggregation?.method
                        }
                    }
                }
                return {}
            },
            [series]
        )

        const legendClickHandler = useCallback((e: any) => {
            const series = e.target
            if (series.isVisible()) {
                series.hide()
            } else {
                series.show()
            }
        }, [])

        const aggregationInterval = useMemo(() => {
            if (aggregation?.interval) {
                return {
                    [aggregation.interval]: aggregation.intervalNum
                }
            }
        }, [aggregation])

        const panesResult = useMemo(
            () => panes?.map((p: any) => <Pane key={p.name} name={p.name} />),
            [panes]
        )

        const axisesResult = useMemo(
            () =>
                axises?.map((a: any) => {
                    return (
                        <ValueAxis
                            key={a.name}
                            name={a.name}
                            pane={a.pane}
                            color={a.color}
                            allowDecimals={a.allowDecimals ? true : false}
                            position={a.position}
                            valueMarginsEnabled={false}
                            wholeRange={a.wholeRange}
                            visualRange={a.wholeRange}
                            visualRangeUpdateMode="keep"
                            // maxValueMargin={0}
                        >
                            <Title text={a.title?.text}>
                                <Font color={a.title?.font?.color} />
                            </Title>
                        </ValueAxis>
                    )
                }),
            [axises]
        )

        return (
            <Chart
                className="chart-component"
                dataSource={data}
                ref={chartRef}
                onLegendClick={legendClickHandler}
                title={component.name}
                adjustOnZoom={false}
            >
                <Size width={realSize.width} />
                <Animation easing="linear" duration={500} maxPointCountSupported={100} />
                <LoadingIndicator enabled={true} />

                <ScrollBar visible={scrollBar} />
                {scrollBar && <ZoomAndPan argumentAxis="both" />}

                <Legend verticalAlignment="bottom" horizontalAlignment="center" />

                <Tooltip enabled={true} />

                <CommonSeriesSettings
                    argumentField={columnNames?.[0]}
                    valueField={columnNames?.[1]}
                />

                <ArgumentAxis
                    valueMarginsEnabled={false}
                    aggregationInterval={aggregationInterval}
                    argumentType="datetime"
                    label={LABEL}
                />

                <SeriesTemplate nameField={columnNames?.[2]} customizeSeries={customizeSeries} />

                {panesResult}
                {axisesResult}
            </Chart>
        )
    }
)

export default memo(GraphComponent)
