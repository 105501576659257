import { TextArea } from "devextreme-react"
import { Position } from "devextreme-react/popup"
import { memo, useCallback, useState } from "react"
import { MenuItem } from "./HeaderItems"
import "./HeaderMiddle.scss"

import { ReactComponent as CalculatorSVG } from "./svg/calculator.svg"
import { ReactComponent as Logo } from "./svg/logo.svg"

import { NativeEventInfo } from "devextreme/events"
import { evaluate } from "mathjs"
import MyPopup, { usePopup } from "../utils/MyPopup"

type Props = {
    loadCurrentUser: (path?: string) => void
}

const HeaderMiddle = ({ loadCurrentUser }: Props) => {
    const visualizationClick = () => {
        loadCurrentUser("/" + MenuItem.GROUPS)
    }

    return (
        <div className="middle-group">
            <div className="left-widgets">
                <div className="widget-button header-button">
                    <Calculator />
                </div>
            </div>
            <div className="header-button logo-button" onClick={visualizationClick}>
                <Logo />
            </div>
            <div className="relative-holder" style={{ display: "none" }}>
                <div className="right-widgets">
                    <div className="widget-button header-button">
                        <Tutorials />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(HeaderMiddle)

const Calculator = memo(() => {
    const [ref, instance] = usePopup()

    const [result, setResult] = useState("")

    const onExpressionChange = useCallback((e: NativeEventInfo<any, UIEvent>) => {
        const toEvaluate = e.component.option("text")
        let result = ""
        try {
            result = evaluate(toEvaluate)
        } catch (error) {
            result = error as any
        }
        console.log("calculator result", result)
        setResult(result + "")
    }, [])

    const popupRender = useCallback(
        () => (
            <>
                <TextArea
                    label="Výraz / převod jednotek"
                    labelMode="floating"
                    onInput={onExpressionChange}
                />
                <TextArea
                    value={result}
                    label="Výsledek"
                    labelMode="floating"
                    height="150px"
                    disabled={true}
                />
                <div>
                    <br></br>
                    <b>Zadejte např.:</b>
                    <br></br> 1 + 2 + sqrt(9)<br></br>12.7 cm to inch<br></br>
                    sin(45 deg) ^ 2<br></br>0x051 + 0b1110 + 10
                </div>
            </>
        ),
        [result, onExpressionChange]
    )

    return (
        <>
            <MyPopup
                ref={ref}
                title="Kalkulačka"
                width="400px"
                height="auto"
                contentRender={popupRender}
            >
                <Position my="top" at="left" of=".calculator" offset="0 50" />
            </MyPopup>
            <CalculatorSVG onClick={instance.showPopup} className="calculator" />
        </>
    )
})

const Tutorials = memo(() => {
    const [ref, instance] = usePopup()

    const popupContent = useCallback(() => <div>ahoj</div>, [])

    return (
        <>
            <MyPopup
                ref={ref}
                contentRender={popupContent}
                title="Tutoriály"
                width="300px"
                height="auto"
                hideOnOutsideClick={true}
                showCloseButton={false}
            >
                <Position my="top" at="left" of=".tutorials" offset="0 50" />
            </MyPopup>
            <CalculatorSVG onClick={instance.showPopup} className="tutorials" />
        </>
    )
})
