import { Position } from "reactflow"
import { isNumber } from "./NumberUtils"

export type Offset = {
    left: number
    top: number
    right: number
    bottom: number
}

export const POSITIONS_ORDER = [Position.Left, Position.Top, Position.Right, Position.Bottom]

export const POSITION_OFFSET_MAP = {
    [Position.Left]: [Position.Left, Position.Top],
    [Position.Top]: [Position.Left, Position.Top],
    [Position.Right]: [Position.Top, Position.Right],
    [Position.Bottom]: [Position.Left, Position.Bottom]
}

export const shiftPosition = (pos: Position, shift: number) => {
    const index = POSITIONS_ORDER.indexOf(pos)
    return POSITIONS_ORDER[(index + shift) % 4]
}

const checkOffset = (originalValue?: number, substituteValue?: number) => {
    if (originalValue === undefined || originalValue === null) {
        return 100 - (substituteValue ?? 0)
    }
    return originalValue
}

export const translateOffset = (offset: Partial<Offset>): Offset => {
    return {
        left: checkOffset(offset.left, offset.right),
        top: checkOffset(offset.top, offset.bottom),
        right: checkOffset(offset.right, offset.left),
        bottom: checkOffset(offset.bottom, offset.top)
    }
}

export const clearOffset = (offset: Partial<Offset>, position: Position) => {
    return {
        left: POSITION_OFFSET_MAP[position].includes(Position.Left) ? offset.left : undefined,
        top: POSITION_OFFSET_MAP[position].includes(Position.Top) ? offset.top : undefined,
        right: POSITION_OFFSET_MAP[position].includes(Position.Right) ? offset.right : undefined,
        bottom: POSITION_OFFSET_MAP[position].includes(Position.Bottom) ? offset.bottom : undefined
    }
}

const plusMinus = (value: number): string => {
    if (value) {
        return value > 0 ? "+" : "-"
    }
    return ""
}

const POINT_QUART = 4

export const offsetFromStyle = (offset: Partial<Offset>, oldP: Position, newP: Position) => {
    const shift = POSITIONS_ORDER.indexOf(oldP) - POSITIONS_ORDER.indexOf(newP)

    let leftPx = 0,
        topPx = 0,
        rightPx = 0,
        bottomPx = 0
    if (oldP === Position.Left && newP === Position.Top) {
        leftPx = POINT_QUART
        topPx = -POINT_QUART
    } else if (oldP === Position.Bottom && newP === Position.Left) {
        leftPx = -POINT_QUART
        topPx = -POINT_QUART
    } else if (oldP === Position.Right && newP === Position.Top) {
        leftPx = -POINT_QUART
        topPx = -POINT_QUART
    } else if (oldP === Position.Top && newP === Position.Right) {
        topPx = POINT_QUART
        rightPx = -POINT_QUART
    } else if (oldP === Position.Left && newP === Position.Bottom) {
        leftPx = POINT_QUART
        bottomPx = -POINT_QUART
    } else if (oldP === Position.Top && newP === Position.Left) {
        leftPx = -POINT_QUART
        topPx = POINT_QUART
    } else if (oldP === Position.Bottom && newP === Position.Right) {
        topPx = -POINT_QUART
        rightPx = -POINT_QUART
    } else if (oldP === Position.Right && newP === Position.Bottom) {
        leftPx = -POINT_QUART
        bottomPx = -POINT_QUART
    } else if (Math.abs(shift) === 2) {
        if (oldP === Position.Left || oldP === Position.Right) {
            leftPx = -2 * POINT_QUART
            rightPx = -2 * POINT_QUART
        } else if (oldP === Position.Top || oldP === Position.Bottom) {
            topPx = -2 * POINT_QUART
            bottomPx = -2 * POINT_QUART
        }
    }

    return {
        left: isNumber(offset.left)
            ? `calc(${offset.left}% ${plusMinus(leftPx)} ${leftPx ? Math.abs(leftPx) + "px" : ""})`
            : undefined,
        top: isNumber(offset.top)
            ? `calc(${offset.top}% ${plusMinus(topPx)} ${topPx ? Math.abs(topPx) + "px" : ""})`
            : undefined,
        right: isNumber(offset.right)
            ? `calc(${offset.right}% ${plusMinus(rightPx)} ${
                  rightPx ? Math.abs(rightPx) + "px" : ""
              })`
            : undefined,
        bottom: isNumber(offset.bottom)
            ? `calc(${offset.bottom}% ${plusMinus(bottomPx)} ${
                  bottomPx ? Math.abs(bottomPx) + "px" : ""
              })`
            : undefined
    }
}
