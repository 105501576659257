import { memo, useCallback, useMemo } from "react"
import { EdgeLabelRenderer, EdgeProps, SmoothStepEdge, getSmoothStepPath } from "reactflow"

import { Button } from "devextreme-react"
import { PIPE_SIZES } from "../../components/Pipe/PipeEditor"
import { TankColor } from "../../components/Tank/TankEditor"
import { useAppStore } from "../../stores/AppStore"
import { useEdgeEditorStore } from "../../stores/EdgeEditorStore"
import { useReportStore } from "../../stores/ReportStore"
import { EdgeData } from "../../types/BaseTypes"
import ColorUtils from "../../utils/ColorUtils"
import { useExpression, useReport2Fields } from "../../utils/CustomHooks"
import "./FlowEdge.scss"

type Props = EdgeProps<EdgeData>

const SPEED_SEC_MULTIPLIER = 0.005
const MIN_SPEED_SEC = 1
const MAX_SPEED_SEC = 5
const MAX_SPEED_VALUE = 100
const FIELD_SIZE_MULTIPLIER = 4
const SPACE_SIZE_MULTIPLIER = 1.5

const FlowEdge = ({
    id,
    source,
    target,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    markerEnd,
    selected
}: Props) => {
    const animations = useAppStore((state) => state.deviceSetup).animations

    const removeEdge = useReportStore((state) => state.removeEdge)

    const [editMode] = useReport2Fields((s) => [s.editMode])

    const setPopupVisible = useEdgeEditorStore((state) => state.setPopupVisible)
    const setData = useEdgeEditorStore((state) => state.setData)

    const { color, size, intensityExpression, reverseExpression } = data ?? {
        color: TankColor.BLUE
    }

    const [intensity] = useExpression(intensityExpression, 0)
    const [reverse] = useExpression(reverseExpression, false)

    const editorClick = useCallback(() => {
        setData(data ?? {}, id)
        setPopupVisible(true)
    }, [data, id, setData, setPopupVisible])

    const removeConnection = useCallback(() => {
        removeEdge(id)
    }, [id, removeEdge])

    const pipeSize = useMemo(() => PIPE_SIZES[(size ?? 3) - 1], [size])

    const styleBorder = useMemo(() => {
        return {
            stroke: "var(--gray-light)",
            strokeWidth: pipeSize
        }
    }, [pipeSize])

    const styleSelected = useMemo(() => {
        return {
            stroke: "var(--primary-dark)",
            strokeWidth: styleBorder.strokeWidth + 4
        }
    }, [styleBorder])

    const styleColor = useMemo(() => {
        return {
            stroke:
                !animations && intensity
                    ? ColorUtils.constantFromColorSecond(color)
                    : ColorUtils.constantFromColorLight(color),
            strokeWidth: styleBorder.strokeWidth * 0.6
        }
    }, [styleBorder, color, animations, intensity])

    const styleAnimation = useMemo(() => {
        const strokeField = pipeSize * FIELD_SIZE_MULTIPLIER
        const strokeSpace = strokeField * SPACE_SIZE_MULTIPLIER

        let offset = strokeField + strokeSpace
        if (reverse) {
            offset = -(2 * offset - 10)
        } else {
            offset = 2 * offset + 10
        }

        const speed =
            (MIN_SPEED_SEC +
                (MAX_SPEED_SEC - MIN_SPEED_SEC) * ((MAX_SPEED_VALUE - intensity) / 100)) *
            SPEED_SEC_MULTIPLIER *
            (strokeSpace + strokeField)

        return {
            stroke: ColorUtils.constantFromColor(color),
            strokeWidth: styleColor.strokeWidth,
            animation: `dashdraw ${speed}s linear infinite`,
            strokeDasharray: `${strokeField} ${strokeSpace}`,
            strokeDashoffset: offset,
            strokeLinecap: "round"
        } as any
    }, [styleColor, color, intensity, reverse, pipeSize])

    const [, labelX, labelY] = useMemo(
        () =>
            getSmoothStepPath({
                sourceX,
                sourceY,
                sourcePosition,
                targetX,
                targetY,
                targetPosition
            }),
        [sourcePosition, sourceX, sourceY, targetPosition, targetX, targetY]
    )

    const buttonsStyle = useMemo(
        () => ({
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            visibility: (selected ? "visible" : "hidden") as any,
            zIndex: 10
        }),
        [labelX, labelY, selected]
    )

    return (
        <>
            {editMode && selected && (
                <SmoothStepEdge
                    sourceX={sourceX}
                    sourceY={sourceY}
                    targetX={targetX}
                    targetY={targetY}
                    sourcePosition={sourcePosition}
                    targetPosition={targetPosition}
                    id={id + "-1"}
                    source={source}
                    target={target}
                    markerEnd={markerEnd}
                    style={styleSelected}
                />
            )}
            <SmoothStepEdge
                sourceX={sourceX}
                sourceY={sourceY}
                targetX={targetX}
                targetY={targetY}
                sourcePosition={sourcePosition}
                targetPosition={targetPosition}
                id={id + "0"}
                source={source}
                target={target}
                markerEnd={markerEnd}
                style={styleBorder}
            />
            <SmoothStepEdge
                sourceX={sourceX}
                sourceY={sourceY}
                targetX={targetX}
                targetY={targetY}
                sourcePosition={sourcePosition}
                targetPosition={targetPosition}
                id={id + "1"}
                source={source}
                target={target}
                markerEnd={markerEnd}
                style={styleColor}
            />
            {animations && intensity && (
                <SmoothStepEdge
                    animated={true}
                    sourceX={sourceX}
                    sourceY={sourceY}
                    targetX={targetX}
                    targetY={targetY}
                    sourcePosition={sourcePosition}
                    targetPosition={targetPosition}
                    id={id + "2"}
                    source={source}
                    target={target}
                    markerEnd={markerEnd}
                    style={styleAnimation}
                />
            )}
            <EdgeLabelRenderer>
                {editMode && (
                    <div
                        className="edge-buttons smaller-dx-button nodrag nopan"
                        style={buttonsStyle}
                    >
                        <div>
                            <Button icon="preferences" hint="Nastavení" onClick={editorClick} />
                            <Button icon="close" hint="Zrušit spojení" onClick={removeConnection} />
                        </div>
                    </div>
                )}
            </EdgeLabelRenderer>
        </>
    )
}

export const edgeTypes = {
    customEdge: FlowEdge
}

export default memo(FlowEdge)
