import { RefObject } from "react"

import DataGrid, {
    Column,
    Editing,
    Form,
    Grouping,
    GroupPanel,
    MasterDetail,
    Paging,
    RequiredRule,
    SearchPanel,
    Selection
} from "devextreme-react/data-grid"
import { Item } from "devextreme-react/form"
import ArrayStore from "devextreme/data/array_store"

import { Button, Popup } from "devextreme-react"
import {
    IDBConnection,
    IGroup,
    INotificationDefinition,
    INotificationGroup,
    IUser
} from "../types/BaseTypes"
import { gqlRead } from "../utils/APIUtils"
import BaseCollection, {
    CollectionProps,
    CollectionState,
    TABLE_HEIGHT_IN_VH
} from "./BaseCollection"

type NotificationsState = CollectionState<INotificationGroup> & {
    popupVisible: boolean
    notificationGroupId?: number
    innerKey: number
    groups: Array<IGroup>
    users: Array<IUser>
    dbConnections: Array<IDBConnection>
}

class Notifications extends BaseCollection<
    INotificationGroup,
    CollectionProps,
    NotificationsState
> {
    private usersTagBoxOptions: any
    private dbConnectionsSelectBoxOptions: any

    constructor(props: CollectionProps) {
        super(props, {
            entityName: "notificationGroup",
            subEntitiesToIds: ["group", "users", "dbConnection"],
            copyButton: true,
            deepCopyButton: true,
            deepDeleteButton: true,
            title: "Skupiny notifikací"
        })
        this.state = {
            ...this.state,
            popupVisible: false,
            innerKey: 0,
            groups: [],
            users: [],
            dbConnections: []
        }

        this.usersTagBoxOptions = {
            ...BaseCollection.TAG_BOX_OPTIONS
        }

        this.dbConnectionsSelectBoxOptions = {
            ...BaseCollection.SELECT_BOX_OPTIONS
        }
    }

    protected getEntities = (): Promise<Array<IGroup>> => {
        return gqlRead({
            notificationGroups: {
                id: true,
                name: true,
                disabled: true,
                smsKey: true,
                smsUtf: true,
                delayInS: true,
                users: {
                    id: true
                },
                group: {
                    id: true
                },
                dbConnection: {
                    id: true
                }
            },
            groups: {
                id: true,
                name: true,
                users: {
                    id: true
                }
            },
            users: {
                id: true,
                name: true,
                groups: {
                    id: true
                }
            },
            dbConnections: {
                id: true,
                name: true,
                group: {
                    id: true
                }
            }
        }).then((r) => {
            this.setState({ groups: r.groups, users: r.users, dbConnections: r.dbConnections })
            return r.notificationGroups
        })
    }

    onGroupChanged = (object: { data?: { group: number } }) => {
        const group = object.data?.group
        if (group) {
            this.filterUsers(group)
            this.filterDbConnections(group)
            this.forceUpdate()
        }
    }

    filterUsers = (group: number) => {
        const data = this.state.users?.filter((u) => u.groups?.some((g) => g.id === group))
        this.usersTagBoxOptions.dataSource = new ArrayStore({ data: data, key: "id" })
        return data
    }

    filterDbConnections = (group: number) => {
        const data = this.state.dbConnections?.filter((db) => db.group?.id === group)
        this.dbConnectionsSelectBoxOptions.dataSource = new ArrayStore({ data: data, key: "id" })
        return data
    }

    setCellValueGroup = (
        newData: any,
        value: number,
        currentRowData: { users: Array<number>; dbConnection: number }
    ) => {
        const availableUsers = this.filterUsers(value)
        let actualUsers = currentRowData.users
        actualUsers = actualUsers?.filter((r: any) =>
            availableUsers?.some((user: any) => user.id === r)
        )

        const availableDbs = this.filterDbConnections(value)
        const actualDb = availableDbs.some((db) => db.id === currentRowData.dbConnection)
            ? currentRowData.dbConnection
            : null

        newData.group = value
        newData.users = actualUsers
        newData.dbConnection = actualDb
    }

    private showPopup = () => {
        this.setState({ popupVisible: true })
    }

    private hidePopup = () => {
        this.setState({ popupVisible: false })
    }

    private notDefsClick = (notGroupId: number) => {
        this.setState({ innerKey: this.state.innerKey + 1, notificationGroupId: notGroupId })
        this.showPopup()
    }

    private customRender = (e: { data: INotificationGroup }) => {
        return <Button text="ahoj" onClick={() => this.notDefsClick(e.data.id!)} />
    }

    private renderPopup = () => {
        return (
            <></>
            // <NotificationDefinitionsGrid data={{ data: { id: this.state.notificationGroupId } }} />
        )
    }

    protected renderGrid = (
        dataSource: any,
        ref: RefObject<DataGrid>,
        offsetTop?: number
    ): JSX.Element => {
        return (
            <>
                <Popup
                    key={this.state.innerKey}
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopup}
                    contentRender={this.renderPopup}
                ></Popup>

                <DataGrid
                    ref={ref}
                    dataSource={dataSource}
                    showBorders={true}
                    columnHidingEnabled={true}
                    onEditorPreparing={this.onEditorPreparing}
                    onToolbarPreparing={this.onToolbarPreparing}
                    // TODO DEVEXTREME
                    onSelectionChanged={this.onSelectionChanged as any}
                    onEditingStart={this.onGroupChanged}
                    height={`calc(${TABLE_HEIGHT_IN_VH}vh - ${offsetTop}px)`}
                >
                    <Paging enabled={false} />
                    <Selection
                        mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="onClick"
                    />
                    <SearchPanel visible={true} />
                    <Editing
                        mode="popup"
                        useIcons={true}
                        allowDeleting={true}
                        allowAdding={true}
                        allowUpdating={true}
                    >
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item itemType="group" caption="Základní">
                                    <Item dataField="name" />
                                    <Item dataField="disabled" editorType="dxCheckBox" />
                                    <Item
                                        dataField="delayInS"
                                        editorType="dxNumberBox"
                                        helpText="Zpoždění skutečného vytvoření notifikace po výskytu v [s]"
                                    />
                                </Item>
                                <Item itemType="group" caption="Uživatelé">
                                    <Item
                                        editorType="dxSelectBox"
                                        dataField="group"
                                        editorOptions={{
                                            ...BaseCollection.SELECT_BOX_OPTIONS,
                                            dataSource: this.state.groups
                                        }}
                                    />
                                    <Item
                                        editorType="dxSelectBox"
                                        dataField="dbConnection"
                                        editorOptions={this.dbConnectionsSelectBoxOptions}
                                    />
                                    <Item
                                        editorType="dxTagBox"
                                        dataField="users"
                                        editorOptions={this.usersTagBoxOptions}
                                    />
                                </Item>
                                <Item itemType="group" caption="Ostatní">
                                    <Item dataField="smsKey" />
                                    <Item dataField="smsUtf" editorType="dxCheckBox" />
                                </Item>
                            </Item>
                        </Form>
                    </Editing>
                    <MasterDetail enabled={true} component={NotificationDefinitionsGrid} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={false} />

                    <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                    {/* test popupu namisto master detailu 
                    <Column cellRender={this.customRender} /> 
                    */}
                    <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                    <Column dataField="name" caption="Skupina notifikací">
                        <RequiredRule />
                    </Column>
                    <Column dataField="disabled" caption="Vypnuto" />
                    <Column dataField="delayInS" caption="Zpoždění" />
                    <Column
                        dataField="group"
                        caption="Skupina"
                        groupIndex={0}
                        visible={false}
                        customizeText={(cellInfo: any) =>
                            this.fromNames(cellInfo, this.state.groups)
                        }
                        setCellValue={this.setCellValueGroup}
                    ></Column>
                    <Column
                        dataField="dbConnection"
                        caption="Databázové spojení"
                        customizeText={(cellInfo: any) =>
                            this.fromNames(cellInfo, this.state.dbConnections)
                        }
                    ></Column>
                    <Column
                        dataField="users"
                        caption="Uživatelé"
                        customizeText={(cellInfo: any) =>
                            this.fromNames(cellInfo, this.state.users)
                        }
                    ></Column>
                    <Column dataField="smsKey" caption="Sms klíč"></Column>
                    <Column dataField="smsUtf" caption="Sms UTF"></Column>
                </DataGrid>
            </>
        )
    }
}

export default Notifications

type NotificationDefinitionsProps = CollectionProps & {
    data: { data: INotificationGroup }
}

type NotificationDefinitionsState = CollectionState<INotificationDefinition> & {
    notificationGroup: INotificationGroup
}

export class NotificationDefinitionsGrid extends BaseCollection<
    INotificationDefinition,
    NotificationDefinitionsProps,
    NotificationDefinitionsState
> {
    public static typeOptions = {
        valueExpr: "value",
        displayExpr: "text",
        items: [
            {
                text: "Více než",
                value: "MORE_THEN"
            },
            {
                text: "Méně než",
                value: "LESS_THEN"
            }
        ]
    }

    constructor(props: NotificationDefinitionsProps) {
        super(props, {
            entityName: "notificationDefinition",
            copyButton: true,
            deepDeleteButton: true,
            title: "Definice notifikací skupiny " + props.data.data.name,
            subEntitiesToIds: ["notificationGroup"]
        })
        this.state = {
            ...this.state,
            notificationGroup: props.data.data
        }
    }

    protected getEntities = (): Promise<Array<IGroup>> => {
        return gqlRead({
            notificationDefinitions: {
                __args: {
                    input: { notificationGroup: { id: this.state.notificationGroup.id } }
                },
                id: true,
                name: true,
                message: true,
                secondMessage: true,
                telephoneNumbers: true,
                emails: true,
                master: true,
                table: true,
                idColumn: true,
                uniqueColumns: true,
                valueColumn: true,
                dateColumn: true,
                conditionType: true,
                number: true,
                notificationGroup: {
                    id: true
                }
            }
        }).then((r) => {
            return r.notificationDefinitions
        })
    }

    private onInitNewRow = (object: { data: INotificationDefinition }) => {
        object.data.notificationGroup = this.state.notificationGroup.id as any
    }

    protected renderGrid = (dataSource: any, ref: RefObject<DataGrid>): JSX.Element => {
        return (
            <DataGrid
                keyExpr="notDefId"
                ref={ref}
                dataSource={dataSource}
                showBorders={true}
                columnHidingEnabled={true}
                onToolbarPreparing={this.onToolbarPreparing}
                onSelectionChanged={this.onSelectionChanged}
                onInitNewRow={this.onInitNewRow as any}
            >
                <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="onClick" />
                <SearchPanel visible={true} />
                <Editing
                    mode="popup"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                >
                    <Form>
                        <Item itemType="group" colCount={3} colSpan={2}>
                            <Item itemType="group" caption="Základní">
                                <Item dataField="name" />
                                <Item dataField="message" editorType="dxTextArea" />
                                <Item dataField="secondMessage" editorType="dxTextArea" />
                                <Item
                                    dataField="telephoneNumbers"
                                    editorType="dxTextArea"
                                    helpText="Oddělte čárkou."
                                />
                                <Item
                                    dataField="emails"
                                    editorType="dxTextArea"
                                    helpText="Oddělte čárkou."
                                />
                                <Item
                                    dataField="master"
                                    editorType="dxCheckBox"
                                    helpText="Při aktivaci této notifikace se všechny ostatní výskyty ze stejné skupiny notifikací zruší."
                                />
                            </Item>
                            <Item itemType="group" caption="Podmínky">
                                <Item
                                    dataField="conditionType"
                                    editorType="dxRadioGroup"
                                    editorOptions={NotificationDefinitionsGrid.typeOptions}
                                />
                                <Item dataField="number" editorType="dxNumberBox" />
                            </Item>
                            <Item itemType="group" caption="Zdroj sql">
                                <Item dataField="table" />
                                <Item itemType="group" caption="Sloupce">
                                    <Item dataField="idColumn" />
                                    <Item
                                        dataField="uniqueColumns"
                                        helpText="Udává sloupec/sloupce k získání typu hodnoty ve formátu: id = 3070 AND device_id = 2"
                                    />
                                    <Item dataField="valueColumn" />
                                    <Item dataField="dateColumn" />
                                </Item>
                            </Item>
                        </Item>
                    </Form>
                </Editing>

                <Column type="buttons" buttons={BaseCollection.TABLE_BUTTONS} />
                <Column dataField="id" caption="ID" allowEditing={false} visible={false} />
                <Column dataField="name" caption="Název">
                    <RequiredRule />
                </Column>
                <Column dataField="message" caption="Zpráva výskytu">
                    <RequiredRule />
                </Column>
                <Column dataField="secondMessage" caption="Zpráva konce výskytu"></Column>
                <Column dataField="telephoneNumbers" caption="Telefonní čísla"></Column>
                <Column dataField="emails" caption="Emaily"></Column>
                <Column dataField="master" caption="Master"></Column>

                <Column dataField="conditionType" caption="Typ podmínky"></Column>
                <Column dataField="number" caption="Hodnota"></Column>

                <Column dataField="table" caption="Tabulka"></Column>
                <Column dataField="idColumn" caption="Id řádku"></Column>
                <Column dataField="uniqueColumns" caption="Unikátnost hodnoty"></Column>
                <Column dataField="valueColumn" caption="Hodnota"></Column>
                <Column dataField="dateColumn" caption="Datum"></Column>
            </DataGrid>
        )
    }
}
