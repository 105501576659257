import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback } from "react"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action } from "../../types/ComponentTypes"
import { ButtonParams } from "../Button/ButtonEditor"
import { ComponentTextSize, TextSizeSettings } from "../Text/TextEditor"

export type ButtonGroupParameters = BaseParams & {
    expression: string
    enabledExpression: string
    fontSize: ComponentTextSize
    buttons?: Array<ButtonParams>
    onChangeActions?: Array<Action>
}

export const getButtonGroupBasicConfigurations = () => {
    return [{}]
}

function ButtonGroupEditor({ parameters }: EditorProps<ButtonGroupParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <TabBasic parameters={parameters} />
            </Item>
            <Item title="Výrazy">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                            <Label text="Výraz" />
                        </SimpleItem>
                        <SimpleItem render={initMonacoEditor(parameters, "enabledExpression")}>
                            <Label text="Výraz aktivnosti" />
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
            <Item title="Actions">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem colCount={2}>
                            <GroupItem caption="OnChange">
                                <ActionsTriggerEditor
                                    listOwner={parameters}
                                    listName="onChangeActions"
                                    expressionHelpText="K aktuální hodnotě je možné přistoupit pomocí self.value, kde value je index tlačítka."
                                />
                            </GroupItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(ButtonGroupEditor)

type ParametersProps = {
    parameters: ButtonGroupParameters
}

const TabBasic = memo(({ parameters }: ParametersProps) => {
    const buttonEditorRender = useCallback((item: ButtonParams) => {
        return (
            <GroupItem>
                <TabPanel animationEnabled={true}>
                    <Item title="Základní">
                        <Form formData={item}>
                            <SimpleItem render={initMonacoEditor(item, "textExpression")}>
                                <Label text="Výraz textu" />
                            </SimpleItem>
                        </Form>
                    </Item>
                    <Item title="OnClick">
                        <ActionsTriggerEditor listOwner={item} listName="onClickActions" />
                    </Item>
                </TabPanel>
            </GroupItem>
        )
    }, [])

    const buttonRender = useCallback((item: ButtonParams) => {
        return <div>{`${item.textExpression}`}</div>
    }, [])

    return (
        <div className="component-setup-container">
            <Form formData={parameters}>
                <GroupItem colCount={2}>
                    <GroupItem>
                        <GroupItem>
                            <TextSizeSettings parameters={parameters} />
                        </GroupItem>
                    </GroupItem>
                    <GroupItem>
                        <ListPopupEditor
                            listOwner={parameters}
                            listName="buttons"
                            formItemContent={buttonEditorRender}
                            guid={true}
                            idProperty="guid"
                            itemRender={buttonRender}
                        />
                    </GroupItem>
                </GroupItem>
            </Form>
        </div>
    )
})
