import { isMobile } from "detect-touch-device"
import { RefObject, memo, useCallback, useEffect, useMemo, useRef } from "react"
import { DropTargetMonitor } from "react-dnd"
import ReactFlow, {
    Background,
    BackgroundVariant,
    ConnectionMode,
    Node,
    PanOnScrollMode,
    ReactFlowProvider,
    ReactFlowRefType,
    Viewport,
    useReactFlow
} from "reactflow"
import { useShallow } from "zustand/react/shallow"
import { useReportStore } from "../../stores/ReportStore"
import { useReportStore2 } from "../../stores/ReportStore2"
import { useSelectionStore } from "../../stores/SelectionStore"
import { IBook, NodeData } from "../../types/BaseTypes"
import ComponentUtils from "../../utils/ComponentUtils"
import { useReport2Fields, useReportFields } from "../../utils/CustomHooks"
import ReportUtils from "../../utils/ReportUtils"
import { DraggableType, DroppableContainer } from "../../utils/dnd/DroppableContainer"
import FlowContextMenu from "../flowContextMenu/FlowContextMenu"
import EdgePopupEditor from "./EdgePopupEditor"
import "./Flow.scss"
import { edgeTypes } from "./FlowEdge"
import { nodeTypes } from "./FlowNode"

export const FLOW_WIDTH = 1910

const GRID_BASE: [number, number] = [5, 5]
const GRID_MIN: [number, number] = [1, 1]
const MIN_ZOOM = 0.1
const MAX_ZOOM = 2

type Props = {}

const FlowInner = memo((props: Props) => {
    const [reportId, flow] = useReportFields((r) => [r.id, r.parameters.flow])
    const onNodesChange = useReportStore((state) => state.onNodesChange)
    const onEdgesChange = useReportStore((state) => state.onEdgesChange)
    const onConnect = useReportStore((state) => state.onConnect)
    const addFlowComponent = useReportStore((state) => state.addFlowComponent)
    // const setNodesIntersecting = useReportStore((state) => state.setNodesIntersecting)

    const [schemaScale, scrollEnabled] = useReportStore2(
        useShallow((state) => [state.schemaScale, state.scrollEnabled])
    )
    const [editMode, gridActive] = useReport2Fields((s) => [s.editMode, s.gridActive])

    const onSelectionChange = useSelectionStore((state) => state.onSelectionChange)

    const nodes = useMemo(() => flow?.nodes ?? [], [flow?.nodes])
    const edges = useMemo(() => flow?.edges ?? [], [flow?.edges])

    const ref: RefObject<ReactFlowRefType> = useRef(null)
    const reactFlowWrapper: RefObject<HTMLDivElement> = useRef(null)

    const { setViewport, project } = useReactFlow()

    useEffect(() => {
        if (!editMode) {
            let zoom = schemaScale
            if (zoom > MAX_ZOOM) {
                zoom = MAX_ZOOM
            } else if (zoom < MIN_ZOOM) {
                zoom = MIN_ZOOM
            }
            setViewport({ x: 0, y: 0, zoom })
        }
    }, [schemaScale, setViewport, editMode])

    const onMove = useCallback(
        (event: MouseEvent | TouchEvent, viewport: Viewport) => {
            if (!editMode) {
                let { x: newX, y: newY, zoom: newZoom } = viewport
                const width = ref.current?.offsetWidth ?? 0
                let needsChangeViewpoert = false

                if (newZoom < schemaScale) {
                    newZoom = schemaScale
                }
                const xOffset = -((width / schemaScale) * newZoom - width)
                // zde musi byt kontrola na xOffset <= 0, protoze jinak se to zacykli.
                // xOffset je totiz nekdy velmi male cislo, napr. 1e-13
                if (xOffset <= 0 && newX < xOffset) {
                    newX = xOffset
                    needsChangeViewpoert = true
                }
                if (newX > 0) {
                    newX = 0
                    needsChangeViewpoert = true
                }
                if (newY > 0) {
                    newY = 0
                    needsChangeViewpoert = true
                }

                if (needsChangeViewpoert) {
                    setViewport({ x: newX, y: newY, zoom: newZoom }, { duration: 0 })
                }
            }
        },
        [editMode, schemaScale, setViewport]
    )

    // const onNodeDrag = useCallback(
    //     (_: MouseEvent, node: Node) => {
    //         const intersections = getIntersectingNodes(node).map((n) => n.id)
    //         setNodesIntersecting(intersections)
    //     },
    //     [setNodesIntersecting, getIntersectingNodes]
    // )

    const onDrop = useCallback(
        (item: IBook, monitor: DropTargetMonitor<any, any>) => {
            const newComponent = ComponentUtils.cloneComponent(item.component)
            ReportUtils.prepareComponentToEdit(newComponent)

            var doc = document.documentElement
            var left = doc.clientLeft || 0
            var top = doc.clientTop || 0

            const mouseOffset = monitor.getClientOffset()
            const sourceOffset = monitor.getSourceClientOffset()
            let x = 0,
                y = 0
            if (mouseOffset) {
                x = mouseOffset.x
                y = mouseOffset.y
                if (sourceOffset) {
                    x -= x - sourceOffset.x
                    y -= y - sourceOffset.y
                }
            }
            x += left
            y += top

            const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect()
            const position = project({
                x: x - (reactFlowBounds?.left ?? 0),
                y: y - (reactFlowBounds?.top ?? 0)
            })
            const newNode: Node<NodeData> = {
                id: newComponent.guid,
                type: "customNode",
                position,
                data: { componentGuid: newComponent.guid },
                style: { width: item.width! / schemaScale, height: item.height! / schemaScale }
            }

            addFlowComponent(newComponent, newNode)
        },
        [addFlowComponent, schemaScale, project]
    )

    const className = useMemo(() => `reactflow-wrapper ${editMode ? "flow-edit-mode" : ""}`, [
        editMode
    ])

    return (
        <>
            {editMode && <FlowContextMenu selector=".flow-container" />}
            <DroppableContainer
                className="flow-container"
                onDrop={onDrop}
                draggableType={DraggableType.LIBRARY}
            >
                <div className={className} ref={reactFlowWrapper}>
                    <ReactFlow
                        id={reportId + ""}
                        ref={ref}
                        nodes={nodes}
                        edges={edges}
                        // onNodeDrag={onNodeDrag}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        snapToGrid={true}
                        snapGrid={gridActive ? GRID_BASE : GRID_MIN}
                        connectionMode={ConnectionMode.Loose}
                        panOnScrollMode={PanOnScrollMode.Vertical}
                        selectionKeyCode={"Control"}
                        multiSelectionKeyCode={"Control"}
                        onSelectionChange={onSelectionChange}
                        // editMode
                        panOnScroll={!editMode && scrollEnabled}
                        nodesDraggable={editMode}
                        zoomOnScroll={editMode}
                        panOnDrag={editMode || isMobile}
                        zoomOnDoubleClick={editMode}
                        // responsivity
                        onMove={onMove}
                        maxZoom={MAX_ZOOM}
                        minZoom={MIN_ZOOM}
                    >
                        {/* {editMode && <MiniMap />} */}
                        {editMode && (
                            <Background variant={BackgroundVariant.Dots} gap={10} size={1} />
                        )}
                    </ReactFlow>
                </div>
            </DroppableContainer>
            {editMode && <EdgePopupEditor />}
        </>
    )
})

function Flow(props: Props) {
    return (
        <ReactFlowProvider>
            <FlowInner />
        </ReactFlowProvider>
    )
}

export default memo(Flow)
