import { confirm } from "devextreme/ui/dialog"
import React from "react"
import { toast, ToastOptions } from "react-toastify"

// export type ErrorObject = {
//     status: number
//     error: string
//     message: string
// }

export enum ToastType {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error"
}

export const STANDARD_NOTIFY_OPTIONS: ToastOptions = {
    position: "top-center",
    style: { top: 50 },
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
}

export const DEFAULT_TOAST_DURATION = 3000

export function standardMessage(
    type?: ToastType,
    message?: string,
    toastId?: React.ReactText,
    autoClose = 3000
) {
    if (message) {
        switch (type) {
            case ToastType.SUCCESS:
                standardSuccess(message, toastId, autoClose)
                break
            case ToastType.WARNING:
                standardWarning(message, toastId, autoClose)
                break
            case ToastType.ERROR:
                standardError(message, toastId, autoClose)
                break
        }
    }
}

export function standardSuccess(
    message: string,
    toastId?: React.ReactText,
    autoClose = DEFAULT_TOAST_DURATION
) {
    if (toastId) {
        toast.update(toastId, {
            ...STANDARD_NOTIFY_OPTIONS,
            render: message,
            type: ToastType.SUCCESS,
            isLoading: false,
            autoClose
        })
    } else {
        toast.success(message, { ...STANDARD_NOTIFY_OPTIONS, autoClose, toastId: message })
    }
}

export function standardWarning(
    message: string,
    toastId?: React.ReactText,
    autoClose = DEFAULT_TOAST_DURATION
) {
    if (toastId) {
        toast.update(toastId, {
            ...STANDARD_NOTIFY_OPTIONS,
            render: message,
            type: ToastType.WARNING,
            isLoading: false,
            autoClose
        })
    } else {
        toast.warn(message, { ...STANDARD_NOTIFY_OPTIONS, autoClose, toastId: message })
    }
}

export function standardError(
    error: any,
    toastId?: React.ReactText,
    autoClose = DEFAULT_TOAST_DURATION
) {
    const message = (error.error ? error.error + "\n" : "") + error.message
    if (toastId) {
        toast.update(toastId, {
            ...STANDARD_NOTIFY_OPTIONS,
            render: message,
            type: ToastType.ERROR,
            isLoading: false,
            autoClose
        })
    } else {
        toast.error(message, { ...STANDARD_NOTIFY_OPTIONS, autoClose, toastId: message })
    }
}

export const confirmation = (message: string, title: string, ok: () => void) => {
    let result = confirm(message, title)
    result.then((result) => {
        if (result === true) {
            ok()
        }
    })
}
