import { Button, Form } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import Popup, { ToolbarItem } from "devextreme-react/popup"
import { cloneDeep } from "lodash"
import {
    forwardRef,
    memo,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useState
} from "react"
import { useReportStore } from "../../../../../stores/ReportStore"
import { useReportStore2 } from "../../../../../stores/ReportStore2"
import { ComponentContext } from "../../../../contexts/ComponentContext"
import { ReportPathContext } from "../../../../contexts/ReportPathContext"

type Props = {
    coordinates: ComponentCoordinates
}

export type ComponentCoordinates = {
    x?: number
    y?: number
    w?: number
    h?: number
}

export interface IComponentCoordinatesEditor {
    handlePopupShow: () => void
}

const ComponentCoordinatesEditor = forwardRef<IComponentCoordinatesEditor, Props>(
    ({ coordinates }: Props, ref) => {
        const setReportPopupVisible = useReportStore2((state) => state.setPopupVisible)

        const componentContext = useContext(ComponentContext)
        const component = componentContext?.component
        const name = component?.name

        const [popupVisible, setPopupVisible] = useState(false)
        const [fullscreen, setFullscreen] = useState(false)

        useImperativeHandle(ref, () => ({
            handlePopupShow: () => {
                setPopupVisible(true)
                setReportPopupVisible(true)
            }
        }))

        const fullscreenButtonOptions = {
            icon: "fullscreen",
            onClick: () => setFullscreen(!fullscreen)
        }

        const handlePopupHidden = () => {
            setPopupVisible(false)
            setReportPopupVisible(false)
        }

        const hidePopup = () => {
            handlePopupHidden()
        }

        return (
            <Popup
                width="auto"
                height="auto"
                showTitle={true}
                fullScreen={fullscreen}
                title={"Souřadnice a vrstva komponenty " + (name ? name : "")}
                dragEnabled={true}
                visible={popupVisible}
                onHiding={handlePopupHidden}
                contentRender={() => (
                    <>
                        <ToolbarItem
                            widget="dxButton"
                            location="after"
                            options={fullscreenButtonOptions}
                        ></ToolbarItem>

                        <CoordinatesEditor coordinates={coordinates} hidePopup={hidePopup} />
                    </>
                )}
            ></Popup>
        )
    }
)

type CoordinatesEditorProps = {
    coordinates: ComponentCoordinates
    hidePopup: (coordinates: ComponentCoordinates) => void
}

function CoordinatesEditor({ coordinates, hidePopup }: CoordinatesEditorProps) {
    const [formData, setFormData] = useState<ComponentCoordinates>()

    const reportPath = useContext(ReportPathContext).reportPath
    const componentContext = useContext(ComponentContext)
    const guid = componentContext?.component.guid

    const changeCoordinates = useReportStore((state) => state.changeCoordinates)

    useEffect(() => {
        setFormData(cloneDeep(coordinates))
    }, [coordinates])

    const handleSave = useCallback(() => {
        if (formData && guid) {
            changeCoordinates(reportPath, formData, guid)
            hidePopup(formData)
        }
    }, [guid, changeCoordinates, formData, hidePopup, reportPath])

    return (
        <form>
            <div className="component-setup-container">
                <Form id="componentForm" formData={formData} labelLocation="left">
                    <GroupItem colCount={2}>
                        <GroupItem caption="Souřadnice">
                            <SimpleItem dataField="x" editorType="dxNumberBox">
                                <Label text="x" />
                            </SimpleItem>
                            <SimpleItem dataField="y" editorType="dxNumberBox">
                                <Label text="y" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption="Rozměry">
                            <SimpleItem dataField="w" editorType="dxNumberBox">
                                <Label text="šířka" />
                            </SimpleItem>
                            <SimpleItem dataField="h" editorType="dxNumberBox">
                                <Label text="výška" />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                </Form>

                <Button text="Ok" type="default" stylingMode="contained" onClick={handleSave} />
            </div>
        </form>
    )
}

export default memo(ComponentCoordinatesEditor)
