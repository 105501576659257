import { API_BASE_URL } from "../../constants"
import { request, accessTokenExists } from "../APIUtils"

export function getModbusFrames(): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/modbusFrames",
            method: "GET"
        })
    })
}

export function deleteModbusFrames(ids: Array<number>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/modbusFrames",
            method: "DELETE",
            body: JSON.stringify(ids)
        })
    })
}

export function deepCopyModbusFrames(ids: Array<number | undefined>): Promise<Response> {
    return accessTokenExists(() => {
        return request({
            url: API_BASE_URL + "/modbusFrames/deepCopy",
            method: "POST",
            body: JSON.stringify(ids)
        })
    })
}

export function uploadCSV(csv: any): Promise<Response> {
    return accessTokenExists(() => {
        return request(
            {
                url: API_BASE_URL + "/modbusFrames/uploadCSV",
                method: "POST",
                body: csv
            },
            null
        )
    })
}
