import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback } from "react"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action } from "../../types/ComponentTypes"
import { ButtonParams } from "../Button/ButtonEditor"
import { TextSettings, TextType } from "../Text/TextEditor"

export type GaugeSwitchParameters = BaseParams &
    TextType & {
        enabledExpression: string
        expression: string
        buttons?: Array<ButtonParams>
        onChangeActions?: Array<Action>
    }

export const getGaugeSwitchBasicConfigurations = () => {
    return [{}]
}

function GaugeSwitchEditor({ parameters }: EditorProps<GaugeSwitchParameters>) {
    const positionRender = useCallback((item: any) => {
        return (
            <GroupItem>
                <TabPanel animationEnabled={true}>
                    <Item title="Základní">
                        <Form formData={item}>
                            <SimpleItem render={initMonacoEditor(item, "textExpression")}>
                                <Label text="Výraz textu" />
                            </SimpleItem>
                        </Form>
                    </Item>
                    <Item title="OnClick">
                        <ActionsTriggerEditor listOwner={item} listName="onClickActions" />
                    </Item>
                </TabPanel>
            </GroupItem>
        )
    }, [])

    const positionListRender = useCallback((item: ButtonParams) => {
        return <div>{`${item.textExpression}`}</div>
    }, [])

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem colCount={2}>
                            <GroupItem>
                                <TextSettings parameters={parameters} />
                            </GroupItem>
                            <GroupItem>
                                <ListPopupEditor
                                    listOwner={parameters}
                                    listName="buttons"
                                    formItemContent={positionRender}
                                    guid={true}
                                    idProperty="guid"
                                    itemRender={positionListRender}
                                />
                            </GroupItem>
                        </GroupItem>
                        <SimpleItem
                            render={initMonacoEditor(parameters, "expression")}
                            helpText="Výraz musí vracet hodnotu z intervalu 0 - n, kde 0 znamená první pozice."
                        >
                            <Label text="Výraz hodnoty" />
                        </SimpleItem>
                        <SimpleItem render={initMonacoEditor(parameters, "enabledExpression")}>
                            <Label text="Výraz aktivnosti" />
                        </SimpleItem>
                    </Form>
                </div>
            </Item>
            <Item title="Actions">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem colCount={2}>
                            <GroupItem caption="OnChange">
                                <ActionsTriggerEditor
                                    listOwner={parameters}
                                    listName="onChangeActions"
                                    expressionHelpText="K aktuální hodnotě je možné přistoupit pomocí self.value, kde value je index pozice přepínače."
                                />
                            </GroupItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(GaugeSwitchEditor)
