import { Popup } from "devextreme-react"
import { ButtonItem, Form, Label, SimpleItem } from "devextreme-react/form"
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box"
import { forwardRef, memo, useCallback, useContext, useMemo, useState } from "react"
import { $enum } from "ts-enum-util"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { EvaluatorContext } from "../../report/contexts/EvaluatorContext"
import { KeyType } from "../../types/KeysTypes"
import ColorUtils from "../../utils/ColorUtils"
import ComponentUtils from "../../utils/ComponentUtils"
import {
    useContainerVisible,
    useExecuteActions,
    useExpression,
    useImperative
} from "../../utils/CustomHooks"
import EvalUtils from "../../utils/EvalUtils"
import "./TextComponent.scss"
import { ComponentTextSize, TextParameters } from "./TextEditor"

type Params = TextParameters

type Props = ComponentProps<Params>

const TextComponent = forwardRef<IBaseComponent, Props>(
    (
        {
            fontSize,
            italic,
            bold,
            horizontalAlign,
            expression,
            popupExpression,
            colorExpression,
            enabledExpression,
            popup,
            onSaveActions,
            setContainerVisible
        },
        ref
    ) => {
        const component = useContext(ComponentContext)?.component
        const evaluatorContext = useContext(EvaluatorContext)
        const execute = useExecuteActions()
        useContainerVisible(setContainerVisible, false)

        const [popupVisible, setPopupVisible] = useState(false)
        const [formData, setFormData] = useState(() => ({ text: "" }))

        const [textState, setTextState] = useExpression(expression, "")
        const [color] = useExpression(colorExpression, 0)
        const [enabled] = useExpression(enabledExpression, true)

        useImperative(
            ref,
            useMemo(() => {
                const messenger = {
                    name: "textSize",
                    enums: $enum(ComponentTextSize)
                        .getValues()
                        .map((e) => ({ enum: e })),
                    value: fontSize
                }
                return [
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [fontSize])
        )

        const handlePopupHidden = useCallback(() => {
            setPopupVisible(false)
        }, [])

        const handleSubmit = useCallback(
            (e: any) => {
                e.preventDefault()
                execute(onSaveActions, { value: formData.text })

                handlePopupHidden()
            },
            [execute, formData, onSaveActions, handlePopupHidden]
        )

        const handlePopupShow = useCallback(() => {
            setFormData((oldFormData) => ({
                text: evaluatorContext?.evaluate(EvalUtils.correctExpression(popupExpression), {
                    self: {
                        value: textState
                    }
                })
            }))
            setPopupVisible(true)
        }, [evaluatorContext, textState, popupExpression])

        const textBoxButtonOptions = useMemo(
            () => ({
                icon: "edit",
                type: "default",
                onClick: handlePopupShow
            }),
            [handlePopupShow]
        )

        const buttonSaveOptions = useMemo(
            () => ({
                type: "default",
                text: "Uložit",
                useSubmitBehavior: true
            }),
            []
        )

        const onValueChanged = useCallback(
            (e: any) => {
                setTextState(e.value)
            },
            [setTextState]
        )

        const className = useMemo(
            () =>
                `text-component text-align-center ${bold && "bold"} ${
                    italic && "italic"
                } ${horizontalAlign} text-${ColorUtils.fromNumber(
                    color
                )} ${ComponentUtils.classFromFontSize(fontSize)}`,
            [bold, color, horizontalAlign, italic, fontSize]
        )

        return (
            <>
                {popup && (
                    <Popup
                        width="auto"
                        height="auto"
                        showTitle={true}
                        title={"Editace " + component?.name}
                        visible={popupVisible}
                        onHiding={handlePopupHidden}
                    >
                        <form onSubmit={handleSubmit}>
                            <Form formData={formData}>
                                <SimpleItem dataField="text">
                                    <Label text="Hodnota" />
                                </SimpleItem>
                                <ButtonItem buttonOptions={buttonSaveOptions} />
                            </Form>
                        </form>
                    </Popup>
                )}
                <TextBox
                    width="100%"
                    height="100%"
                    className={className}
                    value={textState + ""}
                    disabled={!enabled}
                    onValueChanged={onValueChanged}
                >
                    {popup && (
                        <TextBoxButton
                            name="password"
                            location="after"
                            options={textBoxButtonOptions}
                        />
                    )}
                </TextBox>
            </>
        )
    }
)

export default memo(TextComponent)
