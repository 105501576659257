import Form, { GroupItem, SimpleItem } from "devextreme-react/form"
import { forwardRef, memo } from "react"
import { FunctionProps } from "../FunctionsList"
import { initMonacoEditor } from "../MonacoEditor"
import OutputVariable from "../OutputVariable"
import { IFunctionRef } from "./TestFunction"

const SetVariable = forwardRef<IFunctionRef, FunctionProps>(({ item }, ref) => {
    return (
        <Form formData={item}>
            <GroupItem>
                <GroupItem>
                    <SimpleItem render={initMonacoEditor(item.parameters, "expression")} />
                </GroupItem>
                <GroupItem>
                    <OutputVariable formData={item} dataField="parameters.output" />
                </GroupItem>
            </GroupItem>
        </Form>
    )
})

export default memo(SetVariable)
