import { ChartData } from "chart.js"
import { Button } from "devextreme-react"
import { isEqual } from "lodash"
import { forwardRef, memo, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Chart } from "react-chartjs-2"
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { ComponentContext } from "../../report/contexts/ComponentContext"
import { useExpression, useScrollActivator } from "../../utils/CustomHooks"
import "./ChartComponent.scss"
import { ChartParameters } from "./ChartEditor"

type Params = ChartParameters

type Props = ComponentProps<Params>

const ChartComponent = forwardRef<IBaseComponent, Props>(
    (
        { type, dataExpression, optionsExpression, realSize, exportImageButton, resetZoomButton },
        ref
    ) => {
        const component = useContext(ComponentContext)?.component!
        const { name, type: cmpType } = component

        useScrollActivator()

        const chartRef = useRef<ChartJSOrUndefined>(null)

        const [key, setKey] = useState(0)
        const [options, setOptions] = useState({})

        const [data] = useExpression<ChartData>(dataExpression, { datasets: [] })
        const [evaluatedOptions] = useExpression(optionsExpression, {})
        const { width, height } = realSize

        useEffect(() => {
            if (!isEqual(options, evaluatedOptions)) {
                setOptions(evaluatedOptions)
            }
        }, [evaluatedOptions, options])

        useEffect(() => {
            setKey((oldValue) => oldValue + 1)
        }, [width, height])

        const resetZoom = useCallback(() => chartRef.current?.resetZoom(), [])
        const exportToImage = useCallback(() => {
            const a = document.createElement("a")
            a.href = chartRef.current?.toBase64Image() ?? ""
            a.download = (name ?? cmpType) + ".png"

            a.click()
            a.remove()
        }, [name, cmpType])

        return (
            <div className="chartjs-component">
                <div className="chartjs-buttons">
                    <Button
                        visible={resetZoomButton === true}
                        icon="refresh"
                        hint="reset zoom"
                        type="default"
                        onClick={resetZoom}
                    />
                    <Button
                        visible={exportImageButton === true}
                        icon="export"
                        hint="export to image and download"
                        type="default"
                        onClick={exportToImage}
                    />
                </div>
                <Chart
                    ref={chartRef}
                    key={key}
                    type={type}
                    options={options}
                    data={data}
                    width={width}
                    height={height}
                />
            </div>
        )
    }
)

export default memo(ChartComponent)
