import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo } from "react"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action } from "../../types/ComponentTypes"
import { TextSettings, TextType } from "../Text/TextEditor"

export type DateTimeParameters = BaseParams &
    TextType & {
        expression: string
        colorExpression: string
        enabledExpression: string
        onChangeActions?: Array<Action>
    }

export const getDateTimeBasicConfigurations = () => {
    return [{}]
}

function DateTimeEditor({ parameters }: EditorProps<DateTimeParameters>) {
    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem>
                            <TextSettings parameters={parameters} />
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem
                                render={initMonacoEditor(parameters, "colorExpression")}
                                helpText="0 - bílá, 1 - modrá, 2 - warning, 3 - error"
                            >
                                <Label text="Výraz barvy" />
                            </SimpleItem>
                            <SimpleItem render={initMonacoEditor(parameters, "expression")}>
                                <Label text="Výraz" />
                            </SimpleItem>
                            <SimpleItem render={initMonacoEditor(parameters, "enabledExpression")}>
                                <Label text="Výraz aktivnosti" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
            <Item title="Actions">
                <div className="component-setup-container">
                    <Form formData={parameters}>
                        <GroupItem colCount={2}>
                            <GroupItem caption="OnChange">
                                <ActionsTriggerEditor
                                    listOwner={parameters}
                                    listName="onChangeActions"
                                    expressionHelpText="K aktuální hodnotě je možné přistoupit pomocí self.value, kde value je objekt Date či řetězec v ISO8601 formátu."
                                />
                            </GroupItem>
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(DateTimeEditor)
