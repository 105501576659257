import { Accordion } from "devextreme-react"
import { memo, useCallback, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { useAppStore } from "../stores/AppStore"
import { useNavBarStore } from "../stores/NavBarStore"
import "./NavigationSidebar.scss"
import { NAV_BAR_FULLSCREEN_WIDTH } from "./ReactCSSVariables"

type Props<G, I extends { name?: string }> = {
    navId: string
    groupKey: Extract<keyof G, string>
    dataSource?: Array<G>
    title: (group: G) => string
    getGroupItems: (group: G) => Array<I>
    getToLink: (group: G, item: I) => string
    renderItem: (item: I) => JSX.Element
    getItemText: (item: I) => string
}

const NavigationSidebar = <G, I extends { name?: string }>({
    navId,
    dataSource,
    title,
    getGroupItems,
    getToLink,
    renderItem,
    getItemText,
    groupKey
}: Props<G, I>) => {
    const mainWidth = useAppStore((state) => state.mainWidth)
    const menuVisible = useNavBarStore((state) => state.menuVisible)
    const setMenuVisibility = useNavBarStore((state) => state.setMenuVisibility)
    const setHeaderVisibility = useNavBarStore((state) => state.setHeaderVisibility)
    const headerText = useNavBarStore((state) => state.headerText)
    const setHeaderText = useNavBarStore((state) => state.setHeaderText)
    const selectedGroupsIds = useNavBarStore((state) => state.navSelectedGroupsIds)[navId]
    const setNavSelectedGroups = useNavBarStore((state) => state.setNavSelectedGroupsIds)
    const setMainIsScrollable = useAppStore((state) => state.setMainIsScrollable)

    useEffect(() => {
        setHeaderVisibility(true)

        return () => {
            setHeaderVisibility(false)
        }
    }, [setHeaderVisibility])

    const isMobile = useMemo(() => mainWidth && mainWidth <= NAV_BAR_FULLSCREEN_WIDTH, [mainWidth])

    useEffect(() => {
        setMainIsScrollable(!(isMobile && menuVisible))
    }, [isMobile, menuVisible, setMainIsScrollable])

    const isSelected = useCallback((item: I) => item.name === headerText, [headerText])

    useEffect(() => {
        const selectGroup = (group: G) => {
            // TODO - jak natypovat, ze G obsahuje field groupKey?
            const isSelected = selectedGroupsIds?.includes(group[groupKey] as string | number)
            if (!isSelected) {
                setNavSelectedGroups(navId, [
                    ...(selectedGroupsIds ?? []),
                    (group as any)[groupKey]
                ])
            }
        }

        dataSource?.forEach((g) => {
            getGroupItems(g).forEach((i) => {
                if (isSelected(i)) {
                    selectGroup(g)
                }
            })
        })
    }, [
        dataSource,
        getGroupItems,
        isSelected,
        selectedGroupsIds,
        navId,
        setNavSelectedGroups,
        groupKey
    ])

    const titleRender = useCallback((item: G) => <span>{title(item)}</span>, [title])

    const groupRender = useCallback(
        (group: G) =>
            getGroupItems(group)?.map((item, i) => {
                const onItemClick = () => {
                    setHeaderText(getItemText(item))

                    if (isMobile) {
                        setMenuVisibility(false)
                    }
                }

                return (
                    <Link key={i} to={getToLink(group, item)} onClick={onItemClick}>
                        <div className={`item-button  ${isSelected(item) ? "selected-item" : ""}`}>
                            <div className="item-button-shield"></div>
                            {renderItem(item)}
                        </div>
                    </Link>
                )
            }),
        [
            getGroupItems,
            getToLink,
            isSelected,
            renderItem,
            setHeaderText,
            setMenuVisibility,
            getItemText,
            isMobile
        ]
    )

    const onSelectionChanged = useCallback(
        (e: any) => {
            let newSelectedGroupsIds = [...selectedGroupsIds]
            e.removedItems.forEach((group: G) => {
                const index = newSelectedGroupsIds.indexOf(group[groupKey] as string | number)
                if (index >= 0) {
                    newSelectedGroupsIds.splice(index, 1)
                }
            })
            if (e.addedItems.length) {
                newSelectedGroupsIds = [
                    ...newSelectedGroupsIds,
                    ...e.addedItems.map((g: G) => g[groupKey])
                ]
            }
            setNavSelectedGroups(navId, newSelectedGroupsIds)
        },
        [selectedGroupsIds, navId, setNavSelectedGroups, groupKey]
    )

    return (
        <div className={`navigation-sidebar ${menuVisible ? "sidebar-open" : ""}`}>
            <Accordion
                dataSource={dataSource}
                multiple={true}
                itemTitleRender={titleRender}
                itemRender={groupRender}
                keyExpr={groupKey}
                collapsible={true}
                selectedItemKeys={selectedGroupsIds ?? []}
                onSelectionChanged={onSelectionChanged}
            />
        </div>
    )
}

export default memo(NavigationSidebar) as typeof NavigationSidebar
