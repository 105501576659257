import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback } from "react"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import {
    CameraRender,
    GroupSettingsRender,
    OthersThreeDRender,
    ThreeDGroup,
    ThreeDMesh,
    positionRender
} from "./ThreeDEditorUtils"

export type ThreeDVector = {
    x?: number
    y?: number
    z?: number
}

export type ThreeDMeshSimple = ThreeDMesh & {
    positionExpression?: string
    geometryExpression?: string
}

export type ThreeDAmbientLight = {
    name?: string
}

export type ThreeDGroupSimple = ThreeDGroup & {}

export type ThreeDCamera = {
    position: ThreeDVector
}

export type ThreeDCanvasBase = {
    ambientLights?: Array<ThreeDAmbientLight>
    camera?: ThreeDCamera
    orbitControls?: boolean
    gridHelper?: boolean
    axesHelper?: boolean
}

export type ThreeDParameters = BaseParams & ThreeDGroupSimple & ThreeDCanvasBase

const ThreeDEditor = ({ parameters }: EditorProps<ThreeDParameters>) => {
    parameters.camera = parameters.camera ?? { position: { x: 0, y: 0, z: 5 } }

    const additionalMeshRender = useCallback((item: any) => {
        return (
            <GroupItem>
                <GroupItem>{positionRender(item)}</GroupItem>
                <SimpleItem
                    render={initMonacoEditor(item, "geometryExpression")}
                    helpText="Výraz musí vracet vektor o velikosti dle zvoleného tělesa. Pro sféru např.: [0.7, 30, 30]. Všechny tvary zde: https://threejs.org/docs/#api/en/geometries/BoxGeometry"
                >
                    <Label text="Velikost" />
                </SimpleItem>
            </GroupItem>
        )
    }, [])

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <GroupSettingsRender
                    owner={parameters}
                    additionalMeshRender={additionalMeshRender}
                />
            </Item>
            <Item title="Kamera">
                <CameraRender parameters={parameters} />
            </Item>
            <Item title="Ostatní">
                <OthersThreeDRender parameters={parameters} />
            </Item>
        </TabPanel>
    )
}

export default memo(ThreeDEditor)

export function getThreeDBasicConfigurations() {
    return [
        {
            positionExpression: "[0, -10, 0]",
            refreshInterval: 3,
            groups: [
                {
                    guid: "209d9178-9d30-45ae-a5d5-b861e2460bed",
                    animation: {},
                    groups: [
                        {
                            guid: "3d7c7014-4256-458e-b626-77c91e102588",
                            animation: {},
                            groups: [],
                            meshs: [
                                {
                                    guid: "62c6af4f-cbb0-476d-adc7-94430d7b06c3",
                                    animation: {},
                                    name: "1",
                                    type: "BOX",
                                    positionExpression: "[3,3,3]",
                                    geometryExpression: "[0.5,2,0.5]",
                                    colorExpression: "0x9d9d9d"
                                },
                                {
                                    guid: "d169de83-b680-47af-977b-f4674ab2ff5a",
                                    animation: {},
                                    name: "2",
                                    type: "BOX",
                                    positionExpression: "[-3, 3, 3]",
                                    geometryExpression: "[0.5, 2, 0.5]",
                                    colorExpression: "0x9d9d9d"
                                },
                                {
                                    guid: "c7d8761b-8080-408c-b3e4-199540f8d54d",
                                    animation: {},
                                    name: "3",
                                    type: "BOX",
                                    positionExpression: "[-3,3,-3]",
                                    geometryExpression: "[0.5,2,0.5,]",
                                    colorExpression: "0x9d9d9d"
                                },
                                {
                                    guid: "1f2912f6-4c60-4e94-a3c0-81bc5ebda7c2",
                                    animation: {},
                                    name: "4",
                                    type: "BOX",
                                    positionExpression: "[3,3,-3]",
                                    geometryExpression: "[0.5,2,0.5,]",
                                    colorExpression: "0x9d9d9d"
                                }
                            ],
                            pointLights: [],
                            name: "nozicky"
                        },
                        {
                            guid: "41758787-d2d4-4ba6-b998-f989a9dc50f1",
                            animation: {},
                            groups: [],
                            meshs: [
                                {
                                    guid: "6c401378-d6df-49a7-ac61-877f8ae56898",
                                    animation: {},
                                    name: "pravy",
                                    type: "BOX",
                                    positionExpression: "[1.8,17,0]",
                                    geometryExpression: "[0.3,1.2,0.5]",
                                    colorExpression: "0x9d9d9d"
                                },
                                {
                                    guid: "8a7dda48-71df-4098-b163-371d3a1aaa98",
                                    animation: {},
                                    name: "levy",
                                    type: "BOX",
                                    positionExpression: "[-1.8,17,0]",
                                    geometryExpression: "[0.3,1.2,0.5]",
                                    colorExpression: "0x9d9d9d"
                                },
                                {
                                    guid: "0cfad5b5-f5aa-42e7-82d2-202b126b19bb",
                                    animation: {},
                                    name: "vrsek",
                                    type: "BOX",
                                    positionExpression: "[0,17.6,0]",
                                    geometryExpression: "[3.9,0.3,0.5]",
                                    colorExpression: "0x9d9d9d"
                                }
                            ],
                            pointLights: [],
                            name: "drzatko"
                        },
                        {
                            guid: "f06ee416-d1b6-4b2f-b570-ca0464374572",
                            animation: {
                                enabledExpression: "v.running",
                                animationExpression: "{\r\n    rotation: [0, 0.05, 0]\r\n}"
                            },
                            groups: [],
                            meshs: [
                                {
                                    guid: "b25ae7ed-63a0-42c9-acb3-d88ed59f6d7a",
                                    animation: {},
                                    name: "0",
                                    type: "CYLINDER",
                                    positionExpression: "[2.2,14.6,0]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "bcfbe599-9016-4fde-9a1d-e6b41d15776e",
                                    animation: {},
                                    name: "15",
                                    type: "CYLINDER",
                                    positionExpression: "[2.1250372,14.6,0.5694]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "b5185334-b506-4127-9b82-d8af1cf6bfeb",
                                    animation: {},
                                    name: "30",
                                    type: "CYLINDER",
                                    positionExpression: "[1.9052,14.6,1.1]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "1814716b-f3b9-45e0-827a-c0aa3d325d87",
                                    animation: {},
                                    name: "45",
                                    type: "CYLINDER",
                                    positionExpression: "[1.5556,14.6,1.5556]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "5683761d-5d93-44d0-9df2-a546f924b202",
                                    animation: {},
                                    name: "60",
                                    type: "CYLINDER",
                                    positionExpression: "[1.1,14.6,1.9052]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "e9ede3be-ffa4-4e34-96c3-efd7fe8aa386",
                                    animation: {},
                                    name: "75",
                                    type: "CYLINDER",
                                    positionExpression: "[0.5694,14.6,2.1250372]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "72473d0e-c486-47d5-80cb-68d5f830dccf",
                                    animation: {},
                                    name: "90",
                                    type: "CYLINDER",
                                    positionExpression: "[0,14.6,2.2]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "cddff9cf-97ad-4372-8388-d7be4c6415a1",
                                    animation: {},
                                    name: "105",
                                    type: "CYLINDER",
                                    positionExpression: "[-0.5694,14.6,2.1250372]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "3fb349ce-8f73-4b0c-81cd-f39b39d86699",
                                    animation: {},
                                    name: "120",
                                    type: "CYLINDER",
                                    positionExpression: "[-1.1,14.6,1.9052]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "5ddd3959-cf11-46b7-bffb-589c82fbb1e6",
                                    animation: {},
                                    name: "135",
                                    type: "CYLINDER",
                                    positionExpression: "[-1.5556,14.6,1.5556]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "316a59f3-c34f-4603-b646-eb92a61aef20",
                                    animation: {},
                                    name: "150",
                                    type: "CYLINDER",
                                    positionExpression: "[-1.9052,14.6,1.1]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "c92eeab9-1e7d-4cac-93af-1da3ff052566",
                                    animation: {},
                                    name: "165",
                                    type: "CYLINDER",
                                    positionExpression: "[-2.1250372,14.6,0.5694]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "72313299-5251-41f2-9adb-f7954ce7c520",
                                    animation: {},
                                    name: "180",
                                    type: "CYLINDER",
                                    positionExpression: "[-2.2,14.6,0]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "6e52231d-b4c1-402c-b84e-d1a98fd65f4f",
                                    animation: {},
                                    name: "195",
                                    type: "CYLINDER",
                                    positionExpression: "[-2.1250372,14.6,-0.5694]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "2d1b1ea5-7c85-4f94-865f-604137f496e5",
                                    animation: {},
                                    name: "210",
                                    type: "CYLINDER",
                                    positionExpression: "[-1.9052,14.6,-1.1]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "6fc63134-f252-4ed6-a6c4-1ef8655c18b0",
                                    animation: {},
                                    name: "225",
                                    type: "CYLINDER",
                                    positionExpression: "[-1.5556,14.6,-1.5556]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "d43f1485-85de-46e5-87ea-74618fd83f53",
                                    animation: {},
                                    name: "240",
                                    type: "CYLINDER",
                                    positionExpression: "[-1.1,14.6,-1.9052]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "d71cd94f-0b25-4af7-8ae9-e0f5a4d97a7b",
                                    animation: {},
                                    name: "255",
                                    type: "CYLINDER",
                                    positionExpression: "[-0.5694,14.6,-2.1250372]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "9309517f-f3cd-42ac-a808-74c817ec0a0b",
                                    animation: {},
                                    name: "270",
                                    type: "CYLINDER",
                                    positionExpression: "[-0,14.6,-2.2]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "47abd278-f0e5-4b51-a6dc-ab0e540303e2",
                                    animation: {},
                                    name: "285",
                                    type: "CYLINDER",
                                    positionExpression: "[0.5694,14.6,-2.1250372]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "b90f8324-cf09-4389-8637-794a90c606f2",
                                    animation: {},
                                    name: "300",
                                    type: "CYLINDER",
                                    positionExpression: "[1.1,14.6,-1.9052]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "d9c443f6-51ae-42de-94b1-66faf353bd75",
                                    animation: {},
                                    name: "315",
                                    type: "CYLINDER",
                                    positionExpression: "[1.5556,14.6,-1.5556]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "fb377c9a-eb16-4b29-bc3c-1e040ab2329b",
                                    animation: {},
                                    name: "330",
                                    type: "CYLINDER",
                                    positionExpression: "[1.9052,14.6,-1.1]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                },
                                {
                                    guid: "f49e30ce-961d-47fc-82f6-1871e003cf65",
                                    animation: {},
                                    name: "345",
                                    type: "CYLINDER",
                                    positionExpression: "[2.1250372,14.6,-0.5694]",
                                    geometryExpression: "[0.08, 0.08, 3.4, 64, 15]",
                                    colorExpression: "0x747474"
                                }
                            ],
                            pointLights: [],
                            name: "sprusle"
                        }
                    ],
                    meshs: [
                        {
                            guid: "56acade4-457e-4d71-839f-3865dc2539bc",
                            animation: {},
                            name: "valec1",
                            type: "CYLINDER",
                            positionExpression: "[0,5,0]",
                            geometryExpression: "[4.8,4.8,3.8,64,10,false,]",
                            colorExpression: "0x9d9d9d"
                        },
                        {
                            guid: "51ecc859-7de9-44da-bb4d-e709cad6be0a",
                            animation: {},
                            name: "valec2",
                            type: "CYLINDER",
                            positionExpression: "[0,8.4,0]",
                            geometryExpression: "[3.7,3.7,3,64,15]",
                            colorExpression: "v.running ? 0x00ff00 : 0x747474",
                            onClickActions: [
                                {
                                    id: 0,
                                    type: "variable",
                                    outputVariable: "915cc747-9b9f-4aeb-8bda-f588bd80ae0a",
                                    expression: "!v.running"
                                }
                            ]
                        },
                        {
                            guid: "b8c132cc-8b60-434d-8621-ab37535bda07",
                            animation: {},
                            name: "valec-zkoseny",
                            type: "CYLINDER",
                            positionExpression: "[0,11.4,0]",
                            geometryExpression: "[2.3,3.7,3,64,15]",
                            colorExpression: "v.running ? 0x00ff00 : 0x747474",
                            onClickActions: [
                                {
                                    id: 0,
                                    type: "variable",
                                    outputVariable: "915cc747-9b9f-4aeb-8bda-f588bd80ae0a",
                                    expression: "!v.running"
                                }
                            ]
                        },
                        {
                            guid: "2eedc428-4d31-4e98-961f-c55db8f8cb00",
                            animation: {},
                            name: "valec-se-spruslema",
                            type: "CYLINDER",
                            positionExpression: "[0,14.6,0]",
                            geometryExpression: "[2.2,2.2,3.4,64,15]",
                            colorExpression: "0x9d9d9d"
                        },
                        {
                            guid: "5973fc2f-b439-4bb2-941d-b5362d7dd425",
                            animation: {},
                            name: "valec-striska",
                            type: "CYLINDER",
                            positionExpression: "[0,16.4,0]",
                            geometryExpression: "[2.3,2.3,0.2,64,15]",
                            colorExpression: "0xc4c4c4"
                        }
                    ],
                    pointLights: [
                        {
                            guid: "581525d3-38e3-4615-a497-2a92c1de49f4",
                            name: "svetlo1",
                            intensityExpression: "1",
                            positionExpression: "[5,10,10]"
                        }
                    ],
                    name: "skupina1"
                }
            ],
            meshs: [],
            pointLights: [
                {
                    guid: "a70de4ca-95a8-4d3f-aff8-3acd463868cb",
                    intensityExpression: "2",
                    name: "svetlo1",
                    positionExpression: "[5,5,5]"
                },
                {
                    guid: "92749a7f-cff0-4d23-8307-efcb01ba4eda",
                    name: "svetlo2",
                    intensityExpression: "1",
                    positionExpression: "[-10,10,-5]"
                }
            ],
            orbitControls: true,
            camera: {
                position: {
                    x: 5,
                    y: 10,
                    z: 20
                }
            },
            animation: {}
        }
    ]
}
