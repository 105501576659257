import { Button, Form, Popup } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { memo, useCallback, useState } from "react"

type SaveProps = {
    onLibraryAdd: (name: string) => void
}

const SaveToLibrary = (props: SaveProps) => {
    const [formData, setFormData] = useState({ name: "" })
    const [popupVisible, setPopupVisible] = useState(false)

    const handlePopupShow = useCallback(() => {
        setPopupVisible(true)
    }, [])

    const handlePopupHidden = useCallback(() => {
        setPopupVisible(false)
    }, [])

    const onSave = useCallback(() => {
        props.onLibraryAdd(formData.name)
        setFormData({ name: "" })
        handlePopupHidden()
    }, [formData.name, handlePopupHidden, props])

    return (
        <>
            <Popup
                width="auto"
                height="auto"
                showTitle={true}
                title={"Přidat do knihovny"}
                dragEnabled={true}
                visible={popupVisible}
                onHiding={handlePopupHidden}
            >
                <Form formData={formData}>
                    <SimpleItem dataField="name">
                        <Label text="Název"></Label>
                    </SimpleItem>
                    <GroupItem>
                        <Button text="Uložit" type="default" onClick={onSave}></Button>
                    </GroupItem>
                </Form>
            </Popup>
            <Button
                text="Uložit do knihovny"
                type="normal"
                stylingMode="contained"
                onClick={handlePopupShow}
            />
        </>
    )
}

export default memo(SaveToLibrary)
