import { RouteComponentProps } from "react-router"
import { Redirect, Switch, withRouter } from "react-router-dom"
import "./Administration.scss"

import { memo, useCallback, useEffect } from "react"
import AdministrationGroups from "../administration/AdministrationGroups"
import AdministrationReports from "../administration/AdministrationReports"
import DBConnections from "../administration/DBConnections"
import ImagesLibrary from "../administration/ImagesLibrary"
import Library from "../administration/Library"
import License from "../administration/License"
import ModbusConnections from "../administration/ModbusConnections"
import ModbusReader from "../administration/ModbusReader"
import Notifications from "../administration/Notifications"
import Roles from "../administration/Roles"
import ScheduledTasks from "../administration/ScheduledTasks"
import System from "../administration/System"
import ThreeDLibrary from "../administration/ThreeDLibrary"
import Users from "../administration/Users"
import { useNavBarStore } from "../stores/NavBarStore"
import { AdministrationGroup, HeaderItem, MENU_ITEMS, MenuItem } from "./HeaderItems"
import NavigationSidebar from "./NavigationSidebar"
import RouteMiddleware from "./RouteMiddleware"

type PathParamsType = {}

type Props = RouteComponentProps<PathParamsType> & {}

const PATH_PREFIX = `/${MenuItem.ADMINISTRATION}/`

const Administration = withRouter((p: Props) => {
    const setHeaderText = useNavBarStore((state) => state.setHeaderText)

    useEffect(() => {
        const parts = p.location.pathname.split("/")
        const path = parts[parts.length - 1]
        const name = MENU_ITEMS.flatMap((g) => g.items).find((i) => i.id === path)?.name
        if (name) {
            setHeaderText(name)
        }
    }, [p.location.pathname, setHeaderText])

    const title = useCallback((group: AdministrationGroup) => group.name, [])

    const getGroupHeaderItems = useCallback((group: AdministrationGroup) => {
        return group.items
    }, [])

    const getHeaderItemLink = useCallback(
        (g: AdministrationGroup, i: HeaderItem) => `/administration/${i.id}`,
        []
    )

    const renderNavHeaderItem = useCallback((i: HeaderItem) => <span>{i.name}</span>, [])

    const getItemText = useCallback((r: HeaderItem) => r.name, [])

    return (
        <div className="administration-layout">
            <NavigationSidebar<AdministrationGroup, HeaderItem>
                navId="administration"
                groupKey="name"
                dataSource={MENU_ITEMS}
                title={title}
                getGroupItems={getGroupHeaderItems}
                getToLink={getHeaderItemLink}
                renderItem={renderNavHeaderItem}
                getItemText={getItemText}
            />

            <div className="administration-content">
                <Switch>
                    <Redirect exact from={PATH_PREFIX} to={PATH_PREFIX + MenuItem.GROUPS} />
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.USERS}>
                        <Users />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.ROLES}>
                        <Roles />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.REPORTS}>
                        <AdministrationReports />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.NOTIFICATIONS}>
                        <Notifications />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.SCHEDULED_TASKS}>
                        <ScheduledTasks />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.DB_CONNECTIONS}>
                        <DBConnections />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.MODBUS_CONNECTIONS}>
                        <ModbusConnections />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.MODBUS_READER}>
                        <ModbusReader />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.SYSTEM_STATE}>
                        <System />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.LICENSE}>
                        <License />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.GROUPS}>
                        <AdministrationGroups />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.LIBRARY}>
                        <Library />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.IMAGES_LIBRARY}>
                        <ImagesLibrary />
                    </RouteMiddleware>
                    <RouteMiddleware path={PATH_PREFIX + MenuItem.THREE_D_LIBRARY}>
                        <ThreeDLibrary />
                    </RouteMiddleware>
                </Switch>
            </div>
        </div>
    )
})

export default memo(Administration)
