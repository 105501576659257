import Form, { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item, TabPanel } from "devextreme-react/tab-panel"
import { memo, useCallback, useMemo } from "react"
import { SLIDER_TOOLTIP_SETTINGS } from "../../component/EditorTypes"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { EditorProps } from "../../report/Schema/Layer/ComponentContainer/ComponentPopupEditor/Editor/Editor"
import { BaseParams } from "../../types/BaseTypes"
import { Action } from "../../types/ComponentTypes"
import { useForceUpdate } from "../../utils/CustomHooks"

export type MapParameters = BaseParams & {
    coordinates?: { lat: string; lng: string }
    disabled?: boolean
    controls?: boolean
    type?: "hybrid" | "roadmap" | "satellite"
    zoom?: number
    markers?: Array<{
        location: { lat: string; lng: string }
        tooltip: { text: string }
        onClickActions?: Array<Action>
        colorExpression: string
    }>

    // pouze pro knihovnu
    onlyPreview?: boolean
}

export const getMapBasicConfigurations = (): Array<MapParameters> => {
    return [{ onlyPreview: true }]
}

const typeOptions = {
    items: ["roadmap", "satellite", "hybrid"]
}

const sliderOptions = {
    min: 1,
    max: 20,
    tooltip: SLIDER_TOOLTIP_SETTINGS
}

function MapEditor({ parameters }: EditorProps<MapParameters>) {
    const [forceUpdate] = useForceUpdate()

    const disabledOptions = useMemo(
        () => ({
            onValueChanged: forceUpdate
        }),
        [forceUpdate]
    )

    const markerEditorRender = useCallback((item: any) => {
        return (
            <GroupItem>
                <SimpleItem dataField="tooltip.text">
                    <Label text="Název" />
                </SimpleItem>
                <SimpleItem dataField="location.lat" editorType="dxNumberBox">
                    <Label text="Šířka" />
                </SimpleItem>
                <SimpleItem dataField="location.lng" editorType="dxNumberBox">
                    <Label text="Délka" />
                </SimpleItem>
                <SimpleItem
                    render={initMonacoEditor(item, "colorExpression")}
                    helpText="0 - šedá, 1 - modrá, 2 - success, 3 - warning, 4 - error"
                >
                    <Label text="Výraz barvy" />
                </SimpleItem>
                <GroupItem caption="OnClick">
                    <ActionsTriggerEditor listOwner={item} listName="onClickActions" />
                </GroupItem>
            </GroupItem>
        )
    }, [])

    const markerRender = useCallback((item: any) => {
        return <div>{`${item.tooltip?.text}`}</div>
    }, [])

    parameters.type = parameters.type ?? "roadmap"
    parameters.zoom = parameters.zoom ?? 18

    return (
        <TabPanel animationEnabled={true}>
            <Item title="Základní">
                <div className="component-setup-container">
                    <Form formData={parameters} colCount={2}>
                        <GroupItem caption="Výchozí nastavení">
                            <SimpleItem dataField="coordinates.lat" editorType="dxNumberBox">
                                <Label text="Šířka" />
                            </SimpleItem>
                            <SimpleItem dataField="coordinates.lng" editorType="dxNumberBox">
                                <Label text="Délka" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="zoom"
                                editorType="dxSlider"
                                editorOptions={sliderOptions}
                            >
                                <Label text="Zoom" />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption="Ostatní">
                            <SimpleItem dataField="onlyPreview" editorType="dxCheckBox">
                                <Label text="Pouze náhled" />
                            </SimpleItem>
                            <SimpleItem
                                dataField="disabled"
                                editorType="dxCheckBox"
                                editorOptions={disabledOptions}
                            >
                                <Label text="Uživatelsky neaktivní" />
                            </SimpleItem>
                            <GroupItem>
                                <SimpleItem
                                    visible={!parameters.disabled}
                                    dataField="controls"
                                    editorType="dxCheckBox"
                                    editorOptions={disabledOptions}
                                >
                                    <Label text="Uživatelská změna typu mapy" />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem>
                                <SimpleItem
                                    visible={parameters.disabled || !parameters.controls}
                                    dataField="type"
                                    editorType="dxSelectBox"
                                    editorOptions={typeOptions}
                                >
                                    <Label text="Typ" />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem>
                            <ListPopupEditor
                                listOwner={parameters}
                                listName="markers"
                                formItemContent={markerEditorRender}
                                guid={true}
                                idProperty="guid"
                                itemRender={markerRender}
                            />
                        </GroupItem>
                    </Form>
                </div>
            </Item>
        </TabPanel>
    )
}

export default memo(MapEditor)
