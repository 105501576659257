import { RouteComponentProps } from "react-router"
import { Redirect, Switch, withRouter } from "react-router-dom"
import "../css/Main.scss"

import { memo, RefObject, useCallback, useEffect, useRef } from "react"
import ReactResizeDetector from "react-resize-detector"
import GroupsList from "../report/GroupsList"
import Reports from "../report/Reports"
import { useAppStore } from "../stores/AppStore"
import ChangePassword from "../user/ChangePassword"
import DeviceSetup from "../user/DeviceSetup"
import Administration from "./Administration"
import { MenuItem } from "./HeaderItems"
import RouteMiddleware from "./RouteMiddleware"

type PathParamsType = {}

type Props = RouteComponentProps<PathParamsType> & {
    loadCurrentUser: () => void
}

const Main = withRouter(({ loadCurrentUser }: Props) => {
    const setMainWidth = useAppStore((state) => state.setMainWidth)
    const mainIsScrollable = useAppStore((state) => state.mainIsScrollable)

    const ref: RefObject<HTMLDivElement> = useRef(null)

    const handleResize = useCallback(
        (width?: number) => {
            setMainWidth(width)
        },
        [setMainWidth]
    )

    const redirectReports = useCallback(
        (props: RouteComponentProps<any>) => (
            <Redirect
                to={`/${MenuItem.GROUPS}/${props.match.params.groupId}/${MenuItem.REPORTS}`}
            />
        ),
        []
    )

    const renderReports = useCallback(
        (props: RouteComponentProps<any>) => (
            <GroupIdSetter groupId={parseInt(props.match.params.groupId!)}>
                <Reports
                    loadCurrentUser={loadCurrentUser}
                    reportId={parseInt(props.match.params.reportId)}
                />
            </GroupIdSetter>
        ),
        [loadCurrentUser]
    )

    return (
        <ReactResizeDetector onResize={handleResize} targetRef={ref} handleWidth>
            <main
                ref={ref}
                className={`marq main-layout ${mainIsScrollable ? "" : "not-scrollable"}`}
            >
                <Switch>
                    <Redirect exact from="/" to={MenuItem.GROUPS} />

                    <RouteMiddleware exact path={`/${MenuItem.GROUPS}`}>
                        <GroupsList />
                    </RouteMiddleware>
                    <RouteMiddleware
                        exact
                        path={`/${MenuItem.GROUPS}/:groupId`}
                        render={redirectReports}
                    />
                    <RouteMiddleware
                        path={`/${MenuItem.GROUPS}/:groupId/${MenuItem.REPORTS}/:reportId?`}
                        render={renderReports}
                    ></RouteMiddleware>

                    <RouteMiddleware path={"/" + MenuItem.CHANGE_PASSWORD}>
                        <ChangePassword />
                    </RouteMiddleware>
                    <RouteMiddleware path={"/" + MenuItem.DEVICE_SETUP}>
                        <DeviceSetup />
                    </RouteMiddleware>
                    <RouteMiddleware path={"/" + MenuItem.ADMINISTRATION}>
                        <Administration />
                    </RouteMiddleware>
                </Switch>
            </main>
        </ReactResizeDetector>
    )
})

export default memo(Main)

type GroupIdSetterProps = {
    groupId: number
    children: JSX.Element
}

export const GroupIdSetter = memo(({ groupId, children }: GroupIdSetterProps) => {
    const groupIdFromStore = useAppStore((state) => state.groupId)
    const setGroupId = useAppStore((state) => state.setGroupId)

    useEffect(() => {
        setGroupId(groupId)
    }, [setGroupId, groupId])

    // useEffect se vola az po prvnim renderu, takze by se bez ifu stahl napr. report pro groupId -1
    if (groupIdFromStore === groupId) {
        return children
    }
    return null
})
