import { mergeWith } from "lodash"
import { IComponent, IComponentParent, IReport, RequiredReport } from "../types/BaseTypes"
import { IFunction } from "../types/FunctionsType"
import ComponentUtils from "./ComponentUtils"

class ReportUtils {
    // namerguje do reportu inicializaci z baseReport a vytvori RequiredReport
    static initReport(report: IReport): RequiredReport {
        // neprepise null hodnoty
        return mergeWith(ReportUtils.baseReport(), report, (a, b) => (b === null ? a : undefined))
    }

    static baseReport(): RequiredReport {
        return {
            name: "",
            rank: 0,
            parameters: { flow: { nodes: [], edges: [], gridWidth: 0, gridHeight: 0 } },
            components: [],
            functions: [],
            variables: []
        }
    }

    static findParentOf(
        componentParent: IComponentParent,
        reportPath: Array<String>
    ): IComponentParent {
        let result = componentParent
        reportPath.forEach((rp) => {
            const node = result.components?.find((c) => c.guid === rp)
            if (node) {
                result = node
            } else {
                throw new Error("Component not found by guid path " + rp)
            }
        })
        return result
    }

    static findLensParentOf(
        componentParent: IComponentParent,
        reportPath: Array<String>
    ): Array<"components" | number> {
        const lensPath: Array<"components" | number> = []
        let parent = componentParent

        reportPath.forEach((rp) => {
            const children = parent.components
            if (children) {
                const childIndex = children?.findIndex((c) => c.guid === rp)
                if (childIndex !== undefined && childIndex !== -1) {
                    lensPath.push("components")
                    lensPath.push(childIndex)
                    parent = children[childIndex]
                } else {
                    throw new Error("Component not found by guid path " + rp)
                }
            }
        })

        return lensPath
    }

    static deepCopyReports(
        reports: Array<IReport>,
        groupId: number,
        dbConnectionId?: number,
        modbusConnectionId?: number
    ) {
        reports.forEach((r) => {
            r.id = undefined
            r.businessId = undefined
            r.name += "_copy"
            r.group = { id: groupId }
            r.variables?.forEach((v) => {
                v.id = undefined
                ;(v as any).businessId = undefined
            })
            ComponentUtils.deepCopyFunctions(r.functions as any, dbConnectionId, modbusConnectionId)
            ComponentUtils.deepCopyComponents(
                r.components as any,
                dbConnectionId,
                modbusConnectionId
            )
        })
    }

    static prepareReportsToSave(reports: Array<IReport>) {
        reports.forEach((r) => {
            ReportUtils.prepareReportToSave(r)
        })
    }

    static prepareReportToSave(report: IReport) {
        ReportUtils.prepareFunctionsToSave(report.functions as any)
        ReportUtils.prepareComponentsToSave(report.components as any)
    }

    static prepareFunctionsToSave(functions?: Array<IFunction>) {
        functions?.forEach((f) => {
            ReportUtils.prepareFunctionToSave(f)
        })
    }

    static prepareFunctionToSave(f: IFunction) {
        if (f.dbConnection) {
            f.dbConnection = { id: f.dbConnection as number }
        }
        if (f.modbusConnection) {
            f.modbusConnection = { id: f.modbusConnection as number }
        }
    }

    static prepareComponentsToSave(components?: Array<IComponent>) {
        components?.forEach((c) => {
            ReportUtils.prepareComponentsToSave(c.components)
            ReportUtils.prepareFunctionsToSave(c.functions)
        })
    }

    static prepareComponentToSave(component: IComponent) {
        ReportUtils.prepareComponentsToSave(component.components)
        ReportUtils.prepareFunctionsToSave(component.functions)
    }

    static prepareComponentToEdit(component: IComponent) {
        ReportUtils.prepareComponentsToEdit(component.components)
        ReportUtils.prepareFunctionsToEdit(component.functions)
    }

    static prepareReportForEdit(report: IReport) {
        ReportUtils.prepareFunctionsToEdit(report.functions as any)
        ReportUtils.prepareComponentsToEdit(report.components as any)
    }

    static prepareFunctionsToEdit(functions?: Array<IFunction>) {
        functions?.forEach((f) => {
            ReportUtils.prepareFunctionToEdit(f)
        })
    }

    static prepareFunctionToEdit(f: IFunction) {
        if (f.dbConnection && (f.dbConnection as any).id) {
            f.dbConnection = (f.dbConnection as { id: number }).id as any
        }
        if (f.modbusConnection && (f.modbusConnection as any).id) {
            f.modbusConnection = (f.modbusConnection as { id: number }).id as any
        }
    }

    static prepareComponentsToEdit(components?: Array<IComponent>) {
        components?.forEach((c) => {
            ReportUtils.prepareComponentsToEdit(c.components)
            ReportUtils.prepareFunctionsToEdit(c.functions)
        })
    }

    static sortReports = (reports: Array<IReport>) => {
        reports.sort((a, b) => {
            if (a.rank !== undefined && b.rank !== undefined) {
                return a.rank - b.rank
            }
            return 0
        })
    }
}

export default ReportUtils
