import { IImage } from "../types/BaseTypes"
import { getImage } from "../utils/API/APIImages"

export default class ImagesCache {
    private static instance: ImagesCache
    // TODO celou tridu otypovat
    private cache: any

    public static getInstance = (): ImagesCache => {
        if (!ImagesCache.instance) {
            ImagesCache.instance = new ImagesCache()
        }
        return ImagesCache.instance
    }

    public constructor() {
        this.cache = require("lru-cache-promise")()
    }

    public getImage = (id: number): Promise<IImage> => {
        return this.cache.getAsync(id, getImage)
    }
}
