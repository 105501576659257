import { createContext, memo, useContext, useMemo } from "react"
import { useAppStore } from "../../stores/AppStore"
import { IComponentParent, IVariable } from "../../types/BaseTypes"
import { standardWarning } from "../../utils/NotifyUtils"
import { useVariables } from "../../variables/Variables"

export type SetVariableParams = {
    guid: string
    data: any
    variableNotFound?: () => void
}

export type GetVariableParams = {
    guid: string
    variableNotFound?: () => any
}

export interface IVariablesContext {
    context: Record<string, any>
    getAvailableVariables(): Array<IVariable>
    setVariableByName(name: string, data: any): void
    setVariableByGuid(params: SetVariableParams): void
    getVariableValueByGuid(params: GetVariableParams): unknown
}

export const VariablesContext = createContext<IVariablesContext | undefined>(undefined)

type Props = {
    children: JSX.Element
    componentParent: IComponentParent
}

export const VariablesProvider = memo(({ children, componentParent }: Props) => {
    const isAdmin = useAppStore((state) => state.currentUser!.admin)

    const { variables } = componentParent

    const parentVariablesContext = useContext(VariablesContext)
    const [setByName, setByGuid, getByGuid, variablesContext] = useVariables(
        componentParent.variables
    )

    const ctx = useMemo(
        () => Object.assign({}, parentVariablesContext?.context ?? {}, variablesContext),
        [parentVariablesContext?.context, variablesContext]
    )

    const context = useMemo(
        () => ({
            context: ctx,
            getAvailableVariables: (): IVariable[] => {
                const result = variables ?? []
                const fromParent = parentVariablesContext?.getAvailableVariables()
                if (fromParent) {
                    return fromParent.concat(result)
                }
                return result
            },
            getVariableValueByGuid: (params: GetVariableParams): unknown => {
                return getByGuid({
                    ...params,
                    variableNotFound: () => {
                        if (parentVariablesContext) {
                            return parentVariablesContext.getVariableValueByGuid({
                                guid: params.guid
                            })
                        } else {
                            if (isAdmin) {
                                standardWarning(
                                    "Proměnnou se nepodařilo dohledat a vrátit její hodnotu. Guid: " +
                                        params.guid
                                )
                            }
                            return undefined
                        }
                    }
                })
            },
            setVariableByGuid: (params: SetVariableParams): void => {
                setByGuid({
                    ...params,
                    variableNotFound: () => {
                        // TODO pokud nezafunguje, tak se to admin nedozvi. Stejne tak ve Variables.ts v setByName
                        parentVariablesContext?.setVariableByGuid(params)
                    }
                })
            },
            setVariableByName: (name: string, data: any) => {
                setByName(name, data, () => {
                    if (parentVariablesContext) {
                        parentVariablesContext.setVariableByName(name, data)
                    } else {
                        if (isAdmin) {
                            standardWarning(
                                "Proměnnou se nepodařilo dohledat a nastavit. Jméno: " + name
                            )
                        }
                    }
                })
            }
        }),
        [parentVariablesContext, variables, ctx, getByGuid, setByName, setByGuid, isAdmin]
    )

    const innerChildren = useMemo(() => {
        const anyName = variables?.find((v) => v.name)?.name
        if (anyName && !ctx.hasOwnProperty(anyName)) {
            return null
        }
        return children
    }, [children, ctx, variables])

    return <VariablesContext.Provider value={context}>{innerChildren}</VariablesContext.Provider>
})
