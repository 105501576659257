import { useCallback, useEffect, useMemo, useState } from "react"
import { subscribeToGlobalTimer, unsubscribeFromGlobalTimer } from "../managers/GlobalTimer"
import { ExecuteParams } from "../report/contexts/FunctionsContext"
import { SetVariableParams } from "../report/contexts/VariablesContext"
import { useReportStore } from "../stores/ReportStore"
import { IComponentParent } from "../types/BaseTypes"
import { Action } from "../types/ComponentTypes"
import { FunctionType, TFunction } from "../types/FunctionsType"
import { executeFunction } from "../utils/API/APIFunctions"
import { useExecuteRef, useReportId } from "../utils/CustomHooks"
import EvalUtils from "../utils/EvalUtils"
import { standardWarning } from "../utils/NotifyUtils"

export type FunctionsParent = {
    name?: string
    evaluate?: (expression: string | undefined) => any
    setVariableByGuid?: (params: SetVariableParams) => void
}

type FuncRefType = (actions?: Array<Action>, selfValue?: any) => void

export const useFunctions = (componentParent: IComponentParent, parent: FunctionsParent) => {
    const executeRef = useExecuteRef()

    const reportId = useReportId()
    const activeReportId = useReportStore((state) => state.activeReportId)

    const [executedIds, setExecutedIds] = useState<Array<string>>([])

    const functionsDefs = componentParent.functions

    const functions = useMemo(() => functionsDefs?.map((def) => new Fun(def)), [functionsDefs])

    useEffect(() => {
        if (functions && functions.length > 0) {
            const subscriptionIds: Array<string> = []
            const executed: Array<string> = []

            functions?.forEach((f) => {
                const refreshInterval = f.fun.parameters?.refreshInterval
                const guid = f.fun.guid

                if (refreshInterval) {
                    const onTick = () => {
                        if (activeReportId === reportId) {
                            f.processFunction(true, parent, executeRef.current)
                        }
                    }

                    if (!executedIds.includes(guid)) {
                        setTimeout(onTick)
                        executed.push(guid)
                    }

                    const subscription = {
                        onTick,
                        tickMs: refreshInterval * 1000
                    }

                    const subscribtionId = subscribeToGlobalTimer(subscription)
                    subscriptionIds.push(subscribtionId)
                }
            })

            if (executed.length > 0) {
                setExecutedIds([...executedIds, ...executed])
            }

            return () => subscriptionIds.forEach((id) => unsubscribeFromGlobalTimer(id))
        }
    }, [functions, executeRef, parent, executedIds, activeReportId, reportId])

    const execute = useCallback(
        ({ guid, params, functionNotFound }: ExecuteParams) => {
            const fun = functions?.find((f) => f.fun.guid === guid)
            if (fun) {
                return fun.processFunction(false, parent, executeRef.current, params)
            }
            return functionNotFound?.()
        },
        [functions, executeRef, parent]
    )

    return execute
}

export class Fun {
    private calling = false
    private warnedId = false
    public readonly fun: TFunction

    constructor(fun: TFunction) {
        this.fun = fun
    }

    private functionName = (parent: FunctionsParent) => {
        const parentName = parent.name
        const { name, id } = this.fun
        return `[${parentName ? parentName : "/bez jména/"}: ${name} (id: ${id})]`
    }

    public processFunction = (
        callingCheck: boolean,
        parent: FunctionsParent,
        executeActions: FuncRefType,
        externParams?: Array<any>
    ): void => {
        const { id, parameters, type } = this.fun
        // TODO - divne, ze zde typovani nefunguje
        const { onSuccessActions, onErrorActions } = parameters as any
        if (!callingCheck || !this.calling) {
            const params =
                externParams && externParams.length > 0 ? externParams : this.evaluateParams(parent)

            switch (type) {
                case FunctionType.SET_VARIABLE:
                    const guid = parameters?.output
                    if (guid) {
                        const value = parent.evaluate?.(
                            EvalUtils.correctExpression(parameters.expression)
                        )
                        parent.setVariableByGuid?.({
                            guid,
                            data: params?.[0] === undefined ? value : params[0]
                        })
                    }
                    break
                default:
                    if (id) {
                        this.calling = true
                        executeFunction(id, params)
                            .then((response: any) => {
                                this.calling = false
                                const guid = this.fun.parameters?.output
                                if (guid) {
                                    parent.setVariableByGuid?.({
                                        guid,
                                        data: response.data
                                    })
                                } else {
                                    switch (type) {
                                        case FunctionType.WRITE_SQL:
                                        case FunctionType.WRITE_MODBUS:
                                            break
                                        case FunctionType.READ_SQL:
                                        case FunctionType.READ_MODBUS:
                                            standardWarning(
                                                `Výsledek funkce ${this.functionName(
                                                    parent
                                                )} se neukládá do žádné proměnné.`,
                                                undefined,
                                                5000
                                            )
                                            break
                                    }
                                }

                                executeActions(onSuccessActions, {
                                    value: response.data
                                })
                            })
                            .catch((error) => executeActions(onErrorActions, { value: error }))
                            .finally(() => {
                                this.calling = false
                            })
                    } else if (!this.warnedId) {
                        this.warnedId = true
                        standardWarning(
                            `Funkce ${this.functionName(
                                parent
                            )} nemá nastaveno id a nelze provést.`,
                            undefined,
                            5000
                        )
                    }
            }
        } else {
            console.log(`Warning - function ${this.functionName(parent)} is already pending.`)
        }
    }

    private evaluateParams = (parent: FunctionsParent) => {
        return this.fun.parameters?.params?.map((p) =>
            parent.evaluate?.(EvalUtils.correctExpression(p.expression))
        )
    }
}
