import { forwardRef, memo, useMemo } from "react"
import { $enum } from "ts-enum-util"
import {
    ComponentProps,
    IBaseComponent
} from "../../report/Schema/Layer/ComponentContainer/CommonComponent/CommonComponent"
import { KeyType } from "../../types/KeysTypes"
import ComponentUtils from "../../utils/ComponentUtils"
import { useExpression, useImperative } from "../../utils/CustomHooks"
import { ComponentTextSize } from "../Text/TextEditor"
import "./HeadingComponent.scss"
import { HeadingParameters } from "./HeadingEditor"

type Params = HeadingParameters

type Props = ComponentProps<Params>

const HeadingComponent = forwardRef<IBaseComponent, Props>(
    ({ text, horizontalAlign, fontSize, bold, italic }, ref) => {
        const expression = useMemo(() => {
            // zpetna kompatibilita
            if (!text) {
                return text
            }
            if (text.includes("v.") || text.includes('"')) {
                return text
            }
            return '"' + text + '"'
        }, [text])
        const [resultText] = useExpression(expression, "")

        useImperative(
            ref,
            useMemo(() => {
                const messenger = {
                    name: "textSize",
                    enums: $enum(ComponentTextSize)
                        .getValues()
                        .map((e) => ({ enum: e })),
                    value: fontSize
                }
                return [
                    {
                        keyType: KeyType.PLUS,
                        messenger: messenger
                    },
                    {
                        keyType: KeyType.MINUS,
                        messenger: messenger,
                        reverse: true
                    }
                ]
            }, [fontSize])
        )

        const className = useMemo(() => `${ComponentUtils.classFromFontSize(fontSize)}`, [fontSize])

        return (
            <div
                className={className}
                style={{
                    textAlign: horizontalAlign,
                    fontWeight: bold ? "bold" : undefined,
                    fontStyle: italic ? "italic" : undefined
                }}
            >
                {resultText}
            </div>
        )
    }
)

export default memo(HeadingComponent)
