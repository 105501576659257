import { Form, TabPanel } from "devextreme-react"
import { GroupItem, Label, SimpleItem } from "devextreme-react/form"
import { Item } from "devextreme-react/tab-panel"
import { memo, useCallback } from "react"
import { $enum } from "ts-enum-util"
import ActionsTriggerEditor from "../../componentsSetup/common/ActionsTriggerEditor"
import ListPopupEditor from "../../componentsSetup/common/ListPopupEditor"
import { initMonacoEditor } from "../../componentsSetup/common/MonacoEditor"
import { Action } from "../../types/ComponentTypes"
import { ThreeDParameters } from "./ThreeDEditor"

export enum MeshType {
    BOX = "BOX",
    SPHERE = "SPHERE",
    CYLINDER = "CYLINDER",
    TORUS = "TORUS",
    CONE = "CONE",
    EXTRUDE = "EXTRUDE",
    LATHE = "LATHE",
    TETRAHEDRON = "TETRAHEDRON",
    OBJECTTHREED = "OBJECT3D"
}

type ListItem = {
    name?: string
    guid: string
}

export type ThreeDPointLight = ListItem & {
    intensityExpression: string
    positionExpression: string
}

export type ThreeDAnimation = {
    enabledExpression?: string
    animationExpression?: string
}

export type ThreeDGroup = ListItem & {
    positionExpression?: string
    meshs?: Array<ThreeDMesh>
    groups?: Array<ThreeDGroup>
    pointLights?: Array<ThreeDPointLight>
    animation?: ThreeDAnimation
}

export type ThreeDMesh = ListItem & {
    type: MeshType
    animation?: ThreeDAnimation
    onClickActions?: Array<Action>
    colorExpression?: string
}

type OtherProps = {
    parameters: ThreeDParameters
}

export const CameraRender = memo(({ parameters }: OtherProps) => {
    parameters.camera = parameters.camera ?? { position: { x: -5, y: 2, z: -1 } }

    return (
        <div className="component-setup-container">
            <Form formData={parameters}>
                <SimpleItem
                    dataField="orbitControls"
                    editorType="dxCheckBox"
                    helpText="Pokud je zatrženo, tak lze myší posouvat kamerou, přibližovat a oddalovat."
                >
                    <Label text="Pohyblivá kamera" />
                </SimpleItem>
                <GroupItem caption="Pozice" colCount={3}>
                    <SimpleItem editorType="dxNumberBox" dataField="camera.position.x">
                        <Label text="x" />
                    </SimpleItem>
                    <SimpleItem editorType="dxNumberBox" dataField="camera.position.y">
                        <Label text="y" />
                    </SimpleItem>
                    <SimpleItem editorType="dxNumberBox" dataField="camera.position.z">
                        <Label text="z" />
                    </SimpleItem>
                </GroupItem>
            </Form>
        </div>
    )
})

export const OthersThreeDRender = memo(({ parameters }: OtherProps) => {
    return (
        <div className="component-setup-container">
            <Form formData={parameters}>
                <SimpleItem dataField="gridHelper" editorType="dxCheckBox">
                    <Label text="Pomocná mřížka" />
                </SimpleItem>
                <SimpleItem dataField="axesHelper" editorType="dxCheckBox">
                    <Label text="Barevné zvýraznění os" />
                </SimpleItem>
            </Form>
        </div>
    )
})

export const positionRender = (item: any) => (
    <SimpleItem
        render={initMonacoEditor(item, "positionExpression")}
        helpText="Výraz musí vracet vektor se třemi souřadnicemi x, y, z. Např.: [0, 0, 0]"
    >
        <Label text="Pozice" />
    </SimpleItem>
)

type AnimationsProps = {
    owner: ThreeDGroup | ThreeDMesh
}

export const RenderAnimations = memo(({ owner }: AnimationsProps) => {
    owner.animation = owner.animation ?? {}

    return (
        <Form formData={owner.animation}>
            <GroupItem>
                <SimpleItem render={initMonacoEditor(owner.animation, "enabledExpression")}>
                    <Label text="Výraz aktivnosti animace" />
                </SimpleItem>
                <SimpleItem
                    render={initMonacoEditor(owner.animation, "animationExpression")}
                    helpText="Výraz musí vrace objekt s položky konkrétní animace. Např.: {rotation: [0, 0.1, 0], position: [Math.sin(self.time) * 0.1, 0, 0]}"
                >
                    <Label text="Animační posun" />
                </SimpleItem>
            </GroupItem>
        </Form>
    )
})

type PointLightProps = {
    item: ThreeDGroup
}

const meshTypeOptions = {
    items: $enum(MeshType).getValues()
}

type GroupSettingsProps = {
    owner: ThreeDGroup | ThreeDMesh
    additionalMeshRender?: (item: any) => JSX.Element
}

export const GroupSettingsRender = memo(({ owner, additionalMeshRender }: GroupSettingsProps) => {
    const groupEditorRender = useCallback(
        (item: any) => (
            <GroupItem>
                <SimpleItem dataField="name">
                    <Label text="jméno" />
                </SimpleItem>
                <GroupItem>
                    <GroupSettingsRender owner={item} additionalMeshRender={additionalMeshRender} />
                </GroupItem>
            </GroupItem>
        ),
        [additionalMeshRender]
    )

    const meshEditorRender = useCallback(
        (item: any) => (
            <GroupItem>
                <TabPanel animationEnabled={true}>
                    <Item title="Základní">
                        <div className="component-setup-container">
                            <Form formData={item}>
                                <GroupItem>
                                    <GroupItem caption="Základní">
                                        <SimpleItem dataField="name">
                                            <Label text="jméno" />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField="type"
                                            editorType="dxSelectBox"
                                            editorOptions={meshTypeOptions}
                                        >
                                            <Label text="typ" />
                                        </SimpleItem>
                                    </GroupItem>
                                    <SimpleItem
                                        render={initMonacoEditor(item, "colorExpression")}
                                        helpText="Výraz musí vracet barvu ve tvaru 0xZZZZZZ, např.: 0xe5d54d"
                                    >
                                        <Label text="Barva" />
                                    </SimpleItem>
                                    {additionalMeshRender?.(item)}
                                </GroupItem>
                            </Form>
                        </div>
                    </Item>
                    <Item title="Animace">
                        <div className="component-setup-container">
                            <RenderAnimations owner={item} />
                        </div>
                    </Item>
                    <Item title="OnClick">
                        <div className="component-setup-container">
                            <Form formData={item}>
                                <GroupItem>
                                    <ActionsTriggerEditor
                                        listOwner={item}
                                        listName="onClickActions"
                                    />
                                </GroupItem>
                            </Form>
                        </div>
                    </Item>
                </TabPanel>
            </GroupItem>
        ),
        [additionalMeshRender]
    )

    const pointLightEditorRender = useCallback(
        (item: PointLightProps) => (
            <GroupItem>
                <SimpleItem dataField="name">
                    <Label text="jméno" />
                </SimpleItem>
                <SimpleItem
                    render={initMonacoEditor(item, "intensityExpression")}
                    helpText="Výraz musí vrace číslo, např.: 1.12"
                >
                    <Label text="Intenzita" />
                </SimpleItem>
                <GroupItem>
                    <GroupItem>{positionRender(item)}</GroupItem>
                </GroupItem>
            </GroupItem>
        ),
        []
    )

    return (
        <div className="component-setup-container">
            <TabPanel animationEnabled={true}>
                <Item title="Prvky">
                    <div className="component-setup-container">
                        <Form formData={owner}>
                            <GroupItem>{positionRender(owner)}</GroupItem>
                            <GroupItem colCount={3}>
                                <GroupItem caption="Skupiny">
                                    <ListPopupEditor
                                        listOwner={owner}
                                        listName="groups"
                                        guid={true}
                                        idProperty="guid"
                                        formItemContent={groupEditorRender}
                                    />
                                </GroupItem>
                                <GroupItem caption="Tvary">
                                    <ListPopupEditor
                                        listOwner={owner}
                                        listName="meshs"
                                        guid={true}
                                        idProperty="guid"
                                        formItemContent={meshEditorRender}
                                    />
                                </GroupItem>
                                <GroupItem caption="Bodová světla">
                                    <ListPopupEditor
                                        listOwner={owner}
                                        listName="pointLights"
                                        guid={true}
                                        idProperty="guid"
                                        formItemContent={pointLightEditorRender}
                                    />
                                </GroupItem>
                            </GroupItem>
                        </Form>
                    </div>
                </Item>
                <Item title="Animace">
                    <RenderAnimations owner={owner} />
                </Item>
            </TabPanel>
        </div>
    )
})
