import { Form } from "devextreme-react"
import { Label, SimpleItem } from "devextreme-react/form"
import { useContext, useMemo } from "react"
import { VariablesContext } from "../../report/contexts/VariablesContext"

type OutputProps = {
    formData: any
    dataField: string
}

export default function OutputVariable(props: OutputProps) {
    const varContext = useContext(VariablesContext)

    const variablesOptions = useMemo(
        () => ({
            items: varContext?.getAvailableVariables(),
            displayExpr: "name",
            valueExpr: "guid",
            showClearButton: true
        }),
        [varContext]
    )

    return (
        <Form formData={props.formData}>
            <SimpleItem
                dataField={props.dataField}
                editorType="dxSelectBox"
                editorOptions={variablesOptions}
            >
                <Label text="Výsledná proměnná" />
            </SimpleItem>
        </Form>
    )
}
