import React from "react"
import "./../css/Login.scss"

import Form, {
    ButtonItem,
    CompareRule,
    Item as FItem,
    GroupItem,
    PatternRule
} from "devextreme-react/form"
import Popup from "devextreme-react/popup"
import { RequiredRule } from "devextreme-react/validator"
import notify from "devextreme/ui/notify"

import { Button } from "devextreme-react"
import { ACCESS_TOKEN } from "../constants"
import { IUser, LoginResponse } from "../types/BaseTypes"
import { firstLogin, login, preLogin } from "../utils/APIUsers"
import { securityApiCall } from "../utils/APIUtils"
import LocalStorageUtils from "../utils/LocalStorageUtils"
import { standardError } from "../utils/NotifyUtils"
import { ReactComponent as Logo } from "./svg/logo-and-name.svg"

type LoginProps = {
    onLogin: (user: IUser) => void
    forgottenPassword: (forgottenPassword: boolean) => void
}

type LoginState = {
    isLoading: boolean
    popupVisible: boolean
    secretSet: boolean
    barcodeAddress: string
}

type LoginStats = {
    usernameOrEmail: string
    password: string
}

export type NewPassword = {
    newPassword: string
    newPasswordAgain: string
    secret: string
}

class Login extends React.Component<LoginProps, LoginState> {
    private loginStats: LoginStats
    private additionalStats: NewPassword

    constructor(props: LoginProps) {
        super(props)
        this.state = {
            isLoading: false,
            popupVisible: false,
            secretSet: false,
            barcodeAddress: ""
        }

        this.loginStats = {
            usernameOrEmail: "",
            password: ""
        }
        this.additionalStats = {
            newPassword: "",
            newPasswordAgain: "",
            secret: ""
        }
    }

    handleLogin = (e: any) => {
        e.preventDefault()
        this.setState({ isLoading: true })

        const setupObject = LocalStorageUtils.loadSetupObject()
        const loginRequest = Object.assign({}, this.loginStats, {
            twoFactorSuperKey: setupObject.twoFactorSuperKey
        })
        securityApiCall(
            () => preLogin(loginRequest),
            (response: LoginResponse) => {
                if (response.token) {
                    localStorage.setItem(ACCESS_TOKEN, response.token.accessToken)
                    this.props.onLogin(response.user!)
                } else {
                    this.setState({
                        popupVisible: true,
                        secretSet: response.secretSet!,
                        barcodeAddress: response.barcodeAddress!
                    })
                }
            },
            () => notify("Špatné jméno, nebo heslo.", "error", 2000),
            standardError,
            () => this.setState({ isLoading: false })
        )
    }

    handleBarcode = () => {
        this.setState({ isLoading: true })

        const loginRequest = Object.assign({}, this.loginStats, this.additionalStats)
        securityApiCall(
            () => firstLogin(loginRequest),
            (response: LoginResponse) => {
                localStorage.setItem(ACCESS_TOKEN, response.token?.accessToken!)
                this.props.onLogin(response.user!)
            },
            () => notify("Neplatné číslo ověření.", "error", 2000),
            standardError,
            () => this.setState({ isLoading: false })
        )
    }

    handleSecret = (e: any) => {
        e.preventDefault()
        this.setState({ isLoading: true })

        const loginRequest = Object.assign({}, this.loginStats, this.additionalStats)
        securityApiCall(
            () => login(loginRequest),
            (response: LoginResponse) => {
                localStorage.setItem(ACCESS_TOKEN, response.token?.accessToken!)
                this.props.onLogin(response.user!)
            },
            () => notify("Neplatné číslo ověření.", "error", 2000),
            standardError,
            () => this.setState({ isLoading: false })
        )
    }

    passwordComparison = () => {
        return this.additionalStats.newPassword
    }

    handlePopupHidden = () => {
        this.setState({ popupVisible: false })
    }

    render() {
        let popupContent = null
        if (this.state.secretSet) {
            popupContent = (
                <div>
                    <form onSubmit={this.handleSecret}>
                        <Form
                            colCount={1}
                            id="loginForm"
                            formData={this.additionalStats}
                            labelLocation="top"
                        >
                            <FItem
                                dataField="secret"
                                editorType="dxTextBox"
                                label={{ text: "Ověření" }}
                                helpText="Zadejte šestimístný kód z aplikace Google Authenticator."
                            ></FItem>
                            <ButtonItem
                                horizontalAlignment="center"
                                buttonOptions={{
                                    text: this.state.isLoading ? "... Přihlašuji" : "Přihlásit",
                                    type: "default",
                                    disabled: this.state.isLoading,
                                    useSubmitBehavior: true
                                }}
                            />
                        </Form>
                    </form>
                </div>
            )
        } else {
            popupContent = (
                <div>
                    <div>
                        Před prvním přihlášením je potřeba nastavit dvoufaktorovou autentifikaci a
                        změnit heslo. Naskenujte barcode v aplikaci Google Authenticator a potom
                        zadejte šestimístný kód do pole ověření.
                    </div>

                    <img src={this.state.barcodeAddress} alt="Barcode"></img>

                    <form>
                        <Form
                            colCount={1}
                            id="loginForm"
                            formData={this.additionalStats}
                            labelLocation="top"
                        >
                            <GroupItem colCount={2}>
                                <GroupItem caption="Změna hesla">
                                    <FItem
                                        dataField="newPassword"
                                        editorType="dxTextBox"
                                        label={{ text: "Nové heslo" }}
                                        editorOptions={{ mode: "password" }}
                                    >
                                        <RequiredRule message="Vyplňte heslo" />
                                        <PatternRule
                                            message="Heslo musí mít alespoň 8 znaků. Musí obsahovat alespoň jedno velké a malé písmeno a číslici."
                                            pattern={
                                                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
                                            }
                                        />
                                    </FItem>
                                    <FItem
                                        dataField="newPasswordAgain"
                                        editorType="dxTextBox"
                                        label={{ text: "Nové heslo znovu" }}
                                        editorOptions={{ mode: "password" }}
                                    >
                                        <RequiredRule message="Vyplňte heslo pro ověření" />
                                        <CompareRule
                                            message="Hesla se neshodují"
                                            comparisonTarget={this.passwordComparison}
                                        />
                                    </FItem>
                                </GroupItem>
                                <GroupItem caption="Ověření">
                                    <FItem
                                        dataField="secret"
                                        editorType="dxTextBox"
                                        label={{ text: "Ověření" }}
                                    >
                                        <RequiredRule message="Vyplňte kód ověření" />
                                    </FItem>
                                </GroupItem>
                            </GroupItem>
                            <ButtonItem
                                horizontalAlignment="center"
                                buttonOptions={{
                                    text: this.state.isLoading ? "... Přihlašuji" : "Přihlásit",
                                    type: "default",
                                    onClick: this.handleBarcode,
                                    disabled: this.state.isLoading
                                }}
                            />
                        </Form>
                    </form>
                </div>
            )
        }

        return (
            <div>
                <Popup
                    width="auto"
                    height="auto"
                    showTitle={true}
                    title="Ověření"
                    visible={this.state.popupVisible}
                    onHiding={this.handlePopupHidden}
                >
                    {popupContent}
                </Popup>

                <div className="wrapper fadeInDown">
                    <div className="form-outer">
                        <form className="form-inner" onSubmit={this.handleLogin}>
                            <a
                                className="logo fadeIn first"
                                href="https://www.marq.software"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Logo />
                            </a>
                            <Form
                                className="dx-form"
                                colCount={1}
                                id="loginForm"
                                formData={this.loginStats}
                                labelLocation="top"
                            >
                                <FItem
                                    cssClass="fadeIn second"
                                    editorOptions={{
                                        placeholder: "Uživatelské jméno nebo e-mail"
                                    }}
                                    dataField="usernameOrEmail"
                                    label={{ visible: false }}
                                >
                                    <RequiredRule message="Vyplňte jméno" />
                                </FItem>
                                <GroupItem cssClass="login-fields-delimiter"></GroupItem>
                                <FItem
                                    cssClass="fadeIn third"
                                    dataField="password"
                                    editorType="dxTextBox"
                                    label={{ visible: false }}
                                    editorOptions={{ mode: "password", placeholder: "Heslo" }}
                                >
                                    <RequiredRule message="Vyplňte heslo" />
                                </FItem>
                            </Form>
                            <div className="login-buttons">
                                <Button
                                    className="fadeIn third login-button-password"
                                    text="Zapomenuté heslo"
                                    type="default"
                                    stylingMode="text"
                                    onClick={() => {
                                        this.props.forgottenPassword(true)
                                    }}
                                />
                                <Button
                                    className="fadeIn fourth"
                                    text={this.state.isLoading ? "... Přihlašuji" : "Přihlásit"}
                                    type="default"
                                    disabled={this.state.isLoading}
                                    useSubmitBehavior={true}
                                />
                            </div>
                            <span className="version fadeIn fourth">
                                {"v. " + process.env.REACT_APP_VERSION}
                            </span>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
