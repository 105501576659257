import { IEvaluatorContext } from "../report/contexts/EvaluatorContext"
import { IFunctionsContext } from "../report/contexts/FunctionsContext"
import { IVariablesContext } from "../report/contexts/VariablesContext"
import { useAppStore } from "../stores/AppStore"
import { useNavBarStore } from "../stores/NavBarStore"
import { Action, ActionEnum } from "../types/ComponentTypes"
import EvalUtils from "./EvalUtils"
import { standardMessage } from "./NotifyUtils"

export function executeActions(
    evaluatorContext?: IEvaluatorContext,
    variablesContext?: IVariablesContext,
    functionsContext?: IFunctionsContext,
    actions?: Array<Action>,
    selfValue?: Record<string, any>
) {
    const subContext = { self: selfValue }
    actions?.forEach((a) => {
        if (a.type === ActionEnum.FUNCTION) {
            const params = a.params?.map((p) =>
                evaluatorContext?.evaluate(EvalUtils.correctExpression(p.expression), subContext)
            )
            if (a.functionGuid) {
                functionsContext?.executeFunction({ guid: a.functionGuid, params })
            }
        } else if (a.type === ActionEnum.VARIABLE) {
            const result = evaluatorContext?.evaluate(
                EvalUtils.correctExpression(a.expression),
                subContext
            )

            if (a.outputVariable) {
                variablesContext?.setVariableByGuid({ guid: a.outputVariable, data: result })
            } else {
                // TODO neni nastavena promenna
            }
        } else if (a.type === ActionEnum.REPORT) {
            const groupId = useAppStore.getState().groupId

            useAppStore.getState().changePath(`/groups/${groupId}/reports/${a.reportId}`)
        } else if (a.type === ActionEnum.TOAST) {
            const message = evaluatorContext?.evaluate(
                EvalUtils.correctExpression(a.toastMessageExpression),
                subContext
            )
            standardMessage(a.toastType, message, undefined, (a.toastDuration ?? 3) * 1000)
        } else if (a.type === ActionEnum.REPORT_STATE) {
            const setReportState = useNavBarStore.getState().setReportState

            const result = evaluatorContext?.evaluate(
                EvalUtils.correctExpression(a.expression),
                subContext
            )
            setReportState(result[0], result[1])
        } else {
            // TODO akce neni definovana
        }
    })
}
