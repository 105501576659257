import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { Button } from "devextreme-react"
import { Prompt } from "react-router-dom"
import { useHistoryReportStore, useReportStore } from "../../stores/ReportStore"
import { useReportStore2 } from "../../stores/ReportStore2"
import { KEY_MAP, useKeyClick, useReport2Fields } from "../../utils/CustomHooks"
import Library from "./Library"
import "./ReportManagement.scss"
import ReportSettingsPopup, { IReportSettingsPopup } from "./ReportSettingsPopup"

type Props = {}

function ReportManagement(props: Props) {
    const [editMode, setEditMode] = useState(false)
    const [addMode, setAddMode] = useState(false)

    const saveReport = useReportStore((state) => state.saveReport)

    const setEditModeReport = useReportStore2((state) => state.setEditMode)
    const setPopupVisible = useReportStore2((state) => state.setPopupVisible)
    const setGridActive = useReportStore2((state) => state.setGridActive)

    const [gridActive, reportSaved] = useReport2Fields((s) => [s.gridActive, s.reportSaved])

    const undo = useHistoryReportStore((state) => state.undo)
    const redo = useHistoryReportStore((state) => state.redo)
    const futureStates = useHistoryReportStore((state) => state.futureStates)
    const pastStates = useHistoryReportStore((state) => state.pastStates)
    const pause = useHistoryReportStore((state) => state.pause)
    const resume = useHistoryReportStore((state) => state.resume)

    const settingsPopupRef = useRef<IReportSettingsPopup>(null)

    const haveUnsavedChanges = useMemo(
        () => (futureStates.length !== 0 || pastStates.length !== 0) && !reportSaved,
        [futureStates, pastStates, reportSaved]
    )

    useEffect(() => {
        window.onbeforeunload = () => {
            return haveUnsavedChanges ? true : undefined
        }
    }, [haveUnsavedChanges])

    useEffect(() => {
        if (editMode) {
            resume()
        } else {
            pause()
        }
    }, [editMode, resume, pause])

    const handleReportSettingsClick = useCallback(() => {
        settingsPopupRef.current?.showPopup()
        setPopupVisible(true)
    }, [setPopupVisible])

    const editClick = useCallback(() => {
        const newEditMode = !editMode
        setEditMode(newEditMode)
        setAddMode(!newEditMode ? false : addMode)
        setEditModeReport(newEditMode)
    }, [addMode, editMode, setEditModeReport])

    const addClick = useCallback(() => {
        setAddMode(!addMode)
    }, [addMode])

    const gridClick = useCallback(() => {
        setGridActive(!gridActive)
    }, [setGridActive, gridActive])

    const reportBack = useCallback(() => undo(), [undo])

    const reportForward = useCallback(() => redo(), [redo])

    useKeyClick(KEY_MAP.BACKWARDS, reportBack)
    useKeyClick(KEY_MAP.FORWARDS, reportForward)
    useKeyClick(
        KEY_MAP.SAVE,
        useCallback(() => saveReport(), [saveReport])
    )

    return (
        <div className={"report-management " + (editMode ? "report-management-active " : "")}>
            <ReportSettingsPopup ref={settingsPopupRef} />
            <div className={`toolbar-buttons`}>
                <div className="additional-buttons">
                    <Button
                        icon="undo"
                        onClick={reportBack}
                        disabled={pastStates.length === 0}
                    ></Button>
                    <Button
                        icon="redo"
                        onClick={reportForward}
                        disabled={futureStates.length === 0}
                    ></Button>
                    <Button
                        className={gridActive ? "button-active" : ""}
                        icon="mergecells"
                        onClick={gridClick}
                        hint="Uchycování do mřížky 5px."
                    ></Button>
                    <Button icon="save" onClick={saveReport}></Button>
                    <Button icon="preferences" onClick={handleReportSettingsClick}></Button>
                </div>
                <Button
                    onClick={editClick}
                    icon="edit"
                    className={`edit-button ${editMode ? "button-active" : ""}`}
                ></Button>
            </div>
            <div className={`bottom-menu-outer ${editMode ? "bottom-menu-outer-active" : ""}`}>
                <div
                    className={"bottom-menu-inner " + (addMode ? "bottom-menu-inner-active " : "")}
                >
                    <div className="add-button-outer">
                        <Button icon="add" className={`add-button `} onClick={addClick} />
                    </div>
                    <Library />
                </div>
            </div>
            <Prompt
                when={haveUnsavedChanges}
                message="Máte neuložené změny, opravdu chcete opustit stránku?"
            />
        </div>
    )
}

export default memo(ReportManagement)
