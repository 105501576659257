import { memo } from "react"
import { useAppStore } from "../stores/AppStore"

type Props = {
    children: JSX.Element | Array<JSX.Element | undefined>
}

function AdminComponent({ children }: Props) {
    const isAdmin = useAppStore((state) => state.currentUser?.admin)

    if (isAdmin) {
        return <>{children}</>
    }
    return null
}

export default memo(AdminComponent)
