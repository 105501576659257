import { createContext, memo, useContext, useMemo } from "react"
import { useFunctions } from "../../functions/Functions"
import { IComponentParent } from "../../types/BaseTypes"
import { IFunction } from "../../types/FunctionsType"
import { standardWarning } from "../../utils/NotifyUtils"
import { EvaluatorContext } from "./EvaluatorContext"
import { VariablesContext } from "./VariablesContext"

export type ExecuteParams = {
    guid: string
    params: Array<any> | undefined
    functionNotFound?: () => void
}

export interface IFunctionsContext {
    executeFunction: (params: ExecuteParams) => void
    getAvailableFunctions(): Array<IFunction>
}

export const FunctionsContext = createContext<IFunctionsContext | undefined>(undefined)

type Props = {
    children: JSX.Element
    component: IComponentParent
    name?: string
}

export const FunctionsProvider = memo(({ children, component, name }: Props) => {
    const parentContext = useContext(FunctionsContext)
    const variablesContext = useContext(VariablesContext)
    const evaluatorContext = useContext(EvaluatorContext)
    const evaluate = evaluatorContext?.evaluate
    const setVariableByGuid = variablesContext?.setVariableByGuid

    const functionsParent = useMemo(() => ({ name, evaluate, setVariableByGuid }), [
        name,
        evaluate,
        setVariableByGuid
    ])
    const execute = useFunctions(component, functionsParent)

    const functions = component.functions

    const context = useMemo(
        () => ({
            executeFunction: (params: ExecuteParams) => {
                execute({
                    ...params,
                    functionNotFound: () => {
                        if (parentContext) {
                            parentContext.executeFunction(params)
                        } else {
                            standardWarning(
                                "Funkci se nepodařilo dohledat a spustit. Guid: " + params.guid
                            )
                            return undefined
                        }
                    }
                })
            },
            getAvailableFunctions: (): IFunction[] => {
                const result = functions ?? []
                const fromParent = parentContext?.getAvailableFunctions()
                if (fromParent) {
                    return fromParent.concat(result)
                }
                return result
            }
        }),
        [parentContext, execute, functions]
    )

    return <FunctionsContext.Provider value={context}>{children}</FunctionsContext.Provider>
})
